define("adept-iq/pods/dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m7DJWYgW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"dashboard-wrapper\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"isWidgetMinified\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"maximize-widget\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \\n  \"],[7,\"div\"],[11,\"class\",\"tile-dashboard-wrapper\"],[9],[0,\"\\n    \"],[1,[27,\"tile-dashboard\",null,[[\"dashboard\"],[[23,[\"workspace\",\"dashboard\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n  \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/dashboard/template.hbs"
    }
  });

  _exports.default = _default;
});