define("adept-iq/models/segment", ["exports", "ember-data", "adept-iq/config/environment", "moment"], function (_exports, _emberData, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  const NOSHOW_STOP_STATUS = 'noshow';

  var _default = Model.extend({
    promiseTime: attr('date'),
    anchor: attr('string'),
    fare: attr('number'),
    travelMode: attr('string'),
    segmentType: attr('number'),
    noSharing: attr('boolean'),
    legOrdinal: attr('number'),
    pick: belongsTo('segment-stop', {
      inverse: 'pickSegment'
    }),
    drop: belongsTo('segment-stop', {
      inverse: 'dropSegment'
    }),
    fareType: belongsTo('fare-type'),
    leg: belongsTo('leg'),
    trips: hasMany('trip'),
    stops: Ember.computed.collect('pick.content', 'drop.content'),
    rider: Ember.computed.alias('leg.rider.content'),
    tripStartTime: Ember.computed('pick.plannedTime', 'leg.requestedTime', 'promiseTime', function () {
      const pickTime = this.get('pick.content.plannedTime');
      return pickTime ? pickTime : this.get('promiseTime');
    }),
    tripEndTime: Ember.computed('drop.plannedTime', 'leg.requestedTime', 'promiseTime', function () {
      const dropTime = this.get('drop.content.plannedTime');
      return dropTime ? dropTime : this.get('promiseTime');
    }),
    formattedPromiseTime: Ember.computed('promiseTime', 'leg.requestedTime', function () {
      if (Ember.isNone(Ember.get(this, 'promiseTime'))) {
        return (0, _moment.default)(Ember.get(this, 'leg.requestedTime')).format(_environment.default.dateTimeFormat.dateTimeMoment);
      }

      return (0, _moment.default)(Ember.get(this, 'promiseTime')).format(_environment.default.dateTimeFormat.dateTimeMoment);
    }),
    location: Ember.computed('pick.location', 'drop.location', function () {
      const location = [];
      const start = [this.get('pick.location.lat'), this.get('pick.location.lng')];
      if (Ember.isPresent(start[0]) && Ember.isPresent(start[1])) location.push(start);
      const end = [this.get('drop.location.lat'), this.get('drop.location.lng')];
      if (Ember.isPresent(end[0]) && Ember.isPresent(end[1])) location.push(end);
      return location;
    }),
    // the trip segment does not matter if it's read in reverse or in order as a line segment to any user doesn't indicate a start or end
    // currently the trip only uses an arrow to indicate the correct direction of a P -> D, it'd be easier to reverse the points
    // to indicate the correct polyline for the trips
    // If we ever need to do something more intense pattern wise, it'd be good to refactor patterns in a better way for all poly lines / zones
    polyline: Ember.computed('pick.place.location.{lat,lng}', 'drop.place.location.{lat,lng}', 'trip.dropStopPoint.status', function () {
      const dropStopPointStatus = this.get('trip.dropStopPoint.status');
      if (dropStopPointStatus === NOSHOW_STOP_STATUS) return null;
      const pickLat = this.get('pick.place.location.lat');
      const pickLng = this.get('pick.place.location.lng');
      const dropLat = this.get('drop.place.location.lat');
      const dropLng = this.get('drop.place.location.lng');

      if ([pickLat, pickLng, dropLat, dropLng].any(Ember.isNone)) {
        return null;
      }

      return [[dropLat, dropLng], [pickLat, pickLng]];
    })
  });

  _exports.default = _default;
});