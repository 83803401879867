define("adept-iq/adapters/activity-log", ["exports", "adept-iq/adapters/-avlm-avlmService"], function (_exports, _avlmAvlmService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _avlmAvlmService.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    getActivityLogs(query) {
      const session = this.get('session');
      var url = this.buildURL('event', null, null, 'query', query);
      return this.get('ajax').post(url, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: query
      });
    },

    createUserRouteActivity(userInfo, routeInfo) {
      const session = this.get('session');
      const json = {
        data: {
          type: 'event',
          attributes: {
            eventType: 'UE',
            actionType: 'userAddedEntry',
            routeId: routeInfo.get('context.route.id'),
            scheduleId: routeInfo.get('context.route.schedule.id'),
            userId: userInfo.content.userId,
            vehicleId: routeInfo.get('context.route.currentRoute.vehicle.id'),
            time: userInfo.content.actualTime,
            createdAt: userInfo.content.actualTime,
            receivedAt: userInfo.content.actualTime,
            alertFlag: false,
            alertFormat: null,
            alertFlagDelete: false,
            alertFlagInsert: false,
            deleteAlertActionTypes: null,
            eventLog: {
              'userAddedEntry': [{
                activity: userInfo.content.activity,
                loggingType: 'route',
                details: userInfo.content.details
              }]
            }
          }
        }
      };
      var url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    createUserTripActivity(userInfo, tripInfo) {
      const session = this.get('session');
      const json = {
        data: {
          type: 'event',
          attributes: {
            eventType: 'UE',
            actionType: 'userAddedEntry',
            routeId: tripInfo.get('context.trip.currentRoute.id'),
            tripId: tripInfo.get('context.trip.id'),
            stopPointId: tripInfo.get('context.trip.currentRoute.stopPoints.id'),
            scheduleId: tripInfo.get('context.trip.schedule.id'),
            userId: userInfo.content.userId,
            vehicleId: tripInfo.get('context.trip.currentRoute.vehicle.id'),
            time: userInfo.content.actualTime,
            createdAt: userInfo.content.actualTime,
            receivedAt: userInfo.content.actualTime,
            alertFlag: false,
            alertFormat: null,
            alertFlagDelete: false,
            alertFlagInsert: false,
            deleteAlertActionTypes: null,
            eventLog: {
              'userAddedEntry': [{
                activity: userInfo.content.activity,
                loggingType: 'trip',
                details: userInfo.content.details
              }]
            }
          }
        }
      };
      var url = this.buildURL('event');
      return this.get('ajax').post(url, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    }

  });

  _exports.default = _default;
});