define("adept-iq/classes/markers/vehicles/base", ["exports", "adept-iq/classes/markers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // vehicles need to render on top of everything else
  const Z_INDEX_OFFSET = 1000;

  var _default = _base.default.extend({
    label: 'Vehicle',
    style: Ember.computed.readOnly('vehicleStyle'),
    zIndexOffset: Z_INDEX_OFFSET,
    // local properties
    otp: null,
    isMoving: false,
    isBreakdown: false,
    isEmergencyVehicle: false,
    isApprovedEmergency: false,
    isRoadSupervisor: false,
    isLoggedInRoadSupervisor: false,
    vehicleStyle: Ember.computed('otp', 'isMoving', 'isBreakdown', 'isEmergencyVehicle', 'isApprovedEmergency', 'isRoadSupervisor', 'isLoggedInRoadSupervisor', function () {
      const otp = this.get('otp');
      const isMoving = this.get('isMoving');
      const isBreakdown = this.get('isBreakdown');
      const isEmergency = this.get('isEmergencyVehicle');
      const isApprovedEmergency = this.get('isApprovedEmergency');
      const isRoadSupervisor = this.get('isRoadSupervisor');
      const isLoggedInRoadSupervisor = this.get('isLoggedInRoadSupervisor');
      let style = 'vehicle';

      if (isBreakdown) {
        style += 'Breakdown';
        return style;
      }

      if (isMoving) {
        style += 'Driving';
      } // TODO: are both of these cases needed?


      if (isEmergency || isApprovedEmergency) {
        style += 'Emergency';
      }

      if (isRoadSupervisor && isLoggedInRoadSupervisor) {
        style += 'LoggedRoadSupervisor';
      } else if (isRoadSupervisor) {
        style += 'RoadSupervisor';
      }

      switch (otp) {
        case 'D':
          style += 'Danger';
          break;

        case 'L':
          style += 'Late';
          break;

        default:
          break;
      }

      return style;
    })
  });

  _exports.default = _default;
});