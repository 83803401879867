define("adept-iq/classes/map-contexts/global", ["exports", "adept-iq/classes/map-contexts/layered", "ember-inflector", "adept-iq/config/environment"], function (_exports, _layered, _emberInflector, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_LATLNG = [_environment.default.tomtom.search.center.lat, _environment.default.tomtom.search.center.lon];

  var _default = _layered.default.extend({
    // must be passed on init
    activeContext: null,
    store: null,
    workspace: null,
    polylineData: Ember.computed.readOnly('activeContext.implicitData'),
    markerData: Ember.computed.readOnly('activeContext.implicitData'),
    polygonData: Ember.computed.readOnly('activeContext.implicitData'),
    shapeData: Ember.computed.readOnly('activeContext.implicitData'),
    mapStateProvider: Ember.computed.readOnly('workspace.dashboard'),
    centerLat: Ember.computed('activeContext.checkedItems.[]', function () {
      const lats = this.get('activeContext.implicitStops').mapBy('lat').compact();
      if (Ember.isEmpty(lats)) return DEFAULT_LATLNG[0];
      return (Math.min(...lats) + Math.max(...lats)) / 2;
    }),
    centerLng: Ember.computed('activeContext.checkedItems.[]', function () {
      const lngs = this.get('activeContext.implicitStops').mapBy('lng').compact();
      if (Ember.isEmpty(lngs)) return DEFAULT_LATLNG[1];
      return (Math.min(...lngs) + Math.max(...lngs)) / 2;
    }),
    layeredRecords: Ember.computed('activeContext.{implicitData,emergencyVehicles}', 'layers', function () {
      return this.get('layers').reduce((obj, layer) => {
        obj[layer.id] = this._recordsForLayer(layer);
        return obj;
      }, {});
    }),

    init() {
      this._super(...arguments);

      const activeContext = this.get('activeContext');
      const store = this.get('store');

      this.focusActiveContext = e => {
        // this `iq` object gets attached by the marker/polyline data join
        if (!e.target.iq) return;
        const {
          isActive,
          modelName,
          modelId
        } = e.target.iq;
        if (!isActive) return;
        const record = store.peekRecord(modelName, modelId);
        if (!record) return;
        activeContext.replaceCheckedRecordsByType([record]);
      };
    },

    destroy() {
      this.focusActiveContext = null;

      this._super(...arguments);
    },

    _recordsForLayer(layer) {
      switch (layer.id) {
        case 'stops':
          return this._recordsForStopsLayer(layer);

        case 'vehicles':
          return this._recordsForVehicleLayer(layer);

        default:
          return this._recordsForOtherLayer(layer);
      }
    },

    _recordsForStopsLayer(layer) {
      const iqStops = this._recordsForOtherLayer(layer); // if it is a trip active context
      // only include the pick and drop stops


      if (this.get('activeContext').tripActiveContext()) {
        return iqStops.filter(iqStop => {
          return ['pick', 'drop'].includes(iqStop.get('stopType'));
        });
      }

      return iqStops;
    },

    _recordsForVehicleLayer(layer) {
      const iqVehicles = this._recordsForOtherLayer(layer);

      const emergencyVehicles = this.get('activeContext.emergencyVehicles');

      if (!Ember.isEmpty(emergencyVehicles)) {
        // emergency vehicles are always visible; this should be a diff. layer
        iqVehicles.addObjects(emergencyVehicles);
      }

      return iqVehicles;
    },

    _recordsForOtherLayer(layer) {
      if (!layer.isVisible) return [];
      const structuredData = this.get('activeContext.structuredImplicitData'); // TODO: read `workspace-context` structured data instead of store

      const records = layer.isActive ? structuredData[layer.modelName] : this.get('store').peekAll(layer.modelName);
      if (Ember.isEmpty(records)) return [];
      return records;
    },

    isSearchAllowed: Ember.computed.bool('workspace.configPermissions.map.searchAllowed'),
    isFilterAllowed: Ember.computed.bool('workspace.configPermissions.map.filterAllowed'),
    contextMenuOptions: Ember.computed('workspace.configPermissions.map.singleActions.[]', function () {
      const contextMenuOptions = [this.get('editContextMenuOption')];
      const permittedSingleActions = this.get('workspace.configPermissions.map.singleActions');
      if (Ember.isEmpty(permittedSingleActions)) return [];
      if (permittedSingleActions.includes('all')) return contextMenuOptions;
      return contextMenuOptions.filter(({
        id
      }) => {
        return permittedSingleActions.includes(id);
      });
    }),
    editContextMenuOption: Ember.computed('', function () {
      return {
        id: 'edit',
        name: 'Edit',
        action: model => {
          const inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
          const modelName = inflector.pluralize(model.constructor.modelName); // TODO: this was the existing behaviour, but it is insufficient;
          // generally, iq-models are passed in, which results in an incorrect
          // widget component name

          const component = `iq-widgets/${modelName}-form-widget`;
          this.get('editModal').open(component, [model]);
        }
      };
    }),

    markerObjectFor()
    /* record */
    {
      const marker = this._super(...arguments);

      if (!marker.get('_dblclickHandlerInitialized')) {
        marker.on('dblclick', this.focusActiveContext);
        marker.set('_dblclickHandlerInitialized', true);
      }

      return marker;
    },

    polylineObjectFor() {
      const polyline = this._super(...arguments);

      if (!polyline.get('_dblclickHandlerInitialized')) {
        polyline.on('dblclick', this.focusActiveContext);
        polyline.set('_dblclickHandlerInitialized', true);
      }

      return polyline;
    }

  });

  _exports.default = _default;
});