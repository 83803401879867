define("adept-iq/models/avlm-route", ["exports", "ember-data", "adept-iq/mixins/otp-label"], function (_exports, _emberData, _otpLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend(_otpLabel.default, {
    otp: attr('string'),
    otpValue: attr('number'),
    status: attr('string'),
    trackingId: attr('string'),
    plannedStartTime: attr('date'),
    plannedEndTime: attr('date'),
    actualStartTime: attr('date'),
    actualEndTime: attr('date'),
    providerName: attr('string'),
    provider: belongsTo(),
    etaRoute: belongsTo('etanav-route'),
    navigationPolyline: belongsTo('polyline'),
    vehicle: belongsTo('avlm-vehicle'),
    avlmVehicle: Ember.computed.alias('vehicle'),
    avlmSchedule: belongsTo('avlm-schedule'),
    startAddress: belongsTo('avlm-address'),
    endAddress: belongsTo('avlm-address'),
    avlmRouteVehicleDrivers: hasMany('avlm-route-vehicle-driver'),
    clusters: hasMany('avlm-cluster'),
    trips: hasMany('avlm-trip'),
    cannedMessages: hasMany('avlm-canned-message'),
    emergencyMessages: hasMany('avlm-emergency-message'),
    avlmRouteBreaks: hasMany('avlm-route-break'),
    stopPoints: hasMany('avlm-stop-point'),

    allStopsPerformedAvlmlite() {
      const orderedStops = this.get('orderedStopsWithAllBreaks'); // empty route

      if (!orderedStops || orderedStops.length === 0) return false;
      const allStopsPerformed = orderedStops.every(stop => stop.get('status') === 'performed');
      return allStopsPerformed;
    },

    statusLabel: Ember.computed('status', function () {
      const status = this.status;
      let statusLabel = '';

      switch (status) {
        case 'A':
          statusLabel = 'Active';
          break;

        case 'C':
          statusLabel = 'Completed';
          break;

        case 'P':
          statusLabel = 'Pending';
          break;

        case 'S':
          statusLabel = 'Assigned';
          break;

        default:
          statusLabel = status;
          break;
      }

      return statusLabel;
    }),
    latestVehicle: Ember.computed.alias('latestRouteVehicleDriver.vehicle'),
    latestDriver: Ember.computed.alias('latestRouteVehicleDriver.driver'),
    latestRouteVehicleDriver: Ember.computed('avlmRouteVehicleDrivers.@each.startTime', function () {
      const routeVehicleDrivers = this.get('avlmRouteVehicleDrivers');
      if (!routeVehicleDrivers) return [];
      return routeVehicleDrivers.sortBy('startTime').get('lastObject');
    }),
    pullinGarageStop: Ember.computed('stopPoints.[]', function () {
      return this.get('stopPoints').find(stop => stop.get('stopType') === 'pullinGarage');
    }),
    pulloutGarageStop: Ember.computed('stopPoints.[]', function () {
      return this.get('stopPoints').find(stop => stop.get('stopType') === 'pulloutGarage');
    }),
    orderedStopsWithAllBreaks: Ember.computed('orderedClusters.@each.orderedStops', function () {
      const pulloutGarage = this.get('pulloutGarageStop');
      const pullinGarage = this.get('pullinGarageStop');
      const stops = pulloutGarage ? [pulloutGarage] : [];
      const clusters = this.get('clusters');
      const stopBreaks = this.get('avlmRouteBreaks').map(routeBreak => routeBreak.get('avlmStopPoint.content'));
      let ordered = clusters.reduce((arr, cluster) => {
        let orderedStops = cluster.get('orderedStops');
        orderedStops = orderedStops.filter(stop => stop.get('parentType') === 'trip');
        arr.push(...orderedStops);
        return arr;
      }, stops);

      if (stopBreaks) {
        ordered.push(...stopBreaks);
        ordered = ordered.sortBy('realRouteIndex');
      }

      if (ordered) {
        stops.push(...ordered);
      }

      if (pullinGarage) {
        stops.push(pullinGarage);
      }

      return stops;
    }),
    orderedStopsWithGaragesAndBreaks: Ember.computed('etaRoute.etaStops.@each.{eta,avlmStopPoint}', 'orderedStopsWithAllBreaks', function () {
      const etaStops = this.get('etaRoute.etaStops');

      if (Ember.isEmpty(etaStops)) {
        return this.get('orderedStopsWithAllBreaks');
      }

      return etaStops.sortBy('eta').mapBy('avlmStopPoint');
    }),
    tripStartTime: Ember.computed('trips.@each.tripStartTime', 'plannedStartTime', function () {
      const trips = this.get('trips');
      let min = this.get('plannedStartTime') ? this.get('plannedStartTime').getTime() : null;
      trips.forEach(trip => {
        if (trip.get('tripStartTime')) {
          min = Math.min(trip.get('tripStartTime').getTime(), min);
        }
      });
      return min ? new Date(min) : null;
    }),
    tripEndTime: Ember.computed('trips.@each.tripEndTime', 'actualEndTime', 'plannedEndTime', function () {
      const trips = this.get('trips');
      let max = this.get('actualEndTime') ? this.get('actualEndTime').getTime() : null;
      const plannedEndTime = this.get('plannedEndTime') ? this.get('plannedEndTime').getTime() : null;

      if (!max) {
        trips.forEach(trip => {
          if (trip.get('tripEndTime')) {
            max = Math.max(trip.get('tripEndTime').getTime(), max);
          }
        });
      }

      if (!max) {
        max = plannedEndTime;
      }

      return max ? new Date(max) : null;
    }),
    plannedPolylinePoints: Ember.computed('orderedStopsWithGaragesAndBreaks.@each.polylinePoints', function () {
      const stopPoints = this.get('orderedStopsWithGaragesAndBreaks');
      if (Ember.isEmpty(stopPoints)) return []; // this returns an array of arrays; it _could_ be flattened, but tomtom
      // is ok with the data in this format, and this allows for discontiguity

      return stopPoints.reduce((arr, stopPoint) => {
        const points = stopPoint.get('polylinePoints');
        if (Ember.isEmpty(points)) return arr;
        arr.push(points);
        return arr;
      }, []);
    }),
    otpValueWithSign: Ember.computed('otp', 'otpValue', function () {
      const otp = this.get('otp');
      const otpValue = this.get('otpValue');
      const sign = otp === 'O' || otp === 'E' ? '-' : '+';

      if (otpValue) {
        return `${sign}${otpValue}`;
      }

      return '';
    })
  });

  _exports.default = _default;
});