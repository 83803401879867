define("adept-iq/config/mapped-permIds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // version:5
  const fullOperations = {
    configureWorkspace: 1,
    savePrivateWorkspace: 2,
    saveWorkspace: 3,
    saveAsWorkspace: 3,
    deletePrivateWorkspace: 4,
    deletePublicWorkspace: 5,
    deleteWorkspace: 6,
    openWorkspace: 7,
    openPrivateWorkspace: 8,
    showRoutes: 9,
    showStops: 10,
    showTrips: 11,
    showMessages: 12,
    showVehicles: 13,
    showAlerts: 14,
    showMaps: 15,
    showDrivers: 16,
    showRoles: 17,
    showZones: 18,
    showSubscriptions: 19,
    downloadRoutes: 20,
    downloadStops: 21,
    downloadTrips: 22,
    downloadVehicles: 23,
    downloadDrivers: 24,
    showSystemConfig: 25,
    newRole: 26,
    editRole: 27,
    deleteRole: 28,
    newUser: 29,
    editUser: 30,
    resetUserPass: 31,
    enableDisableUser: 32,
    timeline: 33,
    createSameDayReplacement: 34,
    emergencyAcknowledge: 35,
    emergencyResolve: 36,
    emergencyCancel: 37,
    reportBreakdown: 38,
    reportRescue: 39,
    createRoute: 40,
    editRoute: 41,
    deleteRoute: 42,
    lockUnlockRoute: 43,
    limitRoute: 44,
    startRoute: 45,
    endRoute: 46,
    createFuture: 47,
    createSameDay: 48,
    cancelFuture: 49,
    cancelSameDay: 50,
    editFuture: 51,
    autoTransferTrip: 52,
    transferTrip: 53,
    waitlist: 54,
    noshow: 55,
    editPast: 56,
    editSameDay: 57,
    markStopArrived: 58,
    markStopDeparted: 59,
    noShowTrip: 60,
    unperformStop: 61,
    navigateStop: 62,
    editStop: 63,
    reorderStops: 64,
    addBreak: 65,
    editBreak: 66,
    deleteBreak: 67,
    newPassenger: 68,
    editPassenger: 69,
    newVehicle: 70,
    editVehicle: 70,
    newdriver: 70,
    editdriver: 70,
    autoReschedule: 71,
    resetDeviceAction: 72,
    vehicleBreakdown: 73,
    playbackVehicle: 74,
    cannedMessageToDriver: 75,
    freeTypeMessageToDriver: 76,
    messageToDispatch: 77,
    newIncident: 78,
    deleteIncident: 79,
    editIncident: 80,
    viewIncident: 81,
    editPlaces: 82,
    newService: 83,
    editService: 83,
    navigateMap: 84,
    addressPinpoint: 85,
    closeSchedule: 86,
    generateSchedule: 87,
    reoptimizeSchedule: 88,
    manageRouteSchedule: 89,
    SignInADEPTIQ: 90,
    showUsers: 91,
    showPassengers: 92,
    downloadMessages: 93,
    downloadAlerts: 94,
    downloadRoles: 95,
    downloadUsers: 96,
    downloadsubscriptions: 97,
    downloadPassengers: 98,
    downloadZones: 99,
    assignVehicleDriver: 100,
    approveNoShowRequest: 101,
    denyNoShowRequest: 101,
    overrideTripCapacityLimits: 102,
    newSubscription: 103,
    editSubscription: 104,
    activityLogUser: 105,
    activityLogRoute: 106,
    activityLog: 106,
    activityLogVehicle: 106,
    showSchedule: 107,
    downloadSchedule: 108,
    editContractManagement: 109,
    assignVehicleOverCapacity: 110,
    downloadMaps: 0,
    sendRouteMessage: 0,
    navigateIntercept: 0,
    sendMessageVehicle: 0,
    showAllMessage: 0,
    hideMessages: 0,
    readMessages: 0,
    sendMessageDriver: 0,
    showAllAlerts: 0,
    hideAlerts: 0
  };
  const operations = fullOperations;
  var _default = operations;
  _exports.default = _default;
});