define("adept-iq/models/trip-stop", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    plannedDistanceToNext: attr('string'),
    // in miles
    plannedTravelTimeToNext: attr('string'),
    // in minutes
    plannedEta: attr('date'),
    plannedRouteOrdinal: attr('number'),
    stopPoint: belongsTo('stop-point', {
      inverse: 'tripStop'
    }),
    pickTripStop: belongsTo('trip', {
      inverse: 'pick'
    }),
    dropTripStop: belongsTo('trip', {
      inverse: 'drop'
    }),
    segmentStop: belongsTo('segment-stop', {
      inverse: 'tripStop'
    })
  });

  _exports.default = _default;
});