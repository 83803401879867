define("adept-iq/pods/components/iq-widgets/subscriptions-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Read Only',
      fields: [{
        id: 'id',
        type: 'text',
        label: 'ID',
        valuePath: 'id',
        editable: false
      }]
    }, {
      title: 'Editable',
      fields: [{
        id: 'anchor',
        type: 'enum',
        label: 'Anchor',
        valuePath: 'anchor',
        extra: {
          options: ['pick', 'drop']
        }
      }, {
        id: 'purpose',
        type: 'text',
        label: 'Purpose',
        valuePath: 'purpose'
      }, {
        id: 'maximumOccurrences',
        type: 'number',
        label: 'Max Occurences',
        valuePath: 'maximumOccurrences'
      }, {
        id: 'requestTime',
        type: 'timeflatpickr',
        label: 'Request Time',
        valuePath: 'requestTime',
        editable: true
      }]
    }, {
      title: 'Fare',
      fields: [{
        id: 'type',
        type: 'searchable-enum',
        label: 'Payment Type',
        valuePath: 'fareType',
        editable: true,
        extra: {
          optionModelName: 'fare-type',
          optionSearchPath: 'name',
          optionSelectedPath: '',
          optionIncludes: '',
          optionSearchEnabled: false
        }
      }, {
        id: 'fare',
        type: 'number',
        label: 'Amount',
        valuePath: 'fare',
        editable: true
      }]
    }, {
      title: 'Origin',
      fields: [{
        id: 'origins',
        type: 'locations',
        label: '',
        valuePath: 'origin',
        modelName: 'place',
        isMultiType: true,
        extra: {
          aliasPath: 'address.content.alias',
          notesPath: 'address.content.notes',
          premisePath: 'address.content.premise',
          streetNumberPath: 'address.content.streetNumber',
          streetNamePath: 'address.content.streetName',
          localityPath: 'address.content.city',
          regionPath: 'address.content.state',
          subRegionPath: 'address.content.county',
          postalCodePath: 'address.content.postalCode',
          countryPath: 'address.content.country',
          tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
          useOptionRecord: true,
          streetAddressPath: 'address.content.streetAddress',
          locationsPath: 'address.locations.content.firstObject.latlng'
        }
      }]
    }, {
      title: 'Destination',
      fields: [{
        id: 'destinations',
        type: 'locations',
        label: '',
        valuePath: 'destination',
        modelName: 'place',
        isMultiType: true,
        extra: {
          aliasPath: 'address.content.alias',
          notesPath: 'address.content.notes',
          premisePath: 'address.content.premise',
          streetNumberPath: 'address.content.streetNumber',
          streetNamePath: 'address.content.streetName',
          localityPath: 'address.content.city',
          regionPath: 'address.content.state',
          subRegionPath: 'address.content.county',
          postalCodePath: 'address.content.postalCode',
          countryPath: 'address.content.country',
          tomtomFormattedAddressPath: 'address.content.tomtomFormattedAddress',
          useOptionRecord: true,
          streetAddressPath: 'address.content.streetAddress',
          locationsPath: 'address.locations.content.firstObject.latlng'
        }
      }]
    }, {
      title: 'Travel Needs',
      fields: [{
        id: 'travelNeeds',
        type: 'bs-travel-needs',
        label: '',
        valuePath: 'subscriptionTravelNeeds',
        modelName: 'subscription-travel-need',
        isMultiType: true,
        extra: {
          travelNeedTypeModelName: 'travel-need-type',
          passengerTypeModelName: 'passenger-type',
          countPath: 'count',
          travelNeedTypePath: 'travelNeedType',
          passengerTypePath: 'passengerType'
        }
      }]
    }, {
      title: 'Subscription',
      fields: [{
        id: 'subscriptions',
        type: 'bs-subscriptions',
        label: '',
        valuePath: 'recurrencePatterns',
        modelName: 'subscription-recurence-pattern',
        isMultiType: true,
        extra: {
          recurringPath: 'recurring',
          dailyConfigPath: 'type',
          selectedWOMPath: 'selectedWOM',
          startDateTimePath: 'subscriptionStartDate',
          endDateTimePath: 'subscriptionEndDate',
          sundayPath: 'sunday',
          mondayPath: 'monday',
          tuesdayPath: 'tuesday',
          wednesdayPath: 'wednesday',
          thursdayPath: 'thursday',
          fridayPath: 'friday',
          saturdayPath: 'saturday',
          excludeStartDateTimePath: 'exclusionStartDate',
          excludeEndDateTimePath: 'exclusionEndDate',
          maxOccurencesPath: 'subscriptionMaxOccurences',
          separationCountPath: 'separationCount',
          dayOfMonthPath: 'dayOfMonth'
        }
      }]
    }]
  };
  _exports.default = _default;
});