define("adept-iq/pods/components/side-drawer/perform-stop/break/locations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MUSBghEo",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"cell edit\"],[11,\"data-test-address-field\",\"\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"options\",\"disabled\",\"search\",\"selected\",\"onkeydown\",\"onchange\"],[\"data-test-power-select\",[23,[\"addressOptions\"]],[23,[\"disabled\"]],[27,\"perform\",[[23,[\"searchAddress\"]]],null],[27,\"readonly\",[[27,\"get\",[[23,[\"address\"]],[23,[\"tomtomFormattedAddressPath\"]]],null]],null],[27,\"action\",[[22,0,[]],\"handleKeydown\",[23,[\"address\"]],[23,[\"addressType\"]]],null],[27,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"onAddressSelected\",[23,[\"address\"]],[23,[\"addressType\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[22,1,[\"address\",\"freeformAddress\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"cell button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onPickOnMap\",[23,[\"address\"]]],null]],[12,\"disabled\",[21,\"disabled\"]],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-map-marker\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer/perform-stop/break/locations/template.hbs"
    }
  });

  _exports.default = _default;
});