define("adept-iq/pods/components/iq-widgets/driver-breaks-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/driver-breaks-form-widget/config", "adept-iq/utils/unwrapProxy", "moment"], function (_exports, _component, _config, _unwrapProxy, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FIXED_BREAK_TYPE = 'fixed';

  var _default = _component.default.extend({
    classNames: ['driver-break-form-widget'],
    editableSections: _config.default.editableSections,
    editModal: Ember.inject.service(),
    notifications: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const route = (0, _unwrapProxy.unwrapProxy)(record.get('route'));
        const estimatedStartMoment = (0, _moment.default)(record.get('requestTime'));
        const estimatedEndMoment = (0, _moment.default)(record.get('projectedEndDate'));
        const routePlannedStartTime = (0, _moment.default)(route.get('plannedStartTime'));
        const routeActualEndTime = route.get('actualEndTimestamp') ? (0, _moment.default)(route.get('actualEndTimestamp')) : null;
        const routeOrdinal = (0, _unwrapProxy.unwrapProxy)(route.get('dispatchRoute')).findNewBreakRoutePosition(record);

        if (routeOrdinal === -1) {
          valid = false;
          this.get('editModal.errors').pushObject('Cannot find a route position for the break start time.');
        }

        record.set('plannedRouteOrdinal', routeOrdinal);

        if (Ember.isNone(record.get('breakType.id'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Must select a break type.');
        }

        if (record.get('breakType.id') === FIXED_BREAK_TYPE && Ember.isEmpty(record.get('place.location.lat'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Fixed break type must have an address.');
        }

        if (estimatedStartMoment.isSameOrAfter(estimatedEndMoment)) {
          valid = false;
          this.get('editModal.errors').pushObject('Break start time must be before Break end time.');
        }

        if (estimatedStartMoment.isBefore(routePlannedStartTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Break start time must be after the route\'s planned start time.');
        }

        if (routeActualEndTime) {
          if (estimatedEndMoment.isAfter(routeActualEndTime)) {
            valid = false;
            this.get('editModal.errors').pushObject('Break end time must be before the route\'s actual end time.');
          }
        }

        record.set('estimatedStart', estimatedStartMoment.toDate());
        record.set('estimatedEnd', estimatedEndMoment.toDate());
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        const lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          const records = this.get('editableRecords') || [];
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          const title = this.get('title');
          this.get('service').apply(title);
        }
      }

    }
  });

  _exports.default = _default;
});