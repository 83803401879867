define("adept-iq/config/widget-types", ["exports", "adept-iq/config/mapped-permIds"], function (_exports, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.widgetTypesHash = _exports.box4WidgetType = _exports.box3WidgetType = _exports.box2WidgetType = _exports.box1WidgetType = _exports.scatterChartWidgetType = _exports.gaugeWidgetType = _exports.barChartWidgetType = _exports.donutChartWidgetType = _exports.rolesWidgetType = _exports.usersWidgetType = _exports.alertsWidgetType = _exports.zonesWidgetType = _exports.metricsWidgetType = _exports.boxWidgetType = _exports.vehiclesWidgetType = _exports.driversWidgetType = _exports.passengersWidgetType = _exports.subscriptionsWidgetType = _exports.mapWidgetType = _exports.tripsWidgetType = _exports.stopsWidgetType = _exports.routesWidgetType = _exports.messagesWidgetType = void 0;
  // this file is for "public" widget config required for listing available
  // widget types and computing widget placements; most other config should be
  // placed in the `config.js` adjacent to the specific widgets' components
  // `name` reflects how the widget appears in a list (e.g. add-widget sidebar).
  // For widget title bar, set `title` in widget's `config.js`.
  // this would be a good place to specify icons for add-widget sidebar
  const messagesWidgetType = {
    id: 'messages',
    name: 'Messages',
    componentName: 'iq-widgets/message-widget',
    permId: _mappedPermIds.default.showMessages,
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.messagesWidgetType = messagesWidgetType;
  const routesWidgetType = {
    id: 'routes',
    name: 'Routes',
    componentName: 'iq-widgets/routes-widget',
    permId: _mappedPermIds.default.showRoutes,
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.routesWidgetType = routesWidgetType;
  const stopsWidgetType = {
    id: 'stops',
    name: 'Stops',
    componentName: 'iq-widgets/stops-widget',
    permId: _mappedPermIds.default.showStops,
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.stopsWidgetType = stopsWidgetType;
  const tripsWidgetType = {
    id: 'trips',
    name: 'Trips',
    componentName: 'iq-widgets/trips-widget',
    permId: _mappedPermIds.default.showTrips,
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.tripsWidgetType = tripsWidgetType;
  const mapWidgetType = {
    id: 'map',
    name: 'Map',
    componentName: 'iq-widgets/map-widget',
    permId: _mappedPermIds.default.showMaps,
    defaultWidth: 3,
    defaultHeight: 3,
    minWidth: 2,
    minHeight: 2
  };
  _exports.mapWidgetType = mapWidgetType;
  const subscriptionsWidgetType = {
    id: 'subscriptions',
    name: 'Subscriptions',
    componentName: 'iq-widgets/subscriptions-widget',
    permId: _mappedPermIds.default.showSubscriptions,
    defaultWidth: 4,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.subscriptionsWidgetType = subscriptionsWidgetType;
  const passengersWidgetType = {
    id: 'passengers',
    name: 'Passenger',
    componentName: 'iq-widgets/passengers-widget',
    permId: _mappedPermIds.default.showPassengers,
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.passengersWidgetType = passengersWidgetType;
  const driversWidgetType = {
    id: 'drivers',
    name: 'Drivers',
    componentName: 'iq-widgets/drivers-widget',
    permId: _mappedPermIds.default.showDrivers,
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.driversWidgetType = driversWidgetType;
  const vehiclesWidgetType = {
    id: 'vehicles',
    name: 'Vehicles',
    componentName: 'iq-widgets/vehicles-widget',
    permId: _mappedPermIds.default.showVehicles,
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  }; // @TODO removing all metric / graphic widgets (temporarly)

  _exports.vehiclesWidgetType = vehiclesWidgetType;
  const boxWidgetType = {
    id: 'b',
    name: 'Box',
    componentName: 'iq-widgets/box-widget',
    permId: _mappedPermIds.default.showBoxes,
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.boxWidgetType = boxWidgetType;
  const metricsWidgetType = {
    id: 'metric',
    name: 'Metrics',
    componentName: 'iq-widgets/metrics-column-widget',
    permId: _mappedPermIds.default.showMetrics,
    defaultWidth: 8,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.metricsWidgetType = metricsWidgetType;
  const zonesWidgetType = {
    id: 'zones',
    name: 'Zones',
    componentName: 'iq-widgets/zones-widget',
    permId: _mappedPermIds.default.showRoles,
    defaultWidth: 3,
    defaultHeight: 3,
    minWidth: 2,
    minHeight: 2
  };
  _exports.zonesWidgetType = zonesWidgetType;
  const alertsWidgetType = {
    id: 'alerts',
    name: 'Alerts',
    componentName: 'iq-widgets/alert-widget',
    permId: _mappedPermIds.default.showAlerts,
    defaultWidth: 6,
    defaultHeight: 2,
    minWidth: 2,
    minHeight: 1
  };
  _exports.alertsWidgetType = alertsWidgetType;
  const usersWidgetType = {
    id: 'users',
    name: 'Users',
    componentName: 'iq-widgets/users-widget',
    permId: _mappedPermIds.default.showUsers,
    defaultWidth: 4,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.usersWidgetType = usersWidgetType;
  const rolesWidgetType = {
    id: 'roles',
    name: 'Roles',
    componentName: 'iq-widgets/roles-widget',
    permId: _mappedPermIds.default.showRoles,
    defaultWidth: 4,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.rolesWidgetType = rolesWidgetType;
  const donutChartWidgetType = {
    id: 'donut',
    name: 'Donut Chart',
    componentName: 'iq-widgets/donut-chart-widget',
    //permId: mappedPermIds.showDonutChart,
    defaultWidth: 3,
    defaultHeight: 2,
    minWidth: 3,
    minHeight: 2
  };
  _exports.donutChartWidgetType = donutChartWidgetType;
  const barChartWidgetType = {
    id: 'bar',
    name: 'Bar Chart',
    componentName: 'iq-widgets/bar-chart-widget',
    //permId: mappedPermIds.showBarChart,
    defaultWidth: 4,
    defaultHeight: 2,
    minWidth: 4,
    minHeight: 2
  };
  _exports.barChartWidgetType = barChartWidgetType;
  const gaugeWidgetType = {
    id: 'g',
    name: 'Gauge',
    componentName: 'iq-widgets/gauge-widget',
    //permId: mappedPermIds.showGaugeChart,
    defaultWidth: 3,
    defaultHeight: 2,
    minWidth: 3,
    minHeight: 2
  };
  _exports.gaugeWidgetType = gaugeWidgetType;
  const scatterChartWidgetType = {
    id: 'scatter-chart',
    name: 'Scatter Chart',
    componentName: 'iq-widgets/scatter-chart-widget',
    //permId: mappedPermIds.showScatterChart,
    defaultWidth: 6,
    defaultHeight: 4,
    minWidth: 4,
    minHeight: 4
  };
  _exports.scatterChartWidgetType = scatterChartWidgetType;
  const box1WidgetType = {
    id: 'box-1',
    name: 'Box 1',
    componentName: 'iq-widgets/box-widget',
    //permId: mappedPermIds.showBox1,
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.box1WidgetType = box1WidgetType;
  const box2WidgetType = {
    id: 'box-2',
    name: 'Box 2',
    componentName: 'iq-widgets/box-widget',
    //permId: mappedPermIds.showBox2,
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.box2WidgetType = box2WidgetType;
  const box3WidgetType = {
    id: 'box-3',
    name: 'Box 3',
    componentName: 'iq-widgets/box-widget',
    //permId: mappedPermIds.showBox3,
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.box3WidgetType = box3WidgetType;
  const box4WidgetType = {
    id: 'box-4',
    name: 'Box 4',
    componentName: 'iq-widgets/box-widget',
    //permId: mappedPermIds.showBox4,
    defaultWidth: 1,
    defaultHeight: 2,
    minWidth: 1,
    minHeight: 1
  };
  _exports.box4WidgetType = box4WidgetType;
  const widgetTypes = [messagesWidgetType, routesWidgetType, stopsWidgetType, tripsWidgetType, mapWidgetType, passengersWidgetType, driversWidgetType, vehiclesWidgetType, boxWidgetType, metricsWidgetType, donutChartWidgetType, barChartWidgetType, gaugeWidgetType, box1WidgetType, box2WidgetType, box3WidgetType, box4WidgetType, alertsWidgetType, scatterChartWidgetType, rolesWidgetType, usersWidgetType, subscriptionsWidgetType, zonesWidgetType];
  const widgetTypesHash = widgetTypes.reduce((obj, widgetType) => {
    obj[widgetType.id] = widgetType;
    return obj;
  }, {});
  _exports.widgetTypesHash = widgetTypesHash;
  var _default = widgetTypes;
  _exports.default = _default;
});