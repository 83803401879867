define("adept-iq/utils/convertDistance", ["exports", "adept-iq/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AVLM_ODOMETER_MILES_RESTRICTION = _exports.convertMilesToMeters = _exports.convertMetersToMiles = _exports.AVLM_ODOMETER_METER_RESTRICTION = void 0;
  const AVLM_ODOMETER_METER_RESTRICTION = 9999999999;
  _exports.AVLM_ODOMETER_METER_RESTRICTION = AVLM_ODOMETER_METER_RESTRICTION;
  const distanceUnit = _environment.default.APP.distanceUnit || 'mi';

  const convertMetersToMiles = distanceInMeters => {
    const odometer = parseInt(distanceInMeters, 10);
    const unit = distanceUnit;

    if (unit === 'mi') {
      return (odometer * 0.000621371).toFixed(0);
    } else if (unit === 'km') {
      return (odometer * 0.001).toFixed(0);
    }

    return odometer;
  };

  _exports.convertMetersToMiles = convertMetersToMiles;

  const convertMilesToMeters = odometerString => {
    const odometer = parseInt(odometerString, 10);

    if (_environment.default.APP.distanceUnit === 'mi') {
      return odometer * 1609.344;
    } else if (_environment.default.APP.distanceUnit === 'km') {
      return odometer * 1000;
    }

    return odometer;
  };

  _exports.convertMilesToMeters = convertMilesToMeters;
  const AVLM_ODOMETER_MILES_RESTRICTION = convertMetersToMiles(AVLM_ODOMETER_METER_RESTRICTION);
  _exports.AVLM_ODOMETER_MILES_RESTRICTION = AVLM_ODOMETER_MILES_RESTRICTION;
});