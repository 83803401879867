define("adept-iq/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONAPIAdapter
  } = _emberData.default;

  var _default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    notifications: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax'),
    authorizer: 'authorizer:sso',

    init() {
      this._super(...arguments);
    },

    headers: Ember.computed('ajaxService.headers', function () {
      const result = this.get('ajaxService.headers');
      return result;
    }),

    normalizeErrorResponse(status, headers, payload) {
      if (payload && typeof payload === 'object' && payload.errors) {
        return payload.errors; // To deal with the API's error messages that do not comply to JSONAPI Standard.
      } else if (Ember.isPresent(payload.message)) {
        const error = {
          status: `${status}`,
          title: 'The backend responded with an error',
          detail: payload.message
        };
        this.get('notifications').info(payload.message);
        return [error];
      }

      return [{
        status: `${status}`,
        title: 'The backend responded with an error',
        detail: `${payload}`
      }];
    },

    pathForType(modelName) {
      return Ember.String.dasherize(modelName);
    },

    ajaxOptions(url, type) {
      const hash = this._super(...arguments); // this._super() handles all but the GET case; in this case, the data
      // object is normally passed straight to $.ajax, but this encodes URI
      // characters like + and -. Instead, we encode params to a string ourself.


      if (hash.data && type === 'GET') {
        hash.data = Object.entries(hash.data).filter(([...value]) => Ember.isPresent(value)).map(([key, value]) => `${key}=${value}`).join('&');
        hash.data = encodeURI(hash.data);
      }

      return hash;
    }

  });

  _exports.default = _default;
});