define("adept-iq/pods/components/iq-widgets/stops-widget/avlmConfig", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/stops-widget',
    rowComponent: 'iq-widgets/stops-widget/stops-row',
    modelName: 'avlm-stop-point',
    defaultSortId: 'tripId',
    defaultSortAsc: false,
    title: 'Stops',
    columns: [{
      id: 'id',
      index: 18,
      type: 'uuid',
      label: 'ID',
      valuePath: 'id',
      valuePreview: '1',
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 60,
      hidden: true,
      unAvailable: true
    }, {
      id: 'type',
      index: 0,
      type: 'text',
      label: 'Type',
      valuePath: 'stopType',
      valuePreview: 'Pick',
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 50
    }, {
      id: 'tripId',
      index: 1,
      type: 'text',
      label: 'Trip ID',
      valuePath: 'avlmTrip.externalId',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'riderLastName',
      index: 2,
      type: 'text',
      label: 'Passenger Last Name',
      valuePath: 'avlmTrip.avlmTripRider.avlmRider.lastName',
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderFirstName',
      index: 3,
      type: 'text',
      label: 'Passenger First Name',
      valuePath: 'avlmTrip.avlmTripRider.avlmRider.firstName',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'riderPhone',
      index: 16,
      type: 'enum',
      label: 'Passenger Phone Number',
      valuePath: 'dsStop.trip.segment.leg.rider.formattedPhoneNumbers',
      searchable: true,
      defaultWidth: 60,
      hidden: true,
      unAvailable: true
    }, {
      id: 'riderId',
      index: 17,
      type: 'text',
      label: 'Passenger ID',
      valuePath: 'avlmTrip.avlmTripRider.avlmRider.bookingClientId',
      searchable: true,
      defaultWidth: 60,
      hidden: true
    }, {
      id: 'status',
      index: 7,
      type: 'text',
      label: 'Status',
      valuePath: 'currentStatus',
      valuePreview: 'Planned',
      editable: false,
      defaultWidth: 60
    }, {
      id: 'OTPstatus',
      index: 4,
      type: 'text',
      label: 'OTP Status',
      valuePath: 'otpLabel',
      defaultWidth: 40,
      highlightable: true
    }, {
      id: 'ETA',
      index: 5,
      type: 'date',
      label: 'ETA',
      valuePath: 'actualETA',
      editable: true,
      format: 'HH:mm A',
      defaultWidth: 40,
      highlightable: true,
      searchable: true
    }, {
      id: 'promisedTime',
      index: 13,
      type: 'datetime',
      label: 'Promise Time',
      valuePath: 'avlmRiderEvent.promisedTime',
      editable: true,
      format: 'MM-DD-YYYY hh:mm A',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'requestedTime',
      index: 6,
      type: 'time',
      label: 'Request Time',
      valuePath: 'avlmRiderEvent.scheduledTime',
      editable: true,
      format: 'hh:mm A',
      defaultWidth: 70
    }, {
      id: 'actualArrivalTime',
      index: 8,
      type: 'time',
      label: 'Actual Arrive Time',
      valuePath: 'actualArriveTime',
      editable: true,
      format: 'hh:mm A',
      defaultWidth: 50
    }, {
      id: 'actualDepartTime',
      index: 9,
      type: 'time',
      label: 'Actual Depart Time',
      valuePath: 'actualDepartTime',
      editable: true,
      format: 'hh:mm A',
      defaultWidth: 50
    }, {
      id: 'plannedDepartTime',
      index: 14,
      type: 'datetime',
      label: 'Planned Depart Time',
      valuePath: 'eta',
      editable: true,
      format: 'MM-DD-YYYY hh:mm A',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'routeName',
      index: 10,
      type: 'text',
      label: 'Route Name',
      valuePath: 'avlmRoute.trackingId',
      valuePreview: '40',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'notes',
      index: 25,
      type: 'text',
      label: 'Notes',
      valuePath: 'avlmRiderEvent.noteForDriver',
      editable: true,
      searchable: true,
      defaultWidth: 100,
      hidden: true
    }, {
      id: 'address',
      index: 11,
      type: 'text',
      label: 'Address ',
      valuePath: 'stopAddresses',
      searchable: true,
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'date',
      index: 12,
      type: 'date',
      label: 'Date',
      valuePath: 'avlmRiderEvent.scheduledTime',
      format: 'MM-DD-YYYY',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'city',
      type: 'text',
      label: 'City',
      valuePath: 'avlmAddress.locality',
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'odometer',
      index: 26,
      type: 'number',
      label: 'Odometer on Arrival',
      valuePath: 'convertedOdometer',
      editable: true,
      searchable: true,
      hidden: true
    }, {
      id: 'serviceWindow',
      index: 15,
      type: 'text',
      label: 'Service Window',
      valuePath: 'avlmRiderEvent.serviceStartTime',
      editable: true,
      hidden: true,
      defaultWidth: 80,
      unAvailable: true
    }, {
      id: 'BreakStartTime',
      index: 19,
      type: 'time',
      label: 'Break Start Time',
      valuePath: 'routeBreak.plannedStartTime',
      editable: true,
      format: 'hh:mm A',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'BreakEndTime',
      index: 20,
      type: 'time',
      label: 'Break End Time',
      valuePath: 'routeBreak.plannedEndTime',
      editable: true,
      format: 'hh:mm A',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'BreakType',
      index: 21,
      type: 'text',
      label: 'Break Type',
      valuePath: 'routeBreak.breakType.name',
      valuePreview: 'Floating',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'BreakCategory',
      index: 22,
      type: 'text',
      label: 'Break Category',
      valuePath: 'routeBreak.breakType.category',
      defaultWidth: 70,
      hidden: true,
      unAvailable: true
    }, {
      id: 'slackMinutes',
      index: 23,
      type: 'text',
      label: 'Slack Minutes',
      valuePath: 'dsStop.slackMinutes',
      defaultWidth: 70,
      hidden: true,
      unAvailable: true
    }, {
      id: 'onboardCount',
      index: 24,
      type: 'number',
      label: 'Onboard Count',
      valuePath: 'bsStop.onboardCount',
      defaultWidth: 70,
      hidden: true,
      unAvailable: true
    }, {
      id: 'stopBreakOrd',
      type: 'number',
      label: 'Stop Break Ord',
      valuePath: 'dsStop.routeOrdinal',
      searchable: false,
      editable: false,
      defaultWidth: 50,
      hidden: true,
      unAvailable: true
    }, {
      id: 'iqId',
      type: 'number',
      label: 'IQ ID',
      valuePath: 'id',
      searchable: false,
      editable: false,
      defaultWidth: 50,
      unAvailable: true
    }, {
      id: 'otp',
      index: 27,
      type: 'number',
      label: 'OTP',
      valuePath: 'otpValueWithSign',
      hidden: true,
      highlightable: true
    }, {
      id: 'arriveLat',
      index: 28,
      type: 'string',
      label: 'Actual Arrive Lat',
      valuePath: 'arriveLat',
      hidden: true,
      highlightable: false
    }, {
      id: 'arriveLng',
      index: 29,
      type: 'string',
      label: 'Actual Arrive Lng',
      valuePath: 'arriveLng',
      hidden: true,
      highlightable: false
    }, {
      id: 'departLat',
      index: 30,
      type: 'string',
      label: 'Actual Depart Lat',
      valuePath: 'departLat',
      hidden: true,
      highlightable: false
    }, {
      id: 'departLng',
      index: 31,
      type: 'string',
      label: 'Actual Depart Lng',
      valuePath: 'departLng',
      hidden: true,
      highlightable: false
    }]
  };
  _exports.default = _default;
});