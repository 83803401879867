define("adept-iq/models/schedule", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    start: attr('ss-date'),
    end: attr('ss-date'),
    status: attr('string', {
      defaultValue: 'unscheduled'
    }),
    // E.g. 'scheduled', 'unscheduled', 'in progress', 'exported'
    engineDatasetId: attr('string'),
    metric: belongsTo('metric'),
    provider: belongsTo('provider'),
    dispatchSchedule: belongsTo('dispatch-schedule'),
    scheduleConfiguration: belongsTo('schedule-configuration'),
    routes: hasMany('route'),
    trips: hasMany('trip'),
    locked: Ember.computed.alias('dispatchSchedule.locked')
  });

  _exports.default = _default;
});