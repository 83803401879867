define("adept-iq/pods/components/iq-widgets/roles-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/roles-widget/config", "adept-iq/config/api-urls", "adept-iq/config/mapped-permIds", "lodash"], function (_exports, _component, _config, _apiUrls, _mappedPermIds, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = [];

  var _default = _component.default.extend({
    classNames: ['roles-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    editComponent: 'iq-widgets/roles-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,

    // @TODO: saveRecordFunction shall be moved to a service later on
    // This save is specific to saving roles and all related configurations / permissions.
    saveRecordFunction(records) {
      // using custom api call because sso api is non standard
      // and in-consistent.
      const session = this.get('session');
      return Promise.all(records.map(async record => {
        const currentPerms = record.get('permissions').map(perm => perm.get('id'));
        const roleName = record.get('roleName');

        if (record.get('isNew')) {
          // create new role
          const json = {
            roleName: roleName,
            status: 'active',
            displayName: record.get('name')
          };
          await this.get('ajax').post(`${_apiUrls.API.ssoService.host}/role`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: json
          });
        }

        let permList = await this.get('ajax').request(`${_apiUrls.API.ssoService.host}/role/${roleName}/role-permission/`, {
          method: 'GET',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        }).catch(() => []);
        permList = permList.map(obj => obj.permId);
        permList = (0, _lodash.difference)(currentPerms, permList);
        record.set('permissions', null);
        record.set('permissionList', null);
        return permList.reduce((acu, permId) => {
          const json = {
            roleId: record.get('roleName'),
            permissionId: permId
          }; // assign permissions to role

          return acu.then(() => this.get('ajax').post(`${_apiUrls.API.ssoService.host}/role-permission`, {
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            },
            data: json
          }).catch(() => {
            this.get('notifications').pushError({
              detail: `Unable to Save Permission Id ${permId}`
            });
          }));
        }, Promise.resolve());
      })).then(async () => {
        await this.get('store').findAll('sso-role');
        await this.get('workspaceContext').get('reloadWorkspace').perform();
        return this.get('activeContext').get('refreshTableContent').perform(['sso-role']);
      });
    },

    condensedPermissions(permissions) {
      return permissions.map(perm => {
        return Ember.Object.create({
          id: perm.get('id'),
          category: perm.get('category'),
          description: perm.get('description')
        });
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('tableActions', [{
        id: 'newRole',
        permId: _mappedPermIds.default.newRole,
        name: 'New Role',
        action: function () {
          const store = this.get('store');
          const permObj = this.condensedPermissions(store.peekAll('sso-permission'));
          const newModel = store.createRecord(this.get('config.modelName'), {
            permissions: permObj
          });
          this.get('records').pushObject(newModel);
          const editComponent = this.get('editComponent');
          this.get('editModal').open(editComponent, [newModel], this.get('saveRecordFunction').bind(this));
        }.bind(this)
      }]);
      this.set('singleActions', [{
        id: 'edit',
        permId: _mappedPermIds.default.editRole,
        name: 'Edit',
        action: async model => {
          const editComponent = this.get('editComponent');
          const session = this.get('session');
          const store = this.get('store');
          const permObj = this.condensedPermissions(store.peekAll('sso-permission'));
          let permissionList = [];
          const roleName = model.get('roleName');

          if (roleName) {
            permissionList = await this.get('ajax').request(`${_apiUrls.API.ssoService.host}/role/${roleName}/role-permission/`, {
              method: 'GET',
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${session.data.authenticated.token}`
              }
            }).catch(() => []);
          }

          permissionList = permissionList.map(perm => perm.permissionId);
          model.set('permissionList', permissionList);
          model.set('permissions', permObj);
          this.get('editModal').open(editComponent, [model], this.get('saveRecordFunction').bind(this));
        }
      }]);
      this.set('bulkActions', [{
        id: 'bulkEdit',
        permId: _mappedPermIds.default.editRole,
        name: 'Bulk Edit',
        action: async models => {
          const store = this.get('store');
          const editComponent = this.get('editComponent');
          const session = this.get('session');
          await models.reduce(async (acu, model) => {
            const permObj = this.condensedPermissions(store.peekAll('sso-permission'));
            let permissionList = [];
            const roleName = model.get('roleName');

            if (roleName) {
              permissionList = await this.get('ajax').request(`${_apiUrls.API.ssoService.host}/role/${roleName}/role-permission/`, {
                method: 'GET',
                contentType: 'application/json',
                headers: {
                  'Authorization': `Bearer ${session.data.authenticated.token}`
                }
              }).catch(() => []);
            }

            permissionList = permissionList.map(perm => perm.permissionId);
            model.set('permissionList', permissionList);
            model.set('permissions', permObj);
            return acu;
          }, Promise.resolve());
          this.get('editModal').open(editComponent, models, this.get('saveRecordFunction').bind(this));
        }
      }]);
    }

  });

  _exports.default = _default;
});