define("adept-iq/models/zone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    type: attr('string'),
    points: attr(),
    noOfPoints: Ember.computed('points', function () {
      return this.get('points') ? this.get('points.length') : 0;
    }),
    polypoints: Ember.computed('points', 'noOfPoints', function () {
      const polygonPoints = [];
      const points = this.get('points');

      if (!points && this.get('noOfPoints') === 1) {
        const item = points[1];
        return item;
      } else if (this.get('points') !== null) {
        points.forEach(item => {
          const polygonPoint = [];
          polygonPoint.push(item.lat);
          polygonPoint.push(item.lng);
          polygonPoints.push(polygonPoint);
        });
      }

      return polygonPoints;
    }),
    status: Ember.computed('points', function () {
      return 'normal';
    }),
    calculateCenter: Ember.computed('points', 'noOfPoints', function () {
      let minlat = false;
      let minlng = false;
      let maxlat = false;
      let maxlng = false;
      let lat = null;
      let lng = null;
      const points = this.get('points');

      if (!Ember.isEmpty(points)) {
        points.forEach(item => {
          if (minlat === false) {
            minlat = item.lat;
          } else {
            minlat = item.lat < minlat ? item.lat : minlat;
          }

          if (maxlat === false) {
            maxlat = item.lat;
          } else {
            maxlat = item.lat > maxlat ? item.lat : maxlat;
          }

          if (minlng === false) {
            minlng = item.lng;
          } else {
            minlng = item.lng < minlng ? item.lng : minlng;
          }

          if (maxlng === false) {
            maxlng = item.lng;
          } else {
            maxlng = item.lng > maxlng ? item.lng : maxlng;
          }
        }); // Calculate the center

        lat = maxlat - (maxlat - minlat) / 2;
        lng = maxlng - (maxlng - minlng) / 2;
      }

      return {
        lat: lat,
        lng: lng
      };
    }),
    lat: Ember.computed.alias('calculateCenter.lat'),
    lng: Ember.computed.alias('calculateCenter.lng')
  });

  _exports.default = _default;
});