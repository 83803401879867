define("adept-iq/pods/components/iq-widgets/trips-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/trips-widget',
    rowComponent: 'table/rows/text-formatted-row',
    modelName: 'trip',
    defaultSortId: 'name',
    defaultSortAsc: false,
    title: 'Trips',
    columns: [{
      id: 'name',
      index: 3,
      type: 'uuid',
      label: 'ID',
      valuePath: 'tripId',
      valuePreview: '1',
      hidden: false,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'riderFirstName',
      index: 0,
      type: 'text',
      label: 'Passenger First Name',
      valuePath: 'rider.firstName',
      hidden: false,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderLastName',
      index: 1,
      type: 'text',
      label: 'Passenger Last Name',
      valuePath: 'rider.lastName',
      hidden: false,
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderPhone',
      type: 'enum',
      index: 2,
      label: 'Passenger Phone Number',
      valuePath: 'rider.formattedPhoneNumbers',
      hidden: true,
      defaultWidth: 70
    }, {
      id: 'externalId',
      index: 11,
      type: 'uuid',
      label: 'Passenger ID',
      valuePath: 'rider.riderId',
      editable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'tripType',
      index: 9,
      type: 'text',
      label: 'Type',
      valuePath: 'type',
      editable: true,
      hidden: true,
      defaultWidth: 60
    }, {
      id: 'routeName',
      index: 4,
      type: 'uuid',
      label: 'Route Name',
      valuePath: 'currentRouteName',
      hidden: false,
      searchable: true,
      defaultWidth: 70
    }, {
      id: 'status',
      index: 5,
      type: 'text',
      label: 'Status',
      valuePath: 'computedStatus',
      hidden: false,
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 75
    }, {
      id: 'statusReason',
      index: 6,
      type: 'text',
      label: 'Status Reason',
      valuePath: 'computedNoShowReason',
      hidden: false,
      defaultWidth: 75
    }, {
      id: 'statusNotes',
      index: 27,
      type: 'text',
      label: 'Status Notes',
      valuePath: 'noShow.notes',
      hidden: true,
      defaultWidth: 75
    }, {
      id: 'otpStatus',
      index: 7,
      type: 'text',
      label: 'OTP Status',
      valuePath: 'otpLabel',
      valuePreview: 'On Time',
      hidden: false,
      searchable: true,
      highlightable: true
    }, {
      id: 'otp',
      index: 28,
      type: 'number',
      label: 'OTP',
      valuePath: 'otpValueWithSign',
      hidden: true,
      highlightable: true
    }, {
      id: 'promisedTime',
      index: 8,
      type: 'datetime',
      label: 'Promise Time',
      valuePath: 'promiseTime',
      valuePreview: '09:00',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 50
    }, {
      id: 'requestedTime',
      index: 10,
      type: 'time',
      label: 'Request Time',
      valuePath: 'segment.leg.requestTime',
      valuePreview: '08:00',
      editable: true,
      hidden: false,
      format: 'HH:mm',
      defaultWidth: 50
    }, {
      id: 'anchorETA',
      index: 12,
      type: 'time',
      label: 'Anchor ETA',
      valuePath: 'anchorStop.stopPoint.etaNavStopPoint.eta',
      valuePreview: '16',
      hidden: true,
      format: 'HH:mm',
      defaultWidth: 50
    }, {
      id: 'serviceStartTime',
      index: 13,
      type: 'date',
      label: 'Service Start Time',
      valuePath: 'segment.leg.serviceWindow.searchWindowStart',
      valuePreview: '?',
      editable: true,
      hidden: true,
      format: 'HH:mm',
      defaultWidth: 50
    }, {
      id: 'serviceEndTime',
      index: 14,
      type: 'date',
      label: 'Service End Time',
      valuePath: 'segment.leg.serviceWindow.searchWindowEnd',
      valuePreview: '?',
      editable: true,
      hidden: true,
      format: 'HH:mm',
      defaultWidth: 50
    }, {
      id: 'noShowTime',
      index: 40,
      type: 'time',
      label: 'No Show Time',
      valuePath: 'pickStopPoint.noShowTime',
      valuePreview: '08:00',
      editable: true,
      hidden: false,
      format: 'HH:mm',
      defaultWidth: 50
    }, {
      id: 'anchor',
      index: 15,
      type: 'text',
      label: 'Anchor',
      valuePath: 'segment.leg.anchor',
      valuePreview: 'Pick',
      searchable: true,
      editable: true,
      hidden: false,
      defaultWidth: 60
    }, {
      id: 'pickupaddress',
      index: 16,
      type: 'text',
      label: 'Pickup Address',
      valuePath: 'segment.pick.place.address.simpleAddress',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'pickupCity',
      index: 17,
      type: 'text',
      label: 'Pickup City',
      valuePath: 'segment.pick.place.address.locality',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'dropaddress',
      index: 18,
      type: 'text',
      label: 'Drop off Address',
      valuePath: 'segment.drop.place.address.simpleAddress',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'dropCity',
      index: 19,
      type: 'text',
      label: 'Drop off City',
      valuePath: 'segment.drop.place.address.locality',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'passengerNotes',
      index: 21,
      type: 'text',
      label: 'Passenger Notes',
      valuePath: 'rider.notes',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 80
    }, {
      id: 'notes',
      index: 20,
      type: 'text',
      label: 'Driver Notes',
      valuePath: 'driver.notes',
      editable: true,
      hidden: true,
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'travelNeeds',
      index: 22,
      type: 'enum',
      label: 'Travel Needs',
      valuePath: 'formattedTravelNeeds',
      cellDesc: 'Travel Needs',
      editable: false,
      hidden: true
    }, // STUB FOR AVA DEMO
    {
      id: 'plannedMiles',
      index: 23,
      type: 'number',
      label: 'Planned Trip Miles',
      valuePath: 'plannedMiles',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, // STUB FOR AVA DEMO
    {
      id: 'actualMiles',
      index: 24,
      type: 'number',
      label: 'Actual Trip Miles',
      valuePath: 'avlmTrip.actualMiles',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'fare',
      index: 25,
      type: 'text',
      label: 'Fare',
      valuePath: 'segment.fare',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'paymentType',
      index: 26,
      type: 'text',
      label: 'Payment Type',
      valuePath: 'segment.fareType.name',
      valuePreview: 'Single',
      editable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'provider',
      index: 29,
      type: 'string',
      label: 'Provider',
      valuePath: 'pickStopPoint.dispatchRoute.provider.name',
      valuePreview: 'MAG',
      hidden: true,
      searchable: true,
      highlightable: true
    }, {
      id: 'serviceMode',
      index: 30,
      type: 'string',
      label: 'Service Mode',
      valuePath: 'segment.travelMode',
      hidden: true,
      highlightable: true
    }, {
      id: 'previousRouteName',
      index: 41,
      type: 'uuid',
      label: 'Previous Route Name',
      valuePath: 'previousRouteAssignment.route.name',
      hidden: true,
      highlightable: false,
      searchable: true,
      defaultWidth: 70
    }]
  };
  _exports.default = _default;
});