define("adept-iq/classes/map-contexts/layered", ["exports", "adept-iq/classes/map-contexts/base", "lodash", "adept-iq/config/environment", "adept-iq/config/column-types", "adept-iq/config/filter-types", "adept-iq/config/map-layers", "adept-iq/config/map-layers-avlmlite", "adept-iq/utils/compute-ordered-ids", "adept-iq/classes/markers/agency", "adept-iq/classes/markers/stops/iq", "adept-iq/classes/markers/vehicles/iq", "adept-iq/classes/markers/vehicles/road-supervisor", "adept-iq/classes/polylines/trips/iq", "adept-iq/classes/polylines/routes/planned/iq", "adept-iq/classes/polylines/routes/navigation/iq", "adept-iq/classes/polylines/routes/performed/iq", "adept-iq/classes/polygons/zone", "adept-iq/classes/shapes/zone", "moment", "adept-iq/utils/filters"], function (_exports, _base, _lodash, _environment, _columnTypes, _filterTypes, _mapLayers, _mapLayersAvlmlite, _computeOrderedIds, _agency, _iq, _iq2, _roadSupervisor, _iq3, _iq4, _iq5, _iq6, _zone, _zone2, _moment, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // base class for layered map contexts; use this when lots of entities are
  // present, or where filtering/opacity is required
  const layersConfig = _environment.default.APP.avlmLite ? _mapLayersAvlmlite.default : _mapLayers.default;

  var _default = _base.default.extend({
    layersConfig,
    polylineService: null,
    // set these in extending subclass
    markersData: Ember.computed('', () => []),
    polylineData: Ember.computed('', () => []),
    shapeData: Ember.computed('', () => []),
    polygonData: Ember.computed('', () => []),
    layeredRecords: Ember.computed('', () => []),
    //used to render rs vehicle if required
    roadSupervisor: null,
    isFiltered: Ember.computed('layers', function () {
      return this.get('layers').any(({
        labels
      }) => {
        return labels.any(label => {
          const {
            filterTypeId,
            filterValues
          } = label;
          const filterType = _filterTypes.filterTypesHash[filterTypeId];
          return (0, _filters.testFilterValues)(filterType, filterValues);
        });
      });
    }),
    // merges the map state on top of the layers config; re-computed every time
    // the underlying state changes
    layers: Ember.computed('mapState', 'layersConfig', function () {
      const config = _lodash.default.cloneDeep(this.get('layersConfig'));

      const mapState = this.get('mapState'); // remove labels that are un-available

      config.forEach(currConfig => {
        const labels = [];
        currConfig.labels.forEach(label => {
          if (!label.unAvailable) {
            labels.push(label);
          }
        });

        if (labels.length > 0) {
          currConfig.labels = labels;
        }
      });
      if (!mapState) return config;
      Object.entries(mapState).forEach(([layerId, layerState]) => {
        const layer = config.findBy('id', layerId);
        if (!layer) return;
        ['isVisible', 'opacity', 'isDisplayMapIconLabel'].forEach(property => {
          if (Ember.isNone(layerState[property])) return;
          layer[property] = layerState[property];
        });

        if (layerState.isDisplayMapIconLabel) {
          layer.selectedDisplay = 'Always';
        } else {
          layer.selectedDisplay = 'On Left Click';
        }

        if (layerState.types) {
          Object.entries(layerState.types).forEach(([typeId, typeState]) => {
            const type = layer.types.findBy('id', typeId);
            if (!type) return;
            ['isVisible'].forEach(property => {
              if (Ember.isNone(typeState[property])) return;
              type[property] = typeState[property];
            });
          });
        }

        if (layerState.labels) {
          Object.entries(layerState.labels).forEach(([labelId, labelState]) => {
            const label = layer.labels.findBy('id', labelId);
            if (!label) return;
            ['isVisible', 'index', 'filterTypeId', 'filterValues'].forEach(property => {
              if (Ember.isNone(labelState[property])) return;
              label[property] = labelState[property];
            });
          });
        }

        layer.labels = layer.labels || []; // ensure every label has index

        const orderedIds = (0, _computeOrderedIds.default)(layer.labels);
        orderedIds.forEach((labelId, index) => {
          const label = layer.labels.findBy('id', labelId);
          label.index = index;
        });
      });
      return config;
    }),
    markers: Ember.computed('layers', 'layeredRecords', 'searchText', function () {
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'markers');
      const layeredRecords = this.get('layeredRecords');
      const layerMarkers = layers.map(layer => {
        const layerRecords = layeredRecords[layer.id] || [];
        const labels = layer.labels;

        const hasSelectedActiveLabel = _lodash.default.filter(labels, value => value.isVisible).length;

        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this._buildSearchFilterNode(layer, searchText), this._buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        return layer.types.map(type => {
          if (type.isVisible === false) return [];
          let typeRecords = layerRecords;

          if (type.filterKey) {
            typeRecords = layerRecords.filter(record => {
              if (type.hasOwnProperty('filterValue')) {
                return record.get(type.filterKey) === type.filterValue;
              }

              return record.get(type.filterKey);
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.reduce((arr, record) => {
            const marker = this.markerObjectFor(layer, type, record);

            if (marker.get('marker')) {
              if (layer.isDisplayMapIconLabel && hasSelectedActiveLabel) {
                if (!marker.get('marker').isPopupOpen()) marker.get('marker').bindPopup(this._getLabel(record, layer), {
                  closeButton: false,
                  autoClose: false,
                  closeOnClick: false,
                  autoPan: false
                }).openPopup();
              } else if (marker.get('marker').isPopupOpen() && !marker.get('marker._popup.options.autoPan')) {
                marker.get('marker').closePopup();
                marker.get('marker').bindPopup(this._getLabel(record, layer), {
                  closeButton: false
                });
              }

              marker.get('marker').setPopupContent(this._getLabel(record, layer));
            }

            marker.setProperties({
              opacity: layer.opacity,
              // marker can update its own style based on layer config
              layer,
              layerType: type,
              // tells marker that it may appear/disappear based on AC
              isActive: layer.isActive
            });
            arr.push(marker);
            return arr;
          }, []);
        });
      });
      layerMarkers.push(_roadSupervisor.default.create({
        record: this.get('roadSupervisor.rsVehicle')
      }));
      return _lodash.default.flatten(_lodash.default.flatten(layerMarkers));
    }),
    polylines: Ember.computed('layeredRecords', 'layers', 'searchText', function () {
      // each item is a store record, with `otp` if so endowed
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'polylines');
      const layeredRecords = this.get('layeredRecords');
      const layerPolylines = layers.map(layer => {
        if (layer.isVisible === false) return [];
        const layerRecords = layeredRecords[layer.id] || [];
        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this._buildSearchFilterNode(layer, searchText), this._buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        const stopsIsVisible = this.get('layers').findBy('id', 'stops').isVisible;
        return layer.types.map(type => {
          if (!type.isVisible) return [];
          let typeRecords = layerRecords;

          if (type.filterKey) {
            typeRecords = typeRecords.filter(record => {
              if (type.hasOwnProperty('filterValue')) {
                return record.get(type.filterKey) === type.filterValue;
              }

              return record.get(type.filterKey);
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.map(record => {
            const polyline = this.polylineObjectFor(layer, type, record);

            const label = this._getLabel(record, layer);

            if (polyline.get('polyline')) {
              if (layer.isDisplayMapIconLabel) {
                if (!polyline.get('polyline').isPopupOpen()) polyline.get('polyline').bindPopup(this._getLabel(record, layer), {
                  closeButton: false,
                  autoClose: false,
                  closeOnClick: false,
                  autoPan: false
                }).openPopup();
              } else if (polyline.get('polyline').isPopupOpen() && !polyline.get('polyline._popup.options.autoPan')) {
                polyline.get('polyline').closePopup();
                polyline.get('polyline').bindPopup(this._getLabel(record, layer), {
                  closeButton: false
                });
              }
            }

            polyline.setProperties({
              layer,
              layerType: type,
              label: label,
              opacity: layer.opacity
            });

            if (layer.id === 'trips') {
              polyline.set('stopsIsVisible', stopsIsVisible);
            }

            return polyline;
          });
        });
      });
      return _lodash.default.flatten(_lodash.default.flatten(layerPolylines));
    }),
    polygons: Ember.computed('layeredRecords', 'layers', 'searchText', function () {
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'polygons');
      const layeredRecords = this.get('layeredRecords');
      const layerPolygons = layers.map(layer => {
        if (layer.isVisible === false) return [];
        let layerRecords;

        if (layer.isActive) {
          layerRecords = layeredRecords[layer.id] || [];
        } else {
          // TODO: query `workspace-context` instead of store
          layerRecords = this.get('store').peekAll(layer.modelName);
        }

        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this._buildSearchFilterNode(layer, searchText), this._buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        return layer.types.map(type => {
          if (type.isVisible === false) return [];
          let typeRecords = layerRecords;

          if (type.filterKey) {
            typeRecords = typeRecords.filter(record => {
              if (type.hasOwnProperty('filterValue')) {
                return record.get(type.filterKey) === type.filterValue;
              }

              return record.get(type.filterKey);
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.reduce((arr, record) => {
            const polygon = this.polygonObjectFor(layer, type, record); // TODO: move this to polygon model

            const polygonStyle = type.style;
            polygon.setProperties({
              style: polygonStyle,
              type: type.id,
              label: this._getLabel(record, layer),
              parentLabel: this._getParentLabel(record, layer),
              opacity: layer.opacity,
              isActive: layer.isActive
            });
            arr.push(polygon);
            return arr;
          }, []);
        });
      });
      return _lodash.default.flatten(_lodash.default.flatten(layerPolygons));
    }),
    shapes: Ember.computed('layeredRecords', 'layers', 'searchText', function () {
      const searchText = this.get('searchText');
      const layers = this.get('layers').filterBy('type', 'shapes');
      const layeredRecords = this.get('layeredRecords');
      const layerShapes = layers.map(layer => {
        if (layer.isVisible === false) return [];
        let layerRecords;

        if (layer.isActive) {
          layerRecords = layeredRecords[layer.id] || [];
        } else {
          // TODO: query `workspace-context` instead of store
          layerRecords = this.get('store').peekAll(layer.modelName);
        }

        const filterNode = (0, _filters.buildCompoundFilterNode)('and', [this._buildSearchFilterNode(layer, searchText), this._buildColumnFilterNode(layer)]);
        const filter = (0, _filters.buildFilterFunction)(filterNode);
        return layer.types.map(type => {
          if (type.isVisible === false) return [];
          let typeRecords = layerRecords;

          if (type.filterKey) {
            typeRecords = typeRecords.filter(record => {
              if (type.hasOwnProperty('filterValue')) {
                return record.get(type.filterKey) === type.filterValue;
              }

              return record.get(type.filterKey);
            });
          }

          const filteredRecords = typeRecords.filter(filter);
          return filteredRecords.reduce((arr, record) => {
            const shape = this.shapeObjectFor(layer, type, record);
            const shapeStyle = type.style;
            shape.setProperties({
              style: shapeStyle,
              type: type.id,
              label: this._getLabel(record, layer),
              parentLabel: this._getParentLabel(record, layer),
              opacity: layer.opacity,
              isActive: layer.isActive,
              radius: record.get('radius'),
              noOfPoints: record.get('noOfPoints')
            });
            arr.push(shape);
            return arr;
          }, []);
        });
      });
      return _lodash.default.flatten(_lodash.default.flatten(layerShapes));
    }),

    setLayerVisibility(layerId, isVisible) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          isVisible
        }
      });
    },

    setDisplayMapIconLabelAllTime(layerId, isDisplayMapIconLabel) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          isDisplayMapIconLabel
        }
      });
    },

    setDisplayMapLabelUpdated(layerId, markerUpdated) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          markerUpdated
        }
      });
    },

    setLayerOpacity(layerId, opacity) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          opacity
        }
      });
    },

    setLayerTypeVisibility(layerId, typeId, isVisible) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          types: {
            [typeId]: {
              isVisible
            }
          }
        }
      });
    },

    setLayerLabelVisibility(layerId, labelId, isVisible) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          labels: {
            [labelId]: {
              isVisible
            }
          }
        }
      });
    },

    setLayerLabelFilterType(layerId, labelId, filterTypeId) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          labels: {
            [labelId]: {
              filterTypeId
            }
          }
        }
      });
    },

    setLayerLabelFilterValues(layerId, labelId, filterValues) {
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          labels: {
            [labelId]: {
              filterValues
            }
          }
        }
      });
    },

    setLayerLabelsOrder(layerId, labelIds) {
      const labels = labelIds.reduce((obj, labelId, index) => {
        obj[labelId] = {
          index
        };
        return obj;
      }, {});
      this.get('mapStateProvider').mergeMapState({
        [layerId]: {
          labels
        }
      });
    },

    polygonObjectFor(layer, layerType, record) {
      if (!this._polygonCache) {
        this._polygonCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let polygon = this._polygonCache[id];

      if (!polygon) {
        const polygonClass = this.polygonClassForLayer(layer);
        polygon = polygonClass.create({
          id,
          record
        });
        this._polygonCache[id] = polygon;
      }

      return polygon;
    },

    markerObjectFor(layer, layerType, record) {
      if (!this._markerCache) {
        this._markerCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let marker = this._markerCache[id];

      if (!marker) {
        const markerClass = this.markerClassForLayer(layer);
        marker = markerClass.create({
          id,
          record
        });
        this._markerCache[id] = marker;
      }

      return marker;
    },

    shapeObjectFor(layer, layerType, record) {
      if (!this._shapeCache) {
        this._shapeCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let shape = this._shapeCache[id];

      if (!shape) {
        const shapeClass = this.shapeClassForLayer(layer);
        shape = shapeClass.create({
          id,
          record
        });
        this._shapeCache[id] = shape;
      }

      return shape;
    },

    polylineObjectFor(layer, layerType, record) {
      if (!this._polylineCache) {
        this._polylineCache = {};
      }

      const id = `${layer.id}-${layerType.id}-${record.get('id')}`;
      let polyline = this._polylineCache[id];

      if (!polyline) {
        const polylineClass = this.polylineClassForLayer(layer, layerType);
        polyline = polylineClass.create({
          id,
          record,
          polylineService: this.get('polylineService')
        });
        this._polylineCache[id] = polyline;
      }

      return polyline;
    },

    markerClassForLayer(layer) {
      switch (layer.id) {
        case 'agency':
          return _agency.default;

        case 'vehicles':
          return _iq2.default;

        case 'stops':
          return _iq.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    },

    polylineClassForLayer(layer, layerType) {
      switch (layer.id) {
        case 'routes':
          return this.routePolylineClassForLayerType(layerType);

        case 'trips':
          return _iq3.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    },

    routePolylineClassForLayerType(layerType) {
      switch (layerType.id) {
        case 'planned':
          return _iq4.default;

        case 'navigation':
          return _iq5.default;

        case 'performed':
          return _iq6.default;

        default:
          throw new Error(`unhandled route layerType id '${layerType.id}'`);
      }
    },

    shapeClassForLayer(layer) {
      switch (layer.id) {
        case 'zones':
          return _zone2.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    },

    polygonClassForLayer(layer) {
      switch (layer.id) {
        case 'zones':
          return _zone.default;

        default:
          throw new Error(`unhandled layer id '${layer.id}'`);
      }
    },

    _buildSearchFilterNode(layer, searchText) {
      if (Ember.isBlank(searchText) || !layer.isVisible) return null;
      if (!layer.isVisible) return null;
      const filterNodes = layer.labels.reduce((arr, label) => {
        if (!label.isSearchable) return arr; // TODO: determine if we shuld be searching hidden labels
        // if (!label.isVisible) return arr;

        const filterType = _columnTypes.columnTypesHash[label.type].searchFilterType;
        const filterNode = (0, _filters.buildValueFilterNode)(filterType, label.valuePath, searchText);
        arr.push(filterNode);
        return arr;
      }, []);
      return (0, _filters.buildCompoundFilterNode)('or', filterNodes);
    },

    _buildColumnFilterNode(layer) {
      if (!layer.isVisible) return null;
      const filterNodes = layer.labels.reduce((arr, label) => {
        if (!label.isVisible || !label.isSearchable) return arr;
        const filterType = _filterTypes.filterTypesHash[label.filterTypeId];
        let filterValues = label.filterValues;
        if (!(0, _filters.testFilterValues)(filterType, filterValues)) return arr;
        filterValues = label.filterValues.map(filterType.parseValue);
        const filterNode = (0, _filters.buildValueFilterNode)(filterType, label.valuePath, filterValues);
        arr.push(filterNode);
        return arr;
      }, []);
      return (0, _filters.buildCompoundFilterNode)('and', filterNodes);
    },

    _getLabel(record, layer) {
      const lines = [];
      const sortedLabels = layer.labels.sort((a, b) => a.index - b.index);
      sortedLabels.forEach(label => {
        if (!label.isVisible) {
          return;
        }

        let recordValue = record.get(`${label.valuePath}`); // We do not have enums such as vehicle availabilities etc in avlmlite
        // This only prevents enums from displaying on the map label

        if (label.type === 'enum' && !_environment.default.APP.avlmLite && Ember.isPresent(recordValue)) {
          const values = Ember.isArray(recordValue) ? recordValue : recordValue.values;
          let header = '';
          const listItems = [];
          values.forEach(value => {
            if (value.className === 'mainItem') {
              header = `${label.label}: ${value.value}`;
            } else {
              listItems.push(`<li>${value.value}</li>`);
            }
          });
          const list = `${header} <ul style="margin: 0;">${listItems.join('')}</ul>`;
          lines.push(list);
        } else {
          if (label.format) {
            if (!Ember.isBlank(recordValue)) {
              recordValue = (0, _moment.default)(recordValue).format(label.format);
            }
          }

          lines.push(`${label.label}: ${recordValue ? recordValue : ''}`);
        }
      });
      return `${lines.join('<br/>')}`;
    },

    _getParentLabel(record, layer) {
      const lines = [];
      const sortedLabels = layer.labels.sort((a, b) => a.index - b.index);
      sortedLabels.forEach(label => {
        if (!label.isVisible || !label.parentLabel) return;
        lines.push(`${record.get(label.valuePath)}`);
      });
      return `${lines.join('<br/>')}`;
    }

  });

  _exports.default = _default;
});