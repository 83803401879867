define("adept-iq/pods/components/side-drawer/perform-stop/drop/ui/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INTERVAL_DELAY = 60000;

  var _default = Ember.Component.extend({
    classNames: ['perform-stop-drawer'],
    timeFormat: 'm-d-Y G:i K',
    shouldArrive: true,
    // pass in data to component
    stopPoint: null,
    errorText: null,
    actualDropTime: null,
    actualArriveTime: null,
    arrive: false,
    depart: false,
    isArrived: null,
    isNextDisabled: null,
    maxDate: null,
    // progress interval every minute
    _interval: null,
    trip: Ember.computed.readOnly('stopPoint.trip'),
    dispatchRoute: Ember.computed.readOnly('stopPoint.dispatchRoute'),
    route: Ember.computed.readOnly('dispatchRoute.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    minTime: Ember.computed('stopPoint.minPerformedTime', function () {
      const arriveTime = this.get('stopPoint.arriveTimestamp');
      const arriveMinTime = this.get('stopPoint.minPerformedTime');
      var currDate = (0, _moment.default)().subtract(1, 'minutes').toDate(); // Subtracting 1 minute in currentdate because getting two differnt instance of currentdate (one is in  arriveTime and other is here)

      if ((0, _moment.default)(arriveMinTime).isAfter((0, _moment.default)(arriveTime))) {
        return currDate;
      }

      return arriveMinTime;
    }),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    routeVehicleDriver: Ember.computed.readOnly('dispatchRoute.latestRouteVehicleDriver'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),

    init() {
      this._super(...arguments);

      this.set('maxDate', new Date()); // create a maxDate ticker that progresses by a minute

      this._interval = setInterval(() => {
        Ember.run.schedule('afterRender', () => {
          if (this.get('isDestroyed')) return;
          this.set('maxDate', new Date());
        });
      }, INTERVAL_DELAY);
    },

    destroy() {
      clearInterval(this._interval);
      this._interval = null;
    },

    actions: {
      onClickDepart(depart) {
        if (depart) {
          this.set('arrive', depart);
          this.set('depart', depart);
          this.set('isNextDisabled', false);
          return;
        }

        this.set('depart', depart);
        this.set('isNextDisabled', true);
      },

      onActualDropTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        const arriveTime = this.get('actualArriveTime');
        this.set('actualDropTime', momentTime.toISOString());
        this.setPickTTimeInArrivalTime(arriveTime, momentTime);
      },

      onActualArriveTimeChange(dateValues) {
        const momentTime = (0, _moment.default)(dateValues[0]);
        const dropTime = this.get('actualDropTime');
        this.set('actualArriveTime', momentTime.toISOString());
        this.setPickTTimeInArrivalTime(momentTime, dropTime);
      }

    },

    setPickTTimeInArrivalTime(arriveTime, dropTime) {
      if ((0, _moment.default)(dropTime).isBefore(arriveTime, 'minutes')) {
        this.set('actualDropTime', this.get('actualArriveTime'));
      }
    }

  });

  _exports.default = _default;
});