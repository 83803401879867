define("adept-iq/pods/components/form-components/datetimeflatpickr/component", ["exports", "moment", "adept-iq/config/environment", "adept-iq/utils/unwrapProxy"], function (_exports, _moment, _environment, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['form-components-datetimeflatpickr'],
    editModal: Ember.inject.service(),
    value: null,
    disabled: false,
    defaultDate: null,
    maxDate: null,
    minDate: null,
    clickOpens: true,
    format: null,
    extra: null,
    minTimePath: readOnly('extra.minTimePath'),
    maxTimePath: readOnly('extra.maxTimePath'),

    init() {
      this._super(...arguments);

      const record = (0, _unwrapProxy.unwrapProxy)(this.get('record'));
      const minTimePath = this.get('minTimePath');

      const format = this.get('format') || _environment.default.dateTimeFormat.dateTimeFlatPickr; // time needs to be inclusive, so we cut seconds off


      const minDate = minTimePath ? (0, _moment.default)(record.get(`${minTimePath}`)).format('YYYY-MM-DD HH:mm A') : (0, _moment.default)(this.get('value')).format('YYYY-MM-DD');
      const maxDateTimePath = this.get('maxTimePath');
      let maxDate = record.get(`${maxDateTimePath}`);
      maxDate = maxDate ? (0, _moment.default)(maxDate).format('YYYY-MM-DD HH:mm A') : null;
      this.set('dateFormat', format);
      this.set('minDate', minDate);
      this.set('maxDate', maxDate);

      if (this.get('readonlyCell')) {
        this.set('clickOpens', false);
      }
    },

    actions: {
      isValidDate(value) {
        const newValueMoment = (0, _moment.default)(value);

        if (newValueMoment._isValid) {
          this.set('value', newValueMoment.toDate());
        }
      },

      onChangeDate(value) {
        this.get('onChange')(value[0]);
      },

      onClose(currentValue, stringValue, datePicker) {
        const newValueMoment = (0, _moment.default)(stringValue);

        if (!newValueMoment._isValid) {
          this.set('editModal.errors', ['Date entered is invalid.']);
        }

        if (datePicker.config.allowInput && datePicker._input.value && newValueMoment._isValid) {
          this.set('value', newValueMoment.toDate());
        }
      }

    }
  });

  _exports.default = _default;
});