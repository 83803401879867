define("adept-iq/classes/markers/stops/iq", ["exports", "adept-iq/classes/markers/stops/base", "adept-iq/config/environment", "adept-iq/models/stop-point"], function (_exports, _base, _environment, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    record: null,
    // this is an iq-stop
    // `BaseMarker` overrides
    isActive: true,
    lat: Ember.computed.readOnly('iqStop.lat'),
    lng: Ember.computed.readOnly('iqStop.lng'),
    label: Ember.computed.oneWay('iqStopLabel'),
    // `BaseStopMarker` overrides
    stopType: Ember.computed.readOnly('iqStop.stopType'),
    otp: Ember.computed.readOnly('iqStop.otp'),
    isWheelchair: Ember.computed.readOnly('hasWheelchairTravelNeed'),
    isPerformed: Ember.computed.equal('stopPoint.status', _stopPoint.PERFORMED_STOP_STATUS),
    isNoShow: Ember.computed.equal('stopPoint.status', _stopPoint.NOSHOW_STOP_STATUS),
    isNext: Ember.computed('stopId', 'currentStopId', function () {
      const stopId = this.get('stopId');
      return Ember.isPresent(stopId) && stopId === this.get('currentStopId');
    }),
    // local properties
    iqStop: Ember.computed.alias('record'),
    stopPoint: Ember.computed.readOnly('record'),
    avlmStopPoint: Ember.computed.readOnly('record'),
    stopId: Ember.computed('iqStop.id', 'stopPoint.id', function () {
      return _environment.default.APP.avlmLite ? this.get('iqStop.id') : this.get('stopPoint.id');
    }),
    currentStopId: Ember.computed('avlmStopPoint.avlmCluster.route.currentStopOrdinalId', 'stopPoint.dispatchRoute.currentStopOrdinalId', function () {
      return _environment.default.APP.avlmLite ? this.get('avlmStopPoint.avlmCluster.route.currentStopOrdinalId') : this.get('stopPoint.dispatchRoute.currentStopOrdinalId');
    }),
    travelNeeds: Ember.computed.readOnly('stopPoint.trip.segment.leg.legTravelNeeds'),
    hasWheelchairTravelNeed: Ember.computed('travelNeeds.@each.isWheelchair', function () {
      // this check might not be necessary anymore
      if (_environment.default.APP.avlmLite) return false;
      const travelNeeds = this.get('travelNeeds');
      if (Ember.isEmpty(travelNeeds)) return false;
      return travelNeeds.any(travelNeed => travelNeed.get('isWheelchair'));
    }),
    iqStopLabel: Ember.computed('stopType', 'id', function () {
      const stopType = this.get('stopType');
      const stopId = this.get('id');
      return `${Ember.String.capitalize(stopType)} stop ${stopId}`;
    })
  });

  _exports.default = _default;
});