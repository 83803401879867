define("adept-iq/pods/components/side-drawer-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sw3hLi7g",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isShrunken\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"containerClassNames\",\"overlayClass\",\"targetAttachment\",\"hasOverlay\"],[\"side-drawer-modal side-drawer-shrunken data-test-shrunken-side-drawer\",\"edit-widget-overlay\",\"left\",false]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"side-drawer-buttons btn-toolbar justify-content-between\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"side-drawer-button\"],[11,\"data-test-toggle-side-drawer\",\"\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onShrinkClick\"],null]],[9],[0,\"\\n\\n        \"],[7,\"span\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-angle-double-right\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"modal-dialog\",null,[[\"containerClassNames\",\"overlayClass\",\"targetAttachment\",\"hasOverlay\"],[[23,[\"containerClassNames\"]],\"edit-widget-overlay\",\"left\",false]],{\"statements\":[[0,\"\\n    \"],[1,[27,\"dashboard/modals/edit-form/resize-handle\",null,[[\"onResizeModal\"],[[27,\"action\",[[22,0,[]],\"resizeModal\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"sideDrawerComponent\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"component\",[[23,[\"sideDrawerComponent\"]]],[[\"stackFrame\",\"currentState\",\"onCloseClick\",\"onShrinkClick\"],[[23,[\"workspace\",\"stack\",\"lastObject\"]],[23,[\"currentState\"]],[27,\"action\",[[22,0,[]],\"onCloseClick\"],null],[27,\"action\",[[22,0,[]],\"onShrinkClick\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/side-drawer-container/template.hbs"
    }
  });

  _exports.default = _default;
});