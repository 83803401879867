define("adept-iq/models/shift-break", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    state: attr('string', {
      defaultValue: 'planned'
    }),
    requestTime: attr('string', {
      defaultValue: '06:00:00'
    }),
    promisedStart: attr('string', {
      defaultValue: '06:00:00'
    }),
    plannedDuration: attr('number', {
      defaultValue: 15
    }),
    notes: attr('string'),
    startTime: attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    endTime: attr('date', {
      defaultValue() {
        return new Date();
      }

    }),
    estimatedStart: attr('date'),
    estimatedEnd: attr('date'),
    sunday: attr('boolean'),
    monday: attr('boolean'),
    tuesday: attr('boolean'),
    wednesday: attr('boolean'),
    thursday: attr('boolean'),
    friday: attr('boolean'),
    saturday: attr('boolean'),
    promisedEnd: attr('string'),
    vehicle: belongsTo('vehicle'),
    place: belongsTo('place'),
    breakType: belongsTo('break-type'),
    provider: belongsTo('provider'),
    shiftStart: Ember.computed('promisedStart', {
      get()
      /* key */
      {
        return Ember.get(this, 'promisedStart');
      },

      set(_, value) {
        const promisedStart = (0, _moment.default)(value, 'HH:mm:ss');
        const promisedEnd = (0, _moment.default)(Ember.get(this, 'promisedEnd'), 'HH:mm:ss');
        this.set('plannedDuration', promisedEnd.diff(promisedStart, 'seconds'));
        this.set('promisedStart', promisedStart.format('HH:mm:ss'));
        this.set('requestTime', promisedStart.format('HH:mm:ss'));
        return value;
      }

    }),
    shiftEnd: Ember.computed('plannedDuration', {
      get()
      /* key */
      {
        const promisedStart = (0, _moment.default)(Ember.get(this, 'promisedStart'), 'HH:mm:ss');
        const promisedEnd = promisedStart.add(Ember.get(this, 'plannedDuration'), 'seconds');
        /*eslint-disable */

        this.set('promisedEnd', promisedEnd.format('HH:mm:ss'));
        /*eslint-enable */

        return promisedEnd.format('HH:mm');
      },

      set(_, value) {
        const promisedEnd = (0, _moment.default)(value, 'HH:mm:ss');
        const promisedStart = (0, _moment.default)(Ember.get(this, 'promisedStart'), 'HH:mm:ss');
        this.set('plannedDuration', promisedEnd.diff(promisedStart, 'seconds'));
        this.set('promisedEnd', promisedEnd.format('HH:mm:ss'));
        return value;
      }

    }),
    formattedShiftStart: Ember.computed('shiftStart', function () {
      return (0, _moment.default)(this.get('shiftStart'), 'HH:mm:ss').format('h:mm a');
    }),
    formattedShiftEnd: Ember.computed('shiftEnd', function () {
      return (0, _moment.default)(this.get('shiftEnd'), 'HH:mm:ss').format('h:mm a');
    }),
    selectedDOWs: Ember.computed('sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', function () {
      const selectedDOWArray = [];
      if (this.get('sunday')) selectedDOWArray.push('sunday');
      if (this.get('monday')) selectedDOWArray.push('monday');
      if (this.get('tuesday')) selectedDOWArray.push('tuesday');
      if (this.get('wednesday')) selectedDOWArray.push('wednesday');
      if (this.get('thursday')) selectedDOWArray.push('thursday');
      if (this.get('friday')) selectedDOWArray.push('friday');
      if (this.get('saturday')) selectedDOWArray.push('saturday');
      return selectedDOWArray;
    })
  });

  _exports.default = _default;
});