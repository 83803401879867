define("adept-iq/utils/turn-by-turn-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.renderTurnByTurnIcon = _exports.MANEUVER_MAP = void 0;
  const MANEUVER_MAP = {
    'ARRIVE': '/arrows-icons/straight.svg',
    'ARRIVE_LEFT': '/arrows-icons/straight.svg',
    'ARRIVE_RIGHT': '/arrows-icons/straight.svg',
    'DEPART': '/arrows-icons/straight.svg',
    'STRAIGHT': '/arrows-icons/straight.svg',
    'KEEP_RIGHT': '/arrows-icons/right1.svg',
    'BEAR_RIGHT': '/arrows-icons/right2.svg',
    'TURN_RIGHT': '/arrows-icons/right3.svg',
    'SHARP_RIGHT': '/arrows-icons/right3.svg',
    'KEEP_LEFT': '/arrows-icons/left1.svg',
    'BEAR_LEFT': '/arrows-icons/left2.svg',
    'TURN_LEFT': '/arrows-icons/left3.svg',
    'SHARP_LEFT': '/arrows-icons/left3.svg',
    'MAKE_UTURN': '/arrows-icons/uturn.svg',
    'ENTER_MOTORWAY': '/arrows-icons/straight.svg',
    'ENTER_FREEWAYIVE': '/arrows-icons/straight.svg',
    'ENTER_FREEWAY': '/arrows-icons/straight.svg',
    'ENTER_HIGHWAY': '/arrows-icons/straight.svg',
    'TAKE_EXIT': '/arrows-icons/straight.svg',
    'MOTORWAY_EXIT_LEFT': '/arrows-icons/straight.svg',
    'MOTORWAY_EXIT_RIGHT': '/arrows-icons/straight.svg',
    'TAKE_FERRY': '/arrows-icons/straight.svg',
    'ROUNDABOUT_CROSS': '/arrows-icons/straight.svg',
    'ROUNDABOUT_RIGHT': '/arrows-icons/straight.svg',
    'ROUNDABOUT_LEFT': '/arrows-icons/straight.svg',
    'ROUNDABOUT_BACK': '/arrows-icons/straight.svg',
    'TRY_MAKE_UTURN': '/arrows-icons/straight.svg',
    'FOLLOW': '/arrows-icons/straight.svg',
    'SWITCH_PARALLEL_ROAD': '/arrows-icons/straight.svg',
    'SWITCH_MAIN_ROAD': '/arrows-icons/straight.svg',
    'ENTRANCE_RAMP': '/arrows-icons/straight.svg',
    'WAYPOINT_LEFT': '/arrows-icons/straight.svg',
    'WAYPOINT_RIGHT': '/arrows-icons/straight.svg',
    'WAYPOINT_REACHED': '/arrows-icons/straight.svg'
  };
  _exports.MANEUVER_MAP = MANEUVER_MAP;

  const renderTurnByTurnIcon = guidance => {
    return MANEUVER_MAP[guidance.maneuver];
  };

  _exports.renderTurnByTurnIcon = renderTurnByTurnIcon;
});