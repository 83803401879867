define("adept-iq/services/notifications", ["exports", "ember-concurrency", "moment", "adept-iq/utils/unwrapProxy"], function (_exports, _emberConcurrency, _moment, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const configOptions = {
    informational: {},
    warning: {},
    success: {},
    error: {},
    // deprecated
    emergency: {},
    emergencyRedisplay: 60000
  };

  var _default = Ember.Service.extend({
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    session: Ember.inject.service(),
    hifi: Ember.inject.service(),
    user: Ember.inject.service(),
    loggedRoadSuperVisorVehicle: null,
    topFlashMessage: Ember.computed('flashMessages.queue.[]', function () {
      return this.get('flashMessages').peekFirst();
    }),

    monitorEmergency(alert) {
      const message = this.generateEmergencyMessage(alert, true);
      this.emergency(message, {}, alert);
      alert.set('emergencyActive', true);
    },

    generateEmergencyMessage(alert, flag) {
      let message = '';
      const vehicleName = alert.get('vehicle.name');
      const driverName = alert.get('driver.name');
      const routeName = alert.get('route.name');
      const isRoadSupEnable = this.user.isRoadSupEnable();

      if (flag) {
        if (routeName && vehicleName && driverName) {
          message = `Emergency message received from Driver ${driverName} on route ${routeName} in Vehicle ${vehicleName}`;
        } else if (vehicleName && driverName) {
          message = `Emergency message received from Driver ${driverName} in Vehicle ${vehicleName}`;
        } else if (vehicleName) {
          message = `Emergency message received from Vehicle ${vehicleName}`;
        } else if (driverName) {
          message = `Emergency message received from Driver ${driverName}`;
        } else if (routeName) {
          message = `Emergency message received on Route ${routeName} in Vehicle ${vehicleName}`;
        } else {
          message = 'Emergency message received';
        }
      } else if (isRoadSupEnable) {
        message = 'NEW MESSAGE FROM DISPATCH';
      }

      return message;
    },

    monitorForEmergencyTask: (0, _emberConcurrency.task)(function* () {
      const alerts = this.get('workspaceContext._structuredWorkspace')['avlm-alert'];
      alerts.forEach(alert => {
        if (!alert.emergencyActive && alert.category === 'emergency' && alert.state === 'active' && (0, _moment.default)().format('x') / 1000 - alert.timestamp.getTime() / 1000 < 60 && alert.isNewEmergencyReceived && !alert.emergency.content.isCanceled && !alert.emergency.content.isAcknowledged && !alert.emergency.content.isResolved && !alert.emergency.content.isAcknowledgedPending) {
          const message = this.generateEmergencyMessage(alert, true);
          this.emergency(message, {}, alert);
          alert.set('emergencyActive', true);
          const emergencyRecords = this.get('store').peekAll('avlm-alert');
          emergencyRecords.forEach(rec => {
            rec.set('isNewEmergencyReceived', false);
          });
        } else if (!alert.emergencyActive && alert.category === 'emergency' && alert.state === 'active' && (0, _moment.default)().format('x') / 1000 - alert.timestamp.getTime() / 1000 > 60 && !alert.emergency.content.isAcknowledged && alert.emergency.content.isEmergency) {
          this.monitorEmergency(alert);
        }
      });
      yield (0, _emberConcurrency.timeout)(configOptions.emergencyRedisplay);
      return this.monitorForEmergencyTask.perform();
    }),
    monitorForMessageTask: (0, _emberConcurrency.task)(function* () {
      const bannerMessage = this.get('workspaceContext._structuredWorkspace')['avlm-canned-message'];

      if (localStorage.getItem('dashboard-info')) {
        const dashboardInfo = localStorage.getItem('dashboard-info');
        const parsedDashboardInfo = JSON.parse(dashboardInfo);

        if (parsedDashboardInfo && parsedDashboardInfo.accessData) {
          const accessDataObj = parsedDashboardInfo.accessData;
          this.loggedRoadSuperVisorVehicle = accessDataObj.vehicleId;
        }
      }

      bannerMessage.forEach(bannerMessages => {
        const vehicle = (0, _unwrapProxy.unwrapProxy)(bannerMessage.vehicle);

        if (vehicle && vehicle.id === this.loggedRoadSuperVisorVehicle && (0, _moment.default)().format('x') / 1000 - bannerMessages.deliveredTime.getTime() / 1000 < 20 && bannerMessages.isNewMessageReceived && bannerMessages.isRecieved) {
          const message = this.generateEmergencyMessage(bannerMessages, false);
          this.info(message, {}, bannerMessages);
          bannerMessages.set('messageActive', true);
          const records = this.get('store').peekAll('avlm-canned-message');
          records.forEach(rec => {
            rec.set('isNewMessageReceived', false);
          });
        }
      });
      yield (0, _emberConcurrency.timeout)(15000);
      return this.monitorForMessageTask.perform();
    }),

    setup() {
      const isRoadSupEnable = this.user.isRoadSupEnable(); // Currently this is called by the dashboard route to fetch configuration
      // and start monitoring for emergencies
      // Setup config for notification timeouts. Config exists for background and font
      // color as well, but these are being deprecated

      this.store.query('cs-config-item', {
        category: 'config-Notification_Banners'
      }).then(configs => {
        // Setup config for notification timeouts by type
        configs.filterBy('name', 'timeout_seconds').forEach(config => {
          const categoryName = config.get('configCategory.name').toLowerCase();
          let value = config.get('value');

          if (config.get('type') === 'Int') {
            value = parseInt(value, 10) * 1000; // convert to milliseconds

            configOptions[categoryName].timeout = value;
          }
        }); // Setup config for emergency redisplay timeout

        const emergencyRedisplayConfig = configs.find(item => {
          return item.get('configCategory.name') === 'Emergency' && item.get('name') === 're_display_seconds';
        });

        if (emergencyRedisplayConfig && emergencyRedisplayConfig.get('type') === 'Int') {
          let value = emergencyRedisplayConfig.get('value');
          value = parseInt(value, 10) * 1000; // convert to milliseconds

          configOptions.emergencyRedisplay = value;
        }
      }); // Start a task that runs at a set interval to check for emergency alerts

      if (!isRoadSupEnable) {
        this.monitorForEmergencyTask.perform();
      }

      this.monitorForMessageTask.perform();
    },

    // Emergency messages are generated by the system where user action is required
    emergency(message, opts = {}, alert) {
      // Combine options passed in with those set by the config service
      const options = Ember.assign(configOptions.emergency, opts, {
        onDestroy() {
          if (alert) alert.set('emergencyActive', false);
        }

      });
      this.get('flashMessages').emergency(message, options);
    },

    // Warning messages are used for providing feedback to a user when an explicit
    // action they take fails, ie. assigning a driver results in a 400 error
    warning(message, opts = {}) {
      // Combine options passed in with those set by the config service
      const options = Ember.assign(configOptions.warning, opts);
      this.get('flashMessages').warning(message, options);
    },

    info(message, opts = {}, alert) {
      const isRoadSupEnable = this.user.isRoadSupEnable(); // Combine options passed in with those set by the config service

      const options = Ember.assign(configOptions.informational, opts, {
        onDestroy() {
          if (alert) alert.set('messageActive', false);
        }

      });
      this.get('flashMessages').info(message, options);

      if (isRoadSupEnable) {
        const oggSource = '/notification_sound.mp3';
        this.get('hifi').play(oggSource).then(({
          sound
        }) => {
          sound.play(oggSource, {
            volume: 10
          });
        }).catch(error => {
          // eslint-disable-next-line no-console
          console.log('error', error);
        });
      }
    },

    // Success messages are used for providing feedback to a user when they take
    // an explicit action and it is successful
    success(message, opts = {}) {
      // Combine options passed in with those set by the config service
      const options = Ember.assign(configOptions.success, opts);
      this.get('flashMessages').success(message, options);
    }

  });

  _exports.default = _default;
});