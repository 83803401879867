define("adept-iq/models/avlm-driver-state-event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default; // notes allowable values in driver state event
  // A - active - Driver is executing route
  // F - signedOff - Driver has signed off
  // B - onBreak - Driver is on Break
  // T - tempOff - not sure
  // W - Working, - Driver signed on
  // E - emergencyOn, - Driver has an emergency
  // M - emergencyOff - Driver doesn't have an emergency

  const signedOnCode = 'W';
  const activeCode = 'A';

  var _default = Model.extend({
    state: attr('string'),
    updatedAt: attr('date'),
    createdAt: attr('date'),
    deletedAt: attr('date'),
    receivedAt: attr('date'),
    lat: attr('number'),
    lng: attr('number'),
    odo: attr('number'),
    speed: attr('number'),
    heading: attr('number'),
    // latest driver state event
    // currently it's linked to route-vehicle-driver not if it's necessary to link it to individual models
    // driver: belongsTo('driver'),
    // vehicle: belongsTo('vehicle'),
    // route: belongsTo('dispatch-route'),
    routeVehicleDriver: belongsTo('route-vehicle-driver'),
    schedule: belongsTo('schedule'),
    isSignedOn: Ember.computed('state', function () {
      const state = this.get('state');
      return state === signedOnCode || state === activeCode;
    })
  });

  _exports.default = _default;
});