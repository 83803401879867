define("adept-iq/models/rider-phone-number", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    areaCode: attr('string'),
    phoneNumber: attr('string'),
    extension: attr('string'),
    type: attr('string', {
      defaultValue: 'default'
    }),
    description: attr('string', {
      defaultValue: 'default'
    }),
    mobileCarrier: attr('string', {
      defaultValue: 'default'
    }),
    useForSMSNotifications: attr('string', {
      defaultValue: false
    }),
    useForVoiceNotifications: attr('string', {
      defaultValue: false
    }),
    rider: belongsTo('rider'),
    formattedPhoneNumber: Ember.computed('areaCode', 'phoneNumber', 'extension', function () {
      const phone = this.get('phoneNumber').match(/\d{3}(?=\d{2,3})|\d+/g).join('-');
      const extension = this.get('extension');
      const areaCode = this.get('areaCode');

      if (extension) {
        return `(${areaCode}) ${phone}-#${extension}`;
      }

      return `(${areaCode}) ${phone}`;
    })
  });

  _exports.default = _default;
});