define("adept-iq/models/avlm-vehicle", ["exports", "ember-data", "adept-iq/mixins/otp-label", "adept-iq/utils/workspace-availability"], function (_exports, _emberData, _otpLabel, _workspaceAvailability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var hasMany = _emberData.default.hasMany;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend(_otpLabel.default, {
    workspace: Ember.inject.service(),
    callSign: attr('string'),
    providerName: attr('string'),
    otp: attr('string'),
    provider: belongsTo('provider'),
    avlmRoute: belongsTo('avlm-route'),
    etaRoute: belongsTo('etanav-route'),
    vehicleInfo: belongsTo('avlm-vehicle-info'),
    iqVehicle: belongsTo('iq-vehicle', {
      inverse: 'avlmVehicle'
    }),
    cannedMessages: hasMany('avlm-canned-message'),
    emergencyMessages: hasMany('avlm-emergency-message'),
    avlmRouteVehicleDrivers: hasMany('avlm-route-vehicle-driver'),
    // only store most current ping
    avlLocation: belongsTo('avl-location', {
      inverse: 'avlmVehicle'
    }),
    lat: Ember.computed.readOnly('avlLocation.lat'),
    lng: Ember.computed.readOnly('avlLocation.lng'),
    heading: Ember.computed.readOnly('avlLocation.heading'),
    speed: Ember.computed.readOnly('avlLocation.speed'),
    odo: Ember.computed.readOnly('avlLocation.odo'),
    timestamp: Ember.computed.readOnly('avlLocation.timestamp'),
    workspaceStartTime: Ember.computed.alias('workspace.startDate'),
    workspaceEndTime: Ember.computed.alias('workspace.endDate'),
    name: Ember.computed.alias('callSign'),
    isEmergencyVehicle: Ember.computed.alias('lastEmergencyMessage.isEmergency'),
    isApprovedEmergency: Ember.computed.alias('lastEmergencyMessage.isAcknowledged'),
    isApprovedEmergencyPending: Ember.computed.alias('lastEmergencyMessage.isAcknowledgedPending'),
    latestDriver: Ember.computed.alias('latestRouteVehicleDriver.driver'),
    latestRoute: Ember.computed.alias('latestRouteVehicleDriver.route'),
    latestRouteStatus: Ember.computed.alias('latestRouteVehicleDriver.route.status'),
    startAddress: Ember.computed.alias('latestRoute.startAddress'),
    endAddress: Ember.computed.alias('latestRoute.endAddress'),
    avlmAlert: hasMany('avlm-alert'),
    mapId: Ember.computed('lastEmergencyMessage.{isEmergency,isAcknowledged,isCanceled}', function () {
      const id = 'avlm-vehicle';
      const message = this.get('lastEmergencyMessage');
      if (!message) return id;else if (message.get('isEmergency') || message.get('isAcknowledged')) return 'avlm-vehicle-emergency';
      return id;
    }),
    latestRouteVehicleDriver: Ember.computed('currentRouteVehicleDrivers.@each.startTime', function () {
      const routeVehicleDrivers = this.get('currentRouteVehicleDrivers');
      if (!routeVehicleDrivers) return [];
      return routeVehicleDrivers.sortBy('startTime').get('lastObject');
    }),
    currentRouteVehicleDrivers: Ember.computed('avlmRouteVehicleDrivers.[]', 'workspaceStartTime', 'workspaceEndTime', function () {
      let routeVehicleDrivers = this.get('avlmRouteVehicleDrivers');
      const startTime = this.get('workspaceStartTime');
      const endTime = this.get('workspaceEndTime');
      if (!routeVehicleDrivers) return [];
      routeVehicleDrivers = routeVehicleDrivers.filter(routeVehicleDriver => {
        if (routeVehicleDriver.get('route.status') === 'C' || routeVehicleDriver.get('route.status') === 'Completed') {
          (0, _workspaceAvailability.processTimeConstraints)(routeVehicleDriver.get('route.tripStartTime'), routeVehicleDriver.get('route.tripEndTime'), startTime, endTime);
        } else {
          return true;
        }
      });
      return routeVehicleDrivers;
    }),
    active: Ember.computed('latestDriver', function () {
      return this.get('latestDriver') ? 'Active' : 'Inactive';
    }),
    sortedEmergencyMessages: Ember.computed('emergencyMessages.[]', function () {
      return this.get('emergencyMessages').sortBy('createdTime');
    }),
    // contains an emergency message with status unresolved or acknowledged
    lastEmergencyMessage: Ember.computed('sortedEmergencyMessages.[]', function () {
      const emergencyMessage = this.get('sortedEmergencyMessages').lastObject;
      return emergencyMessage;
    }),
    latestReceivedEmergencyMessage: Ember.computed('sortedEmergencyMessages.[]', function () {
      const emergencyMessages = this.get('sortedEmergencyMessages').toArray();

      for (let i = emergencyMessages.length - 1; i >= 0; i--) {
        if (emergencyMessages[i].get('isEmergency') || emergencyMessages[i].get('isAcknowledged')) {
          return emergencyMessages[i];
        }
      }

      return null;
    }),
    vehicleStatus: Ember.computed('lastEmergencyMessage.{isEmergency,isAcknowledged,isCanceled,isAcknowledgedPending}', 'latestRoute.trackingId', 'latestRouteStatus', function () {
      const message = this.get('lastEmergencyMessage');
      const routeName = this.get('latestRoute.trackingId');
      const routeStatus = this.get('latestRouteStatus');
      if (routeName && routeName.includes('SUP') && routeStatus !== 'C') return 'In Progress';
      if (routeName && routeName.includes('SUP') && routeStatus === 'C') return '';
      if (!message) return '';

      if (message.get('isEmergency')) {
        return 'Emergency Received';
      }

      if (message.get('isAcknowledged')) {
        return 'Emergency Acknowledged';
      }

      if (message.get('isAcknowledgedPending')) {
        return 'Pending';
      }

      return '';
    }),
    point: Ember.computed.collect('lat', 'lng')
  });

  _exports.default = _default;
});