define("adept-iq/pods/components/iq-widgets/cancel-type-form-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    editableSections: [{
      title: 'Cancel Reason',
      fields: [{
        id: 'cancelType',
        type: 'enum',
        label: 'Reason',
        valuePath: 'cancelType',
        cellDesc: 'cancelType',
        editable: true,
        hidden: false,
        extra: {
          optionModelName: 'cancel-type',
          optionSearchPath: 'description'
        }
      }, {
        id: 'notes',
        type: 'text',
        editable: true,
        label: 'Notes',
        valuePath: 'cancelNotes'
      }]
    }, {
      title: 'Trip Information',
      fields: [{
        id: 'riderFirstName',
        label: 'First Name',
        valuePath: 'leg.rider.firstName',
        editable: false
      }, {
        id: 'riderLastName',
        label: 'Last Name',
        valuePath: 'leg.rider.lastName',
        editable: false
      }, {
        id: 'externalId',
        label: 'Trip ID',
        valuePath: 'id',
        editable: false
      }, {
        id: 'requestedTime',
        label: 'Promise Time',
        valuePath: 'formattedPromiseTime',
        editable: false
      }, {
        id: 'pickupaddress',
        label: 'Pick Up Address ',
        valuePath: 'pick.place.location.fullAddress',
        editable: false
      }, {
        id: 'dropaddress',
        label: 'Drop Off Address',
        valuePath: 'drop.place.location.fullAddress',
        editable: false
      }, {
        id: 'anchor',
        label: 'Anchor',
        valuePath: 'anchor',
        editable: false
      }]
    }]
  };
  _exports.default = _default;
});