define("adept-iq/services/active-context", ["exports", "adept-iq/config/environment", "adept-iq/config/filter-types", "adept-iq/config/server-relationships", "adept-iq/utils/graph", "adept-iq/utils/rql", "adept-iq/utils/sorts", "lodash", "adept-iq/config/active-context-graph", "adept-iq/config/active-context-graph-avlmlite", "adept-iq/utils/local-traversal", "adept-iq/utils/filters", "ember-concurrency"], function (_exports, _environment, _filterTypes, _serverRelationships, _graph, _rql, _sorts, _lodash, _activeContextGraph, _activeContextGraphAvlmlite, _localTraversal, _filters, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const activeContextGraph = _environment.default.APP.avlmLite ? _activeContextGraphAvlmlite.avlmActiveContextGraph : _activeContextGraph.coreActiveContextGraph;
  const activeContextNodes = _environment.default.APP.avlmLite ? _activeContextGraphAvlmlite.avlmActiveContextNodes : _activeContextGraph.coreActiveContextNodes;

  const flattenData = data => {
    return Object.entries(data).reduce((arr, [modelName, records]) => {
      records.forEach(record => {
        arr.push({
          modelName,
          record
        });
      });
      return arr;
    }, []);
  };

  var _default = Ember.Service.extend(Ember.Evented, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    workspace: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    user: Ember.inject.service(),
    checkedItems: null,
    refreshedModelNames: null,
    emptyImplicitHash: null,
    refreshInProgress: false,
    workspaceData: Ember.computed.alias('workspaceContext._structuredWorkspace'),
    consumedModelNames: Ember.computed('workspace.dashboardInstance.widgets.[]', function () {
      const widgets = this.get('workspace.dashboardInstance.widgets');

      const widgetModels = _lodash.default.reduce(widgets, (acc, widget) => _lodash.default.union(acc, widget.get('models')), []);

      return widgetModels;
    }),
    // structured data sets (grouped by model name)
    structuredActiveData: Ember.computed('workspaceData', 'checkedItems.[]', function () {
      try {
        const workspaceData = this.get('workspaceData');
        const checkedItems = this.get('checkedItems');
        return (0, _localTraversal.computeActiveContext)(workspaceData, checkedItems);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Exception occured while refreshing Workspace Data in structuredActiveData' + e);
      }
    }),
    structuredImplicitData: Ember.computed('workspaceData', 'checkedItems.[]', function () {
      try {
        const workspaceData = this.get('workspaceData');
        const checkedItems = this.get('checkedItems');
        return (0, _localTraversal.computeImplicitContext)(workspaceData, checkedItems);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Exception occured while refreshing Workspace Data in structuredImplicitData' + e);
      }
    }),
    // flattened data sets for easy binding & interation
    activeData: Ember.computed('structuredActiveData', function () {
      const structuredActiveData = this.get('structuredActiveData');
      return flattenData(structuredActiveData);
    }),
    implicitData: Ember.computed('structuredImplicitData', function () {
      const structuredImplicitData = this.get('structuredImplicitData');
      return flattenData(structuredImplicitData);
    }),
    hashImplicitData: Ember.computed('structuredImplicitData', function () {
      const structuredImplicitData = this.get('structuredImplicitData');
      const hash = this.get('emptyImplicitHash');

      _lodash.default.forOwn(structuredImplicitData, (value, key) => {
        hash[key] = {};
        value.forEach(v => {
          const id = v.get('id');
          hash[key][id] = true;
        });
      });

      return hash;
    }),
    // map-context binds to this
    implicitStops: Ember.computed('implicitData', function () {
      const implicitData = Ember.makeArray(this.get('implicitData'));
      if (_environment.default.APP.avlmLite) return implicitData.filterBy('modelName', 'avlm-stop').mapBy('record');
      return implicitData.filterBy('modelName', 'stop-point').mapBy('record');
    }),
    emergencyVehicles: Ember.computed('workspaceData', function () {
      const activeVehicles = _environment.default.APP.avlmLite ? this.get('workspaceData')['avlm-vehicle'] : this.get('workspaceData').vehicle; // find all emergency vehicles that current have an emergency or approved emergency

      if (this.user.isRoadSupEnable()) {
        if (localStorage.getItem('dashboard-info')) {
          const dashboardInfo = localStorage.getItem('dashboard-info');
          const parsedDashboardInfo = JSON.parse(dashboardInfo);

          if (parsedDashboardInfo && parsedDashboardInfo.accessData) {
            const accessDataObj = parsedDashboardInfo.accessData;
            return activeVehicles.filter(vehicle => vehicle.isEmergencyVehicle || vehicle.isApprovedEmergency || vehicle.id === accessDataObj.vehicleId);
          }
        }
      } else {
        return activeVehicles.filter(vehicle => vehicle.isEmergencyVehicle || vehicle.isApprovedEmergency);
      }
    }),

    tripActiveContext() {
      return this.get('checkedItems').some(record => {
        return record.modelName === 'iq-trip' || record.modelName === 'avlm-trip';
      });
    },

    init() {
      this._super(...arguments);

      this.set('checkedItems', []);
      this.set('refreshedModelNames', []);

      this._onWorkspaceContextChange = modelNames => {
        Ember.run.schedule('actions', this, 'onWorkspaceContextChange', modelNames);
      };

      const nodes = activeContextNodes.reduce((acu, node) => {
        acu[node.modelName] = {};
        return acu;
      }, {});
      this.set('emptyImplicitHash', nodes);
      this.get('workspaceContext').on('change', this._onWorkspaceContextChange);
      this.set('activeContextNodes', activeContextNodes);
      this.startRefreshQueue();
    },

    reset() {
      this.set('checkedItems', []);
      this.notifyPropertyChange('implicitData');
    },

    destroy() {
      this.get('workspaceContext').off('change', this._onWorkspaceContextChange);

      this._super(...arguments);
    },

    async query(modelName, params = {}, extra = {}) {
      if (_environment.default.APP.localFilteringEnabled) {
        return await Ember.RSVP.resolve(this.queryLocal(modelName, params));
      }

      return await this.queryServer(modelName, params, extra);
    },

    queryLocal(modelName, params = {}) {
      const compareFn = (0, _sorts.buildCompareFunction)(params.sorts);
      const filterFn = (0, _filters.buildFilterFunction)(params.filter);
      const activeData = this.get('structuredActiveData');
      return activeData[modelName].filter(filterFn).sort(compareFn);
    },

    // currently unused
    queryServer(modelName, params = {}, extra = {}) {
      const {
        filter,
        includes
      } = this._buildActiveContextParams(modelName);

      const extendedFilter = (0, _filters.buildCompoundFilterNode)('and', [filter, params.filter]);
      const extendedIncludes = [];

      if (!Ember.isEmpty(includes)) {
        extendedIncludes.addObjects(includes);
      }

      if (!Ember.isEmpty(params.includes)) {
        extendedIncludes.addObjects(params.includes);
      }

      const extendedParams = _lodash.default.merge({}, params, {
        filter: extendedFilter,
        includes: extendedIncludes
      });

      let queryParams = (0, _rql.buildQueryParams)(extendedParams);
      queryParams = _lodash.default.merge({}, queryParams, extra);
      return this.get('store').query(modelName, queryParams);
    },

    refreshAll() {
      const modelNames = activeContextNodes.mapBy('modelName');
      this.refreshTableContent.perform(modelNames);
    },

    // do not refresh if it is already on the list of
    // refresh to do
    addUniqueModelNamesRefresh(modelNames) {
      const newModelNames = Ember.makeArray(modelNames); // remove any models that aren't actively being listened for

      const consumedModels = this.get('consumedModelNames');

      const filteredModelNames = _lodash.default.filter(newModelNames, model => consumedModels.includes(model));

      const refreshedModelNames = this.get('refreshedModelNames');
      filteredModelNames.forEach(modelName => {
        if (!refreshedModelNames.includes(modelName)) {
          refreshedModelNames.pushObject(modelName);
        }
      });
    },

    refreshTableContent: (0, _emberConcurrency.task)(function* (modelNames) {
      this.addUniqueModelNamesRefresh(modelNames);
      yield this.startRefreshQueue();
    }).restartable(),

    isRecordChecked(record) {
      if (!Ember.isNone(record)) {
        const checkedItems = this.get('checkedItems');
        const modelName = record.constructor.modelName;
        const modelId = record.get('id');
        return checkedItems.any(item => {
          return item.modelName === modelName && item.record.get('id') === modelId;
        });
      }

      return false;
    },

    isMultiRecordsChecked(record) {
      if (!Ember.isNone(record)) {
        const checkedItems = this.get('checkedItems');
        const modelName = record.constructor.modelName;
        let numRecordsChecked = 0;
        checkedItems.forEach(item => {
          if (item.modelName === modelName) {
            numRecordsChecked++;
          }
        });
        return numRecordsChecked > 1;
      }

      return false;
    },

    clearCheckedItems() {
      this.set('checkedItems', []);
      this.notifyPropertyChange('implicitData');
      this.refreshAll();
    },

    setRecordsChecked(records, checked) {
      const store = this.get('store');
      const checkedItems = this.get('checkedItems').slice();
      Ember.makeArray(records).forEach(record => {
        const isChecked = this.isRecordChecked(record);
        const storeRecord = store.peekRecord(record.constructor.modelName, record.get('id'));

        if (isChecked === checked) {
          return;
        }

        if (checked) {
          checkedItems.push({
            modelName: record.constructor.modelName,
            record: storeRecord
          });
        } else {
          const item = checkedItems.findBy('record', storeRecord);
          checkedItems.removeObject(item);
        }
      });
      this.set('checkedItems', checkedItems);
      this.notifyPropertyChange('implicitData');
      const modelNames = records.mapBy('constructor.modelName').uniq();
      modelNames.forEach(modelName => {
        const relatedModelNames = this._getRelatedModelNames(modelName);

        this.addUniqueModelNamesRefresh(relatedModelNames);
      });
      this.refreshAll();
    },

    replaceCheckedRecordsByType(records) {
      const modelNames = Ember.makeArray(records).mapBy('constructor.modelName').uniq(); // uncheck any records with modelName matching an argument element

      const checkedItems = this.get('checkedItems').reject(({
        record
      }) => {
        return modelNames.includes(record.constructor.modelName);
      });
      records.forEach(record => {
        checkedItems.pushObject({
          modelName: record.constructor.modelName,
          record
        });
      });
      this.set('checkedItems', checkedItems);
      modelNames.forEach(modelName => {
        const relatedModelNames = this._getRelatedModelNames(modelName);

        this.addUniqueModelNamesRefresh(relatedModelNames);
      });
      this.notifyPropertyChange('implicitData');
    },

    _getRelatedModelNames(modelName) {
      const modelNode = activeContextNodes.findBy('modelName', modelName); // queue the neighbours of this modelName's active context node

      const queue = Ember.makeArray(modelNode.links).map(({
        nodeId
      }) => {
        return activeContextGraph[nodeId];
      }); // don't revisit originating node

      const visited = [modelNode];

      const getNeighbours = ({
        links
      }) => {
        return Ember.makeArray(links).map(({
          nodeId
        }) => activeContextGraph[nodeId]);
      };

      const modelNames = [];
      /*eslint-disable */

      const visitNode = ({
        modelName
      }) => {
        modelNames.push(modelName);
      };
      /*eslint-enable */
      // trigger refresh events for related models


      (0, _graph.breadthFirstSearch)({
        queue,
        visited,
        getNeighbours,
        visitNode
      });
      return modelNames;
    },

    // Staggered column widget refresh so UI does not become
    // unresponsive
    startRefreshQueue() {
      setTimeout(() => {
        const refreshInProgress = this.get('refreshInProgress');

        if (!refreshInProgress) {
          const refreshedModelNames = this.get('refreshedModelNames');

          if (refreshedModelNames.length > 0) {
            this.set('refreshInProgress', true);
            this.trigger('refresh', [refreshedModelNames[0]]);
            this.get('refreshedModelNames').shift();
            this.startRefreshQueue();
          }
        }
      }, 10);
    },

    // only used for server-side queries
    _buildActiveContextParams(modelName) {
      const filters = [];
      const includes = [];

      _serverRelationships.serverRelationships[modelName].forEach(relationship => {
        const items = this.get('checkedItems');
        const relatedItems = items.filterBy('modelName', relationship.modelName);

        if (Ember.isEmpty(relatedItems)) {
          return;
        }

        let path; // push necessary includes for filter

        if (relationship.type === 'belongsTo') {
          path = `${relationship.path}Id`;
          const pathSegments = relationship.path.split('.');

          if (pathSegments.length > 1) {
            const parentPath = pathSegments.slice(0, -1).join('.');
            includes.push(parentPath);
          }
        }

        if (relationship.type === 'hasMany') {
          path = `${relationship.path}.id`;
          includes.push(relationship.path);
        } // build filter node


        const values = relatedItems.mapBy('record.id');
        const filter = (0, _filters.buildValueFilterNode)(_filterTypes.uuidIn, path, values);
        filters.push(filter);
      });

      const filter = (0, _filters.buildCompoundFilterNode)('and', filters);
      return {
        filter,
        includes
      };
    },

    _buildTimeFilter(modelName) {
      const filters = [];
      const node = activeContextGraph[modelName]; // select entities that overlap time window, not just that are contained

      if (node.leftTimeConstraint) {
        const endDate = this.get('workspace.endDate').toISOString();
        const filter = (0, _filters.buildValueFilterNode)(_filterTypes.dateLte, node.leftTimeConstraint, [endDate]);
        filters.push(filter);
      }

      if (node.rightTimeConstraint) {
        const startDate = this.get('workspace.startDate').toISOString();
        const filter = (0, _filters.buildValueFilterNode)(_filterTypes.dateGte, node.rightTimeConstraint, [startDate]);
        filters.push(filter);
      }

      return (0, _filters.buildCompoundFilterNode)('and', filters);
    },

    // this fires when the workspace context is modified
    onWorkspaceContextChange(modelNames) {
      const activeContextModelNames = activeContextNodes.mapBy('modelName');
      const didChange = modelNames.any(modelName => {
        return activeContextModelNames.includes(modelName);
      });

      if (didChange) {
        // the debounce value needs to be higher than the one
        // in workspace-context forceRefresh
        this.refreshAll();
      }
    }

  });

  _exports.default = _default;
});