define("adept-iq/pods/components/about-adept/component", ["exports", "adept-iq/config/api-urls", "adept-iq/config/environment"], function (_exports, _apiUrls, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    aboutAdeptDescription: 'ADEPT IQ provides a comprehensive operations management solution for the Demand Response business.',
    versionData: null,
    uiVersion: '',
    uiBuildNumber: '',

    init() {
      const session = this.get('session');
      const ajax = this.get('ajax');

      this._super(...arguments);

      if (_environment.default.aboutAdeptDescription) {
        this.set('aboutAdeptDescription', _environment.default.aboutAdeptDescription);
      }

      return ajax.post(_apiUrls.API.aboutPageService.host, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(response => {
        const defaultVersionData = response ? response.body : {};
        this.set('uiVersion', defaultVersionData.ReleaseVersion);
        this.set('uiBuildNumber', defaultVersionData.BuildNumber);
        this.set('versionData', defaultVersionData.components);
      });
    },

    actions: {
      onCloseAboutAdept() {
        const workspace = this.get('workspace');
        workspace.toggleProperty('isShowAbout');
      }

    }
  });

  _exports.default = _default;
});