define("adept-iq/services/permission-layer", ["exports", "lodash", "adept-iq/pods/components/iq-widgets/roles-form-widget/config", "adept-iq/config/environment"], function (_exports, _lodash, _config, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    permissions: null,
    groupedPermissions: null,
    permissionSections: null,
    categories: null,
    userPermissionHash: null,

    async getPermissions() {
      let permissions = this.get('store').peekAll('sso-permission');

      if (permissions.length === 0) {
        permissions = await this.get('store').findAll('sso-permission');
      }

      this.set('permissions', permissions);
      this.set('permissionSections', this.preparePermissionSections(permissions)); // section sucks
      // @TODO AVLMLITE requires sso perm associations temp hack for A-team

      const userPerms = _environment.default.APP.avlmLite ? Array.from(Array(250).keys()) : this.get('session.data.authenticated.permissions');
      this.configureUserHash(userPerms);
    },

    createPermissionSection(permissions) {
      if (permissions.length === 0) return;
      const permission = permissions[0];
      const perms = permissions.map(perm => perm.id);
      return {
        title: `${permission.category}-Permissions`,
        fields: [{
          id: `${permission.category}-Permissions`,
          type: 'permission',
          valuePath: 'permissions',
          modelName: 'sso-permission',
          isMultiType: true,
          extra: {
            category: `${permission.category}`,
            useOptionRecord: true,
            totalPermIds: perms,
            rolePermIds: []
          }
        }]
      };
    },

    preparePermissionSections(permissions) {
      if (!permissions) return;
      if (permissions.length === 0) return;
      const groupedPermissions = (0, _lodash.groupBy)(permissions.toArray(), 'category');
      this.set('groupedPermissions', groupedPermissions);
      const permKeys = (0, _lodash.keys)(groupedPermissions);
      this.set('categories', permKeys);

      _config.default.editableSections.push(...permKeys.map(key => {
        return this.createPermissionSection(groupedPermissions[key]);
      }));

      return _config.default.editableSections;
    },

    permInUserHash(permId, permIds) {
      let permGranted = false;
      const userPermHash = this.get('userPermissionHash');

      if (userPermHash) {
        if (Ember.isBlank(permId) && permIds) {
          permGranted = permIds.some(id => userPermHash[id]);
        } else permGranted = userPermHash[permId];
      }

      return permGranted;
    },

    configureUserHash(permissions) {
      // need to think of a better way to index the perm hash
      const permIds = Array.from(Array(250).keys());
      const hash = permissions.reduce((acu, perm) => {
        if (permIds) {
          if (permIds.includes(perm)) acu[perm] = true;
        } else {
          acu[perm] = false;
        }

        return acu;
      }, {});
      this.set('userPermissionHash', hash);
    }

  });

  _exports.default = _default;
});