define("adept-iq/services/geocode", ["exports", "adept-iq/config/environment", "adept-iq/config/api-urls", "tomtom", "adept-iq/utils/zone-validation", "adept-iq/pods/components/iq-widgets/map-widget/config/arrow", "adept-iq/pods/components/iq-widgets/map-widget/decorations/arrow", "adept-iq/pods/components/iq-widgets/map-widget/config/polyline", "adept-iq/pods/components/iq-widgets/map-widget/config/marker"], function (_exports, _environment, _apiUrls, _tomtom, _zoneValidation, _arrow, _arrow2, _polyline, _marker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeGeocode: false,
    activeMarker: null,
    activePickMarker: null,
    activeDropMarker: null,
    oldPickMarker: null,
    oldDropMarker: null,
    oldTripPolyline: null,
    activeTripPolyline: null,
    activeTripArrow: null,
    oldMarker: null,
    markerType: '',
    latlng: null,
    tomtomAddress: null,
    zoom: 12,
    currentRecord: null,

    init() {
      this._super(...arguments);
    },

    addressSearchResults(results) {
      const record = this.get('currentRecord.locations.firstObject');

      if (results.length > 0) {
        const latLng = results.firstObject.position;

        const markerOptions = _marker.default[this.get('markerType')];

        this.set('latlng', {
          lat: latLng.lat,
          lng: latLng.lon
        });

        const tomtomMarker = _tomtom.default.L.marker(this.get('latlng'), markerOptions);

        if (this.get('markerType') === 'pick') {
          if (this.get('activePickMarker')) {
            this.set('oldPickMarker', this.get('activePickMarker'));
          }

          this.set('activePickMarker', tomtomMarker);
        } else if (this.get('markerType') === 'drop') {
          if (this.get('activeDropMarker')) {
            this.set('oldDropMarker', this.get('activeDropMarker'));
          }

          this.set('activeDropMarker', tomtomMarker);
        } else {
          this.set('oldMarker', this.get('activeMarker'));
          this.set('activeMarker', tomtomMarker);
        }
      } else if (!Ember.isNone(record) && record.lat !== 0 && record.lng !== 0) {
        this.set('latlng', {
          lat: record.lat,
          lng: record.lng
        });

        const tomtomMarker = _tomtom.default.L.marker(this.get('latlng'));

        if (this.get('markerType') === 'pick') {
          if (this.get('activePickMarker')) {
            this.set('oldPickMarker', this.get('activePickMarker'));
          }

          this.set('activePickMarker', tomtomMarker);
        } else if (this.get('markerType') === 'drop') {
          if (this.get('activeDropMarker')) {
            this.set('oldDropMarker', this.get('activeDropMarker'));
          }

          this.set('activeDropMarker', tomtomMarker);
        } else {
          this.set('oldMarker', this.get('activeMarker'));
          this.set('activeMarker', tomtomMarker);
        }
      }

      if (this.get('activePickMarker') && this.get('activeDropMarker')) {
        const tripPoints = [[this.get('activePickMarker._latlng.lat'), this.get('activePickMarker._latlng.lng')], [this.get('activeDropMarker._latlng.lat'), this.get('activeDropMarker._latlng.lng')]];
        const arrowPoints = tripPoints.slice(tripPoints.length - 2, tripPoints.length);
        /*eslint-disable */

        const polyLineOptions = _polyline.default['dotted'];
        const arrowOptions = _arrow.default['tripArrowPlannedOffset'];
        const activeTripArrow = (0, _arrow2.default)(arrowPoints, arrowOptions);
        /*eslint-enable */

        const tripPolyline = _tomtom.default.L.polyline(tripPoints, polyLineOptions);

        if (this.get('activeTripPolyline')) {
          this.set('oldTripPolyline', this.get('activeTripPolyline'));
        }

        if (this.get('activeTripArrow')) {
          this.set('oldTripArrow', this.get('activeTripArrow'));
        }

        this.set('activeTripArrow', activeTripArrow);
        this.set('activeTripPolyline', tripPolyline);
      }
    },

    findLocationForRecord(record) {
      if (!Ember.isNone(record)) {
        this.set('currentRecord', record);

        if (!Ember.isNone(record.get('streetName'))) {
          if (!Ember.isNone(record.get('streetNumber')) || !Ember.isNone(record.get('streetName')) || !Ember.isNone(record.get('city'))) {
            _tomtom.default.fuzzySearch().key(_environment.default.tomtom.search.searchKey).query(`${record.get('streetNumber')} ${record.get('streetName')} ${record.get('city')}`).countrySet(_environment.default.tomtom.search.countrySet).typeahead(false).limit(1).center(_environment.default.tomtom.search.center).radius(_environment.default.tomtom.search.radius).callback(this.addressSearchResults.bind(this)).go();
          }
        } else {
          /*eslint-disable */
          if (!Ember.isNone(record.get('streetNumber')) || !Ember.isNone(record.get('streetAddress')) || !Ember.isNone(record.get('subRegion'))) {
            _tomtom.default.fuzzySearch().key(_environment.default.tomtom.search.searchKey).query(`${record.get('streetNumber')} ${record.get('streetAddress')} ${record.get('subRegion')}`).countrySet(_environment.default.tomtom.search.countrySet).typeahead(false).limit(1).center(_environment.default.tomtom.search.center).radius(_environment.default.tomtom.search.radius).callback(this.addressSearchResults.bind(this)).go();
          }
          /*eslint-enable */

        }
      }
    },

    activateGeocode(activeGeocode, record, markerType) {
      this.findLocationForRecord(record);
      this.set('activeGeocode', activeGeocode);
      this.set('markerType', markerType);
    },

    deactivateGeocode() {
      Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');

      if (this.get('activeMarker')) {
        this.get('activeMarker').remove();
        this.set('activeMarker', null);
      }

      if (this.get('oldMarker')) {
        this.get('oldMarker').remove();
        this.set('oldMarker', null);
      }

      if (this.get('oldPickMarker')) {
        this.get('oldPickMarker').remove();
        this.set('oldPickMarker', null);
      }

      if (this.get('oldDropMarker')) {
        this.get('oldDropMarker').remove();
        this.set('oldDropMarker', null);
      }

      if (this.get('activePickMarker')) {
        this.get('activePickMarker').remove();
        this.set('activePickMarker', null);
      }

      if (this.get('activeDropMarker')) {
        this.get('activeDropMarker').remove();
        this.set('activeDropMarker', null);
      }

      if (this.get('oldTripPolyline')) {
        this.get('oldTripPolyline').remove();
        this.set('oldTripPolyline', null);
      }

      if (this.get('activeTripPolyline')) {
        this.get('activeTripPolyline').remove();
        this.set('activeTripPolyline', null);
      }

      if (this.get('activeTripArrow')) {
        this.get('activeTripArrow').remove();
        this.set('activeTripArrow', null);
      }

      this.set('activeGeocode', false);
      this.set('tomtomAddress', null);
      this.set('markerType', '');
    },

    reverseGeocodeResult(result) {
      const latlng = this.get('latlng');
      result.position = {
        lat: parseFloat(latlng.lat.toFixed(5)),
        lon: parseFloat(latlng.lng.toFixed(5))
      };
      this.set('tomtomAddress', result);
    },

    saveNewGeocode(latlng, tomtomAddress) {
      this.set('latlng', latlng); // if the point is outside the valid zone polygon
      // return error and exit

      if (!(0, _zoneValidation.insideZone)([latlng.lat, latlng.lng])) {
        this.get('notifications').warning('Unable to save. Location must be within the agency service boundary.');
        return;
      }

      const tomtomMarker = _tomtom.default.L.marker(latlng);

      if (Ember.isNone(tomtomAddress)) {
        _tomtom.default.reverseGeocode().key(_environment.default.tomtom.search.searchKey).position(`${latlng.lat},${latlng.lng}`).go().then(this.reverseGeocodeResult.bind(this));
      } else {
        this.set('tomtomAddress', tomtomAddress);
      }

      if (this.get('markerType') === 'pick') {
        this.set('oldPickMarker', this.get('activePickMarker'));
        this.set('activePickMarker', tomtomMarker);
      } else if (this.get('markerType') === 'drop') {
        this.set('oldDropMarker', this.get('activeDropMarker'));
        this.set('activeDropMarker', tomtomMarker);
      } else {
        this.set('oldMarker', this.get('activeMarker'));
        this.set('activeMarker', tomtomMarker);
      }

      if (this.get('activePickMarker') && this.get('activeDropMarker')) {
        const tripPoints = [[this.get('activePickMarker._latlng.lat'), this.get('activePickMarker._latlng.lng')], [this.get('activeDropMarker._latlng.lat'), this.get('activeDropMarker._latlng.lng')]];
        /*eslint-disable */

        const arrowPoints = tripPoints.slice(tripPoints.length - 2, tripPoints.length);
        const polyLineOptions = _polyline.default['dotted'];
        const arrowOptions = _arrow.default['tripArrowPlannedOffset'];
        const activeTripArrow = (0, _arrow2.default)(arrowPoints, arrowOptions);
        /*eslint-enable */

        const tripPolyline = _tomtom.default.L.polyline(tripPoints, polyLineOptions);

        if (this.get('activeTripPolyline')) {
          this.set('oldTripPolyline', this.get('activeTripPolyline'));
        }

        if (this.get('activeTripArrow')) {
          this.set('oldTripArrow', this.get('activeTripArrow'));
        }

        this.set('activeTripArrow', activeTripArrow);
        this.set('activeTripPolyline', tripPolyline);
      }
    },

    // geonode is part of DDS' service used by the scheduling engine
    getGeonode(queryString) {
      const session = this.get('session');
      return this.get('ajax').request(`${_apiUrls.API.geocodeService.host}/geocode`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          query: queryString,
          resultCount: 1
        }
      }).then(results => {
        const result = results.data.find(object => {
          return !Ember.isNone(object.geonode);
        });
        return result;
      }).catch(() => {
        return;
      });
    },

    fuzzySearch(query, options = {}) {
      const limit = options.limit || 1;
      const idxSet = options.idxSet || 'POI,PAD,Str,Xstr,Geo,Addr'; // default to point addresses

      const center = options.center || _environment.default.tomtom.search.center;
      return _tomtom.default.fuzzySearch().key(_environment.default.tomtom.search.searchKey).query(query).countrySet(_environment.default.tomtom.search.countrySet).typeahead(false).limit(limit).center(center).radius(_environment.default.tomtom.search.radius).idxSet(idxSet).go();
    }

  });

  _exports.default = _default;
});