define("adept-iq/mixins/fetchAssignableVehicleDrivers", ["exports", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/utils/vehicleCapacity", "adept-iq/config/mapped-permIds", "moment", "adept-iq/models/dispatch-route"], function (_exports, _emberConcurrency, _unwrapProxy, _vehicleCapacity, _mappedPermIds, _moment, _dispatchRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // check the status of route for vehicle and driver list
  var _default = Ember.Mixin.create({
    permissionLayer: Ember.inject.service(),
    resetVehicles: Ember.observer('plannedStartTime', 'plannedEndTime', 'provider', 'usePlaceHolderTask', function () {
      if (this.get('usePlaceHolderTask')) {
        return this.get('fetchPlaceHolderVehiclesTask').perform();
      }

      return this.get('fetchVehiclesTask').perform();
    }),
    resetDrivers: Ember.observer('plannedStartTime', 'plannedEndTime', 'provider', function () {
      return this.get('fetchDriversTask').perform();
    }),
    fetchVehiclesTask: (0, _emberConcurrency.task)(function* () {
      let vehicles = yield this.get('store').peekAll('vehicle');
      const provider = (0, _unwrapProxy.unwrapProxy)(this.get('provider'));
      const plannedStart = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEnd = (0, _moment.default)(this.get('plannedEndTime'));
      const assignVehicleOverCapacityPerm = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.assignVehicleOverCapacity, null);
      if (!vehicles || !vehicles.length) return []; // make sure vehicles are active and stay in the same provider as route

      if (provider) {
        vehicles = vehicles.filter(vehicle => {
          return vehicle.get('active') && !vehicle.get('isBroken') && provider.get('id') === vehicle.get('provider.id');
        });
      } // make sure any existing route->vehicle assignments in dispatch domain
      // do not intersect with destination route's planned window;


      vehicles = vehicles.filter(vehicle => {
        return vehicle.get('routeVehicleDrivers').every(routeVehicleDriver => {
          if (routeVehicleDriver.get('dispatchRoute.status') === _dispatchRoute.ROUTE_COMPLETED_STATUS) {
            return true;
          }

          const start = (0, _moment.default)(routeVehicleDriver.get('startTime'));
          const end = (0, _moment.default)(routeVehicleDriver.get('endTime'));
          return start.isAfter(plannedEnd) || end.isBefore(plannedStart);
        });
      }); // availability is disabled we should still filter for valid vehicles to execute a route

      const travelNeedCounts = this.get('dispatchRoute.maxTravelNeedCounts'); // show vehicles that does not have enough capacity if user have permission

      if (!assignVehicleOverCapacityPerm && Ember.isPresent(travelNeedCounts)) {
        vehicles = vehicles.filter(function (vehicle) {
          const capacityCounts = vehicle.get('capacityCounts');
          return !(0, _vehicleCapacity.notEnoughCapacity)(travelNeedCounts, capacityCounts);
        });
      } // filter unneeded placeholder vehicles


      vehicles = vehicles.filter(vehicle => !vehicle.get('placeholder'));
      this.set('vehicles', vehicles);
    }),
    fetchPlaceHolderVehiclesTask: (0, _emberConcurrency.task)(function* () {
      const provider = this.get('provider');
      let vehicles = yield this.get('store').peekAll('vehicle');

      if (provider) {
        vehicles = vehicles.filter(vehicle => vehicle.get('provider.id') === provider.get('id'));
      }

      const placeholderVehicles = vehicles.filterBy('placeholder', true);
      this.set('vehicles', placeholderVehicles);
    }),
    fetchDriversTask: (0, _emberConcurrency.task)(function* () {
      let drivers = yield this.get('store').peekAll('driver');
      const provider = (0, _unwrapProxy.unwrapProxy)(this.get('provider'));
      const plannedStart = (0, _moment.default)(this.get('plannedStartTime'));
      const plannedEnd = (0, _moment.default)(this.get('plannedEndTime'));
      if (!drivers || !drivers.length) return []; // make sure vehicles are active and stay in the same provider as route

      if (provider) {
        drivers = drivers.filter(driver => {
          return driver.get('active') && provider.get('id') === driver.get('provider.id');
        });
      } // make sure any existing route->vehicle assignments in dispatch domain
      // do not intersect with destination route's planned window;


      drivers = drivers.filter(driver => {
        return driver.get('routeVehicleDrivers').every(routeVehicleDriver => {
          if (routeVehicleDriver.get('dispatchRoute.status') === _dispatchRoute.ROUTE_COMPLETED_STATUS) {
            return true;
          }

          const start = (0, _moment.default)(routeVehicleDriver.get('startTime'));
          const end = (0, _moment.default)(routeVehicleDriver.get('endTime'));
          return start.isAfter(plannedEnd) || end.isBefore(plannedStart);
        });
      });
      this.set('drivers', drivers);
    })
  });

  _exports.default = _default;
});