define("adept-iq/pods/components/side-drawer/map-label-settings/component", ["exports", "adept-iq/config/column-types", "adept-iq/config/filter-types"], function (_exports, _columnTypes, _filterTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const displays = ['On Left Click', 'Always'];

  var _default = Ember.Component.extend({
    mapService: Ember.inject.service('map'),
    workspace: Ember.inject.service(),
    classNames: ['filter-settings'],
    columnTypesHash: _columnTypes.columnTypesHash,
    filterTypesHash: _filterTypes.filterTypesHash,
    layer: null,
    toggleEye: false,
    selectedDisplay: null,
    isEyeOpen: Ember.computed.not('toggleEye'),

    didInsertElement() {
      this._super(...arguments);

      this.set('displays', displays);
    },

    sortedLabels: Ember.computed('layer.labels', function () {
      const labels = this.get('layer.labels');
      const sortedLabels = labels.sortBy('index');
      return sortedLabels;
    }),

    selectDisplay(display) {
      this.set('layer.selectedDisplay', display);
    },

    actions: {
      toggleCheckBoxes() {
        const layer = this.get('layer');
        const sortedLabels = this.get('sortedLabels') || [];
        const eye = this.toggleProperty('isEyeOpen');
        sortedLabels.forEach(label => {
          const layerId = Ember.get(layer, 'id');
          const labelId = Ember.get(label, 'id');
          this.get('mapService').setLayerLabelVisibility(layerId, labelId, eye);
        });

        if (!eye) {
          const labelId = Ember.get(sortedLabels[0], 'id');
          const layerId = Ember.get(layer, 'id');
          this.get('mapService').setLayerLabelVisibility(layerId, labelId, !eye);
        }
      },

      onReorderItems(labels) {
        const layer = this.get('layer');
        const labelIds = labels.mapBy('id');
        this.get('mapService').setLayerLabelsOrder(layer.id, labelIds);
      },

      setVisibility(label, event) {
        const layer = this.get('layer');
        const layerId = Ember.get(layer, 'id');
        const labelId = Ember.get(label, 'id');
        this.get('mapService').setLayerLabelVisibility(layerId, labelId, event.target.checked);
      },

      onSelectFilterType(label, filterType) {
        const layer = this.get('layer');
        const layerId = Ember.get(layer, 'id');
        const labelId = Ember.get(label, 'id');
        const filterTypeId = Ember.get(filterType, 'id');
        this.get('mapService').setLayerLabelFilterType(layerId, labelId, filterTypeId);
      },

      onFilterValueChange(label, index, event) {
        const layer = this.get('layer');
        const layerId = Ember.get(layer, 'id');
        const labelId = Ember.get(label, 'id');
        let filterValues = Ember.get(label, 'filterValues') || []; // treat filter values as immutable

        filterValues = filterValues.slice();
        filterValues[index] = event.target.value;
        this.get('mapService').setLayerLabelFilterValues(layerId, labelId, filterValues);
      },

      clearFilters() {
        const dashboard = this.get('workspace.dashboard');
        dashboard.set('mapState', null);
      },

      onDisplaySelect(display) {
        this.selectDisplay(display);
        let displayAlways;

        if (display === 'Always') {
          displayAlways = true;
        } else {
          displayAlways = false;
        }

        this.get('mapService').setDisplayMapIconLabelAllTime(this.get('layer.id'), displayAlways);
      }

    }
  });

  _exports.default = _default;
});