define("adept-iq/models/no-show-reason-code", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    code: attr('string'),
    externalCode: attr('string'),
    description: attr('string'),
    noShow: hasMany('no-show'),
    trips: hasMany('trip'),
    avlmTrips: hasMany('avlm-trip'),
    name: Ember.computed.alias('description')
  });

  _exports.default = _default;
});