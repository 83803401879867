define("adept-iq/config/active-context-graph-avlmlite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.avlmActiveContextGraph = _exports.avlmActiveContextNodes = _exports.ssoUserNode = _exports.alertNode = _exports.avlmMessageNode = _exports.avlmTripNode = _exports.avlmStopNode = _exports.avlmClusterNode = _exports.avlmRouteVehicleDriverNode = _exports.avlmRouteNode = _exports.avlmVehicleNode = _exports.avlmDriverNode = _exports.avlmRiderEventNode = _exports.avlmRiderNode = _exports.avlmTripRiderNode = void 0;
  // Rider Managament Related Nodes
  const avlmTripRiderNode = {
    id: 'avlm-trip-rider',
    modelName: 'avlm-trip-rider',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-trip',
      path: 'avlmTrip'
    }, {
      type: 'belongsTo',
      nodeId: 'avlm-rider',
      path: 'avlmRider'
    }],
    isActive: false,
    providerFilterKey: false
  };
  _exports.avlmTripRiderNode = avlmTripRiderNode;
  const avlmRiderNode = {
    id: 'avlm-rider',
    modelName: 'avlm-rider',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-trip-rider',
      path: 'avlmTripRider'
    }, {
      type: 'hasMany',
      nodeId: 'avlm-rider-event',
      path: 'avlmRiderEvents'
    }],
    isActive: false,
    providerFilterKey: false
  };
  _exports.avlmRiderNode = avlmRiderNode;
  const avlmRiderEventNode = {
    id: 'avlm-rider-event',
    modelName: 'avlm-rider-event',
    links: [],
    isActive: false,
    providerFilterKey: false
  }; // Driver Related Nodes

  _exports.avlmRiderEventNode = avlmRiderEventNode;
  const avlmDriverNode = {
    id: 'avlm-driver',
    modelName: 'avlm-driver',
    links: [{
      type: 'hasMany',
      nodeId: 'avlm-route-vehicle-driver',
      path: 'currentRouteVehicleDrivers'
    }],
    isActive: false,
    providerFilterKey: false
  }; // Vehicle Related Nodes

  _exports.avlmDriverNode = avlmDriverNode;
  const avlmVehicleNode = {
    id: 'avlm-vehicle',
    modelName: 'avlm-vehicle',
    links: [{
      type: 'hasMany',
      nodeId: 'avlm-route-vehicle-driver',
      path: 'currentRouteVehicleDrivers'
    }, {
      type: 'hasMany',
      nodeId: 'avlm-alert',
      path: 'avlmAlert'
    }],
    isActive: false,
    providerFilterKey: 'providerName'
  };
  _exports.avlmVehicleNode = avlmVehicleNode;
  const avlmRouteNode = {
    id: 'avlm-route',
    modelName: 'avlm-route',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-vehicle',
      path: 'latestVehicle'
    }, {
      type: 'belongsTo',
      nodeId: 'avlm-driver',
      path: 'latestDriver'
    }, {
      type: 'hasMany',
      nodeId: 'avlm-stop',
      path: 'orderedStopsWithGaragesAndBreaks'
    }, {
      type: 'hasMany',
      nodeId: 'avlm-message',
      path: 'cannedMessages'
    }],
    isActive: true,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime',
    providerFilterKey: 'providerName'
  };
  _exports.avlmRouteNode = avlmRouteNode;
  const avlmRouteVehicleDriverNode = {
    id: 'avlm-route-vehicle-driver',
    modelName: 'avlm-route-vehicle-driver',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-vehicle',
      path: 'vehicle'
    }, {
      type: 'belongsTo',
      nodeId: 'avlm-driver',
      path: 'driver'
    }, {
      type: 'belongsTo',
      nodeId: 'avlm-route',
      path: 'route'
    }],
    isActive: true,
    leftTimeConstraint: 'startTime',
    rightTimeConstraint: 'endTime',
    providerFilterKey: false
  };
  _exports.avlmRouteVehicleDriverNode = avlmRouteVehicleDriverNode;
  const avlmClusterNode = {
    id: 'avlm-cluster',
    modelName: 'avlm-cluster',
    links: [],
    isActive: true,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime',
    providerFilterKey: false
  };
  _exports.avlmClusterNode = avlmClusterNode;
  const avlmStopNode = {
    id: 'avlm-stop',
    modelName: 'avlm-stop-point',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-cluster',
      path: 'avlmCluster'
    }, {
      type: 'belongsTo',
      nodeId: 'avlm-trip',
      path: 'avlmTrip'
    }, {
      type: 'belongsTo',
      nodeId: 'avlm-route',
      path: 'stopRoute'
    }],
    isActive: true,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime',
    providerFilterKey: 'providerName'
  };
  _exports.avlmStopNode = avlmStopNode;
  const avlmTripNode = {
    id: 'avlm-trip',
    modelName: 'avlm-trip',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-trip-rider',
      path: 'avlmTripRider'
    }, {
      type: 'belongsTo',
      nodeId: 'avlm-route',
      path: 'avlmRoute'
    }, {
      type: 'hasMany',
      nodeId: 'avlm-stop',
      path: 'avlmStopPoints'
    }],
    isActive: true,
    leftTimeConstraint: 'routeTripStartTime',
    rightTimeConstraint: 'routeTripEndTime',
    providerFilterKey: false
  };
  _exports.avlmTripNode = avlmTripNode;
  const avlmMessageNode = {
    id: 'avlm-message',
    modelName: 'avlm-canned-message',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-driver',
      path: 'avlmDriver'
    }, {
      type: 'belongsTo',
      nodeId: 'avlm-vehicle',
      path: 'avlmVehicle'
    }, {
      type: 'belongsTo',
      nodeId: 'avlm-route',
      path: 'avlmRoute'
    }],
    isActive: true,
    leftTimeConstraint: 'tripStartTime',
    rightTimeConstraint: 'tripEndTime',
    providerFilterKey: false
  };
  _exports.avlmMessageNode = avlmMessageNode;
  const alertNode = {
    id: 'avlm-alert',
    modelName: 'avlm-alert',
    links: [{
      type: 'belongsTo',
      nodeId: 'avlm-vehicle',
      path: 'avlmVehicle'
    }],
    isActive: true,
    leftTimeConstraint: 'timestamp',
    rightTimeConstraint: 'timestamp',
    providerFilterKey: false
  };
  _exports.alertNode = alertNode;
  const ssoUserNode = {
    id: 'sso-user',
    modelName: 'sso-user',
    links: [],
    providerFilterKey: false
  };
  _exports.ssoUserNode = ssoUserNode;
  const avlmActiveContextNodes = [// sign-on
  ssoUserNode, // avlm graph
  avlmRouteNode, avlmRouteVehicleDriverNode, avlmClusterNode, avlmTripNode, avlmStopNode, avlmVehicleNode, avlmMessageNode, // vehicle graph
  avlmVehicleNode, // driver graph
  avlmDriverNode, // rider graph
  avlmRiderNode, avlmTripRiderNode, avlmRiderEventNode, // alerts
  alertNode];
  _exports.avlmActiveContextNodes = avlmActiveContextNodes;
  const avlmActiveContextGraph = avlmActiveContextNodes.reduce((obj, node) => {
    obj[node.id] = node;
    return obj;
  }, {});
  _exports.avlmActiveContextGraph = avlmActiveContextGraph;
  var _default = avlmActiveContextGraph;
  _exports.default = _default;
});