define("adept-iq/classes/markers/stops/base", ["exports", "adept-iq/classes/markers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OTP_CODE_LATE = 'L';
  const OTP_CODE_DANGER = 'D'; // see `app/pods/components/iq-widgets/map-widget/config/marker.js`

  const NOSHOW_STYLE = 'noshow';
  const BREAK_STYLE = 'break';
  const OTP_STYLE_SUFFIX_LATE = 'Late';
  const OTP_STYLE_SUFFIX_DANGER = 'Danger';
  const NEXT_STYLE_SUFFIX = 'Next';
  const WHEELCHAIR_STYLE_SUFFIX = 'Wheelchair';
  const PERFORMED_STYLE_SUFFIX = 'Performed';

  var _default = _base.default.extend({
    label: Ember.computed.readOnly('stopLabel'),
    style: Ember.computed.readOnly('stopStyle'),
    // isHidden: computed.readOnly('isStopHidden'),
    // stop-specific params
    stopType: null,
    // 'pick', 'drop', 'garagePullin', 'garagePullout', 'break'
    otp: null,
    // 'L', 'D'
    isNext: false,
    isWheelchair: false,
    isPerformed: false,
    isNoShow: false,
    // local properties
    isPick: Ember.computed.equal('stopType', 'pick'),
    isDrop: Ember.computed.equal('stopType', 'drop'),
    isDeploy: Ember.computed.equal('stopType', 'deployStop'),
    isBreak: Ember.computed.equal('stopType', 'break'),
    isGarage: Ember.computed.or('isGaragePullIn', 'isGaragePullOut'),
    isGaragePullIn: Ember.computed.equal('stopType', 'garagePullin'),
    isGaragePullOut: Ember.computed.equal('stopType', 'garagePullout'),
    isStopHidden: Ember.computed.and('isNoShow', 'isDrop'),
    stopLabel: Ember.computed('stopType', function () {
      const stopType = this.get('stopType');
      return `${Ember.String.capitalize(stopType)} stop`;
    }),
    stopStyle: Ember.computed('isNoShow', 'isBreak', 'isPerformed', 'typeStylePrefix', 'otpStyleSuffix', 'nextStyleSuffix', 'wheelchairStyleSuffix', function () {
      if (this.get('isNoShow')) return NOSHOW_STYLE;
      if (this.get('isBreak')) return BREAK_STYLE;
      const typeStylePrefix = this.get('typeStylePrefix');

      if (this.get('isPerformed')) {
        return [typeStylePrefix, PERFORMED_STYLE_SUFFIX].join('');
      }

      const suffixes = [this.get('otpStyleSuffix'), this.get('nextStyleSuffix'), this.get('wheelchairStyleSuffix')].compact();
      const style = [typeStylePrefix, ...suffixes].join('');
      return style;
    }),
    typeStylePrefix: Ember.computed('stopType', function () {
      switch (this.get('stopType')) {
        case 'pick':
          return 'pick';

        case 'deployStop':
          return 'deployStop';

        case 'drop':
          return 'drop';

        case 'pullinGarage':
        case 'pulloutGarage':
          return 'garage';
        // both types use same icon

        default:
          // we don't care about break, noshow, etc.
          return null;
      }
    }),
    otpStyleSuffix: Ember.computed('otp', function () {
      switch (this.get('otp')) {
        case OTP_CODE_LATE:
          return OTP_STYLE_SUFFIX_LATE;

        case OTP_CODE_DANGER:
          return OTP_STYLE_SUFFIX_DANGER;

        default:
          return null;
      }
    }),
    nextStyleSuffix: Ember.computed('isNext', function () {
      return this.get('isNext') ? NEXT_STYLE_SUFFIX : null;
    }),
    wheelchairStyleSuffix: Ember.computed('isWheelchair', 'isGarage', function () {
      // garage icons do not have a wheelchair version
      if (this.get('isGarage')) return null;
      return this.get('isWheelchair') ? WHEELCHAIR_STYLE_SUFFIX : null;
    })
  });

  _exports.default = _default;
});