define("adept-iq/services/workspace-context", ["exports", "adept-iq/config/environment", "adept-iq/classes/work-queue", "adept-iq/config/active-context-graph", "adept-iq/config/active-context-graph-avlmlite"], function (_exports, _environment, _workQueue, _activeContextGraph, _activeContextGraphAvlmlite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // refresh widgets at least this often (if fresh data available)
  const MIN_RELOAD_INTERVAL = 5000;
  const LOCAL_STORAGE_KEY = 'dashboard-info';
  const activeContextNodes = _environment.default.APP.avlmLite ? _activeContextGraphAvlmlite.avlmActiveContextNodes : _activeContextGraph.coreActiveContextNodes;

  var _default = Ember.Service.extend(Ember.Evented, {
    session: Ember.inject.service(),
    map: Ember.inject.service(),
    socket: Ember.inject.service(),
    store: Ember.inject.service(),
    work: Ember.inject.service(),
    workspace: Ember.inject.service(),
    user: Ember.inject.service(),
    providerNames: Ember.computed.readOnly('session.data.authenticated.tokenInfo.providerNames'),
    // work queues
    normalizeQueue: null,
    storeQueue: null,
    reloadQueue: null,
    nodeQueue: null,
    // internal data structures
    _structuredWorkspace: null,
    _selectedProviders: null,
    // all providers are selected in dropdown
    _allProviders: true,
    // keeps track of records that have been (or are pending) deletion
    _deletedLookup: null,
    // keeps track of model types that may have been loaded or unloaded; used to
    // refresh widgets
    _dirtyModelNames: null,

    init() {
      this._super(...arguments);

      this.set('_deletedLookup', {});
      this.set('_dirtyModelNames', []); // this queue handles ember data normalization

      const normalizeQueueOptions = Object.assign({}, _environment.default.work['wc-normalize'], {
        name: 'wc-normalize',
        perform: jobs => {
          jobs.forEach(job => {
            this._processNormalizeJob(job);
          });
        }
      });

      const normalizeQueue = _workQueue.default.create(normalizeQueueOptions);

      this.get('work').registerQueue(normalizeQueue);
      this.set('normalizeQueue', normalizeQueue); // this queue handles pushing records into ember store

      const storeQueueOptions = Object.assign({}, _environment.default.work['wc-store'], {
        name: 'wc-store',
        perform: jobs => {
          jobs.forEach(job => {
            this._processStoreJob(job);
          });
        }
      });

      const storeQueue = _workQueue.default.create(storeQueueOptions);

      this.get('work').registerQueue(storeQueue);
      this.set('storeQueue', storeQueue); // this queue handles notifying the UI for refresh

      const reloadQueueOptions = Object.assign({}, _environment.default.work['wc-reload'], {
        name: 'wc-reload',
        workspace: this.get('workspace'),
        perform: jobs => {
          jobs.forEach(job => this._processReloadJob(job));
        }
      });

      const reloadQueue = _workQueue.default.extend({
        isDisabled: Ember.computed.readOnly('workspace.isRoadSupervisorModeEnabled')
      }).create(reloadQueueOptions);

      this.get('work').registerQueue(reloadQueue);
      this.set('reloadQueue', reloadQueue);
      const nodeQueueOptions = Object.assign({}, _environment.default.work['wc-node'], {
        name: 'wc-node',
        perform: jobs => {
          jobs.forEach(job => {
            this._processNodeJob(job);
          });
        }
      });

      const nodeQueue = _workQueue.default.extend({
        isDisabled: Ember.computed.readOnly('workspace.isRoadSupervisorModeEnabled')
      }).create(nodeQueueOptions);

      this.get('work').registerQueue(nodeQueue);
      this.set('nodeQueue', nodeQueue);
      const structuredWorkspace = activeContextNodes.reduce((obj, node) => {
        obj[node.modelName] = [];
        return obj;
      }, {});
      this.set('_structuredWorkspace', structuredWorkspace);
      this.loadAPIData(); // activates observer and triggers initial load

      this.onDatesChange();
    },

    destroy() {
      ['storeQueue', 'normalizeQueue', 'reloadQueue', 'nodeQueue'].forEach(queueName => {
        const queue = this.get(queueName);
        this.get('work').unregisterQueue(queue);
        queue.destroy();
      });
      this.set('_deletedLookup', null);
      this.get('_dirtyModelNames').clear();
      this.set('_structuredWorkspace', null);

      this._super(...arguments);
    },

    // flattened for easy binding & iteration
    workspaceData: Ember.computed('_structuredWorkspace', function () {
      const data = this.get('_structuredWorkspace');
      return Object.entries(data).reduce((arr, [modelName, records]) => {
        records.forEach(record => {
          arr.push({
            modelName,
            record
          });
        });
        return arr;
      }, []);
    }),
    onDatesChange: Ember.observer('workspace.{startDate,endDate}', function () {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();

      if (startTime < endTime) {
        Ember.run.scheduleOnce('afterRender', this, 'resetAndConnect');
      }
    }),
    onWorkspaceChange: Ember.observer('_structuredWorkspace', function () {
      Ember.run.scheduleOnce('afterRender', this, 'refreshMap');
      Ember.run.scheduleOnce('afterRender', this, 'refreshWidgets');
    }),

    resetAndConnect() {
      this.get('reloadQueue').addJob('reload'); // reconnect to sockets

      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      (true && !(startTime < endTime) && Ember.assert('workspace startTime must predate endTime', startTime < endTime)); // this will wait for any sync topics before resolving

      this.get('socket').connect({
        startDate,
        endDate
      });
    },

    // loads static data at application start
    loadAPIData() {
      return Ember.RSVP.all([this.get('store').findAll('cs-config-category'), this.get('store').findAll('cs-config-item')]);
    },

    pushPayloads(payloads) {
      payloads.forEach((payload, i) => {
        this.pushPayload(payload, i === payloads.length - 1);
      });
    },

    pushPayload(payload, start = true) {
      const flattenedPayloads = this._flattenPayload(payload);

      flattenedPayloads.forEach(flattenedPayload => {
        if (_environment.default.APP.HIGH_PRIORITY_MODELS[flattenedPayload.data.type]) {
          this.get('normalizeQueue').unshiftJob({
            payload: flattenedPayload
          });
        } else {
          this.get('normalizeQueue').pushJob({
            payload: flattenedPayload
          });
        }
      });

      if (start) {
        this.get('work').start();
      }
    },

    removeRecord(modelName, id) {
      // this may not actually exist yet
      const record = this.get('store').peekRecord(modelName, id);
      const storeQueue = this.get('storeQueue');
      storeQueue.pushJob({
        action: 'remove',
        modelName,
        id,
        record
      });
      this.get('work').start();
    },

    async manualReloadContext() {
      this._processReloadJob();

      const nodeQueue = this.get('nodeQueue');
      return nodeQueue.start();
    },

    refreshMap() {
      this.get('map.mapContexts').forEach(mapContext => {
        mapContext.trigger('refresh');
      });
    },

    refreshWidgets() {
      const dirtyModelNames = this.get('_dirtyModelNames').slice();
      this.get('_dirtyModelNames').clear(); // expand dirty model names

      const expandedModelNames = dirtyModelNames.reduce((arr, modelName) => {
        arr.addObject(modelName);
        const sourceNode = activeContextNodes.findBy('modelName', modelName);
        if (!sourceNode) return arr;
        return arr;
      }, []);
      this.trigger('change', expandedModelNames);
    },

    _processNormalizeJob(job) {
      const storeQueue = this.get('storeQueue');

      const normalizedPayload = this._normalizePayload(job.payload);

      if (_environment.default.APP.HIGH_PRIORITY_MODELS[normalizedPayload.data.type]) {
        storeQueue.unshiftJob({
          action: 'push',
          payload: normalizedPayload
        });
      } else {
        storeQueue.pushJob({
          action: 'push',
          payload: normalizedPayload
        });
      }
    },

    _processStoreJob(job) {
      const store = this.get('store');
      const dirtyModelNames = this.get('_dirtyModelNames');

      switch (job.action) {
        case 'push':
          {
            // complete at last second in case related entities have been loaded
            const completedPayload = this._completePayload(job.payload); // can't use the store's `pushPayload()` method to do serialization
            // because it does not process `included` array the same way


            store.push(completedPayload);
            dirtyModelNames.addObject(job.payload.data.type);
            break;
          }

        case 'remove':
          {
            const record = job.record || store.peekRecord(job.modelName, job.id);

            if (record && !record.get('isRemoved')) {
              // this allow for faster filtering later
              const key = `${record.constructor.modelName}:${record.get('id')}`;
              record.set('isRemoved', true); //remove node

              if (this._structuredWorkspace[record.constructor.modelName]) {
                const index = this._structuredWorkspace[record.constructor.modelName].findIndex(a => a.id === record.id);

                if (index > -1) {
                  this._structuredWorkspace[record.constructor.modelName].splice(index, 1);
                } // mark record as deleted so it will be excluded from widget refreshes


                this._deletedLookup[key] = true;
              }
            }

            break;
          }

        default:
          break;
      }

      const t = new Date();
      const reloadQueue = this.get('reloadQueue');
      const lastReloadAt = reloadQueue.get('lastRunAt'); // run when both queues clear, or at least every MIN_RELOAD_INTERVAL ms

      if (this.get('storeQueue.length') === 0 && this.get('normalizeQueue.length') === 0) {
        reloadQueue.addJob('reload');
      } else if (!lastReloadAt || t - lastReloadAt > MIN_RELOAD_INTERVAL) {
        reloadQueue.addJob('reload');
      }
    },

    _processReloadJob() {
      const isRoadSupEnable = this.user.isRoadSupEnable();
      const dashboardInfo = localStorage.getItem(LOCAL_STORAGE_KEY);
      const providers = this.get('store').peekAll('provider').toArray(); // FIXME: this should be done once elsewhere

      if (_environment.default.APP.avlmLite) {
        if (isRoadSupEnable && dashboardInfo) {
          const parsedDashboardInfo = JSON.parse(dashboardInfo);

          if (parsedDashboardInfo && parsedDashboardInfo.accessData) {
            const accessDataObj = parsedDashboardInfo.accessData;
            const provider = this.store.peekRecord('provider', accessDataObj.provider);

            if (!provider) {
              const newProvider = this.store.createRecord('provider', {
                id: accessDataObj.provider,
                name: accessDataObj.provider,
                isChecked: true
              });
              providers.push(newProvider);
            }
          }
        }
      }

      this.set('_selectedProviders', providers.filter(record => {
        return record.isChecked;
      }));
      activeContextNodes.forEach((node, i) => {
        // trigger map & widget refresh after processing last node of this reload
        const forceRefresh = i === activeContextNodes.length - 1;
        this.get('nodeQueue').pushJob({
          node,
          forceRefresh
        });
      });
    },

    _processNodeJob(job) {
      const {
        node,
        forceRefresh
      } = job;
      const store = this.get('store');
      const isRoadSupEnable = this.user.isRoadSupEnable();
      const dashboardInfo = localStorage.getItem(LOCAL_STORAGE_KEY);
      const workspace = this.get('workspace');
      const startTime = workspace.get('startDate').getTime();
      const endTime = workspace.get('endDate').getTime();
      let peekedRecords;
      const providerDict = this.get('_selectedProviders').reduce((acu, item) => {
        acu[item.id] = true;
        return acu;
      }, {}); // TODO: clean up this block

      if (_environment.default.APP.avlmLite) {
        if (node.providerFilterKey && (isRoadSupEnable || dashboardInfo) && !this.providerNames.includes('ALL')) {
          peekedRecords = store.peekAll(node.modelName).filter(record => {
            if (!record.get(node.providerFilterKey)) return false;
            return providerDict[record.get(`${node.providerFilterKey}.id`)];
          });
        } else {
          peekedRecords = store.peekAll(node.modelName);
        }
      } else if (node.providerFilterKey && !this.get('_allProviders')) {
        peekedRecords = store.peekAll(node.modelName).filter(record => {
          if (!record.get(node.providerFilterKey)) return false; // for passengers being part of multiple providers filtering for multiple providers

          if (Ember.isArray(record.get(`${node.providerFilterKey}`))) {
            return record.get(`${node.providerFilterKey}`).some(provider => {
              return providerDict[provider.get('id')];
            });
          }

          return providerDict[record.get(`${node.providerFilterKey}.id`)];
        });
      } else {
        peekedRecords = store.peekAll(node.modelName);
      }

      peekedRecords = peekedRecords.toArray();
      const filteredRecords = [];
      /* eslint-disable no-continue */

      for (let i = 0; i < peekedRecords.length; i++) {
        const record = peekedRecords[i];
        const key = `${record.constructor.modelName}:${record.get('id')}`;
        if (this._deletedLookup[key] || this._isRecordRemoved(record)) continue;

        if (node.isActive) {
          if (node.leftTimeConstraint) {
            const leftDate = record.get(node.leftTimeConstraint);

            if (Ember.typeOf(leftDate) !== 'date' && leftDate) {
              // eslint-disable-next-line no-console
              console.log(node.modelName, record.id, node.leftTimeConstraint); // required to be a date type, print model name, id, and path

              continue;
            } // skip it if it starts after window


            if (!leftDate || leftDate.getTime() > endTime) continue;
          }

          if (node.rightTimeConstraint) {
            const rightDate = record.get(node.rightTimeConstraint);

            if (Ember.typeOf(rightDate) !== 'date' && rightDate) {
              // eslint-disable-next-line no-console
              console.log(node.modelName, record.id, node.rightTimeConstraint); // required to be a date type, print model name, id, and path

              continue;
            } // skip it if it end before window


            if (!rightDate || rightDate.getTime() < startTime) continue;
          }
        }

        filteredRecords.push(record);
      }
      /* eslint-enable no-continue */


      const obj = this.get('_structuredWorkspace');
      obj[node.modelName] = obj[node.modelName] || [];
      obj[node.modelName].splice(0, obj[node.modelName].length, ...filteredRecords);

      if (forceRefresh) {
        // only trigger observers if specifically instructed
        this.notifyPropertyChange('_structuredWorkspace');
      }
    },

    _isRecordRemoved(record) {
      switch (record.constructor.modelName) {
        case 'avlm-trip':
          return record.get('status') === 'X';

        case 'avlm-stop-point':
          return record.get('status') === 'R';

        case 'avlm-alert':
          return record.get('state') === 'completed';

        default:
          return record.get('isRemoved');
      }
    },

    // recursively unpacks included payloads into a flat array
    _flattenPayload(payload) {
      const q = [payload];
      const flattened = [];

      const handleIncludedPayload = data => {
        if (data.included) {
          // non-standard, but does happen in IQUX
          delete data.included;
          q.push({
            data: data.included
          });
        } else {
          q.push({
            data
          });
        }
      };

      while (q.length) {
        const p = q[q.length - 1];

        if (p.included) {
          p.included.forEach(handleIncludedPayload);
          delete p.included;
        } else {
          flattened.push(p);
          q.pop();
        }
      }

      return flattened;
    },

    // processes a payload with its serializers' `normalize()` method
    _normalizePayload(payload) {
      const store = this.get('store');
      const modelClass = store.modelFor(payload.data.type);
      const serializer = store.serializerFor(payload.data.type);

      if (!modelClass || !serializer) {
        throw new Error('no modelClass or serializer for ' + payload.data.id);
      }

      return serializer.normalize(modelClass, payload.data);
    },

    // adds placeholder models to payload's `included` hash for any related
    // models that don't exist in store yet
    _completePayload(payload) {
      const store = this.get('store');
      if (!payload.data) return payload;
      payload.included = payload.included || [];

      if (payload.data.relationships) {
        Object.values(payload.data.relationships).forEach(rel => {
          if (Array.isArray(rel.data)) {
            rel.data.forEach(data => {
              if (!store.peekRecord(data.type, data.id)) {
                if (data.type !== 'sso-user') {
                  // sso-user need to query from sso services
                  payload.included.push(data);
                }
              }
            });
          } else if (rel.data) {
            if (!store.peekRecord(rel.data.type, rel.data.id)) {
              if (rel.data.type !== 'sso-user') {
                // sso-user need to query from sso services
                payload.included.push(rel.data);
              }
            }
          }
        });
      }

      return payload;
    }

  });

  _exports.default = _default;
});