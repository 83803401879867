define("adept-iq/pods/components/iq-widgets/alert-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/alert-widget/config", "adept-iq/pods/components/iq-widgets/alert-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _avlmConfig, _environment, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['alert-widget', 'data-test-alerts-widget'],
    store: Ember.inject.service(),
    workspace: Ember.inject.service(),
    config: _config.default,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('tableActions', [{
        id: 'showAll',
        name: 'Show All',
        permId: _mappedPermIds.default.showAllAlerts,
        action: () => {
          const rows = this.get('table.rows') || [];
          rows.forEach(r => {
            r.set('hidden', false);
          });
        }
      }]);
      this.set('hideAction', {
        id: 'hide',
        name: 'Hide',
        permId: _mappedPermIds.default.hideAlerts,
        action: model => {
          const row = this.get('table.rows').findBy('id', model.get('id'));

          if (row) {
            row.set('hidden', true);
          }
        }
      });
    },

    singleActions: Ember.computed('table.rows.@each.selected}', function () {
      const hideAction = this.get('hideAction'); // searches a row whose "selected" is true;

      const row = this.get('table.rows').findBy('selected', true);

      if (!row) {
        return [];
      }

      return [hideAction];
    })
  });

  _exports.default = _default;
});