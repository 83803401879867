define("adept-iq/utils/vehicleCapacity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notEnoughCapacity = void 0;

  const notEnoughCapacity = (travelNeedCounts, capacityCounts) => {
    if (!capacityCounts) return false;
    return Object.entries(travelNeedCounts).some(([travelNeedTypeName, travelNeedCount]) => {
      const capacityCount = capacityCounts[travelNeedTypeName] || 0;
      return capacityCount < travelNeedCount;
    });
  };

  _exports.notEnoughCapacity = notEnoughCapacity;
});