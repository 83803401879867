define("adept-iq/services/work", ["exports", "ember-concurrency", "adept-iq/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // chrome will show a warning if a timeout exceeds 50ms; this is non-critical
  // but best to avoid if possible
  const CHROME_TIMEOUT_WARNING_THRESHOLD = 50; // settings from `config/environment.js`; see comments for details

  const DEFAULT_FPS = _environment.default.work.fps;
  const DEFAULT_CYCLE_SHARE = _environment.default.work.cycleShare;

  var _default = Ember.Service.extend({
    interaction: Ember.inject.service(),
    fps: DEFAULT_FPS,
    cycleShare: DEFAULT_CYCLE_SHARE,
    maxLoopTime: Infinity,
    _queues: null,
    computeTime: Ember.computed('fps', 'cycleShare', function () {
      const fps = this.get('fps');
      const cycleShare = this.get('cycleShare');
      const computeTime = Math.round(1000.0 * cycleShare / fps);

      if (computeTime >= CHROME_TIMEOUT_WARNING_THRESHOLD) {
        console.warn(`${computeTime}ms computeTime meets or exceeds ${CHROME_TIMEOUT_WARNING_THRESHOLD}ms warning threshold; increase fps or decrease cycleShare`); // eslint-disable-line no-console
      }

      if (computeTime < 1) {
        console.warn('computeTime cannot be less than 1ms; increase cycle share or decrease fps'); // eslint-disable-line no-console

        return 1;
      }

      return computeTime;
    }),
    sleepTime: Ember.computed('fps', 'cycleShare', function () {
      const fps = this.get('fps');
      const cycleShare = this.get('cycleShare');
      const sleepTime = Math.round(1000.0 * (1 - cycleShare) / fps);

      if (sleepTime < 1) {
        console.warn('sleepTime cannot be less than 1ms; decrease cycleShare or fps'); // eslint-disable-line

        return 1;
      }

      return sleepTime;
    }),
    queues: Ember.computed('_queues.@each.priority', function () {
      return this.get('_queues').sortBy('priority');
    }),

    init() {
      this._super(...arguments);

      this.set('_queues', []);
    },

    destroy() {
      this.get('_queues').clear();

      this._super(...arguments);
    },

    registerQueue(queue) {
      this.get('_queues').addObject(queue);
    },

    unregisterQueue(queue) {
      this.get('_queues').removeObject(queue);
    },

    start() {
      return this.get('_task').perform();
    },

    /* eslint-disable no-continue */
    _task: (0, _emberConcurrency.task)(function* () {
      const maxLoopTime = this.get('maxLoopTime');
      const computeTime = this.get('computeTime');
      const sleepTime = this.get('sleepTime');
      const tInit = new Date().getTime();
      let tStart = tInit;
      let tRemaining;
      let n = 0;

      const findFn = q => n === 0 || q.getMinWorkTime() < tRemaining;

      while (!this.get('queues').isEvery('isEmpty')) {
        const tNow = new Date().getTime(); // break out of loop if we have exceeded maximum loop time

        if (tNow - tInit > maxLoopTime) break;
        tRemaining = Math.max(computeTime - (tNow - tStart), 0);
        const viableQueues = this.get('queues').reject(q => {
          return q.get('isEmpty') || q.get('isThrottled') || q.get('isDestroyed') || q.get('isDisabled');
        });
        const queue = viableQueues.find(findFn) || viableQueues[0];

        if (tRemaining === 0 || !queue || this.get('interaction.isBusy')) {
          // out of time or UI in use; sleep for remainder of cycle
          yield (0, _emberConcurrency.timeout)(tRemaining + sleepTime); // begin a new cycle

          tStart = new Date().getTime();
          n = 0;
          continue;
        }

        const t0 = new Date().getTime();
        const count = yield queue.start(tRemaining);
        const tDiff = new Date().getTime() - t0;

        if (_environment.default.APP.DEBUG_QUEUES && tDiff >= CHROME_TIMEOUT_WARNING_THRESHOLD) {
          console.warn(`${queue.name} took ${tDiff}ms for ${count} job(s) (given ${tRemaining}ms)`); // eslint-disable-line no-console
        }

        n += 1;
      }
    }).drop()
    /* eslint-enable no-continue */

  });

  _exports.default = _default;
});