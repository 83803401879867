define("adept-iq/serializers/segment-stop", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    attrs: {
      onboardCount: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});