define("adept-iq/pods/components/iq-widgets/routes-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/routes-widget/config", "adept-iq/pods/components/iq-widgets/routes-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/utils/find-row-record", "adept-iq/config/api-urls", "adept-iq/utils/unwrapProxy", "adept-iq/config/mapped-permIds", "moment", "adept-iq/models/dispatch-route"], function (_exports, _component, _config, _avlmConfig, _environment, _findRowRecord, _apiUrls, _unwrapProxy, _mappedPermIds, _moment, _dispatchRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // display statuses
  const specialRouteHost = _apiUrls.API.dispatchService.host;
  const INCLUDES = ['clusters', 'vehicleRoutes', 'vehicleRoutes.driverShifts', 'vehicleRoutes.vehicle', 'vehicleRoutes.driverShifts.driver', 'vehicleRoutes.vehicle.vehicleType', 'driverBreaks', 'driverBreaks.driverBreakEvents', 'vehicleRoutes.vehicle.vehicleEvents'];

  var _default = _component.default.extend({
    classNames: ['routes-widget', 'data-test-routes-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    dragCoordinator: Ember.inject.service(),
    routeexecevent: Ember.inject.service(),
    session: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    workspace: Ember.inject.service(),
    booking: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    editComponent: 'iq-widgets/routes-form-widget',
    newComponent: 'iq-widgets/routes-widget/new',
    routesEndComponent: 'iq-widgets/routes-end-form-widget',
    routesStartComponent: 'iq-widgets/routes-start-form-widget',
    breakdownReplacementComponent: 'iq-widgets/breakdown-replacement-form-widget',
    driverBreakEditComponent: 'iq-widgets/driver-breaks-form-widget',
    config: _config.default,
    defaultIncludes: INCLUDES,

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);
    },

    saveRouteFunction(records) {
      return Ember.RSVP.all(records.map(async dispatchRoute => {
        try {
          const route = (0, _unwrapProxy.unwrapProxy)(dispatchRoute.get('route'));
          const rdv = dispatchRoute.get('latestRouteVehicleDriver');
          await rdv.save();
          await route.save();
          this.get('notifications').success(`ROUTE ${dispatchRoute.get('route.name')} WAS EDITED SUCCESSFULLY`);
        } catch (error) {
          this.get('notifications').warning(`EDIT FOR ROUTE ${dispatchRoute.get('route.name')} FAILED`);
        }
      }));
    },

    resetRouteFunction(records) {
      return Ember.RSVP.all(records.map(async record => {
        const route = (0, _unwrapProxy.unwrapProxy)(record.get('route'));
        const rdv = record.get('latestRouteVehicleDriver');
        rdv.rollbackAttributes();
        route.rollbackAttributes();
      }));
    },

    saveRouteStartRecordFunction(records) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return Ember.RSVP.all(records.map(record => {
        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot start a route that does not have a vehicle.'));
        }

        record.set('actualStartTimestamp', record.get('timestamp'));
        return routeExecEventAdapter.createStartRouteEvent(record).then(() =>
        /*response*/
        {
          record.set('isForceDirty', false);
          this.get('activeContext').get('refreshTableContent').perform('route');
          this.get('notifications').success('Route started successfully');
          return Ember.RSVP.Promise.resolve();
        }).catch(e => {
          this.get('notifications').warning('Start route failed');
          return Ember.RSVP.Promise.reject(e);
        });
      }));
    },

    saveRouteEndRecordFunction(records) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      return Ember.RSVP.all(records.map(record => {
        if (Ember.isNone(record.get('dispatchRoute.assignedDriver.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a driver.'));
        }

        if (Ember.isNone(record.get('dispatchRoute.assignedVehicle.id'))) {
          return Ember.RSVP.Promise.reject(new Error('You cannot end a route that does not have a vehicle.'));
        }

        record.set('actualEndTimestamp', record.get('timestamp'));
        return routeExecEventAdapter.createEndRouteEvent(record).then(() =>
        /*response*/
        {
          record.set('isForceDirty', false);
          this.get('activeContext').get('refreshTableContent').perform('route');
          this.get('notifications').success('Route completed successfully');
          return Ember.RSVP.Promise.resolve();
        }).catch(e => {
          this.get('notifications').warning('Failed to complete route');
          return Ember.RSVP.Promise.reject(e);
        });
      }));
    },

    // 1. Create a new place in the Address service that represents the breakdown location, address and place
    // 2. Mark the vehicle as broken down in the Transit Vehicle service.
    // 3. Create a replacement route in Transit Dispatch service
    // 4. Move trips from the broken down route to the replacement route. Optional
    saveBreakdownReplacementFunction(dispatchRoutes) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const dispatchRouteAdapter = store.adapterFor('dispatch-Route');
      this.set('editModal.errors', []);
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!Ember.isEmpty(dispatchRoutes)) {
          return dispatchRoutes.forEach(async dispatchRoute => {
            const vehicleBreakdown = (0, _unwrapProxy.unwrapProxy)(dispatchRoute.get('assignedVehicle.vehicleBreakdown'));
            const breakdownPlace = (0, _unwrapProxy.unwrapProxy)(vehicleBreakdown.get('place'));
            let address = (0, _unwrapProxy.unwrapProxy)(breakdownPlace.get('address'));

            try {
              // save location for start garage and end garage
              const locationResponse = await locationAdapter.postLocation(breakdownPlace.get('location.content')); // temporary assignment of zone type until we figure out how to deal with zone types

              address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
              address = await addressAdapter.postAddress(breakdownPlace.get('address'));
              await addressAdapter.postAddressLocation(locationResponse, address);
              const newDispatchRoute = await dispatchRouteAdapter.postSpecialRoute(dispatchRoute, breakdownPlace);

              if (dispatchRoute.get('replacementMoveAllTrips')) {
                const stops = dispatchRoute.get('orderedStops'); // get all pick / drop stop points

                const pickOrDropStops = stops.filter(stop => stop.get('isPickOrDrop')) || []; // get all trips

                const trips = pickOrDropStops.filter(stop => stop.get('isPick')).map(stop => (0, _unwrapProxy.unwrapProxy)(stop.get('trip'))); // get all trips that are still scheduled and active

                const unperformedTrips = trips.filter(trip => !trip.get('isPickPerformed') && !trip.get('isNoShow')); // get all active stops

                const activePicksAndDrops = unperformedTrips.reduce((acu, trip) => {
                  const pickStopPoint = trip.get('pickStopPoint');
                  const dropStopPoint = trip.get('dropStopPoint');
                  acu.push(...[pickStopPoint, dropStopPoint]);
                  return acu;
                }, []);
                await dispatchRouteAdapter.transferAllTripsToRouteFunction(dispatchRoute, newDispatchRoute, activePicksAndDrops);
              } // give it a second to refresh the widgets because socket updates takes awhile to update
              // multiple records


              Ember.run.later(() => {
                this.get('activeContext').get('refreshTableContent').perform(this.get('vehicle'));
              }, 1000);
              this.get('notifications').success(`YOU REPLACED ROUTE ${dispatchRoute.get('route.name')} WITH ROUTE ${dispatchRoute.get('replacementRouteId')} SUCCESSFULLY`);
              return resolve(null);
            } catch (error) {
              this.get('notifications').warning(`ROUTE BREAKDOWN REPLACEMENT FOR ROUTE ${dispatchRoute.get('route.name')} FAILED`);
              reject(error);
            }
          });
        }
      });
    },

    saveDriverBreakFunction(records) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const routeBreakAdapter = store.adapterFor('route-break');
      const dispatchRouteAdapter = store.adapterFor('dispatch-route');
      this.set('editModal.errors', []);
      return records.reduce(async (acu, record) => {
        const requestTime = record.get('requestTime');
        const projectedEndDate = record.get('projectedEndDate');
        const place = (0, _unwrapProxy.unwrapProxy)(record.get('place'));
        const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
        const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
        const locationExist = !Ember.isEmpty(location.get('lat'));
        let placeResponse;
        place.set('type', 'driverBreak');
        place.set('schedule', (0, _unwrapProxy.unwrapProxy)(record.get('schedule')));
        record.set('estimatedStart', requestTime);
        record.set('estimatedEnd', projectedEndDate); // temporary assignment of zone type until we figure out how to deal with
        // zone types

        address.set('zoneName', store.peekAll('zone-type').get('firstObject')); // creating new driver break

        try {
          if (locationExist) {
            record.set('place', place);
            const locationResponse = await locationAdapter.postLocation(location);
            const addressResponse = await addressAdapter.postAddress(address);
            await addressAdapter.postAddressLocation(locationResponse, addressResponse);
            placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
          }

          const placeId = Ember.isNone(placeResponse) ? '' : placeResponse.data.id;
          const routeBreak = await routeBreakAdapter.postRouteBreak(record, placeId);
          return acu.then(() => dispatchRouteAdapter.createDriverBreak((0, _unwrapProxy.unwrapProxy)(record.get('route.dispatchRoute')), routeBreak.data.attributes.plannedRouteOrdinal, routeBreak.data.id)).then(() => {
            this.get('notifications').success(`Successfully added driver break B${routeBreak.data.id} to route ${record.get('route.name')}`);
            this.get('activeContext').get('refreshTableContent').perform([this.get('modelName'), 'stop-point']);
            return Ember.RSVP.Promise.resolve();
          });
        } catch (e) {
          this.get('notifications').warning(`Failed to add driver break to route ${record.get('route.name')}`);
          return Ember.RSVP.Promise.reject();
        }
      }, Ember.RSVP.Promise.resolve());
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('tableActions').unshiftObject({
        id: 'newRoute',
        permId: _mappedPermIds.default.createRoute,
        name: 'New Route',
        action: () => {
          const tripLimitParam = this.store.peekRecord('cs-config-item', 'config-UX_parameters/routeTripLimit');
          const distanceLimitParam = this.store.peekRecord('cs-config-item', 'config-UX_parameters/routeDistanceLimit');
          let tripLimit = 9;
          let distanceLimit = 99; // prepare getting values from config service for tripLimit and distanceLimit
          // Special Route creation does not accept these 2 new variables yet.

          if (!Ember.isNone(tripLimitParam) && !Ember.isNone(tripLimitParam.get('value'))) {
            tripLimit = parseInt(tripLimitParam.get('value'), 10);
          }

          if (!Ember.isNone(distanceLimitParam) && !Ember.isNone(distanceLimitParam.get('value'))) {
            distanceLimit = parseInt(distanceLimitParam.get('value'), 10);
          }

          const newRouteModel = Ember.Object.create({
            routeDate: new Date(this.workspace.startDate),
            plannedStartTime: (0, _moment.default)().toDate(),
            plannedEndTime: (0, _moment.default)().endOf('day').toDate(),
            tripLimit: tripLimit,
            distanceLimit: distanceLimit
          });
          let minDate = null;
          minDate = new Date().getTime();
          this.get('workspace').pushState('addNewRoute', {
            displayName: 'Add Route',
            newRouteModel,
            minDate: (0, _moment.default)(minDate).toDate()
          }, {
            replaceSelf: true
          });
        }
      });
      this.set('assignVehicleDriver', {
        id: 'assignVehicleDriver',
        permId: _mappedPermIds.default.assignVehicleDriver,
        name: 'Assign Vehicle & Driver',
        action: dispatchRoute => {
          const store = this.get('store');
          const name = dispatchRoute.get('route.name');
          const routeVehicleDrivers = (0, _unwrapProxy.unwrapProxy)(dispatchRoute.get('routeVehicleDrivers')); // possibility of a route not having assigned driver and vehicle

          if (!routeVehicleDrivers.length || Ember.isNone(routeVehicleDrivers)) {
            const routeVehicleDriver = store.createRecord('route-vehicle-driver');
            dispatchRoute.get('routeVehicleDrivers').push(routeVehicleDriver);
          }

          this.get('workspace').pushState('assignVehicleDriverToRoute', {
            displayName: `Assign Vehicle & Driver to Route #${name}`,
            dispatchRoute
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      });
      this.set('sendMessage', {
        id: 'sendMessage',
        permId: _mappedPermIds.default.sendRouteMessage,
        name: 'Send Message',
        action: route => {
          if (_environment.default.APP.avlmLite) {
            this.sendMessagesToRoutesAvlmLite([route]);
          } else {
            this.sendMessagesToRoutes([route], 'singleMessage');
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('startRoute', {
        id: 'startRoute',
        permId: _mappedPermIds.default.startRoute,
        name: 'Start Route',
        model: 'vehicle-event',
        action: model => {
          const route = model.get('route');
          const routesStartComponent = this.get('routesStartComponent');
          route.set('odometerInMiles', '');
          route.set('timestamp', new Date());
          this.get('editModal').open(routesStartComponent, [route], this.get('saveRouteStartRecordFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('endRoute', {
        id: 'endRoute',
        permId: _mappedPermIds.default.endRoute,
        name: 'End Route',
        action: model => {
          const route = model.get('route');
          const plannedEndTime = new Date();
          const routesEndComponent = this.get('routesEndComponent');
          route.set('odometerInMiles', '');
          route.set('timestamp', plannedEndTime);
          this.get('editModal').open(routesEndComponent, [route], this.get('saveRouteEndRecordFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('activityLog', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLogRoute,
        name: 'Activity Log',
        action: route => {
          let routeName = route.get('route.name');

          if (_environment.default.APP.avlmLite) {
            routeName = route.get('trackingId');
          }

          this.get('workspace').pushState('routeActivityLog', {
            displayName: `Route ${routeName} Activity Log`,
            route: _environment.default.APP.avlmLite ? (0, _unwrapProxy.unwrapProxy)(route) : (0, _unwrapProxy.unwrapProxy)(route.get('route'))
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: true
      });
      this.set('deleteRoute', {
        id: 'deleteRoute',
        permId: _mappedPermIds.default.deleteRoute,
        name: 'Delete Route',
        model: 'vehicle-event',
        action: model => {
          this.deleteRoute(model);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('addBreakdownReplacement', {
        id: 'addBreakdownReplacement',
        permId: _mappedPermIds.default.createSameDayReplacement,
        name: 'Breakdown Replacement',
        action: async model => {
          const dispatchRoute = model;
          const breakdownReplacementComponent = this.get('breakdownReplacementComponent'); // setup new route information

          dispatchRoute.set('replacementRouteId', '');
          dispatchRoute.set('replacementStartTime', dispatchRoute.get('route.plannedStartTime'));
          dispatchRoute.set('replacementEndTime', dispatchRoute.get('route.plannedEndTime'));
          dispatchRoute.set('replacementRouteType', 'breakdown replacement');
          dispatchRoute.set('replacementDriver', null);
          dispatchRoute.set('replacementVehicle', null);
          dispatchRoute.set('replacementMoveAllTrips', false);
          dispatchRoute.set('breakdownPlace', dispatchRoute.get('assignedVehicle.vehicleBreakdown.place'));
          this.get('editModal').open(breakdownReplacementComponent, [dispatchRoute], this.get('saveBreakdownReplacementFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('rescueRoute', {
        id: 'rescueRoute',
        permId: _mappedPermIds.default.reportRescue,
        name: 'Rescue',
        action: dsRoute => {
          this.get('workspace').pushState('rescueRoute', {
            dsRoute
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      });
      this.set('lockRoute', {
        id: 'lockRoute',
        permId: _mappedPermIds.default.lockUnlockRoute,
        name: 'Lock Route',
        action: model => {
          this.lockRouteConfirmation((0, _unwrapProxy.unwrapProxy)(model));
        },
        disableOnLocked: true,
        disableOnLockedRoute: false
      });
      this.set('unlockRoute', {
        id: 'unlockRoute',
        permId: _mappedPermIds.default.lockUnlockRoute,
        name: 'Unlock Route',
        action: model => {
          this.unLockRouteConfirmation((0, _unwrapProxy.unwrapProxy)(model));
        },
        disableOnLocked: true,
        disableOnLockedRoute: false
      });
      this.set('editRoute', {
        id: 'editRoute',
        permId: _mappedPermIds.default.editRoute,
        name: 'Edit',
        action: model => {
          const editComponent = this.get('editComponent');
          const rdv = model.get('latestRouteVehicleDriver');

          if (rdv) {
            rdv.set('originalPlannedStartTime', rdv.get('startTime'));
            rdv.set('originalPlannedEndTime', rdv.get('endTime'));
            this.get('editModal').open(editComponent, [model], this.get('saveRouteFunction').bind(this), this.get('resetRouteFunction').bind(this));
          }
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('addDriverBreakAction', {
        id: 'addDriverBreak',
        permId: _mappedPermIds.default.reportBreakdown,
        name: 'Add Driver Break',
        action: model => {
          const store = this.get('store');
          const route = (0, _unwrapProxy.unwrapProxy)(model.get('route'));
          const lastStop = route.get('dispatchRoute.orderedStopsWithBreaks').lastObject;
          const driverBreak = store.createRecord('route-break', {
            estimatedStart: new Date(),
            requestTime: new Date(),
            route: route,
            schedule: route.get('schedule'),
            notes: 'Driver Break',
            plannedRouteOrdinal: lastStop.get('routeOrdinal') + 1,
            plannedDuration: 15,
            state: 'planned',
            status: 'unscheduled'
          });

          if (Ember.isNone(driverBreak.get('place.id'))) {
            const locationModel = store.createRecord('location');
            const addressModel = store.createRecord('address', {
              locations: [locationModel]
            });
            const placeModel = store.createRecord('place', {
              address: addressModel,
              location: locationModel
            });
            driverBreak.set('place', placeModel);
          }

          this.get('records').pushObject(driverBreak);
          const editComponent = this.get('driverBreakEditComponent');
          this.get('editModal').open(editComponent, [driverBreak], this.get('saveDriverBreakFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('bulkActions', [{
        id: 'bulkMessage',
        permId: _mappedPermIds.default.sendRouteMessage,
        name: 'Bulk Message',
        action: routes => {
          if (_environment.default.APP.avlmLite) {
            this.sendMessagesToRoutesAvlmLite(routes.map(route => route));
          } else {
            this.sendMessagesToRoutes(routes, 'bulkMessage');
          }
        },
        disableOnLockedRoute: true
      }]);
    },

    dragRowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),

    dragStart(event) {
      const row = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('dragRowGroup'));
      if (Ember.isBlank(row)) return;
      const dragId = Ember.guidFor(row);
      this.get('dragCoordinator').setSourceRow(dragId, row, 'routes');
      event.dataTransfer.setData('text', dragId);
    },

    dragEnd() {
      this.get('dragCoordinator').clearSourceRows();
    },

    saveRecordFunction(records) {
      return Ember.RSVP.all(records.map(record => {
        const {
          name,
          selectedProvider,
          selectedDriver,
          selectedVehicle,
          plannedStartTime,
          plannedEndTime
        } = record.getProperties('name', 'selectedProvider', 'selectedDriver', 'selectedVehicle', 'plannedStartTime', 'plannedEndTime');
        const ajax = this.get('ajax');
        const token = this.get('session.data.authenticated.token'); // const scheduleId = record.get('schedule.id');

        const vehicleId = selectedVehicle ? selectedVehicle.get('id') : null;
        const driverId = selectedDriver ? selectedDriver.get('id') : null;
        const providerNameId = selectedProvider ? selectedProvider.get('id') : null;
        const json = {
          data: {
            type: 'specialRoute',
            attributes: {
              name,
              routeType: 'supervisor',
              shiftStartTime: plannedStartTime,
              shiftEndTime: plannedEndTime
            },
            relationships: {
              dispatchSchedule: {
                data: {
                  id: '1',
                  // TODO: fetch this
                  type: 'dispatchSchedule'
                }
              },
              driver: {
                data: {
                  id: driverId,
                  type: 'driver'
                }
              },
              providerName: {
                data: {
                  id: providerNameId,
                  type: 'providerName'
                }
              },
              vehicle: {
                data: {
                  id: vehicleId,
                  type: 'vehicle'
                }
              }
            }
          }
        };
        return ajax.post(`${specialRouteHost}/special-route`, {
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: json
        }).then(() =>
        /* route */
        {// TODO: handle success
        }).catch(() =>
        /* error */
        {// TODO: show errors?
        });
      }));
    },

    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,status}', function () {
      const sendMessage = this.get('sendMessage');
      const startRoute = this.get('startRoute');
      const endRoute = this.get('endRoute');
      const activityLog = this.get('activityLog');
      const addBreakdownReplacement = this.get('addBreakdownReplacement');
      const rescueRoute = this.get('rescueRoute');
      const lockRoute = this.get('lockRoute');
      const unlockRoute = this.get('unlockRoute');
      const editRoute = this.get('editRoute');
      const addDriverBreakAction = this.get('addDriverBreakAction');
      const row = this.get('table.selectedRows')[0];
      if (!row) return [];
      const userId = this.get('session').data.authenticated.userId;
      const assignVehicleDriver = this.get('assignVehicleDriver');
      const vehicle = row.get('assignedVehicle');
      const isLocked = row.get('record.isLocked');
      const isLockedByOtherUser = row.get('record.isLockedByOtherUser');
      const computedStatus = row.get('record.computedStatus');
      const dispatchRoute = (0, _unwrapProxy.unwrapProxy)(row.get('record'));
      const allStopsPerformed = dispatchRoute.allStopsPerformed();
      const genericActions = [sendMessage, activityLog, editRoute];
      let user;

      if (userId) {
        user = this.get('store').peekRecord('sso-user', userId);
      } // only display the locked route action for "unlocked routes"


      if (!isLocked) {
        genericActions.push(lockRoute);
      } // Check if the route is locked.  If it is, then:
      //  * if the user is an admin, or is the user that locked the route, add the 'unlock' entry, but go ahead and
      //    get the rest of the actions
      //  * if the user is not the user that locked the route, or an admin, then just show the actions that are
      //    always allowed (sendMessage and activityLog)


      if (isLocked) {
        // should not show unlock if locked by another user and non-admin
        const isUserAdmin = user && user.get('isAdmin');

        if (isLockedByOtherUser && !isUserAdmin) {
          return [sendMessage, activityLog];
        } // User is an admin, or was the user that locked the route, allow unlock


        genericActions.push(unlockRoute);
      }

      if (vehicle && vehicle.get('isBroken')) {
        genericActions.push(addBreakdownReplacement);
      }

      if (allStopsPerformed && computedStatus !== _dispatchRoute.ROUTE_COMPLETED_STATUS) {
        genericActions.push(endRoute);
      }

      switch (computedStatus) {
        case _dispatchRoute.ROUTE_SIGNED_ON_STATUS:
          genericActions.push(...[startRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_STARTED_STATUS:
        case _dispatchRoute.ROUTE_IN_PROGRESS_STATUS:
        case _dispatchRoute.ROUTE_ON_BREAK_STATUS:
          genericActions.push(...[rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_COMPLETED_STATUS:
          break;

        case _dispatchRoute.ROUTE_AWAITING_STATUS:
        case _dispatchRoute.ROUTE_DISPATCHED_STATUS:
          genericActions.push(...[assignVehicleDriver, startRoute, rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_EMERGENCY_STATUS:
          genericActions.push(...[assignVehicleDriver, startRoute, rescueRoute, addDriverBreakAction]);
          break;

        case _dispatchRoute.ROUTE_NOT_STARTED_STATUS:
        case _dispatchRoute.ROUTE_NO_DRIVER_STATUS:
        default:
          genericActions.push(...[assignVehicleDriver, rescueRoute, addDriverBreakAction]);
      }

      return genericActions;
    }),

    async sendMessagesToRoutesAvlmLite(avlmRoutes) {
      const avlmRouteList = avlmRoutes ? avlmRoutes : [];
      const draftMessages = avlmRouteList.map(avlmRoute => {
        const driverId = avlmRoute.get('latestDriver') ? avlmRoute.get('latestDriver.id') : null;
        const vehicleId = avlmRoute.get('latestVehicle') ? avlmRoute.get('latestVehicle.callSign') : null;
        const routeId = avlmRoute.get('latestRouteVehicleDriver.route.id') ? avlmRoute.get('latestRouteVehicleDriver.route.id') : null;
        if (!driverId) return this.get('notifications').warning(`Can not send message due to missing driver id on route id = ${routeId}`);
        avlmRoute.set('route', {
          id: routeId
        });
        avlmRoute.set('messageNr', '0');
        avlmRoute.set('avlmDriver', {
          id: driverId,
          endPointType: 'recipient',
          fullName: avlmRoute.get('latestDriver') ? avlmRoute.get('latestDriver.fullName') : null,
          badgeNumber: avlmRoute.get('latestDriver') ? avlmRoute.get('latestDriver.badgeNumber') : null
        });
        avlmRoute.set('avlmVehicle.content', {
          id: vehicleId
        });
        return avlmRoute;
      });
      this.get('workspace').pushState('addNewMessage', {
        draftMessages
      });
    },

    sendMessagesToRoutes(dsRoutes, messageType) {
      const dsRouteList = dsRoutes ? dsRoutes : [];
      const draftMessages = dsRouteList.map(dsRoute => {
        const trueVehicle = (0, _unwrapProxy.unwrapProxy)(dsRoute.get('assignedVehicle'));

        if (Ember.isNone(trueVehicle)) {
          return [];
        }

        const driverId = trueVehicle.get('latestDriver') ? trueVehicle.get('latestDriver.id') : null;
        const badgeNumber = trueVehicle.get('latestDriver') ? trueVehicle.get('latestDriver.badgeNumber') : null;

        if (messageType === 'singleMessage' && !badgeNumber) {
          this.get('notifications').warning(`Can not send message due to missing driver id on vehicle id = ${trueVehicle.get('id')}`);
        }

        if (messageType === 'bulkMessage' && !badgeNumber) {
          this.get('notifications').warning('MESSAGE COULD NOT BE SENT TO SOME VEHICLES WHICH DO NOT HAVE DRIVER ASSOCIATED.');
        }

        trueVehicle.set('driver', {
          id: driverId,
          endPointType: 'recipient',
          fullName: trueVehicle.get('latestDriver.fullName'),
          badgeNumber: badgeNumber
        });
        const vehicleId = trueVehicle.get('id');
        const vehicleName = trueVehicle.get('name');
        trueVehicle.set('vehicle', {
          id: vehicleId,
          name: vehicleName
        });
        const routeId = trueVehicle.get('latestRouteVehicleDriver.route.id');
        const routeName = trueVehicle.get('latestRouteVehicleDriver.dispatchRoute.route.name');
        trueVehicle.set('route', {
          id: routeId,
          name: routeName
        });
        trueVehicle.set('messageNr', '0');

        if (_environment.default.APP.avlmLite) {
          trueVehicle.set('avlmDriver', {
            id: driverId,
            endPointType: 'recipient'
          });
          trueVehicle.set('avlmVehicle', {
            id: vehicleId
          });
        }

        return trueVehicle;
      });

      if (messageType === 'singleMessage' && draftMessages.firstObject.driver.badgeNumber) {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      } else if (messageType === 'bulkMessage') {
        this.get('workspace').pushState('addNewMessage', {
          draftMessages
        });
      }
    },

    deleteRoute(route) {
      const isCompleted = route.get('isCompleted');
      const isEmptyRoute = route.get('isEmptyRoute');

      if (isCompleted || !isEmptyRoute) {
        this.get('notifications').warning('Cannot delete trips that is not empty');
      } else {
        // delete endpoint does not exist in dispatch yet.
        route.deleteRecord();
        route.save();
      }
    },

    doLockRoute(dispatchRoute) {
      const session = this.get('session');
      const notifications = this.get('notifications');
      const timeoutParam = this.store.peekRecord('cs-config-item', 'config-UX_parameters/routeLockTimeout');
      const routeLockTimeout = timeoutParam ? parseInt(timeoutParam.get('value'), 10) : 1800;
      const json = {
        data: {
          type: 'lock',
          id: dispatchRoute.get('id'),
          attributes: {
            entityId: dispatchRoute.get('id'),
            entityType: 'dispatchRoute',
            timeToLive: routeLockTimeout,
            persist: true
          }
        }
      };
      this.get('ajax').post(_apiUrls.API.dispatchService.host + '/lock', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      }).catch(function (error) {
        notifications.warning(`Error creating route lock, ${error.message}`);
      });
    },

    lockRouteConfirmation(dispatchRoute) {
      const tooltip = this.get('tooltip');
      const tip = `You are locking route ${dispatchRoute.get('route.name')}`;
      const title = 'Lock Route';

      if (dispatchRoute.get('isLocked')) {
        return;
      }

      tooltip.setProperties({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.doLockRoute(dispatchRoute);
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return;
        }
      });
    },

    unLockRouteConfirmation(dispatchRoute) {
      const tooltip = this.get('tooltip');
      const tip = `Route Unlocked ${dispatchRoute.get('route.name')}`;
      const title = 'Unlock Route';

      if (!dispatchRoute.get('isLocked')) {
        return;
      }

      tooltip.setProperties({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          return this.doUnlockRoute(dispatchRoute);
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => {
          return;
        }
      });
    },

    doUnlockRoute(dispatchRoute) {
      const session = this.get('session');
      const notifications = this.get('notifications');
      const userId = session.data.authenticated.userId;
      const dispatchRouteLock = dispatchRoute.get('dispatchRouteLock.content');
      const user = this.get('store').peekRecord('sso-user', userId);

      if (!dispatchRoute.get('isLocked')) {
        return;
      }

      if (userId !== dispatchRouteLock.get('owner.id') && !user.get('isAdmin')) {
        notifications.info('You do not have permission to unlock this route.');
        return;
      }

      if (!Ember.isNone(dispatchRouteLock)) {
        this.get('ajax').delete(_apiUrls.API.dispatchService.host + '/lock/force/dispatchRoute/' + dispatchRouteLock.get('id'), {
          method: 'DELETE',
          contentType: 'application/json',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          }
        }).catch(function (error) {
          notifications.warning(`Error removing route lock, ${error.message}`);
        });
      }
    }

  });

  _exports.default = _default;
});