define("adept-iq/pods/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    workspace: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    socket: Ember.inject.service(),
    work: Ember.inject.service(),
    user: Ember.inject.service(),

    beforeModel() {
      this.get('workspace').initUserRecord();
    },

    sessionInvalidated() {
      const lastDashboardId = localStorage.getItem('lastDashboardId');
      const currentUser = localStorage.getItem('currentUser');
      localStorage.clear(); // put this back for re-login

      const userDash = `${currentUser}*${lastDashboardId}`;
      localStorage.setItem('currentUser', currentUser);
      localStorage.setItem('userDash', userDash);
      localStorage.setItem('lastDashboardId', lastDashboardId); // `store.unloadAll()` takes a really long time; this is much quicker

      window.location.assign('/login');
    },

    actions: {
      openDashboard(dashboard) {
        // TODO: prompt to save changes
        this.get('tooltip').reset();
        this.transitionTo('dashboard', dashboard);
      },

      transitionTo() {
        this.transitionTo(...arguments);
      },

      async logout() {
        return Ember.RSVP.resolve().then(() => {
          if (this.get('user').isRoadSupEnable()) {
            return this.get('roadSupervisor').onLogoutPerformPullIn();
          }
        }).then(() => {
          return this.get('session').invalidate();
        });
      }

    }
  });

  _exports.default = _default;
});