define("adept-iq/models/avlm-stop-point", ["exports", "ember-data", "adept-iq/mixins/otp-label", "adept-iq/utils/convertDistance"], function (_exports, _emberData, _otpLabel, _convertDistance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const REMOVE_STATUS = 'R';

  var _default = Model.extend(_otpLabel.default, {
    activeContext: Ember.inject.service(),
    routeIndex: attr('string'),
    eta: attr('date'),
    status: attr('string'),
    otp: attr('string'),
    otpValue: attr('string'),
    odometer: attr('string'),
    otpStatus: attr('string'),
    dwellTime: attr('string'),
    clusterIndex: attr('string'),
    arriveLat: attr('string'),
    arriveLng: attr('string'),
    departLat: attr('string'),
    departLng: attr('string'),
    actualArriveTime: attr('date'),
    actualDepartTime: attr('date'),
    avlmTrip: belongsTo('avlm-trip'),
    avlmCluster: belongsTo('avlm-cluster'),
    avlmSchedule: belongsTo('avlm-schedule'),
    avlmAddress: belongsTo('avlm-address'),
    avlmRiderEvent: belongsTo('avlm-rider-event'),
    routeBreak: belongsTo('avlm-route-break'),
    polyline: belongsTo('polyline'),
    // contains the polyline from one stop-point to another stop-point
    etaNavStopPoint: belongsTo('etanav-stop-point'),
    stopRoute: belongsTo('avlm-route'),
    providerName: Ember.computed('avlmTrip.{pick,drop}', function () {
      if (this.get('avlmTrip.avlmRoute.providerName')) {
        return this.get('avlmTrip.avlmRoute.providerName');
      }

      return this.get('stopRoute.providerName');
    }),
    iqStop: belongsTo('iq-stop', {
      inverse: 'avlmStop'
    }),
    actualETA: Ember.computed('etaNavStopPoint', 'eta', 'etaNavStopPoint.eta', function () {
      const eta = this.get('eta');
      const navETA = this.get('etaNavStopPoint.eta');

      if (navETA) {
        return navETA;
      }

      return eta;
    }),
    lat: Ember.computed('place.latitude', 'estimatedBreakLatLng', function () {
      const lat = this.get('place.latitude');
      if (lat) return lat;
      const estimatedBreakLatLng = this.get('estimatedBreakLatLng');
      if (estimatedBreakLatLng) return estimatedBreakLatLng[0];
      return null;
    }),
    lng: Ember.computed('place.longitude', 'estimatedBreakLatLng', function () {
      const lng = this.get('place.longitude');
      if (lng) return lng;
      const estimatedBreakLatLng = this.get('estimatedBreakLatLng');
      if (estimatedBreakLatLng) return estimatedBreakLatLng[1];
      return null;
    }),
    place: Ember.computed('avlmAddress', 'routeBreak.avlmAddress', 'stopRoute', function () {
      const avlmAddress = this.get('avlmAddress.content');
      const routeBreakPlace = this.get('routeBreak.avlmAddress.content');
      const startGarage = this.get('stopRoute.startAddress.content');
      const endGarage = this.get('stopRoute.endAddress.content');
      const stopType = this.get('stopType');
      if (avlmAddress) return avlmAddress;
      if (routeBreakPlace) return routeBreakPlace;
      if (stopType === 'pulloutGarage' && startGarage) return startGarage;
      if (stopType === 'pullinGarage' && endGarage) return endGarage;
      return null;
    }),
    stopAddresses: Ember.computed('avlmAddress', 'routeBreak.avlmAddress', 'stopRoute', function () {
      const avlmAddress = this.get('avlmAddress.content');
      const routeBreakPlace = this.get('routeBreak.avlmAddress.content');
      const startGarage = this.get('stopRoute.startAddress.content');
      const endGarage = this.get('stopRoute.endAddress.content');
      const stopType = this.get('stopType');

      if (avlmAddress) {
        return this.extractAddress(avlmAddress.fullAddress);
      }

      if (routeBreakPlace) {
        return this.extractAddress(routeBreakPlace.fullAddress);
      }

      if (stopType === 'pulloutGarage' && startGarage) {
        return this.extractAddress(startGarage.fullAddress);
      }

      if (stopType === 'pullinGarage' && endGarage) {
        return this.extractAddress(endGarage.fullAddress);
      }

      return null;
    }),

    extractAddress(address) {
      if (address === ' , , , ') {
        return null;
      }

      let place = address.trim();
      /*eslint-disable */

      place.replace(/[&\==/\\#+()$~%-.'":*?<>{}]/g, '');
      place = place.replace(/\//g, '');
      place = place.replace(/==/g, '');
      place = place.replace(/\*/g, '');
      place = place.replace(/>/g, '');
      place = place.replace(/</g, '');
      /*eslint-disable */

      if (place) {
        return place;
      }

      return null;
    },

    avlmRoute: Ember.computed('stopRoute', 'routeBreak', 'avlmCluster', function () {
      const stopRoute = this.get('stopRoute.content');
      const routeBreak = this.get('routeBreak.content');
      if (stopRoute) return stopRoute;
      if (routeBreak) return routeBreak.get('avlmRoute'); // return this.get('avlmCluster.route');

      return this.get('etaNavStopPoint.etaNavRoute');
    }),
    avlmRouteOnlyBreaks: Ember.computed('stopRoute', 'routeBreak', 'avlmCluster', function () {
      const stopRoute = this.get('stopRoute.content');
      const routeBreak = this.get('routeBreak.content');
      if (stopRoute) return stopRoute;
      if (routeBreak) return routeBreak.get('avlmRoute');
      return null;
    }),
    stopType: Ember.computed('avlmTrip.{pick,drop}', function () {
      const pickId = this.get('avlmTrip.pick.id');
      const dropId = this.get('avlmTrip.drop.id');
      const id = this.get('id');

      if (id.includes('DP')) {
        return 'deployStop';
      } else if (pickId) {
        return pickId === id ? 'pick' : 'drop';
      } else if (dropId) {
        return dropId === id ? 'drop' : 'pick';
      } else if (id.includes('GP')) {
        return 'pulloutGarage';
      } else if (id.includes('GD')) {
        return 'pullinGarage';
      }

      return 'break';
    }),
    parentType: Ember.computed('stopType', function () {
      const stopType = this.get('stopType');

      switch (stopType) {
        case 'pick':
        case 'drop':
          return 'trip';

        case 'deployStop':
          return 'deployStop';

        default:
          return 'break';
      }
    }),
    realRouteIndex: Ember.computed('routeIndex', 'routeBreak.routeIndex', function () {
      const breakIndex = this.get('routeBreak.routeIndex');
      const index = breakIndex ? breakIndex : this.get('routeIndex');
      if (!index) return null;
      const t = parseInt(index, 10);
      return t;
    }),
    currentStatus: Ember.computed('status', function () {
      let status = this.get('status');
      let stopType = this.get('stopType');

      if (!status) {
        status = 'A';
      }

      if (stopType === "deployStop" && status === "performed") {
        status = 'Depart';
      }

      if (stopType === "break" && status === "arrived") {
        status = 'OnBreak';
      }

      if (stopType === "break" && status === "OffBreak") {
        status = 'performed';
      }

      return status;
    }),
    statusChanged: Ember.observer('status', function () {
      const status = this.get('status');
      const oldStatus = this.get('_oldStatus');

      if (status === REMOVE_STATUS && oldStatus !== REMOVE_STATUS) {
        // cache old value
        this.set('_oldStatus', status);
        this.get('activeContext').refreshTableContent(['avlm-stop-point']);
      } else if (oldStatus === REMOVE_STATUS && status !== REMOVE_STATUS) {
        // cache old value
        this.set('_oldStatus', status);
        this.get('activeContext').refreshTableContent(['avlm-stop-point']);
      }
    }),
    etaChange: Ember.observer('eta', function () {
      const oldETA = this.get('_oldETA');
      const eta = this.get('eta');
      if (Ember.isEmpty(eta)) return;
      if (Ember.isEmpty(oldETA)) return;

      if (oldETA.getTime() !== eta.getTime()) {
        this.get('activeContext').refreshTableContent(['avlm-stop-point']);
        this.set('_oldETA', eta);
      }
    }),
    convertedOdometer: Ember.computed('odometer', function () {
      const odometer = this.get('odometer');

      if (odometer) {
        return (0, _convertDistance.convertMetersToMiles)(odometer);
      }

      return '';
    }),
    otpValueWithSign: Ember.computed('otp', 'otpValue', function () {
      const otp = this.get('otp');
      const otpValue = this.get('otpValue');
      const sign = otp === 'O' || otp === 'E' ? '-' : '+';

      if (otpValue) {
        return `${sign}${otpValue}`;
      }

      return '';
    }),
    tripStartTime: Ember.computed('avlmTrip.tripStartTime', 'avlmRoute.tripStartTime', 'avlmRiderEvent.scheduledTime', 'eta', function () {
      const tripStartTime = this.get('avlmTrip.content.tripStartTime');
      const minTripStartTime = this.get('avlmRoute.tripStartTime');
      const eta = this.get('avlmRiderEvent.scheduledTime') ? this.get('avlmRiderEvent.scheduledTime') : this.get('eta');

      switch (this.get('parentType')) {
        case 'break':
          return minTripStartTime ? minTripStartTime : eta;

        case 'pulloutGarage':
        case 'pullinGarage':
          return minTripStartTime ? minTripStartTime : eta;

        case 'deployStop':
          return minTripStartTime ? minTripStartTime : eta;

        default:
          if (minTripStartTime) return minTripStartTime;else if (tripStartTime) return tripStartTime;
          return eta;
      }
    }),
    tripEndTime: Ember.computed('avlmTrip.tripEndTime', 'avlmRoute.tripEndTime', 'avlmRiderEvent.scheduledTime', 'eta', function () {
      const tripEndTime = this.get('avlmTrip.content.tripEndTime');
      const maxTripEndTime = this.get('avlmRoute.tripEndTime');
      const eta = this.get('avlmRiderEvent.scheduledTime') ? this.get('avlmRiderEvent.scheduledTime') : this.get('eta');

      switch (this.get('parentType')) {
        case 'break':
          return maxTripEndTime ? maxTripEndTime : eta;

        case 'pulloutGarage':
        case 'pullinGarage':
          return maxTripEndTime ? maxTripEndTime : eta;

        case 'deployStop':
          return maxTripEndTime ? maxTripEndTime : eta;

        default:
          if (maxTripEndTime) return maxTripEndTime;else if (tripEndTime) return tripEndTime;
          return eta;
      }
    }),
    stopCloseToBreak: Ember.computed('realRouteIndex', 'avlmRoute.orderedStopsWithGaragesAndBreaks', function () {
      const ordinal = this.get('realRouteIndex');
      const orderedStops = this.get('avlmRoute.orderedStopsWithGaragesAndBreaks');

      if (this.get('stopType') === 'break' && ordinal && ordinal > 0 && orderedStops) {
        // either a stop in front or behind the break
        const behind = ordinal - 1;
        const inFront = ordinal + 1; // get infront stop first as the closer stop

        let stop = orderedStops.find(s => s.get('realRouteIndex') === inFront);

        if (stop) {
          return stop;
        } // get behind stop if break is last


        stop = orderedStops.find(s => s.get('realRouteIndex') === behind);

        if (stop) {
          return stop;
        }
      }

      return null;
    }),
    estimatedBreakLatLng: Ember.computed('stopCloseToBreak', 'departLat', 'departLng', function () {
      const stop = this.get('stopCloseToBreak');
      const departLat = this.get('departLat');
      const departLng = this.get('departLng');

      if (departLat && departLng) {
        return [departLat, departLng];
      }

      if (stop) {
        // otherwise return an estimated lat,lng
        const polyline = stop.get('polyline.coords');

        if (polyline) {
          const median = Math.floor(polyline.length / 2);
          return polyline[median];
        }
      }

      return null;
    }),
    polylinePoints: Ember.computed.readOnly('polyline.coords')
  });

  _exports.default = _default;
});