define("adept-iq/pods/components/form-components/bs-travel-needs/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // status of travel needs within the UI
  const STATUS_TYPE_EXISTING = 'existing'; // already part of rider

  const STATUS_TYPE_AVAILABLE = 'available'; // available for rider to add

  const STATUS_TYPE_ADDED = 'added'; // it will be added to rider once it is saved

  var _default = Ember.Component.extend({
    classNames: ['form-components-travel-need'],
    store: Ember.inject.service(),
    editModal: Ember.inject.service(),
    value: null,
    disabled: false,
    pcaState: false,
    serviceAnimalState: false,
    travelNeedTypes: null,
    passengerTypes: null,
    travelNeeds: Ember.computed.readOnly('value'),
    countPath: Ember.computed.readOnly('extra.countPath'),
    travelNeedTypePath: Ember.computed.readOnly('extra.travelNeedTypePath'),
    passengerTypePath: Ember.computed.readOnly('extra.passengerTypePath'),
    modelName: Ember.computed.readOnly('field.modelName'),
    equipments: Ember.computed('record.leg.{equipments,newTravelNeeds}', 'record.{equipments,newTravelNeeds}', function () {
      const equipments = this.get('record.leg.equipments') || [];
      const legTravelNeeds = this.get('record.leg.legTravelNeeds') || [];
      const recordEquipments = this.get('record.equipments') || [];
      const newTravelNeeds = this.get('record.newTravelNeeds') || [];

      if (equipments && legTravelNeeds) {
        equipments.map(travelNeed => {
          travelNeed.set('status', STATUS_TYPE_EXISTING);
        });
        legTravelNeeds.map(travelNeed => {
          travelNeed.set('status', STATUS_TYPE_AVAILABLE);
        });
        return _lodash.default.concat(equipments, legTravelNeeds);
      }

      recordEquipments.map(travelNeed => {
        travelNeed.set('status', STATUS_TYPE_EXISTING);
      });
      newTravelNeeds.map(travelNeed => {
        travelNeed.set('status', STATUS_TYPE_AVAILABLE);
      });
      return _lodash.default.concat(recordEquipments, newTravelNeeds);
    }),
    newEquipments: Ember.computed('record.{newTravelNeeds,leg.newTravelNeeds}', function () {
      const legNewTravelNeeds = this.get('record.leg.newTravelNeeds') || [];
      const newTravelNeeds = this.get('record.newTravelNeeds') || [];
      let travelNeeds = [];

      if (legNewTravelNeeds) {
        travelNeeds = legNewTravelNeeds.filter(travelNeed => {
          return travelNeed.get('passengerType.isClient') && !travelNeed.get('travelNeedType.isServiceAnimal') && travelNeed.get('status') === STATUS_TYPE_AVAILABLE;
        });
      }

      travelNeeds = newTravelNeeds.filter(travelNeed => {
        return travelNeed.get('passengerType.isClient') && !travelNeed.get('travelNeedType.isServiceAnimal') && travelNeed.get('status') === STATUS_TYPE_AVAILABLE;
      });
      return travelNeeds;
    }),
    extraPassengers: Ember.computed('record.{extraPassengers,newTravelNeeds,leg.extraPassengers,leg.newTravelNeeds}', function () {
      const legExtraPassengers = this.get('record.leg.extraPassengers') || [];
      const newTravelNeeds = this.get('record.newTravelNeeds') || [];
      const extraPassengers = this.get('record.extraPassengers') || [];
      let tempArray = null;

      if (legExtraPassengers) {
        tempArray = _lodash.default.concat(legExtraPassengers, newTravelNeeds);
      } else tempArray = _lodash.default.concat(extraPassengers, newTravelNeeds);

      const extraPassObjects = []; // arrange in specific order

      extraPassObjects.push(tempArray.find(travelNeed => travelNeed.get('travelNeedType.isServiceAnimal')));
      extraPassObjects.push(tempArray.find(travelNeed => travelNeed.get('passengerType.isPca')));
      extraPassObjects.push(tempArray.find(travelNeed => travelNeed.get('passengerType.isCompanion')));
      return extraPassObjects;
    }),
    init: function () {
      this._super(...arguments);

      const travelNeedTypeModelName = this.get('extra.travelNeedTypeModelName');
      const passengerTypeModelName = this.get('extra.passengerTypeModelName');
      this.set('travelNeedTypes', this.get('store').peekAll(travelNeedTypeModelName));
      this.set('passengerTypes', this.get('store').peekAll(passengerTypeModelName));
      this.setupNewEquipments();
      this.setupNewExtraPassengers();
    },
    clientPassengerType: function () {
      const passengerTypes = this.get('passengerTypes');
      return passengerTypes.find(types => types.isClient);
    },
    serviceAnimalTravelNeedType: function () {
      const travelNeedTypes = this.get('travelNeedTypes');
      return travelNeedTypes.find(types => types.isServiceAnimal);
    },
    ambulatoryTravelNeedType: function () {
      const travelNeedTypes = this.get('travelNeedTypes');
      return travelNeedTypes.find(types => types.isAmbulatory);
    },
    pcaPassengerType: function () {
      const passengerTypes = this.get('passengerTypes');
      return passengerTypes.find(types => types.isPca);
    },
    companionPassengerType: function () {
      const passengerTypes = this.get('passengerTypes');
      return passengerTypes.find(types => types.isCompanion);
    },
    setupNewEquipments: function () {
      const currentTravelNeeds = this.get('travelNeeds') || [];
      const travelNeedTypes = this.get('travelNeedTypes') || [];
      const newTravelNeeds = []; // add other travel need types that this person does not have
      // with empty count

      travelNeedTypes.map(travelNeedType => {
        if (!travelNeedType.isServiceAnimal && !currentTravelNeeds.any(travelNeed => {
          return travelNeed.get('travelNeedType.id') === travelNeedType.id;
        })) {
          const newRecord = this.get('store').createRecord(this.get('modelName'));
          newRecord.set('travelNeedType', travelNeedType);
          newRecord.set('passengerType', this.clientPassengerType());
          newRecord.set('status', STATUS_TYPE_AVAILABLE);
          newTravelNeeds.push(newRecord);
        }
      });
      if (this.get('modelName') === 'leg-travel-need') this.set('record.leg.newTravelNeeds', newTravelNeeds);
      this.set('record.newTravelNeeds', newTravelNeeds);
    },
    setupNewExtraPassengers: function () {
      const currentTravelNeeds = this.get('travelNeeds') || [];
      const travelNeeds = this.get('record.newTravelNeeds') || [];
      const newTravelNeeds = [];
      let retVal;
      travelNeeds.forEach(travelNeed => {
        newTravelNeeds.push(travelNeed);
      }); // add service animal travel need type if this rider does not have
      // in current travel needs. leave count as empty

      retVal = currentTravelNeeds.find(travelNeed => travelNeed.get('travelNeedType.isServiceAnimal'));

      if (_lodash.default.isUndefined(retVal)) {
        const newRecord = this.get('store').createRecord(this.get('modelName'));
        this.set('serviceAnimalState', false);
        newRecord.set('travelNeedType', this.serviceAnimalTravelNeedType());
        newRecord.set('passengerType', this.clientPassengerType());
        newTravelNeeds.push(newRecord);
      } else {
        this.set('serviceAnimalState', retVal.get('count') > 0);
      } // add pca passenger type for this rider if it does not have
      // in current travel needs. leave count as empty


      retVal = currentTravelNeeds.find(travelNeed => {
        return travelNeed.get('passengerType.isPca');
      });

      if (_lodash.default.isUndefined(retVal)) {
        const newRecord = this.get('store').createRecord(this.get('modelName'));
        this.set('pcaState', false);
        newRecord.set('travelNeedType', this.ambulatoryTravelNeedType());
        newRecord.set('passengerType', this.pcaPassengerType());
        newTravelNeeds.push(newRecord);
      } else {
        this.set('pcaState', retVal.get('count') > 0);
      } // add companion passenger type for this rider if it does not have
      // in current travel needs. leave count as empty


      retVal = currentTravelNeeds.find(travelNeed => travelNeed.get('passengerType.isCompanion'));

      if (_lodash.default.isUndefined(retVal)) {
        const newRecord = this.get('store').createRecord(this.get('modelName'));
        newRecord.set('travelNeedType', this.ambulatoryTravelNeedType());
        newRecord.set('passengerType', this.companionPassengerType());
        newTravelNeeds.push(newRecord);
      }

      if (this.get('modelName') === 'leg-travel-need') this.set('record.leg.newTravelNeeds', newTravelNeeds);
      this.set('record.newTravelNeeds', newTravelNeeds);
    },
    actions: {
      onInput(record, valuePath, value) {
        const useOptionRecord = true;
        const options = {
          record,
          valuePath,
          useOptionRecord
        };
        this.set('record.isForceDirty', true);
        this.get('onChange')(parseInt(value, 10), options);
      },

      onAddNewTravelNeed() {
        // const newEquipments = this.newEquipments();
        const newEquipments = this.newEquipments() || [];
        const travelNeed = newEquipments.find(currTravelNeed => {
          return currTravelNeed.status === STATUS_TYPE_AVAILABLE && currTravelNeed.isEquipment;
        });

        if (!Ember.isNone(travelNeed)) {
          travelNeed.set('status', STATUS_TYPE_ADDED);
        }
      },

      onTravelNeedTypeChange(oldTravelNeed, newTravelNeed) {
        oldTravelNeed.set('status', STATUS_TYPE_AVAILABLE);
        oldTravelNeed.set('count', 0);
        newTravelNeed.set('status', STATUS_TYPE_ADDED);
      },

      onRemoveTravelNeed(record, valuePath) {
        const options = {
          record,
          valuePath
        };

        if (!Ember.isNone(record)) {
          record.set('status', STATUS_TYPE_AVAILABLE);
        }

        this.get('onChange')(0, options);
      },

      onPCAChange(record, valuePath) {
        const useOptionRecord = true;
        this.toggleProperty('pcaState');
        const options = {
          record,
          valuePath,
          useOptionRecord
        };
        const currValue = this.get('pcaState') ? 1 : 0;
        this.set('record.isForceDirty', true);
        this.get('onChange')(currValue, options);
      },

      onServiceAnimalChange(record, valuePath) {
        const useOptionRecord = true;
        this.toggleProperty('serviceAnimalState');
        const options = {
          record,
          valuePath,
          useOptionRecord
        };
        const currValue = this.get('serviceAnimalState') ? 1 : 0;
        this.set('record.isForceDirty', true);
        this.get('onChange')(currValue, options);
      },

      onPassengerTypeChange(record, valuePath, value) {
        const useOptionRecord = true;
        const options = {
          record,
          valuePath
        };
        this.get('onChange')(value, options, useOptionRecord);
      }

    }
  });

  _exports.default = _default;
});