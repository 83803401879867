define("adept-iq/models/provider", ["exports", "ember-data", "adept-iq/config/environment", "lodash"], function (_exports, _emberData, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const LOCAL_STORAGE_KEY = 'dashboard-info';

  function getIsCheckedDefaultValue() {
    const dashboardInfo = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (_environment.default.APP.avlmLite && dashboardInfo) {
      return false;
    }

    return true;
  }

  var _default = Model.extend({
    session: Ember.inject.service(),
    name: attr('string'),
    providerType: belongsTo(),
    schedule: belongsTo(),
    bookings: hasMany('booking'),
    vehicles: hasMany('vehicle'),
    avlmVehicle: hasMany('avlm-vehicle'),
    trips: hasMany('trip'),
    avlmTrip: hasMany('avlm-trip'),
    routes: hasMany('route'),
    avlmRoute: hasMany('avlm-route'),
    drivers: hasMany('driver'),
    avlmDriver: hasMany('avlm-driver'),
    isChecked: attr('bool', {
      defaultValue: getIsCheckedDefaultValue()
    }),
    providerName: Ember.computed('name', function () {
      const name = this.get('name');
      if (name) return name;
      const id = this.get('id');
      return (0, _lodash.startCase)(id);
    }),
    // return true if the user have access to this provider
    userCanAccessProvider: Ember.computed('id', function () {
      const userProviders = this.get('session.data.authenticated.tokenInfo.providerNames') || [];
      const canAccess = userProviders.includes('ALL') ? true : userProviders.includes(this.get('id'));
      return canAccess;
    })
  });

  _exports.default = _default;
});