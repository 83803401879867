define("adept-iq/pods/components/notification-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    flashMessage: Ember.computed.readOnly('notifications.topFlashMessage'),
    classNames: ['notification-bar'],
    actions: {
      close() {
        this.get('flash').destroyMessage();
      }

    }
  });

  _exports.default = _default;
});