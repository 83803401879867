define("adept-iq/pods/components/iq-widgets/trips-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/column-widget/component", "adept-iq/pods/components/iq-widgets/trips-widget/config", "adept-iq/pods/components/iq-widgets/trips-widget/avlmConfig", "adept-iq/config/environment", "adept-iq/utils/find-row-record", "adept-iq/utils/unwrapProxy", "adept-iq/config/api-urls", "moment", "lodash", "adept-iq/config/mapped-permIds"], function (_exports, _component, _config, _avlmConfig, _environment, _findRowRecord, _unwrapProxy, _apiUrls, _moment, _lodash, _mappedPermIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = ['rider', 'cluster.route', 'stops', 'stops.place', 'rider.travelNeeds', 'rider.travelNeeds.travelNeedType', 'cluster', 'cluster.route,stops.stopEvents', 'fareType', 'serviceWindow'];

  var _default = _component.default.extend({
    dragCoordinator: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['trips-widget', 'data-test-trips-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    booking: Ember.inject.service(),
    notifications: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    config: _config.default,
    defaultIncludes: INCLUDES,
    noShowComponent: 'iq-widgets/noshow-event-stop-form-widget',
    editTripComponent: 'iq-widgets/edit-trip-form-widget',
    cancelTripComponent: 'iq-widgets/cancel-type-form-widget',
    routeexecevent: Ember.inject.service(),

    init() {
      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      this._super(...arguments);
    },

    saveTripRecordFunction(records) {
      const travelNeedsChangeArray = [];

      function saveDeleteAllTravelNeeds(objects) {
        const deleteObjects = _lodash.default.difference(objects.content.canonicalState, objects.content.currentState) || []; // delete objects that have been removed from the array

        return Promise.all(deleteObjects.map(deleteObject => {
          travelNeedsChangeArray.push(deleteObject._record.get('travelNeedType.name'));

          deleteObject._record.deleteRecord();

          return deleteObject.save();
        })).then(() => {
          return Promise.all(objects.map(object => {
            if (object.dirtyType) {
              travelNeedsChangeArray.push(object.get('travelNeedType.name'));
            }

            object.save();
          }));
        });
      }
      /*eslint-disable */


      function saveNewTravelNeedObjects(rider, oldObjects, newObjects) {
        return Promise.all(newObjects.map(newObject => {
          if (newObject.get('count') !== null && newObject.get('count') > 0) {
            newObject.set('rider', rider);
            newObject.save();
            travelNeedsChangeArray.push(newObject.get('travelNeedType.name'));
            return newObject;
          }
        }));
      } // It's only being used for patching specific trips currently


      return records.reduce(async (acu, record) => {
        await saveDeleteAllTravelNeeds(record.get('leg.legTravelNeed'));
        await saveNewTravelNeedObjects(record, record.get('leg.legTravelNeed'), record.get('leg.newTravelNeed'));
        await record.get('leg.segment.pick.place').save();
        await record.get('leg.segment.drop.place').save();
        await record.get('leg.segment').save();
        await record.get('leg').save();
        return record.save();
      });
    },

    saveCancelRecordFunction(records) {
      /*eslint-disable */
      const me = this;
      /*eslint-enable */

      return new Promise((resolve, reject) => {
        if (!Ember.isEmpty(records)) {
          records.forEach(record => {
            const cancelData = this.get('routeexecevent').cancelData();
            const prepareCancelData = this.get('routeexecevent').prepareTripCancelData(cancelData, record);
            const json = JSON.stringify(prepareCancelData);
            const session = this.get('session');
            return this.get('ajax').delete(_apiUrls.API.avlmBookingService.host + '/booking/' + record.get('id'), {
              method: 'DELETE',
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${session.data.authenticated.token}`
              },
              data: json
            }).then(() =>
            /*response*/
            {
              record.set('isForceDirty', false); // record.set('id',response.data.id);

              me.get('activeContext').get('refreshTableContent').perform('route');
              me.sendMessagesToVehicles(record, 'No Show');
            }).catch(function (error) {
              reject(error);
            });
          });
        }
      });
    },

    saveNoShowRecordFunction(records) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripNoShowAdapter = store.adapterFor('trip-noshow');
      const notifications = this.get('notifications');
      return records.reduce((acu, record) => {
        const dsStop = (0, _unwrapProxy.unwrapProxy)(record);
        const noshow = (0, _unwrapProxy.unwrapProxy)(dsStop.get('noShow'));
        const noshowReason = (0, _unwrapProxy.unwrapProxy)(noshow.get('noShowReasonCode'));
        const trip = (0, _unwrapProxy.unwrapProxy)(dsStop.get('trip'));
        trip.set('noshowDetails', noshow.get('notes'));
        return acu.then(() => routeExecEventAdapter.createStopNoShowEvent(record)).then(() => tripNoShowAdapter.createTripNoShow(trip, noshowReason)).then(() => {
          notifications.success(`Successfully No Showed Trip ${dsStop.get('trip.tripId')}`);
        }).catch(e => {
          notifications.warning(`Failed to No Show Trip ${dsStop.get('trip.tripId')}`);
          throw new Error(e);
        });
      }, Promise.resolve());
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('tripTransferAction', {
        id: 'tripTransfer',
        permId: _mappedPermIds.default.transferTrip,
        name: 'Transfer Trip',
        action: trip => {
          this.get('workspace').pushState('tripTransfer', {
            trip
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('noShowAction', {
        id: 'noShow',
        permId: _mappedPermIds.default.noShowTrip,
        name: 'No Show',
        action: model => {
          const store = this.get('store');
          const pickStopPoint = model.get('pickStopPoint');
          const noShowReasonCodes = this.get('store').peekAll('no-show-reason-code');
          pickStopPoint.set('noShow', store.createRecord('no-show', {
            noShowReasonCode: noShowReasonCodes.firstObject
          }));
          pickStopPoint.set('timestamp', new Date());
          const noShowComponent = this.get('noShowComponent');
          this.get('editModal').open(noShowComponent, [pickStopPoint], this.get('saveNoShowRecordFunction').bind(this), null, {
            title: 'No Show Trip'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('unPerformNoShowAction', {
        id: 'unperform',
        permId: _mappedPermIds.default.unperformStop,
        name: 'Unperform No Show',
        action: model => {
          const workspace = this.get('workspace');
          const pickStopPoint = model.get('pickStopPoint');
          const title = 'Unperform No Show';
          workspace.pushState('unPerformNoShow', {
            stopPoint: pickStopPoint,
            title
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      });
      this.set('approveNoShowRequestAction', {
        id: 'approveNoShowRequest',
        permId: _mappedPermIds.default.approveNoShowRequest,
        name: 'Approve NoShow Request',
        action: trip => {
          this.get('workspace').pushState('noShowForm', {
            displayName: 'No Show Approval Form',
            trip,
            sectionId: 'approve'
          });
        },
        disableOnLocked: false
      });
      this.set('denyNoShowRequestAction', {
        id: 'denyNoShowRequest',
        permId: _mappedPermIds.default.denyNoShowRequest,
        name: 'Deny NoShow Request',
        action: trip => {
          this.get('workspace').pushState('noShowForm', {
            displayName: 'No Show Denial Form',
            trip,
            sectionId: 'deny'
          });
        },
        disableOnLocked: true
      });
      this.set('waitlistAction', {
        id: 'waitlist',
        permId: _mappedPermIds.default.waitlist,
        name: 'Waitlist',
        action: model => {
          this.waitlistTrip(model);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('cancelAction', {
        id: 'cancelTrip',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: model => {
          const bsBooking = model.get('bsBooking.content');
          bsBooking.set('timestamp', new Date());
          const cancelTripComponent = this.get('cancelTripComponent');
          this.get('editModal').open(cancelTripComponent, [bsBooking.get('legs.firstObject.segments.firstObject')], this.get('saveCancelRecordFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editAction', {
        id: 'edit',
        permIds: [_mappedPermIds.default.editFuture, _mappedPermIds.default.editPast, _mappedPermIds.default.editSameDay],
        name: 'Edit',
        action: model => {
          const updateSegment = model.get('segment.content');
          const editTripComponent = this.get('editTripComponent');
          this.get('editModal').open(editTripComponent, [updateSegment], this.get('saveTripRecordFunction').bind(this));
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('activityLogAction', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLog,
        name: 'View Activity Log',
        action: trip => {
          const id = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
          this.get('workspace').pushState('tripActivityLog', {
            displayName: `Trip ${id} Activity Log`,
            trip
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: true
      });
    },

    rowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    oneDayTimeline: Ember.computed('workspace.{startDate,endDate}', function () {
      const startDate = (0, _moment.default)(this.get('workspace.startDate')).format('YYYY-MMM-D');
      const endDate = (0, _moment.default)(this.get('workspace.endDate')).format('YYYY-MMM-D');
      return startDate === endDate;
    }),

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto widget's other elements.

      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).addClass('drag-target');
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
    },

    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      const targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('rowGroup'));
      this.assignCluster(targetRowRecord, sourceRowRecord);
    },

    assignCluster(tripRow, routeRow) {
      const tripRecord = tripRow.get('record');
      const routeRecord = routeRow.get('record');

      if (routeRecord.constructor.modelName !== 'route') {
        return;
      }

      let cluster = routeRecord.get('clusters').firstObject;

      if (Ember.isBlank(cluster)) {
        cluster = this.get('store').createRecord('cluster', {
          route: routeRecord
        });
        tripRecord.set('cluster', cluster);
        cluster.save();
      } else {
        tripRecord.set('cluster', cluster);
        tripRecord.save();
      }
    },

    waitlistTrip(model) {
      const session = this.get('session');
      const notifications = this.get('notifications');
      const tooltip = this.get('tooltip');
      const tip = `Are you sure you want to Waitlist Trip ${model.get('tripId')}`;
      const title = 'Waitlist Trip';
      const ajax = this.get('ajax');
      tooltip.setProperties({
        tip,
        title,
        hasOverlay: true,
        primaryActionText: 'Confirm',
        secondaryActionText: 'Cancel',

        primaryAction() {
          return ajax.put(_apiUrls.API.dispatchService.host + '/trip/' + model.get('id') + '/waitlist', {
            method: 'DELETE',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${session.data.authenticated.token}`
            }
          }).then(() => {
            notifications.success(`Successfully Waitlisted Trip ${model.get('tripId')}`);
          }).catch(() => {
            notifications.warning(`Failed to Waitlist Trip ${model.get('tripId')}`);
          });
        },

        secondaryAction() {
          return;
        }

      });
    },

    //TODO
    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,computedStatus}', function () {
      const tripTransferAction = this.get('tripTransferAction');
      const noShowAction = this.get('noShowAction');
      const waitlistAction = this.get('waitlistAction');
      const activityLogAction = this.get('activityLogAction');
      const oneDayTimeline = this.get('oneDayTimeline');
      const approveNoShowRequestAction = this.get('approveNoShowRequestAction');
      const denyNoShowRequestAction = this.get('denyNoShowRequestAction');
      const unPerformNoShowAction = this.get('unPerformNoShowAction');
      const row = this.get('table.selectedRows')[0];
      if (!row) return [];
      const tripStatus = `${row.get('computedStatus')}`.toLowerCase();
      const nextUnperformedStop = row.get('dispatchRoute.firstUnperformedStop');

      switch (tripStatus) {
        case 'scheduled':
          {
            const stopStatus = `${row.get('pickStopPoint.computedStatus')}`.toLowerCase();

            switch (stopStatus) {
              case 'arrive':
                if (Ember.isNone(row.get('dropStopPoint.computedStatus'))) {
                  return [noShowAction, activityLogAction];
                }

                return [activityLogAction];

              case 'depart':
                return [activityLogAction];

              default:
                if (oneDayTimeline) {
                  // can no-show if stop is the next stop on the list
                  if (nextUnperformedStop && nextUnperformedStop.get('trip.id') === row.id) {
                    return [waitlistAction, activityLogAction, tripTransferAction, noShowAction];
                  }

                  return [waitlistAction, activityLogAction, tripTransferAction];
                }

                if (nextUnperformedStop && nextUnperformedStop.get('trip.id') === row.id) {
                  return [waitlistAction, activityLogAction, noShowAction, tripTransferAction];
                }

                return [waitlistAction, activityLogAction, tripTransferAction];
            }
          }

        case 'waitlisted':
        case 'illegal':
          return [activityLogAction, tripTransferAction];

        case 'no show':
          return [activityLogAction, unPerformNoShowAction];

        case 'no show request':
          return [approveNoShowRequestAction, denyNoShowRequestAction, activityLogAction];

        case 'active':
          return [activityLogAction];

        case 'completed':
          return [activityLogAction];

        default:
          return [activityLogAction, tripTransferAction];
      }
    })
  });

  _exports.default = _default;
});