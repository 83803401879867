define("adept-iq/classes/road-supervisor-api", ["exports", "fetch", "moment", "pako", "ember-concurrency", "adept-iq/config/environment", "adept-iq/config/api-urls"], function (_exports, _fetch, _moment, _pako, _emberConcurrency, _environment, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_LATLNG = [_environment.default.tomtom.search.center.lat, _environment.default.tomtom.search.center.lon];
  const LOCATION_PING_INTERVAL = 20000;
  const PING_WORKER_PATH = '/workers/avl-ping.js';
  let flag = true;

  function unpackBuffer(arr) {
    const inflated = _pako.default.inflate(arr);

    const guidanceJSON = String.fromCharCode.apply(null, inflated);

    try {
      return JSON.parse(guidanceJSON);
    } catch (e) {
      // bad json
      return null;
    }
  }

  const RoadSupervisorApi = Ember.Object.extend({
    previousLocationObj: null,
    currentLocationObj: null,
    cord: DEFAULT_LATLNG,
    geoLocation: null,
    currentRoute: null,
    preparePromise: null,
    polyline: null,
    guidance: null,
    isNavigationScreen: false,

    initialize({
      driver,
      vehicle,
      location
    }) {
      this.driver = driver;
      this.vehicle = vehicle;
      this.cord = location || DEFAULT_LATLNG;
      const dashboardInfo = localStorage.getItem('dashboard-info');
      const parsedDashboardInfo = JSON.parse(dashboardInfo);

      if (parsedDashboardInfo && parsedDashboardInfo.accessData) {
        const accessDataObj = parsedDashboardInfo.accessData;
        this.driver.badgeNr = accessDataObj.driverId;
        this.driver.id = accessDataObj.driverId;
        this.vehicle.callsign = accessDataObj.vehicleId;
        this.vehicle.id = accessDataObj.vehicleId;
        this.provider = accessDataObj.provider;
      }

      if (localStorage.getItem('currentRouteRS') && localStorage.getItem('currentRouteRSID')) {
        this.currentRoute = JSON.parse(localStorage.getItem('currentRouteRS'));
        this.currentRoute.id = localStorage.getItem('currentRouteRSID');
        this.preparePromise = this.prepare();
      } else if (!this.preparePromise) {
        this.preparePromise = this.prepare().then(() => this.apiCreateRoute()).then(() => this.apiExecuteRouteEvent()).then(() => this.apiExecuteDriverEvent({
          type: 'signOn'
        }));
      }
    },

    setWayPoint(waypoint) {
      this.set('isNavigationScreen', true);
      const preparePromise = this.preparePromise.then(() => this.apiAddStopToRoute(waypoint)).then(() => this.pingAndGetPolyLine.perform());
      this.preparePromise = preparePromise;
      return preparePromise;
    },

    pingAndGetPolyLine: (0, _emberConcurrency.task)(function* () {
      const sleepTime = 3000;
      let data;
      let failedCount = 0; // ping at least once before getting polyline

      yield this.apiExecutePingEvent(); // ensure original get succeeds; this can take a few tries

      do {
        data = yield this.apiETAGetPolyline();

        if (!data) {
          failedCount++;
          yield (0, _emberConcurrency.timeout)(sleepTime);
        }

        if (!this.get('isNavigationScreen')) {
          break;
        }
      } while (!data && failedCount <= 5); // original is backwards, so this needs to succeed too


      do {
        data = yield this.apiGetUpdatedPolyline();
        if (!data) yield (0, _emberConcurrency.timeout)(sleepTime);
      } while (!data);

      return data;
    }),
    pingAndUpdatePolyLine: (0, _emberConcurrency.task)(function* (delay = false) {
      Ember.set(this, 'guidance', null);
      const sleepTime = 3000;
      const delayTime = 2000;
      let data; // location updated, make delay for server calculating

      if (delay) yield (0, _emberConcurrency.timeout)(delayTime);

      do {
        data = yield this.apiGetUpdatedPolyline();
        if (!data) yield (0, _emberConcurrency.timeout)(sleepTime);
      } while (!data);

      return data;
    }).drop(),

    clearWayPoint(isDeparted) {
      Ember.set(this, 'polyline', null);
      Ember.set(this, 'guidance', null);
      this.set('isNavigationScreen', false);
      clearTimeout(this._polyLineTimer);

      if (isDeparted === false) {
        return this.apiExecuteRouteEvent({
          type: 'pullIn'
        });
      }

      return this.apiExecuteRouteEvent({
        type: 'depart'
      });
    },

    setLocation(loc) {
      this.geoLocation = loc;
      const location = [loc.coords.latitude, loc.coords.longitude];
      this.cord = location;
    },

    setLocationHistory(lat, lng, speed, heading, timestamp) {
      const currentObject = this.currentLocationObj;

      if (currentObject) {
        this.previousLocationObj = {
          'previousLat': currentObject.currentLat,
          'previousLng': currentObject.currentLng,
          'previousSpeed': currentObject.currentSpeed,
          'previousHeading': currentObject.currentHeading,
          'previousTimeStamp': currentObject.currentTimeStamp
        };
      }

      this.currentLocationObj = {
        'currentLat': lat,
        'currentLng': lng,
        'currentSpeed': speed,
        'currentHeading': heading,
        'currentTimeStamp': timestamp
      };
    },

    prepare() {
      return this.apiGetAccessToken();
    },

    startLocationPing() {
      if (this._interval) return;
      this._interval = setInterval(() => {
        this.sendWorkerPing();
      }, LOCATION_PING_INTERVAL);
    },

    sendWorkerPing() {
      let pingWorker = this.get('pingWorker');

      if (!pingWorker) {
        pingWorker = new Worker(PING_WORKER_PATH);
        this.set('pingWorker', pingWorker);
      }

      pingWorker.postMessage({
        timestamp: (0, _moment.default)().toISOString(),
        vehicleId: this.vehicle.id,
        lat: this.cord[0],
        lng: this.cord[1],
        token: this.token,
        url: `${_apiUrls.API.avlService.host}/avl`
      });
    },

    endLocationPing() {
      if (!this._interval) return;
      clearTimeout(this._interval);
      this._interval = null;
    },

    apiExecutePingEvent() {
      const time = (0, _moment.default)().toISOString();
      const body = {
        data: {
          type: 'avl',
          attributes: {
            vehicleId: this.vehicle.id,
            timestamp: time,
            lat: this.cord[0],
            lng: this.cord[1]
          }
        }
      };
      return this.makeRequest(`${_apiUrls.API.avlService.host}/avl`, {
        method: 'POST',
        body
      });
    },

    apiGetAccessToken() {
      const headers = {
        Authorization: 'Basic aXEtZHJpdmVyQGRkc3dpcmVsZXNzLmNvbTp3dWJUNnhmc1dwWGJRZS9a'
      };
      return (0, _fetch.default)(`${_apiUrls.API.ssoService.host}/login`, {
        headers
      }).then(res => res.json()).then(({
        token
      }) => {
        this.token = token;
      });
    },

    apiAddStopToRoute(waypoint) {
      const time = (0, _moment.default)().toISOString();
      const {
        driverScheduleId,
        vehicleScheduleId
      } = this.currentRoute;
      const body = {
        data: {
          type: 'route',
          id: this.currentRoute.id,
          attributes: {
            state: 'active',
            version: this.currentRoute.version,
            driver: {
              id: this.driver.id,
              badgeNr: this.driver.badgeNr || this.currentRoute.driver.badgeNr
            },
            vehicle: {
              id: this.vehicle.id,
              callsign: this.vehicle.callsign || this.currentRoute.vehicle.callsign
            },
            driverId: this.driver.id,
            trackingId: this.currentRoute.trackingId,
            vehicleId: this.vehicle.id,
            deploy: [{
              address: {
                formattedAddress: waypoint.fullAddress,
                coord: {
                  lat: waypoint.lat,
                  lng: waypoint.lng
                }
              },
              dwellTime: 240,
              eta: time
            }],
            requestedProvider: {
              // is this right?
              type: 'dedicated',
              id: '1',
              name: '1'
            },
            driverScheduleId,
            vehicleScheduleId
          },
          links: {
            self: 'https://11gpajx0kl.execute-api.us-west-2.amazonaws.com/production/route'
          }
        }
      };
      return this.makeRequest(`${_apiUrls.API.avlmService.host}/route/${this.currentRoute.id}`, {
        method: 'PATCH',
        body
      }).then(res => {
        if (!res) return;
        const {
          data
        } = res;
        const latestAddedStopObj = data.attributes.deploy.sortBy('index').lastObject; // FIXME: is this necessary?

        this.currentRoute.deployId = latestAddedStopObj.deployId;
        this.currentRoute.eta = latestAddedStopObj.eta; // this.currentRoute.id = data[0].id;
      });
    },

    apiCreateRoute() {
      const body = {
        timeStamp: (0, _moment.default)().toISOString(),
        badgeNr: this.driver.badgeNr || 3000,
        callsign: this.vehicle.callsign || 2001,
        currLat: this.cord[0],
        currLng: this.cord[1],
        include: 'guidance',
        provider: this.provider || 'special'
      };
      return this.makeRequest(`${_apiUrls.API.avlmService.host}/route`, {
        body
      }).then(({
        data
      }) => {
        localStorage.setItem('currentRouteRS', JSON.stringify(data[0].attributes));
        localStorage.setItem('currentRouteRSID', data[0].id);
        this.currentRoute = data[0].attributes;
        this.currentRoute.id = data[0].id;
      });
    },

    apiExecuteRouteEvent({
      type = 'pullOut'
    } = {}) {
      const time = (0, _moment.default)().toISOString();
      if (!this.currentRoute) return;
      const {
        driver,
        vehicle
      } = this.currentRoute;
      const body = {
        data: {
          type: 'routeExecEvent',
          attributes: {
            routeId: this.currentRoute.id,
            content: {
              type: type,
              actualTime: time
            },
            driver: {
              id: driver.id,
              badgeNr: driver.badgeNr
            },
            vehicle: {
              id: vehicle.id,
              callsign: vehicle.callsign,
              location: {
                coord: {
                  lat: this.cord[0],
                  lng: this.cord[1]
                },
                time
              },
              mov: {
                speed: 50,
                heading: 0
              },
              odo: 0
            },
            source: {
              type: 'driverApp'
            }
          }
        }
      };

      if (type === 'depart') {
        body.data.attributes.content.stopId = this.currentRoute.deployId;
        body.data.attributes.content.eta = this.currentRoute.eta;
      }

      if (flag || type === 'depart' || type === 'pullIn') {
        flag = false;
        return this.makeRequest(`${_apiUrls.API.avlmService.host}/route-exec-event`, {
          method: 'POST',
          body
        });
      }
    },

    apiExecuteDriverEvent({
      type = 'signOn'
    } = {}) {
      const time = (0, _moment.default)().toISOString();
      const {
        driverId,
        id,
        driverScheduleId,
        vehicleScheduleId,
        driver,
        vehicle
      } = this.currentRoute;
      const {
        badgeNr
      } = driver;
      const body = {
        data: {
          type: 'driverStateEvent',
          attributes: {
            driverId,
            routeId: id,
            driverScheduleId,
            vehicleScheduleId,
            type,
            badgeNr,
            time,
            vehicle: {
              id: vehicle.id,
              callsign: vehicle.callsign,
              location: {
                coord: {
                  lat: this.cord[0],
                  lng: this.cord[1]
                },
                time
              },
              mov: {
                speed: 50,
                heading: 0
              },
              odo: 0,
              provider: {
                type: 'dedicated',
                id: this.provider || 'special',
                name: this.provider || 'special'
              }
            }
          }
        }
      };
      return this.makeRequest(`${_apiUrls.API.avlmService.host}/driver-state-event`, {
        method: 'POST',
        body
      });
    },

    apiETAGetPolyline() {
      const routeId = this.currentRoute.id;
      const body = {
        include: 'polyline,guidance'
      };
      return this.makeRequest(`${_apiUrls.API.etaService.host}/navigation-info/${routeId}`, {
        body
      }).then(res => {
        if (!res) return null;
        return res.data.attributes.stopPoints.filter(({
          guidance,
          polyline
        }) => guidance && polyline).map(({
          guidance,
          polyline
        }) => {
          return {
            polyline,
            guidance: unpackBuffer(guidance.data)
          };
        });
      }).then(res => {
        if (!res || !res[0]) return null;
        const {
          polyline,
          guidance
        } = res[0]; // this data is backwards; for now use data from updated polyline
        // set(this, 'polyline', polyline);
        // set(this, 'guidance', guidance);

        return {
          polyline,
          guidance
        };
      });
    },

    apiGetUpdatedPolyline() {
      const routeId = this.currentRoute.id;
      let body = {
        include: 'polyline,guidance'
      };
      const previousObject = this.previousLocationObj;
      const currentObject = this.currentLocationObj;

      if (previousObject && currentObject && currentObject.currentLat !== previousObject.previousLat && currentObject.currentLng !== previousObject.previousLng) {
        body = {
          'currHeading': currentObject.currentHeading,
          'currLat': currentObject.currentLat,
          'currLng': currentObject.currentLng,
          'currSpeed': currentObject.currentSpeed,
          'currTimestamp': _moment.default.utc(currentObject.currentTimeStamp).format(),
          'include': 'polyline,guidance',
          'prevHeading': previousObject.previousHeading,
          'prevLat': previousObject.previousLat,
          'prevLng': previousObject.previousLng,
          'prevSpeed': previousObject.previousSpeed,
          'prevTimestamp': _moment.default.utc(previousObject.previousTimeStamp).format()
        };
      }

      return this.makeRequest(`${_apiUrls.API.etaService.host}/navigation-info/${routeId}/updatedNavInfo`, {
        body
      }).then(res => {
        if (!res) return null;
        const {
          polyline,
          guidance
        } = res.data.attributes.current;
        const result = {
          polyline
        };
        this.set('polyline', polyline);

        if (guidance) {
          const unpackedGuidance = unpackBuffer(guidance.data);
          this.set('guidance', unpackedGuidance);
          result.guidance = unpackedGuidance;
        }

        return result;
      });
    },

    makeRequest(url, props) {
      let params = '';

      if (props.body) {
        if ('method' in props && props.method !== 'GET') {
          props.body = JSON.stringify(props.body);
        } else {
          params = Ember.$.param(props.body);
          delete props.body;
        }
      }

      return (0, _fetch.default)(`${url}?${params}`, Object.assign({
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`
        }
      }, props)).then(res => {
        if (res.status === 200) {
          return res.text().then(data => data ? JSON.parse(data) : {});
        }
      });
    }

  });
  let instance;
  RoadSupervisorApi.reopenClass({
    getRoleName() {
      return 'roadsup';
    },

    getConfigId() {
      return 'config-UI_restrictions-roadsup/restrictions';
    },

    getSingleton() {
      if (!instance) {
        instance = RoadSupervisorApi.create();
      }

      return instance;
    }

  });
  var _default = RoadSupervisorApi;
  _exports.default = _default;
});