define("adept-iq/services/system-config", ["exports", "ember-concurrency", "lodash", "adept-iq/classes/tree-nodes/category", "adept-iq/classes/tree-nodes/object", "adept-iq/classes/tree-nodes/value", "adept-iq/config/config-object-template"], function (_exports, _emberConcurrency, _lodash, _category, _object, _value, _configObjectTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ROOT_CATEGORY = 'config';
  const MIN_SEARCH_TEXT_LENGTH = 3;
  const logger = {
    warn: console.warn // eslint-disable-line no-console

  };

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    rootCategory: null,
    rootNodes: null,
    searchText: null,
    isSearchEffective: Ember.computed('searchText', function () {
      const searchText = this.get('searchText');
      return Ember.isPresent(searchText) && searchText.length >= MIN_SEARCH_TEXT_LENGTH;
    }),
    searchTextChanged: Ember.observer('searchText', function () {
      Ember.run.debounce(this, 'propagateSearchText', 500);
    }),
    isLoading: Ember.computed.readOnly('fetchTask.isRunning'),
    isSaving: Ember.computed.readOnly('saveTask.isRunning'),
    isProcessing: Ember.computed.or('isLoading', 'isSaving'),
    isError: Ember.computed.readOnly('fetchTask.last.isError'),
    isModified: Ember.computed('rootNodes.@each.isTreeModified', function () {
      const rootNodes = this.get('rootNodes');
      if (Ember.isEmpty(rootNodes)) return false;
      return rootNodes.any(node => node.get('isTreeModified'));
    }),
    isOutdated: Ember.computed('rootNodes.@each.isTreeOutdated', function () {
      const rootNodes = this.get('rootNodes');
      if (Ember.isEmpty(rootNodes)) return false;
      return rootNodes.any(node => node.get('isTreeOutdated'));
    }),
    isDirty: Ember.computed.or('isModified', 'isOutdated'),

    init() {
      this._super(...arguments);

      this.refresh();
    },

    refresh() {
      return this.get('fetchTask').perform();
    },

    save() {
      if (!this.get('isModified')) {
        throw new Error('cannot save unmodified config tree');
      }

      return this.get('saveTask').perform();
    },

    fetchTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store'); // this loads all associated categories as a side-effect

      yield store.query('cs-config-item', {
        category: ROOT_CATEGORY
      });
      const rootCategory = store.peekRecord('cs-config-category', ROOT_CATEGORY);
      this.set('rootCategory', rootCategory);
      const rootNodes = rootCategory.get('children').filter(node => {
        return node.canDisplay;
      }).sortBy('name').map(category => {
        const visibleChildren = category.children.filter(node => {
          return node.canDisplay;
        });
        category.set('children', visibleChildren);
        return this.buildCategoryTree(category);
      });
      this.set('rootNodes', rootNodes);
      this.propagateSearchText();
    }).drop(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500);
      const modifiedNodes = [];
      const rootNodes = this.get('rootNodes'); // make sure we duplicate array!

      const queue = [...rootNodes]; // BFS search means we can prune at unmodified & object nodes

      while (queue.length > 0) {
        const node = queue.shift();
        /* eslint-disable no-continue */

        if (!node.get('isTreeModified') && !node.get('isRemoved')) continue;
        /* eslint-enable no-continue */

        if (node.get('isObject') || node.get('isValue')) {
          modifiedNodes.push(node);
        } else {
          const children = node.get('children');
          queue.push(...children);
        }
      }

      const promises = modifiedNodes.map(node => {
        let value;
        const parent = node.get('parent');
        const configItem = node.get('configItem'); // removing a new record

        if (node.get('isRemoved') && node.get('isNew')) {
          const index = parent.get('children').findIndex(child => {
            return !child.configItem.id && child.configItem.name === node.configItem.name;
          });
          parent.get('children').removeAt(index, 1);
          return;
        } // object removed


        if (node.get('isRemoved')) {
          configItem.deleteRecord();
          return configItem.save().then(() => {
            const index = parent.get('children').findIndex(child => {
              return child.configItem.name === node.configItem.name;
            });
            parent.get('children').removeAt(index, 1);
          });
        } // a new record. we cannot have space or funny charaters for a name


        if (node.get('isNew')) {
          const camelizedName = Ember.String.camelize(node.get('configItem.displayName'));
          node.set('configItem.name', camelizedName.replace(/[_\W]+/g, '_'));

          if (node.get('configItem.name.length') < 1) {
            this.get('notifications').warning('New config name cannot be blank.');
            return;
          }
        } // object modified


        if (node.get('isValue')) {
          value = node.get('value');
        }

        if (node.get('isObject')) {
          value = node.get('children').reduce((obj, child) => {
            let key = child.get('label');
            const childValue = child.get('value');

            if (parent.objectTemplate) {
              const newkey = parent.objectTemplate.fields.find(object => {
                return object.displayName === key;
              });

              if (newkey) {
                key = newkey.fieldName;
              }
            }

            obj[key] = childValue;
            return obj;
          }, {});
        }

        configItem.set('value', value);
        return configItem.save().then(() => {
          if (node.get('isValue')) {
            node.set('originalValue', value);
          }

          if (node.get('isObject')) {
            node.get('children').forEach(child => {
              child.set('originalValue', child.get('value'));
            });
          }
        });
      });
      return yield Ember.RSVP.all(promises);
    }).drop(),

    getObjectTemplate(category) {
      if (_configObjectTemplate.default[category.path]) {
        return _configObjectTemplate.default[category.path];
      }

      return;
    },

    buildCategoryTree(category, parent) {
      const node = _category.default.create({
        label: category.get('displayName'),
        canDisplay: category.get('canDisplay'),
        canRemove: category.get('canDelete'),
        canAdd: category.get('canAdd'),
        minimumItem: category.get('minimumItem'),
        type: 'category',
        depth: parent ? parent.get('depth') + 1 : 0,
        parent
      });

      node.objectTemplate = this.getObjectTemplate(category);
      const children = [];
      category.get('children').sortBy('name').forEach(childCategory => {
        const child = this.buildCategoryTree(childCategory, node);
        children.push(child);
      });
      category.get('configItems').forEach(configItem => {
        const child = this.buildItemTree(configItem, node);
        children.push(child);
      });
      node.set('children', children);
      return node;
    },

    buildOjectsTemplate(fields) {
      const newObject = {};
      fields.forEach(field => {
        newObject[field.fieldName] = '';
      });
      return newObject;
    },

    insertNewItemTree(parent) {
      if (!parent.objectTemplate) {
        return;
      }

      const record = this.store.createRecord('cs-config-item', {
        displayName: '',
        description: '',
        unit: 'NA',
        category: parent.objectTemplate.categoryPath,
        type: 'object',
        value: this.buildOjectsTemplate(parent.objectTemplate.fields),
        defaultValue: ''
      });
      const newNode = this.buildItemTree(record, parent);
      newNode.set('isExpanded', true);
      parent.set('isExpanded', true);
      parent.get('children').pushObject(newNode);
    },

    buildItemTree(configItem, parent) {
      const name = configItem.get('displayName');
      const value = configItem.get('value');
      const specifiedType = this.specifiedTypeFor(configItem.get('type'));
      const inferredType = this.inferredTypeFor(value);
      let type = this.nodeTypeFor(specifiedType) || this.nodeTypeFor(inferredType) || 'default';

      if (inferredType === 'object' && type !== 'object') {
        logger.warn(`config-item ${name} has object value but specified type ${type}`);
        type = 'object';
      }

      const depth = parent ? parent.get('depth') + 1 : 0;
      const node = this.treeNodeClassFor(type).create({
        configItem,
        type,
        specifiedType,
        inferredType,
        label: name,
        value: _lodash.default.isArray(value) ? _lodash.default.cloneDeep(JSON.stringify(value)) : _lodash.default.cloneDeep(value),
        originalValue: _lodash.default.cloneDeep(value),
        depth,
        parent
      });

      if (type === 'object') {
        // handle JSON
        const children = Object.entries(value).map(([key, val]) => {
          const inferredChildType = this.inferredTypeFor(val);
          const childType = this.nodeTypeFor(inferredChildType) || 'default';
          let radioButton;

          if (childType === 'boolean' && parent.objectTemplate) {
            const newkey = parent.objectTemplate.fields.find(object => {
              return object.displayName === key;
            });

            if (newkey) {
              radioButton = newkey.radioButton;
            }
          }

          return this.treeNodeClassFor(childType).create({
            label: key,
            type: childType,
            value: _lodash.default.isArray(val) ? _lodash.default.cloneDeep(JSON.stringify(val)) : _lodash.default.cloneDeep(val),
            originalValue: _lodash.default.cloneDeep(val),
            depth: depth + 1,
            parent: node,
            radioButton: radioButton
          });
        }).sortBy('label');
        node.set('children', children);
      }

      return node;
    },

    specifiedTypeFor(typeString) {
      return Ember.isPresent(typeString) ? typeString.toLowerCase() : null;
    },

    inferredTypeFor(value) {
      return Ember.isPresent(value) ? Ember.typeOf(value) : null;
    },

    nodeTypeFor(type) {
      switch (type) {
        case 'int':
        case 'float':
        case 'number':
          return 'number';

        case 'char':
        case 'string':
          return 'string';

        case 'json':
        case 'object':
          return 'object';

        case 'boolean':
        case 'true/false':
          return 'boolean';

        case 'color':
          return 'color';

        default:
          return null;
      }
    },

    treeNodeClassFor(type) {
      switch (type) {
        case 'category':
          return _category.default;

        case 'object':
          return _object.default;

        default:
          return _value.default;
      }
    },

    propagateSearchText() {
      const rootNodes = this.get('rootNodes');
      const searchText = this.get('searchText');
      const isSearchEffective = this.get('isSearchEffective');
      if (Ember.isEmpty(rootNodes)) return; // sets search text on each node

      rootNodes.forEach(rootNode => {
        rootNode.bfs(node => {
          node.set('searchText', searchText);
        });
      });
      if (!isSearchEffective) return; // update expansion settings

      rootNodes.forEach(rootNode => {
        rootNode.dfs(node => {
          // do nothing if not part of search tree
          if (!node.get('isSearchMatch') && !node.get('isUpstreamSearchMatch') && !node.get('isDownstreamSearchMatch')) return; // expand when node is a match or has a descendent that matches

          node.set('isExpanded', node.get('isSearchMatch') || node.get('isUpstreamSearchMatch'));
        });
      });
    }

  });

  _exports.default = _default;
});