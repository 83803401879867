define("adept-iq/pods/components/side-drawer-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RESIZE_CURSOR_OFFSET = -2;
  const SIDE_DRAWER_SHRUNKEN_WIDTH = 20;
  const AUTO_CLOSE_MODAL_WIDTH = 213;

  var _default = Ember.Component.extend({
    // NOTE: actual styling classes are applied to the modal in template;
    // it's element is not a child of this component's element
    classNames: ['side-drawer-container'],
    workspace: Ember.inject.service(),
    lastModalWidth: null,
    currentState: null,
    sideDrawerTitle: Ember.computed.alias('workspace.topStateDisplayName'),
    isShrunken: Ember.computed.alias('workspace.isDrawerShrunken'),
    containerClassNames: Ember.computed('workspace.topOptions', function () {
      let classes = ['ember-modal-dialog', 'side-drawer-modal'];
      const topOptions = this.get('workspace.topOptions');
      const additionalClasses = topOptions.classNames || [];
      classes = classes.concat(additionalClasses);
      return classes;
    }),
    actions: {
      resizeModal(position) {
        if (this.get('isShrunken')) {
          return;
        }

        const windowWidth = Ember.$(window).width(); // Resize cursor offset ensures cursor is centered on handle.

        const modalWidth = windowWidth - position.x + RESIZE_CURSOR_OFFSET;
        Ember.run.scheduleOnce('afterRender', () => {
          if (windowWidth - modalWidth > AUTO_CLOSE_MODAL_WIDTH) {
            Ember.$('.side-drawer-modal').css('right', `${modalWidth}px`);
          } else {
            this.send('onShrinkClick');
          }
        });
      },

      onCloseClick() {
        this.get('workspace').popState();
      },

      onShrinkClick() {
        const isShrunken = this.get('isShrunken');

        if (isShrunken) {
          // Let's restore the old modal width
          const modalWidth = this.get('lastModalWidth');
          Ember.$('.side-drawer').css('right', `${modalWidth}px`);
          Ember.$('.side-drawer').css('left', '0px');
        } else {
          // Shrink the modal and save the old minWidth
          const currentModalWidth = parseInt(Ember.$('.side-drawer').css('right'), 10);
          this.set('lastModalWidth', currentModalWidth);
          const windowWidth = Ember.$(window).width();
          const rightPosition = windowWidth - SIDE_DRAWER_SHRUNKEN_WIDTH; // this has to be the former modal width, minus the side drawer side

          const leftPosition = 0 - parseInt(currentModalWidth, 10) + SIDE_DRAWER_SHRUNKEN_WIDTH;
          Ember.$('.side-drawer').css('right', `${rightPosition}px`);
          Ember.$('.side-drawer').css('left', `${leftPosition}px`);
        }

        this.toggleProperty('isShrunken');
      }

    }
  });

  _exports.default = _default;
});