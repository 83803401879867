define("adept-iq/pods/components/generic-widgets/map-widget/component", ["exports", "adept-iq/pods/components/generic-widgets/base-widget/component", "tomtom", "adept-iq/config/environment"], function (_exports, _component, _tomtom, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    interaction: Ember.inject.service(),
    maximizer: Ember.inject.service(),
    classNames: ['map-widget'],
    layoutName: 'components/generic-widgets/map-widget',
    classNameBindings: ['testMapWidget'],
    testMapWidget: false,
    centerLat: _environment.default.tomtom.search.center.lat,
    centerLng: _environment.default.tomtom.search.center.lon,
    zoom: 12,
    map: null,
    isWidgetMaximized: Ember.computed.not('maximizer.isWidgetMinified'),
    // tomtom will only accept an element ID, so create a unique one
    mapElementId: Ember.computed('elementId', function () {
      const elementId = this.get('elementId');
      return `${elementId}-map`;
    }),

    init() {
      this._super(...arguments);

      this._didZoomMap = () => {
        Ember.run.scheduleOnce('afterRender', this, 'didZoomMap');
      };
    },

    didInsertElement() {
      this._super(...arguments);

      const mapElementId = this.get('mapElementId');
      const centerLat = this.get('centerLat');
      const centerLng = this.get('centerLng');
      const zoom = this.get('zoom');

      const map = _tomtom.default.map(mapElementId, {
        zoom,
        center: [centerLat, centerLng],
        key: _environment.default.tomtom.key,
        source: _environment.default.tomtom.source,
        basePath: _environment.default.tomtom.basePath,
        styleUrlMapping: _environment.default.tomtom.styleUrlMapping,
        isMaximized: this.get('isWidgetMaximized')
      });

      map.on('zoomstart', this._didZoomMap);
      this.set('map', map);
    },

    willDestroyElement() {
      const map = this.get('map');
      map.off('zoomstart', this._didZoomMap);
      map.remove();
      this.set('map', null);

      this._super(...arguments);
    },

    destroy() {
      this._didZoomMap = null;

      this._super(...arguments);
    },

    didZoomMap() {
      this.get('interaction').didZoomMap();
    },

    onWidgetResize() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'invalidateMapSize');
    },

    onCenterOrZoomChange: Ember.observer('centerLat', 'centerLng', 'map', 'zoom', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateMapView');
    }),

    invalidateMapSize() {
      const map = this.get('map');
      if (!map) return;
      map.invalidateSize();
    },

    updateMapView() {
      var geoc = this.get('geocode');
      const map = this.get('map');
      const centerLat = this.get('centerLat');
      const centerLng = this.get('centerLng');
      const zoom = geoc.get('zoom');
      if (!map || Ember.isNone(centerLat) || Ember.isNone(centerLng) || Ember.isNone(zoom)) return;
      const mapViewDigest = JSON.stringify([centerLat, centerLng]); // don't do anything if center hasn't changed

      if (mapViewDigest !== this._mapViewDigest) {
        map.setView([centerLat, centerLng], zoom, {
          animate: false
        });
        this._mapViewDigest = mapViewDigest;
      }
    }

  });

  _exports.default = _default;
});