define("adept-iq/pods/components/iq-widgets/routes-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/routes-widget',
    rowComponent: 'iq-widgets/routes-widget/routes-row',
    modelName: 'dispatch-route',
    title: 'Routes',
    defaultSortId: 'name',
    defaultSortAsc: true,
    columns: [{
      id: 'name',
      type: 'uuid',
      // adept 4 is no longer part of the system, route's will include characters
      label: 'Name',
      valuePath: 'route.name',
      valuePreview: '22',
      editable: true,
      isMapLabelVisible: true,
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'vehicle',
      type: 'uuid',
      label: 'Vehicle ID',
      valuePath: 'assignedVehicle.name',
      searchable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'driverId',
      type: 'uuid',
      label: 'Driver ID',
      valuePath: 'assignedDriver.driverId',
      searchable: true,
      defaultWidth: 50
    }, {
      id: 'driverLastName',
      type: 'text',
      label: 'Driver Last Name',
      valuePath: 'assignedDriver.lastName',
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'driverFirstName',
      type: 'text',
      label: 'Driver First Name',
      valuePath: 'assignedDriver.firstName',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'driverPhoneNumber',
      type: 'text',
      label: 'Driver Phone Number',
      valuePath: 'assignedDriverPhoneNumber',
      searchable: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'status',
      type: 'text',
      label: 'Status',
      valuePath: 'computedStatus',
      valuePreview: 'Planned',
      editable: true,
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 75
    }, {
      id: 'plannedStartTime',
      type: 'date',
      label: 'Planned Start Time',
      valuePath: 'plannedStartTime',
      valuePreview: '09:00',
      editable: true,
      hidden: true,
      format: 'HH:mm',
      searchable: true
    }, {
      id: 'actualStartTime',
      type: 'date',
      label: 'Actual Start Time',
      valuePath: 'route.actualStartTimestamp',
      valuePreview: '09:05',
      hidden: true,
      searchable: true,
      format: 'HH:mm'
    }, {
      id: 'plannedBreaks',
      type: 'enum',
      label: 'Planned Breaks',
      valuePath: 'plannedDriverBreaks',
      cellDesc: 'Driver Breaks',
      hidden: true
    }, {
      id: 'actualBreaks',
      type: 'enum',
      label: 'Actual Breaks',
      valuePath: 'actualDriverBreaks',
      cellDesc: 'Driver Breaks',
      hidden: true
    }, {
      id: 'vehicleType',
      type: 'text',
      label: 'Vehicle Type',
      valuePath: 'assignedVehicle.vehicleType.name',
      searchable: true,
      hidden: true
    }, {
      id: 'plannedEndTime',
      type: 'date',
      label: 'Planned End Time',
      valuePath: 'plannedEndTime',
      valuePreview: '04:00',
      editable: true,
      format: 'HH:mm ',
      searchable: true,
      hidden: true
    }, {
      id: 'actualEndTime',
      type: 'date',
      label: 'Actual End Time',
      valuePath: 'route.actualEndTimestamp',
      valuePreview: '04:35',
      format: 'HH:mm',
      searchable: true,
      hidden: true
    }, {
      id: 'plannedStartDate',
      type: 'date',
      label: 'Planned Start Date',
      valuePath: 'plannedStartTime',
      valuePreview: '2018-05-15',
      editable: true,
      format: 'MM/DD/YYYY',
      hidden: true
    }, {
      id: 'actualStartDate',
      type: 'date',
      label: 'Actual Start Date',
      valuePath: 'route.actualStartTimestamp',
      valuePreview: '2018-05-15',
      format: 'MM/DD/YYYY',
      hidden: true
    }, {
      id: 'plannedEndDate',
      type: 'date',
      label: 'Planned End Date',
      valuePath: 'plannedEndTime',
      valuePreview: '2018-05-15',
      editable: true,
      format: 'MM/DD/YYYY',
      hidden: true
    }, {
      id: 'actualEndDate',
      type: 'date',
      label: 'Actual End Date',
      valuePath: 'route.actualEndTimestamp',
      valuePreview: '2018-05-15',
      format: 'MM/DD/YYYY',
      hidden: true
    }, {
      id: 'otpStatus',
      type: 'string',
      label: 'OTP Status',
      valuePath: 'route.otpLabel',
      valuePreview: 'On Time',
      searchable: true,
      highlightable: true
    }, {
      id: 'otp',
      type: 'number',
      label: 'OTP',
      valuePath: 'route.otpValueWithSign',
      hidden: true,
      highlightable: true
    }, {
      id: 'provider',
      type: 'string',
      label: 'Provider',
      valuePath: 'provider.name',
      valuePreview: 'MAG',
      hidden: true,
      searchable: true,
      highlightable: true
    }, {
      id: 'routeType',
      type: 'string',
      label: 'Type',
      valuePath: 'route.type',
      hidden: true,
      searchable: true,
      highlightable: true
    }, {
      id: 'replacedRoute',
      type: 'uuid',
      label: 'Replaced Route Name',
      valuePath: 'route.replacedRoute.name',
      hidden: true,
      searchable: true,
      defaultWidth: 50
    }]
  };
  _exports.default = _default;
});