define("adept-iq/tests/factories/route", ["ember-data-factory-guy", "moment"], function (_emberDataFactoryGuy, _moment) {
  "use strict";

  const normalStart = (0, _moment.default)().startOf('day').add(6, 'hours');
  const evening = (0, _moment.default)().startOf('day').add(13, 'hours');
  const night = (0, _moment.default)().startOf('day').add(18, 'hours');

  _emberDataFactoryGuy.default.define('route', {
    sequences: {
      routeNames: num => num
    },
    default: {
      name: _emberDataFactoryGuy.default.generate('routeNames'),
      plannedStartTime: normalStart.toDate(),
      plannedEndTime: night.toDate(),
      timestamp: normalStart.toDate(),
      status: 'normal',
      otp: 'L',
      type: 'route',
      schedulingMode: 'automatic'
    },
    traits: {
      evening: {
        startTime: evening.toDate()
      },
      night: {
        startTime: night.toDate()
      }
    }
  });
});