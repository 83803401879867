define("adept-iq/services/polyline", ["exports", "ember-concurrency", "adept-iq/config/api-urls"], function (_exports, _emberConcurrency, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_CONCURRENCY = 2;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    async getPerformedPolyline(routeId) {
      const session = this.get('session');
      return await this.get('ajax').request(_apiUrls.API.avlmService.host + '/performed-polyline?routeId=' + routeId, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(response => {
        return response.data;
      });
    },

    fetchPerformedPolyline: (0, _emberConcurrency.task)(function* (routeId, dispatchRouteId) {
      const store = this.get('store');
      let stopPoints = [];
      yield this.getPerformedPolyline(routeId).then(response => {
        stopPoints = response;
      });
      if (Ember.isEmpty(stopPoints)) return [];
      const coords = stopPoints.reduce((arr, stopPoint) => {
        const points = stopPoint.polylineArray;
        if (Ember.isEmpty(points)) return arr;
        const perturbedPoints = [points.lat, points.lng]; // should flatten to single line

        arr.push(perturbedPoints);
        return arr;
      }, []);
      const payload = {
        data: {
          id: `polyline-${routeId}`,
          type: 'polyline',
          attributes: {
            coords
          }
        },
        included: [{
          id: dispatchRouteId,
          type: 'dispatch-route',
          relationships: {
            performedPolyline: {
              data: {
                id: `polyline-${routeId}`,
                type: 'polyline'
              }
            }
          }
        }]
      };
      store.push(payload);
    }).enqueue().maxConcurrency(MAX_CONCURRENCY)
  });

  _exports.default = _default;
});