define("adept-iq/tests/factories/cs-config-item", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  /* eslint-disable no-use-before-define */
  _emberDataFactoryGuy.default.define('cs-config-item', {
    sequences: {
      ids: num => csConfigItems[num % csConfigItems.length].id,
      names: num => csConfigItems[num % csConfigItems.length].name,
      categories: num => csConfigItems[num % csConfigItems.length].category,
      values: num => csConfigItems[num % csConfigItems.length].value
    },
    default: {
      id: _emberDataFactoryGuy.default.generate('ids'),
      name: _emberDataFactoryGuy.default.generate('names'),
      category: _emberDataFactoryGuy.default.generate('categories'),
      value: _emberDataFactoryGuy.default.generate('values')
    }
  });

  const csConfigItems = [
  /* Config Travel Need Type Fixtures */
  {
    id: 'config-System_Configuration-travel_need_types/ambulatory',
    name: 'travel_need_types',
    displayName: 'Ambulatory',
    category: 'config-System_Configuration-travel_need_types',
    value: JSON.stringify({
      id: 1,
      name: 'ambulatory',
      vehicleCapacityType: 'ambulatory',
      vehicleCapacityCount: 1,
      loadTime: 240,
      unloadTime: 60,
      displayName: 'Ambulatory',
      displayOrder: 1,
      description: 'Description for Ambulatory'
    })
  }, {
    id: 'config-System_Configuration-travel_need_types/serviceAnimal',
    name: 'travel_need_types',
    displayName: 'Service Animal',
    category: 'config-System_Configuration-travel_need_types',
    value: JSON.stringify({
      id: 5,
      name: 'serviceAnimal',
      vehicleCapacityType: 'serviceAnimal',
      vehicleCapacityCount: 1,
      loadTime: 60,
      unloadTime: 60,
      displayName: 'Service Animal',
      displayOrder: 5,
      description: 'Description for Service Animal'
    })
  }, {
    id: 'config-System_Configuration-travel_need_types/wheelchair',
    name: 'travel_need_types',
    displayName: 'Wheelchair',
    category: 'config-System_Configuration-travel_need_types',
    value: JSON.stringify({
      id: 3,
      name: 'wheelchair',
      vehicleCapacityType: 'wheelchair',
      vehicleCapacityCount: 1,
      loadTime: 420,
      unloadTime: 240,
      displayName: 'Wheelchair',
      displayOrder: 3,
      description: 'Description for Wheelchair'
    })
  }, {
    id: 'config-System_Configuration-travel_need_types/wideAmbulatory',
    name: 'travel_need_types',
    displayName: 'Wide Wheelchair',
    category: 'config-System_Configuration-travel_need_types',
    value: JSON.stringify({
      id: 2,
      name: 'wideAmbulatory',
      vehicleCapacityType: 'wideAmbulatory',
      vehicleCapacityCount: 1,
      loadTime: 420,
      unloadTime: 240,
      displayName: 'Wide Ambulatory',
      displayOrder: 2,
      description: 'Description for Wide Ambulatory'
    })
  }, {
    id: 'config-System_Configuration-travel_need_types/wideWheelchair',
    name: 'travel_need_types',
    displayName: 'Wide Wheelchair',
    category: 'config-System_Configuration-travel_need_types',
    value: JSON.stringify({
      id: 4,
      name: 'wideWheelchair',
      vehicleCapacityType: 'wheelchair',
      vehicleCapacityCount: 2,
      loadTime: 420,
      unloadTime: 240,
      displayName: 'Wide Wheelchair',
      displayOrder: 4,
      description: 'Description for Wide Wheelchair'
    })
  },
  /* Config Passenger Type Fixtures */
  {
    id: 'config-System_Configuration-passenger_types/client',
    name: 'passenger_types',
    displayName: 'Client',
    category: 'config-System_Configuration-passenger_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-passenger_types/client',
      name: 'client'
    })
  }, {
    id: 'config-System_Configuration-passenger_types/companion',
    name: 'passenger_types',
    displayName: 'Companion',
    category: 'config-System_Configuration-passenger_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-passenger_types/companion',
      name: 'companion'
    })
  }, {
    id: 'config-System_Configuration-passenger_types/pca',
    name: 'passenger_types',
    displayName: 'PCA',
    category: 'config-System_Configuration-passenger_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-passenger_types/pca',
      name: 'pca'
    })
  },
  /* Config Vehicle types Fixtures */
  {
    id: 'config-System_Configuration-vehicle_types/largeBus',
    name: 'vehicle_types',
    displayName: 'Large Bus',
    category: 'config-System_Configuration-vehicle_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-vehicle_types/largeBus',
      name: 'largeBus'
    })
  }, {
    id: 'config-System_Configuration-vehicle_types/smallBus',
    name: 'vehicle_types',
    displayName: 'Small Bus',
    category: 'config-System_Configuration-vehicle_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-vehicle_types/smallBus',
      name: 'smallBus'
    })
  }, {
    id: 'config-System_Configuration-vehicle_types/standard',
    name: 'vehicle_types',
    displayName: 'Standard',
    category: 'config-System_Configuration-vehicle_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-vehicle_types/standard',
      name: 'standard'
    })
  }, {
    id: 'config-System_Configuration-vehicle_types/taxi',
    name: 'vehicle_types',
    displayName: 'Taxi',
    category: 'config-System_Configuration-vehicle_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-vehicle_types/taxi',
      name: 'taxi'
    })
  }, {
    id: 'config-System_Configuration-vehicle_types/cab',
    name: 'vehicle_types',
    displayName: 'Cab',
    category: 'config-System_Configuration-vehicle_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-vehicle_types/cab',
      name: 'cab'
    })
  },
  /* Config Fare Type Fixtures */
  {
    id: 'config-System_Configuration-fare_types/cash',
    name: 'fare_types',
    displayName: 'Cash',
    category: 'config-System_Configuration-fare_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-fare_types/cash',
      name: 'cash'
    })
  }, {
    id: 'config-System_Configuration-fare_types/creditCard',
    name: 'fare_types',
    displayName: 'Credit Card',
    category: 'config-System_Configuration-fare_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-fare_types/creditCard',
      name: 'creditCard'
    })
  }, {
    id: 'config-System_Configuration-fare_types/smartCard',
    name: 'fare_types',
    displayName: 'Smart Card',
    category: 'config-System_Configuration-fare_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-fare_types/smartCard',
      name: 'smartCard'
    })
  },
  /* Config break type Fixtures */
  {
    id: 'config-System_Configuration-break_types/fixed',
    name: 'break_types',
    displayName: 'Fixed',
    category: 'config-System_Configuration-break_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-break_types/fixed',
      name: 'fixed',
      displayName: 'Fixed Breaks',
      defaultDuration: 0,
      isPaid: false,
      isRecognized: false,
      serviceWindowName: 'fixedBreak'
    })
  }, {
    id: 'config-System_Configuration-break_types/floating',
    name: 'break_types',
    displayName: 'Floating',
    category: 'config-System_Configuration-break_types',
    value: JSON.stringify({
      type: 'configItem',
      id: 'config-System_Configuration-break_types/floating',
      name: 'floating',
      displayName: 'Floating Breaks',
      defaultDuration: 0,
      isPaid: false,
      isRecognized: false,
      serviceWindowName: 'floatingBreak'
    })
  },
  /* Config Vehicle Capacity Fixtures */
  {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig00',
    name: 'vehicles_capacity_configs',
    displayName: 'vehicleCapConfig00',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'standard',
      vehicleCapacityType: 'ambulatory',
      group: 1,
      count: 20
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig01',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'standard',
      vehicleCapacityType: 'wheelchair',
      group: 1,
      count: 2
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig02',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'standard',
      vehicleCapacityType: 'serviceAnimal',
      group: 1,
      count: 1
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig03',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'standard',
      vehicleCapacityType: 'wideAmbulatory',
      group: 1,
      count: 0
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig04',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'taxi',
      vehicleCapacityType: 'ambulatory',
      group: 1,
      count: 1
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig05',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'taxi',
      vehicleCapacityType: 'wheelchair',
      group: 1,
      count: 1
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig06',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'taxi',
      vehicleCapacityType: 'serviceAnimal',
      group: 1,
      count: 0
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig07',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'taxi',
      vehicleCapacityType: 'wideAmbulatory',
      group: 1,
      count: 0
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig08',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'smallBus',
      vehicleCapacityType: 'ambulatory',
      group: 1,
      count: 20
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig09',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'smallBus',
      vehicleCapacityType: 'wheelchair',
      group: 1,
      count: 2
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig10',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'smallBus',
      vehicleCapacityType: 'serviceAnimal',
      group: 1,
      count: 2
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig11',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'smallBus',
      vehicleCapacityType: 'wideAmbulatory',
      group: 1,
      count: 2
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig12',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'largeBus',
      vehicleCapacityType: 'ambulatory',
      group: 1,
      count: 40
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig13',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'largeBus',
      vehicleCapacityType: 'wheelchair',
      group: 1,
      count: 4
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig14',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'largeBus',
      vehicleCapacityType: 'serviceAnimal',
      group: 1,
      count: 4
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_configs/vehicleCapConfig15',
    name: 'vehicles_capacity_configs',
    category: 'config-System_Configuration-vehicle_capacity_configs',
    value: JSON.stringify({
      vehicleType: 'largeBus',
      vehicleCapacityType: 'wideAmbulatory',
      group: 1,
      count: 4
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_types/ambulatory',
    name: 'vehicle_capacity_types',
    category: 'config-System_Configuration-vehicle_capacity_types',
    value: JSON.stringify({
      displayName: 'Ambulatory',
      description: 'Ambulatory seat',
      engineId: 'A',
      engineName: 'ambulatory',
      constrainedByLIFO: false,
      dwellFactor: 90
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_types/serviceAnimal',
    name: 'vehicle_capacity_types',
    category: 'config-System_Configuration-vehicle_capacity_types',
    value: JSON.stringify({
      displayName: 'Service Animal',
      description: 'Service animal space',
      engineId: 'N',
      engineName: 'serviceanimal',
      constrainedByLIFO: false,
      dwellFactor: 100
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_types/wheelchair',
    name: 'vehicle_capacity_types',
    category: 'config-System_Configuration-vehicle_capacity_types',
    value: JSON.stringify({
      displayName: 'Wheelchair',
      description: 'Wheelchair space',
      engineId: 'W',
      engineName: 'wheelchair',
      constrainedByLIFO: false,
      dwellFactor: 50
    })
  }, {
    id: 'config-System_Configuration-vehicle_capacity_types/wideAmbulatory',
    name: 'vehicle_capacity_types',
    category: 'config-System_Configuration-vehicle_capacity_types',
    value: JSON.stringify({
      displayName: 'Wide Ambulatory',
      description: 'Wide Ambulatory space',
      engineId: 'B',
      engineName: 'wideambulatory',
      constrainedByLIFO: false,
      dwellFactor: 50
    })
  }, {
    id: 'config-Canned_Messages-to_driver/callDispatchWhenSafe',
    name: 'callDispatchWhenSafe',
    displayName: 'Call Dispatch When Safe',
    category: 'config-Canned_Messages-to_driver',
    value: {
      message: 'Call dispatch when safe'
    },
    defaultValue: ''
  }, {
    id: 'config-Canned_Messages-to_driver/collectFareAtDestination',
    name: 'collectFareAtDestination',
    displayName: 'Collect Fare At Destination',
    category: 'config-Canned_Messages-to_driver',
    value: {
      message: 'Collect fare at destination'
    },
    defaultValue: ''
  }, {
    id: 'config-Canned_Messages-to_driver/waitForPassenger',
    name: 'collectFareAtDestiwaitForPassengernation',
    displayName: 'Wait For Passenger',
    category: 'config-Canned_Messages-to_driver',
    value: {
      message: 'Wait for passenger'
    },
    defaultValue: ''
  }, {
    id: 'config-UI_restrictions/restrictions/admin',
    description: 'This JSON payload is described in and used by the UX to determine what controls should be accessible to the user',
    unit: '',
    type: 'object',
    defaultValue: '',
    name: 'restrictions',
    category: 'config-UI_restrictions',
    value: JSON.stringify({
      headerFunctions: ['configureWorkspace', 'saveWorkspace', 'saveAsWorkspace', 'deleteWorkspace', 'openWorkspace', 'generateSchedule', 'closeSchedule', 'systemConfiguration', 'resetPassword', 'logout'],
      timeline: ['fullAccess'],
      trips: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['assignRoute', 'noShow', 'waitlist', 'cancelTrip', 'edit', 'activityLog']
      },
      stops: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['arrive', 'depart', 'noShow', 'addDriverBreak', 'deleteDriverBreak', 'editDriverBreak', 'startBreak', 'endBreak', 'reorderStops']
      },
      routes: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newRoute', 'print'],
        singleActions: ['sendMessage', 'startRoute', 'endRoute', 'activityLog', 'deleteRoute']
      },
      map: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        singleActions: ['edit']
      },
      alerts: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        singleActions: ['print']
      },
      drivers: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newDriver', 'print'],
        singleActions: ['sendMessage', 'edit']
      },
      vehicles: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newVehicle', 'print'],
        singleActions: ['sendMessage', 'edit', 'resetDevice']
      },
      passengers: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newPassenger', 'print'],
        singleActions: ['addTrip', 'edit']
      },
      messages: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['showAll', 'print'],
        singleActions: ['read', 'hide', 'send-message']
      },
      subscriptions: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['edit']
      },
      users: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newUser', 'print'],
        singleActions: ['edit']
      },
      zones: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: [],
        singleActions: []
      },
      metrics: {
        show: true
      },
      gauge: {
        show: true
      },
      box: {
        show: true
      },
      'donut-chart': {
        show: true
      },
      'bar-chart': {
        show: true
      }
    })
  }, {
    id: 'config-UI_restrictions/restrictions/avlm',
    description: 'This JSON payload is described in and used by the UX to determine what controls should be accessible to the user',
    unit: '',
    type: 'object',
    defaultValue: '',
    name: 'restrictions',
    category: 'config-UI_restrictions',
    value: JSON.stringify({
      headerFunctions: ['configureWorkspace', 'saveWorkspace', 'saveAsWorkspace', 'deleteWorkspace', 'openWorkspace', 'generateSchedule', 'closeSchedule', 'systemConfiguration', 'resetPassword', 'logout'],
      timeline: ['fullAccess'],
      trips: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['assignRoute', 'noShow', 'waitlist', 'cancelTrip', 'edit', 'activityLog']
      },
      stops: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['arrive', 'depart', 'noShow', 'addDriverBreak', 'deleteDriverBreak', 'editDriverBreak', 'startBreak', 'endBreak', 'reorderStops']
      },
      routes: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newRoute', 'print'],
        singleActions: ['sendMessage', 'startRoute', 'endRoute', 'activityLog', 'deleteRoute']
      },
      map: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        singleActions: []
      },
      alerts: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        singleActions: ['print']
      },
      drivers: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newDriver', 'print'],
        singleActions: ['sendMessage', 'edit']
      },
      vehicles: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newVehicle', 'print'],
        singleActions: ['sendMessage', 'edit', 'resetDevice']
      },
      passengers: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newPassenger', 'print'],
        singleActions: ['addTrip', 'edit']
      },
      messages: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['showAll', 'print'],
        singleActions: ['read', 'hide']
      },
      subscriptions: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['print'],
        singleActions: ['edit']
      },
      users: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: ['newUser', 'print'],
        singleActions: ['edit']
      },
      zones: {
        show: true,
        filterAllowed: true,
        searchAllowed: true,
        cellEditAllowed: true,
        tableActions: [],
        singleActions: []
      },
      metrics: {
        show: true
      },
      gauge: {
        show: true
      },
      box: {
        show: true
      },
      'donut-chart': {
        show: true
      },
      'bar-chart': {
        show: true
      }
    })
  }];
});