define("adept-iq/adapters/route-break", ["exports", "adept-iq/adapters/-ss-schedulingService", "adept-iq/config/api-urls"], function (_exports, _ssSchedulingService, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    session: Ember.inject.service(),

    postRouteBreak(routeBreak, placeId) {
      const session = this.get('session');
      const json = {
        attributes: {
          state: routeBreak.get('state'),
          status: routeBreak.get('status'),
          plannedRouteOrdinal: routeBreak.get('plannedRouteOrdinal'),
          requestTime: routeBreak.get('requestTime'),
          promisedStart: routeBreak.get('promisedStart'),
          estimatedStart: routeBreak.get('estimatedStart'),
          estimatedEnd: routeBreak.get('estimatedEnd'),
          plannedDuration: routeBreak.get('plannedDuration'),
          notes: routeBreak.get('notes')
        },
        relationships: {
          route: {
            data: {
              type: 'route',
              id: routeBreak.get('route.id')
            }
          },
          breakTypeName: {
            data: {
              type: 'breakTypeName',
              id: routeBreak.get('breakType.name')
            }
          }
        },
        type: 'routeBreak'
      };

      if (!Ember.isEmpty(placeId)) {
        json.relationships.place = {
          data: {
            type: 'place',
            id: placeId
          }
        };
      }

      return this.get('ajaxService').post(_apiUrls.API.schedulingService.host + '/route-break', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    },

    patchRouteBreak(routeBreak, placeId) {
      const session = this.get('session');
      const json = {
        attributes: {
          state: routeBreak.get('state'),
          status: routeBreak.get('status'),
          plannedRouteOrdinal: routeBreak.get('plannedRouteOrdinal'),
          requestTime: routeBreak.get('requestTime'),
          promisedStart: routeBreak.get('promisedStart'),
          estimatedStart: routeBreak.get('estimatedStart'),
          estimatedEnd: routeBreak.get('estimatedEnd'),
          plannedDuration: routeBreak.get('plannedDuration'),
          notes: routeBreak.get('notes')
        },
        relationships: {
          route: {
            data: {
              type: 'route',
              id: routeBreak.get('route.id')
            }
          },
          breakTypeName: {
            data: {
              type: 'breakTypeName',
              id: routeBreak.get('breakType.name')
            }
          }
        },
        type: 'routeBreak',
        id: routeBreak.get('id')
      };

      if (!Ember.isEmpty(placeId)) {
        json.relationships.place = {
          data: {
            type: 'place',
            id: placeId
          }
        };
      }

      return this.get('ajaxService').post(_apiUrls.API.schedulingService.host + '/route-break/' + routeBreak.id, {
        method: 'PATCH',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    }

  });

  _exports.default = _default;
});