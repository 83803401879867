define("adept-iq/models/address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    alias: attr('string', {
      defaultValue: ''
    }),
    premise: attr('string', {
      defaultValue: ''
    }),
    streetNumber: attr('string'),
    streetAddress: attr('string'),
    locality: attr('string', {
      defaultValue: ''
    }),
    subLocality: attr('string'),
    subRegion: attr('string', {
      defaultValue: ''
    }),
    region: attr('string', {
      defaultValue: ''
    }),
    postalCode: attr('string', {
      defaultValue: ''
    }),
    country: attr('string', {
      defaultValue: ''
    }),
    notes: attr('string', {
      defaultValue: ''
    }),
    locations: hasMany('location'),
    places: hasMany('place'),
    zoneName: belongsTo('zone-type'),
    // needed to syncronize attribute name with tomtom
    countrySecondarySubdivision: Ember.computed('region', function () {
      return this.get('region');
    }),
    // computed position is needed because of dealing with tomtom address format
    // and other format model from booking service, rider management,...
    position: Ember.computed('places.firstObject.location.{lat,lng}', function () {
      const firstLocation = this.get('places.firstObject.location');

      if (Ember.isEmpty(firstLocation)) {
        return {
          lat: '',
          lng: '',
          lon: ''
        };
      }

      return {
        lat: firstLocation.get('lat'),
        lng: firstLocation.get('lng'),
        lon: firstLocation.get('lng')
      };
    }),
    tomtomFormattedAddress: Ember.computed('freeformAddress', 'premise', 'streetNumber', 'streetAddress', 'locality', 'position', function () {
      let address = null;

      if (!Ember.isEmpty(this.get('freeformAddress'))) {
        const tempAddress = this.get('freeformAddress');
        address = {
          address: {
            freeformAddress: tempAddress
          }
        };
      } else if (!Ember.isEmpty(this.get('streetAddress')) && !Ember.isEmpty(this.get('streetNumber'))) {
        let tempAddress = `${this.get('streetNumber')} ${this.get('streetAddress')} ${this.get('locality')}`;

        if (!Ember.isEmpty(this.get('premise'))) {
          tempAddress = `${this.get('premise')} - ` + tempAddress;
        }

        address = {
          address: {
            freeformAddress: tempAddress
          }
        };
      } else if (this.get('position.lat')) {
        address = {
          address: {
            freeformAddress: `${this.get('position.lat')}, ${this.get('position.lng')}`
          }
        };
      }

      return address;
    }),
    address: Ember.computed('alias', 'premise', 'streetNumber', 'streetAddress', 'locality', function () {
      let address = null;

      if (!Ember.isNone(this.get('streetAddress'))) {
        let tempAddress = `${this.get('alias')} ${this.get('streetNumber')} ${this.get('streetAddress')} ${this.get('locality')}`;

        if (!Ember.isNone(this.get('premise')) && this.get('premise').length > 0) {
          tempAddress = `${this.get('alias')} ${this.get('premise')} - ${this.get('streetNumber')} ${this.get('streetAddress')} ${this.get('locality')}`;
        }

        address = {
          freeformAddress: tempAddress
        };
      }

      return address;
    }),
    location: Ember.computed('locations.[]', function () {
      let location = null;

      if (!Ember.isEmpty(this.get('locations').firstObject)) {
        location = this.get('locations').firstObject;
      }

      return location;
    }),
    simpleAddress: Ember.computed('premise', 'streetNumber', 'streetAddress', function () {
      let result = null;

      if (!Ember.isNone(this.get('streetAddress'))) {
        result = `${this.get('streetNumber')} ${this.get('streetAddress')}`;

        if (!Ember.isNone(this.get('premise'))) {
          result = `${this.get('premise')} - ${this.get('streetNumber')} ${this.get('streetAddress')}`;
        }
      }

      return result;
    }),
    fullAddress: Ember.computed('streetNumber', 'streetAddress', 'locality', 'region', 'postalCode', 'alias', function () {
      const alias = Ember.get(this, 'alias');
      const streetNumber = Ember.get(this, 'streetNumber');
      const streetAddress = Ember.get(this, 'streetAddress');
      const locality = Ember.get(this, 'locality');
      const region = Ember.get(this, 'region');
      const postalCode = Ember.get(this, 'postalCode');
      const address = [];

      if (alias) {
        address.push(alias + ',');
      }

      address.push(streetNumber);
      address.push(streetAddress);
      const result = [address.join(' ')];
      result.push(locality);
      result.push(region);
      result.push(postalCode);
      return result.join(', ');
    }).readOnly(),
    readableAddress: Ember.computed('streetNumber', 'streetAddress', 'region', 'postalCode', 'alias', function () {
      const alias = Ember.get(this, 'alias');
      const streetNumber = Ember.get(this, 'streetNumber');
      const streetAddress = Ember.get(this, 'streetAddress');
      const region = Ember.get(this, 'region');
      const postalCode = Ember.get(this, 'postalCode');
      const address = [];

      if (alias) {
        address.push(alias + ',');
      }

      address.push(streetNumber);
      address.push(streetAddress);
      const result = [address.join(' ')];
      result.push(region);
      result.push(postalCode);
      return result.join(', ');
    }).readOnly()
  });

  _exports.default = _default;
});