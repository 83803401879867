define("adept-iq/topic-transforms/eta-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transform;

  // TODO: CLEAN
  // helper for building `stop-point` and `avlm-stop-point` payloads
  const buildStopPointPayload = (etanavStopPoint, route, avlm = false) => {
    return {
      data: {
        id: etanavStopPoint.id,
        type: avlm ? 'avlm-stop-point' : 'stop-point',
        attributes: etanavStopPoint.attributes,
        relationships: {
          stopRoute: {
            data: {
              type: avlm ? 'avlm-route' : 'route',
              id: route.data.id
            }
          },
          etaNavStopPoint: {
            data: {
              type: 'etanav-stop-point',
              id: etanavStopPoint.id
            }
          }
        }
      }
    };
  };

  function transform(payload, ENV) {
    const transformedPayloads = [];
    if (!payload.data || !payload.data.relationships) return transformedPayloads;
    const {
      route,
      stopPoints,
      vehicle,
      routeBreaks
    } = payload.data.relationships;

    if (payload.included) {
      delete payload.data.relationships.etaStops;
      payload.included.filter(etanavStopPoint => {
        return etanavStopPoint.type === 'etanav-stop-point';
      }).forEach(etanavStopPoint => {
        etanavStopPoint.relationships.etaNavRoute = {
          data: {
            type: 'etanav-route',
            id: payload.data.id
          }
        };
        transformedPayloads.push({
          data: etanavStopPoint
        });
        const {
          stopPoint
        } = etanavStopPoint.relationships;

        if (ENV.APP.avlmLite) {
          // rename relationship to 'avlmStopPoint'
          delete etanavStopPoint.relationships.stopPoint;
          etanavStopPoint.relationships.avlmStopPoint = stopPoint;
        } // handle id prefix


        const [idPrefix] = etanavStopPoint.id.match(/^([A-Z])+/) || [''];

        switch (idPrefix) {
          case '':
          case 'P':
          case 'D':
            // pick or drop; include relationship placeholder only
            transformedPayloads.push(stopPoint);
            break;

          case 'GD':
            {
              // garage pullin
              etanavStopPoint.attributes.stopType = 'pullinGarage';
              etanavStopPoint.attributes.parentType = 'pullinGarage'; // need to build [avlm-]stop-point

              const stopPointPayload = buildStopPointPayload(etanavStopPoint, route, ENV.APP.avlmLite);
              transformedPayloads.push(stopPointPayload);
              break;
            }

          case 'GP':
            {
              // garage pullout
              etanavStopPoint.attributes.stopType = 'pulloutGarage';
              etanavStopPoint.attributes.parentType = 'pulloutGarage';
              etanavStopPoint.attributes.routeOrdinal = '0'; // need to build [avlm-]stop-point

              const stopPointPayload = buildStopPointPayload(etanavStopPoint, route, ENV.APP.avlmLite);
              transformedPayloads.push(stopPointPayload);
              break;
            }

          case 'B':
            {
              // break stop
              etanavStopPoint.stopType = 'break';
              etanavStopPoint.parentType = 'break'; // need to build [avlm-]stop-point

              const stopPointPayload = buildStopPointPayload(etanavStopPoint, route, ENV.APP.avlmLite);

              if (stopPointPayload.data.attributes.status) {
                delete stopPointPayload.data.attributes.status;
              }

              transformedPayloads.push(stopPointPayload);
              break;
            }

          case 'DP':
            {
              // TODO: what is this? do we need special handling here?
              // need to build [avlm-]stop-point
              const stopPointPayload = buildStopPointPayload(etanavStopPoint, route, ENV.APP.avlmLite);
              transformedPayloads.push(stopPointPayload);
              break;
            }

          default:
            throw new Error(`unhandled etanavStopPoint id prefix '${idPrefix}'`);
        }
      });
      payload.included.filter(etaNavRouteBreak => {
        return etaNavRouteBreak.type === 'etanav-route-break';
      }).forEach(etaNavRouteBreak => {
        etaNavRouteBreak.relationships.etaNavRoute = {
          data: {
            type: 'etanav-route',
            id: payload.data.id
          }
        };
        transformedPayloads.push({
          data: etaNavRouteBreak
        });
      });
      delete payload.included;
    }

    if (route && ENV.APP.avlmLite) {
      delete payload.data.relationships.route;
      payload.data.relationships.avlmRoute = route; // prevent API requests

      transformedPayloads.push({
        data: {
          id: route.data.id,
          type: 'avlm-route'
        }
      });
      transformedPayloads.push({
        data: {
          id: route.data.id,
          type: 'iq-route',
          relationships: {
            avlmRoute: {
              id: route.data.id,
              type: 'avlm-route'
            }
          }
        }
      });
    }

    if (vehicle && ENV.APP.avlmLite) {
      delete payload.data.relationships.vehicle;
      payload.data.relationships.avlmVehicle = vehicle;
    }

    if (stopPoints) {
      delete payload.data.relationships.stopPoints;
      payload.data.relationships.etaStops = stopPoints;
    }

    if (routeBreaks) {
      delete payload.data.relationships.routeBreaks;
      payload.data.relationships.etaRouteBreaks = routeBreaks;
    }

    transformedPayloads.push(payload);
    return transformedPayloads;
  }
});