define("adept-iq/config/map-layers", ["exports", "adept-iq/config/icon-paths", "adept-iq/config/environment", "adept-iq/pods/components/iq-widgets/routes-widget/config", "adept-iq/pods/components/iq-widgets/trips-widget/config", "adept-iq/pods/components/iq-widgets/stops-widget/config", "adept-iq/pods/components/iq-widgets/vehicles-widget/config", "adept-iq/pods/components/iq-widgets/zones-widget/config", "adept-iq/pods/components/iq-widgets/routes-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/trips-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/stops-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/vehicles-widget/avlmConfig"], function (_exports, _iconPaths, _environment, _config, _config2, _config3, _config4, _config5, _avlmConfig, _avlmConfig2, _avlmConfig3, _avlmConfig4) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @TODO: make this JSON-only
  // @TODO: it would be better to look up values from static config at time of use,
  // but for now this handles the differences in config format
  const extractLabelsFromWidgetConfig = widgetConfig => {
    return widgetConfig.columns.map(columnConfig => {
      const {
        id,
        index,
        type,
        label,
        valuePath,
        valuePreview,
        searchable,
        isMapLabelVisible,
        unAvailable,
        format
      } = columnConfig;
      return {
        id,
        index,
        type,
        label,
        valuePath,
        valuePreview,
        format,
        isSearchable: searchable,
        isVisible: isMapLabelVisible,
        unAvailable: unAvailable
      };
    });
  };

  const layers = [{
    id: 'agency',
    type: 'markers',
    label: 'Agency',
    modelName: 'agency-marker',
    isVisible: false,
    isActive: false,
    // does not respond to active context
    opacity: 1,
    types: [{
      id: 'location',
      label: 'Agency Location',
      style: 'location',
      isVisible: true,
      display: true,
      canFilter: true
    }],
    labelDisplay: 'select',
    labels: []
  }, {
    id: 'stops',
    type: 'markers',
    label: 'Stops',
    modelName: 'stop-point',
    isVisible: true,
    isActive: true,
    opacity: 1,
    types: [{
      id: 'pullinGarage',
      label: 'Pullin Garage',
      filterKey: 'mapId',
      filterValue: 'pullinGarage',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned
    }, {
      id: 'pulloutGarage',
      label: 'Pullout Garage',
      filterKey: 'mapId',
      filterValue: 'pulloutGarage',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned
    }, {
      id: 'break',
      label: 'Break',
      filterKey: 'mapId',
      filterValue: 'break',
      style: 'break',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.break
    }, {
      id: 'pick',
      label: 'Pickup',
      filterKey: 'mapId',
      filterValue: 'pick',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlanned
    }, {
      id: 'drop',
      label: 'Drop Off',
      filterKey: 'mapId',
      filterValue: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.dropPlanned
    }, {
      id: 'noshow',
      label: 'No Show Stop',
      filterKey: 'mapId',
      filterValue: 'noshow',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.noshow
    }, {
      id: 'performedStop',
      label: 'Performed Stop',
      filterKey: 'mapId',
      filterValue: 'performedStop',
      canFilter: true,
      isVisible: !_environment.default.APP.avlmLite,
      display: !_environment.default.APP.avlmLite,
      iconPath: _iconPaths.default.stops.pickCompleted
    }, {
      id: 'next',
      label: 'Next Stop',
      filterKey: 'mapId',
      filterValue: 'next',
      isVisible: true,
      display: true,
      canFilter: false,
      iconPath: _iconPaths.default.stops.pickNext
    }, {
      id: 'wheelchair',
      label: 'Wheelchair Stop',
      filterKey: 'mapId',
      filterValue: 'wheelchair',
      isVisible: true,
      display: true,
      canFilter: false,
      iconPath: _iconPaths.default.stops.pickPlannedWheelchair
    }, {
      id: 'NextWheelchair',
      label: 'Wheelchair Next Stop',
      filterKey: 'mapId',
      filterValue: 'NextWheelchair',
      isVisible: true,
      display: true,
      canFilter: false,
      iconPath: _iconPaths.default.stops.pickNextWheelchair
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_environment.default.APP.avlmLite ? _avlmConfig3.default : _config3.default)
  }, {
    id: 'routes',
    type: 'polylines',
    label: 'Routes',
    modelName: 'dispatch-route',
    isVisible: true,
    isActive: true,
    opacity: 1,
    types: [{
      id: 'planned',
      label: 'Day of Service - Planned',
      isVisible: true,
      canFilter: true,
      display: true,
      iconPath: _iconPaths.default.routes.planned,
      linePath: _iconPaths.default.routes.plannedLine
    }, {
      id: 'navigation',
      label: 'Navigation',
      filterKey: 'isNavigating',
      isVisible: true,
      canFilter: true,
      display: true,
      iconPath: _iconPaths.default.routes.navigation,
      linePath: _iconPaths.default.routes.navigationLine
    }, {
      id: 'performed',
      label: 'Performed',
      // needs to be present for any selected routes, even if it has no data
      // filterKey: 'isPerformed',
      isVisible: false,
      canFilter: true,
      display: true,
      iconPath: _iconPaths.default.routes.performed,
      linePath: _iconPaths.default.routes.performedLine
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_environment.default.APP.avlmLite ? _avlmConfig.default : _config.default)
  }, {
    id: 'trips',
    type: 'polylines',
    label: 'Trips',
    modelName: 'trip',
    isVisible: true,
    isActive: true,
    opacity: 1,
    types: [{
      id: 'Illegal',
      label: 'Illegal',
      filterKey: 'mapId',
      filterValue: 'Illegal',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.planned
    }, {
      id: 'Waitlisted',
      label: 'Waitlist',
      filterKey: 'mapId',
      filterValue: 'Waitlisted',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.planned
    }, {
      id: 'Scheduled',
      label: 'Scheduled',
      filterKey: 'mapId',
      filterValue: 'Scheduled',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.planned
    }, {
      id: 'Active',
      label: 'Active',
      filterKey: 'mapId',
      filterValue: 'Active',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.performed
    }, {
      id: 'Completed',
      label: 'Completed',
      filterKey: 'mapId',
      filterValue: 'Completed',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.performed
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_environment.default.APP.avlmLite ? _avlmConfig2.default : _config2.default)
  }, {
    id: 'vehicles',
    type: 'markers',
    label: 'Vehicles',
    modelName: 'vehicle',
    isVisible: true,
    isActive: true,
    opacity: 1,
    types: [{
      id: 'iq-vehicle',
      label: 'Vehicle',
      filterKey: 'mapId',
      filterValue: 'iq-vehicle',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.atWork
    }, {
      id: 'iq-vehicle-danger',
      label: 'Vehicle OTP Yellow (Danger)',
      filterKey: 'mapId',
      filterValue: 'iq-vehicle-danger',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: false,
      iconPath: _iconPaths.default.vehicles.atWorkDanger
    }, {
      id: 'iq-vehicle-late',
      label: 'Vehicle OTP Red (Late)',
      filterKey: 'mapId',
      filterValue: 'iq-vehicle-late',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: false,
      iconPath: _iconPaths.default.vehicles.atWorkLate
    }, {
      id: 'iq-vehicle-emergency',
      label: 'Vehicle in Emergency',
      filterKey: 'mapId',
      filterValue: 'iq-vehicle-emergency',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: false,
      iconPath: _iconPaths.default.vehicles.atWorkEmergency
    }, {
      id: 'iq-vehicle-emergency-danger',
      label: 'Vehicle in Emergency w/ OTP Danger',
      filterKey: 'mapId',
      filterValue: 'iq-vehicle-emergency-danger',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: false,
      iconPath: _iconPaths.default.vehicles.atWorkEmergencyDanger
    }, {
      id: 'iq-vehicle-emergency-late',
      label: 'Vehicle in Emergency w/ OTP Late',
      filterKey: 'mapId',
      filterValue: 'iq-vehicle-emergency-late',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: false,
      iconPath: _iconPaths.default.vehicles.atWorkEmergencyLate
    }, {
      id: 'iq-vehicle-break',
      label: 'Vehicle on Break',
      filterKey: 'mapId',
      filterValue: 'iq-vehicle-break',
      style: 'vehicle',
      isVisible: !_environment.default.APP.avlmLite,
      display: !_environment.default.APP.avlmLite,
      canFilter: false,
      iconPath: _iconPaths.default.vehicles.onBreak
    }, {
      id: 'iq-vehicle-breakdown',
      label: 'Vehicle Breakdown',
      filterKey: 'mapId',
      filterValue: 'iq-vehicle-breakdown',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: false,
      iconPath: _iconPaths.default.vehicles.vehicleBreakdown
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_environment.default.APP.avlmLite ? _avlmConfig4.default : _config4.default)
  }, {
    id: 'zones',
    type: 'shapes',
    label: 'Zones',
    modelName: 'zone',
    isVisible: true,
    isActive: false,
    isTraversal: true,
    opacity: 1,
    types: [{
      id: 'normal',
      label: 'Normal',
      filterKey: 'status',
      filterValue: 'normal',
      style: 'normal',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.zones.normal
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_config5.default)
  }];
  var _default = layers;
  _exports.default = _default;
});