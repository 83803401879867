define("adept-iq/pods/components/dropdown-provider/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown-provider'],
    store: Ember.inject.service(),
    workspaceContext: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    user: Ember.inject.service(),
    isEnable: Ember.computed('isEnabled', 'user', function () {
      const isRoadSupEnable = this.get('user').isRoadSupEnable();
      return !isRoadSupEnable;
    }),
    isEnabled: Ember.computed.oneWay('isEnable'),
    title: 'All Providers',
    isOpen: false,
    isOpened: Ember.computed.oneWay('isOpen'),
    providersList: null,
    disabledProvider: null,
    // disable all provider checkboxes
    hasOneProvider: Ember.computed.lte('providersList.length', 1),

    resetDisableProvider() {
      const disabledProvider = this.get('disabledProvider');

      if (disabledProvider) {
        disabledProvider.set('isDisabled', false);
        this.set('disabledProvider', null);
      }
    },

    updateTitle() {
      const results = this.get('providersList').filterBy('isChecked', true);
      const workspaceContext = this.get('workspaceContext');

      if (results.length === 1) {
        this.set('title', results[0].providerName);
        Ember.set(results[0], 'isDisabled', true);
        this.set('disabledProvider', results[0]);
        workspaceContext.set('_allProviders', false);
      } else if (results.length < this.providersList.length && results.length > 1) {
        this.set('title', 'Multiple');
        workspaceContext.set('_allProviders', false);
        this.resetDisableProvider();
      } else if (results.length === this.providersList.length) {
        this.set('title', 'All Providers');
        workspaceContext.set('_allProviders', true);
        this.resetDisableProvider();
      }
    },

    async setupProviderList() {
      const providers = this.get('store').peekAll('provider').filter(provider => {
        return !Ember.isEmpty(provider.get('name')) && !Ember.isEmpty(provider.get('id')) && provider.get('userCanAccessProvider');
      });
      this.set('providersList', providers);
    },

    init() {
      this._super(...arguments);

      const workspaceContext = this.get('workspaceContext');
      workspaceContext.set('_allProviders', true);
      this.get('store').findAll('provider').then(() => {
        this.setupProviderList();
        this.updateTitle();
      });
    },

    toggleSelect() {
      this.toggleProperty('isOpen');
    },

    actions: {
      onToggleSelect() {
        this.toggleSelect();
      },

      onOpen() {
        this.toggleSelect();
        this.setupProviderList();
      },

      onClose() {
        this.toggleSelect();
      },

      async onClickCheckBox(provider, event) {
        var selectedProvider = provider;
        Ember.set(selectedProvider, 'isChecked', event.target.checked);
        this.updateTitle();
        await this.get('workspaceContext').manualReloadContext();
        this.get('activeContext').refreshAll();
      }

    }
  });

  _exports.default = _default;
});