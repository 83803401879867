define("adept-iq/serializers/leg", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    serialize()
    /*snapshot, options*/
    {
      const json = this._super(...arguments);

      const serviceWindowRelationship = json.data.relationships.serviceWindow;

      if (!Ember.isNone(serviceWindowRelationship) && !Ember.isNone(serviceWindowRelationship.data)) {
        serviceWindowRelationship.data.type = 'serviceWindowName';
        json.data.relationships.serviceWindowName = json.data.relationships.serviceWindow;
        delete json.data.relationships.serviceWindow;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});