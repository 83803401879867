define("adept-iq/utils/local-traversal", ["exports", "ember-data", "adept-iq/utils/graph", "adept-iq/config/environment", "adept-iq/utils/unwrapProxy", "adept-iq/config/active-context-graph", "adept-iq/config/active-context-graph-avlmlite"], function (_exports, _emberData, _graph, _environment, _unwrapProxy, _activeContextGraph, _activeContextGraphAvlmlite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computeImplicitContext = computeImplicitContext;
  _exports.computeActiveContext = computeActiveContext;
  const activeContextGraph = _environment.default.APP.avlmLite ? _activeContextGraphAvlmlite.avlmActiveContextGraph : _activeContextGraph.coreActiveContextGraph;
  const activeContextNodes = _environment.default.APP.avlmLite ? _activeContextGraphAvlmlite.avlmActiveContextNodes : _activeContextGraph.coreActiveContextNodes;
  const unwrapPossibleProxy = _unwrapProxy.unwrapProxy; // helper for converting flattened data set to a structured record set

  const makeRecordSet = data => {
    return data.reduce((obj, {
      modelName,
      record
    }) => {
      let arr = obj[modelName];

      if (!arr) {
        arr = [];
        obj[modelName] = arr;
      }

      arr.push(record);
      return obj;
    }, {});
  };

  const getRelatedRecords = (recordSet, modelName, link) => {
    return recordSet[modelName].reduce((arr, record) => {
      let relation = null;

      if (record instanceof _emberData.default.Model) {
        relation = unwrapPossibleProxy(record.get(link.path));
      }

      if (!relation) return arr;

      switch (link.type) {
        case 'belongsTo':
          {
            arr.push(relation);
            break;
          }

        case 'hasMany':
          /*eslint-disable */
          relation.forEach(record => {
            record = unwrapPossibleProxy(record);

            if (record) {
              arr.push(record);
            }
          });
          break;

        default:
          /*eslint-disable */
          throw `unknown relationship type '${link.type}'`;

        /*eslint-enable */
      }

      return arr;
    }, []);
  };
  /**
   * Safely adds records of a single type to a record set in place.
   * @param {RecordSet} recordSet
   * @param {string} modelName
   * @param {Array} records
   * @returns {Boolean} whether or not records were added
   */


  const augmentRecordSet = (recordSet, modelName, records) => {
    let collection = recordSet[modelName];

    if (!collection) {
      collection = [];
      recordSet[modelName] = collection;
    }

    const lengthBefore = collection.length;
    records.forEach(record => {
      collection.addObject(record);
    });
    return collection.length - lengthBefore;
  };
  /**
   * Traverses the domain relationship graph; this is not a true BFS!
   * @param {RecordSet} recordSet - the set of records to augment
   * @param {ModelNode[]} q - queue of model nodes to visit
   * @param {ModelNode[]} blackList - model nodes to never visit
   */


  const expandGraph = (recordSet = {}, q = [], blackList = []) => {
    // don't mutate input queue

    /*eslint-disable */
    q = q.slice();
    /*eslint-enable */
    // for each node, keep track of which nodes we came from

    const predecessors = {};

    while (q.length > 0) {
      const sourceNode = q.shift();
      /*eslint-disable */

      if (blackList.includes(sourceNode)) continue;
      /*eslint-enable */

      Ember.makeArray(sourceNode.links).forEach(link => {
        const targetNode = activeContextGraph[link.nodeId]; // don't backtrack across hasManys

        if (link.type === 'hasMany') {
          const ids = predecessors[sourceNode.id] || [];
          if (ids.includes(targetNode.id) || predecessors[targetNode.id]) return;
        }

        const records = getRelatedRecords(recordSet, sourceNode.modelName, link);
        const count = augmentRecordSet(recordSet, targetNode.modelName, records); // only [re-]visit target if we actually added something new

        if (count > 0) {
          q.addObject(targetNode);
          predecessors[targetNode.id] = predecessors[targetNode.id] || [];
          predecessors[targetNode.id].push(sourceNode.id);
        }
      });
    }

    return recordSet;
  };
  /**
   * Computes the union of two or more record sets.
   * @param {RecordSet[]} recordSets - record sets to combine
   */


  const unionRecordSets = function mergeRecordSets(...recordSets) {
    return recordSets.reduce((obj, recordSet) => {
      Object.entries(recordSet).forEach(([modelName, records]) => {
        let collection = obj[modelName];

        if (!collection) {
          collection = [];
          obj[modelName] = collection;
        }

        if (Ember.isEmpty(records)) return;
        records.forEach(record => {
          if (collection.includes(record)) return;
          collection.push(record);
        });
      });
      return obj;
    }, {});
  };
  /**
   * Computes the intersection of two record sets.
   * @param {RecordSet[]} recordSets - record sets to intersect
   */


  const intersectRecordSets = function intersectRecordSets(...recordSets) {
    return recordSets.reduce((obj, recordSet, i) => {
      if (i === 0) {
        Object.entries(recordSet).forEach(([modelName, records]) => {
          obj[modelName] = records.slice();
        });
        return obj;
      }

      Object.entries(recordSet).forEach(([modelName, records]) => {
        let collection = obj[modelName];

        if (!collection) {
          collection = [];
          obj[modelName] = collection;
        } // find existing records that are not in the new set


        const missingRecords = collection.reduce((arr, record) => {
          if (records.includes(record)) return arr;
          arr.push(record);
          return arr;
        }, []); // remove those from the existing set

        missingRecords.forEach(record => {
          const index = collection.indexOf(record);
          collection.splice(index, 1);
        });
      });
      return obj;
    }, {});
  };
  /**
   * Computes the Implicit Context graph induced by a given selection of entities.
   * @param {RecordSet} allData - the complete data set
   * @param {Object[]} selectedData - the entities that have been "checked"
   */


  function computeImplicitContext(allData, selectedData) {
    // implicit context is empty until something explicitly selected
    if (Ember.isEmpty(selectedData)) return {};
    let union = null;
    let intersection = null;
    const selectedRecordSet = makeRecordSet(selectedData);

    try {
      // compute induced subgraphs of each entity type group
      const recordSets = Object.keys(selectedRecordSet).map(modelName => {
        const modelNode = activeContextNodes.findBy('modelName', modelName);
        const recordSet = {}; // for *implicit* data, we seed with only the selected records; this results
        // in a smaller data set when only one type is selected

        recordSet[modelName] = selectedRecordSet[modelName];
        const queue = [];
        Ember.makeArray(modelNode.links).forEach(link => {
          // get related records of _selected_ items only
          const records = getRelatedRecords(selectedRecordSet, modelName, link);
          const linkModelName = activeContextGraph[link.nodeId].modelName;
          recordSet[linkModelName] = records;
          const node = activeContextGraph[link.nodeId];
          queue.push(node);
        });
        const visited = [modelNode]; // push related records as far as they will go

        const expandedRecordSet = expandGraph(recordSet, queue, visited); // zero out any unvisited nodes *in this graph component*

        (0, _graph.breadthFirstSearch)({
          queue,
          visited,
          getNeighbours: ({
            links
          }) => {
            return Ember.makeArray(links).map(({
              nodeId
            }) => activeContextGraph[nodeId]);
          },

          /*eslint-disable */
          visitNode: ({
            modelName
          }) => {
            expandedRecordSet[modelName] = expandedRecordSet[modelName] || [];
          }
          /*eslint-enable */

        });
        return expandedRecordSet;
      });
      intersection = intersectRecordSets(allData, ...recordSets); // restore any unconstrained model types; this is necessary when an isolated
      // graph component has no selected items

      activeContextNodes.forEach(({
        modelName
      }) => {
        if (intersection[modelName]) return;
        intersection[modelName] = allData[modelName];
      }); // add the selected records back in where missing

      union = unionRecordSets(intersection, selectedRecordSet);
    } catch (error) {
      console.log('Exception in computeImplicitContext in local-traversal.js' + error); // eslint-disable-line no-console
    }

    try {
      // add the selected records back in where missing
      union = unionRecordSets(intersection, selectedRecordSet);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Exception in computeImplicitContext in local-traversal.js' + error);
    }

    return union;
  }
  /**
   * Computes the Active Context graph induced by a given selection of entities.
   * @param {RecordSet} allData - the complete data set
   * @param {Object[]} selectedData - the entities that have been "checked"
   */


  function computeActiveContext(allData, selectedData) {
    let union = null;
    let intersection = null;
    const selectedRecordSet = makeRecordSet(selectedData);

    try {
      // compute induced subgraphs of each entity type group
      const recordSets = Object.keys(selectedRecordSet).map(modelName => {
        const modelNode = activeContextNodes.findBy('modelName', modelName);
        const recordSet = {}; // for *full* active context, we seed with all data of this type

        recordSet[modelName] = allData[modelName];
        const queue = [];
        Ember.makeArray(modelNode.links).forEach(link => {
          // get related records of _selected_ items only
          const records = getRelatedRecords(selectedRecordSet, modelName, link);
          const linkModelName = activeContextGraph[link.nodeId].modelName;
          recordSet[linkModelName] = records;
          const node = activeContextGraph[link.nodeId];
          queue.push(node);
        });
        const visited = [modelNode]; // push related records as far as they will go

        const expandedRecordSet = expandGraph(recordSet, queue, visited); // zero out any unvisited nodes *in this graph component*

        (0, _graph.breadthFirstSearch)({
          queue,
          visited,
          getNeighbours: ({
            links
          }) => {
            return Ember.makeArray(links).map(({
              nodeId
            }) => activeContextGraph[nodeId]);
          },

          /*eslint-disable */
          visitNode: ({
            modelName
          }) => {
            expandedRecordSet[modelName] = expandedRecordSet[modelName] || [];
          }
          /*eslint-enable */

        });
        return expandedRecordSet;
      });
      intersection = intersectRecordSets(allData, ...recordSets); // restore any unconstrained model types; this is necessary when an isolated
      // graph component has no selected items

      activeContextNodes.forEach(({
        modelName
      }) => {
        if (intersection[modelName]) return;
        intersection[modelName] = allData[modelName];
      }); // add the selected records back in where missing

      union = unionRecordSets(intersection, selectedRecordSet);
    } catch (error) {
      console.log('Exception in computeActiveContext in local-traversal.js' + error); // eslint-disable-line no-console
    }

    try {
      // add the selected records back in where missing
      union = unionRecordSets(intersection, selectedRecordSet);
    } catch (error) {
      console.log('Exception in computeActiveContext in local-traversal.js' + error); // eslint-disable-line no-console
    }

    return union;
  }
});