define("adept-iq/services/tooltip", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: this service should employ a stack to prevent competing confirmations
  // from obliterating each others' transactions; this would require replacing
  // instances of `tooltip.setProperties({ ... })` with an equivalent push method
  var _default = Ember.Service.extend({
    tip: null,
    errors: null,
    title: null,
    primaryActionText: null,
    secondaryActionText: null,
    primaryAction: null,
    secondaryAction: null,
    hasOverlay: false,
    // covers interface so user must confirm or cancel
    warning: false,
    isRunning: Ember.computed.or('primaryActionTask.isRunning', 'secondaryActionTask.isRunning'),
    isErrorList: Ember.computed('errors', function () {
      const errors = this.get('errors');
      if (errors && errors.length > 0) return true;
      return false;
    }),
    // `drop()` might be better than `enqueue()` here, but this ensures that
    // tasks won't be cancelled by accident during messy transitions
    primaryActionTask: (0, _emberConcurrency.task)(function* () {
      const primaryAction = this.get('primaryAction');

      if (primaryAction) {
        try {
          yield primaryAction();
          this.reset();
        } catch (e) {
          // TODO: allow user to try again without reset
          this.reset();
          throw new Error(e);
        }
      }
    }).enqueue(),
    secondaryActionTask: (0, _emberConcurrency.task)(function* () {
      const secondaryAction = this.get('secondaryAction');

      if (secondaryAction) {
        yield secondaryAction();
      }

      this.reset();
    }).enqueue(),

    reset() {
      this.setProperties({
        tip: null,
        errors: null,
        title: null,
        primaryActionText: null,
        secondaryActionText: null,
        primaryAction: null,
        secondaryAction: null,
        hasOverlay: false,
        warning: false
      });
    }

  });

  _exports.default = _default;
});