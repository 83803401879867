define("adept-iq/pods/components/side-drawer/perform-stop/drop/container/component", ["exports", "moment", "lodash", "ember-concurrency", "adept-iq/utils/unwrapProxy", "adept-iq/utils/convertDistance", "adept-iq/models/stop-point"], function (_exports, _moment, _lodash, _emberConcurrency, _unwrapProxy, _convertDistance, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    // passed in by side-drawer-container component
    stopPoint: null,
    onShrinkClick: null,
    onCloseClick: null,
    arrive: false,
    depart: false,
    shouldArrive: true,
    trip: Ember.computed.readOnly('stopPoint.trip'),
    dispatchRoute: Ember.computed.readOnly('stopPoint.dispatchRoute'),
    route: Ember.computed.readOnly('dispatchRoute.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    routeVehicleDriver: Ember.computed.readOnly('dispatchRoute.latestRouteVehicleDriver'),
    isArrived: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.ARRIVE_STOP_STATUS),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    actualDropTime: Ember.computed('stopPoint.departTimestamp', function () {
      const departTime = this.get('stopPoint.departTimestamp');
      const curDate = new Date();

      if (Ember.isNone(departTime)) {
        return curDate;
      }

      return departTime;
    }),
    actualArriveTime: Ember.computed('stopPoint.arriveTimestamp', function () {
      const arriveTime = this.get('stopPoint.arriveTimestamp');
      const curDate = new Date();

      if (Ember.isNone(arriveTime)) {
        return curDate;
      }

      return arriveTime;
    }),

    init() {
      this._super(...arguments); // Ensure flatpickr has a date


      const stopPoint = this.get('stopPoint');
      const curDate = new Date();

      if (Ember.isNone(stopPoint.get('arriveTimestamp'))) {
        this.set('stopPoint.arriveTimestamp', curDate);
      }

      if (Ember.isNone(stopPoint.get('departTimestamp'))) {
        this.set('stopPoint.departTimestamp', curDate);
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.get('refreshTask').perform();
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const stopPoint = yield this.get('stopPoint');
      const isArrived = this.get('isArrived');
      stopPoint.set('odometerInMiles');
      stopPoint.set('odometer');
      this.set('arrive', isArrived);
      this.set('depart', false);
    }).keepLatest(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const stopPoint = (0, _unwrapProxy.unwrapProxy)(this.get('stopPoint'));
      const isArrived = this.get('isArrived');
      const savePromises = []; // convert temporary attribute in miles to odometer in meters

      stopPoint.set('odometer', (0, _convertDistance.convertMilesToMeters)(stopPoint.get('odometerInMiles')));

      if (this.get('arrive') && !isArrived) {
        const arrive = yield routeExecEventAdapter.createStopArriveEvent(stopPoint);
        savePromises.push(arrive);
      }

      if (this.get('depart')) {
        const depart = yield routeExecEventAdapter.createStopDepartEvent(stopPoint);
        savePromises.push(depart);
      }

      return Ember.RSVP.all(savePromises);
    }).drop(),

    didInsertElement() {
      const setStopContainerReference = this.get('setStopContainerReference');

      if (setStopContainerReference) {
        setStopContainerReference(this);
      }
    },

    actions: {
      onRefreshButtonClick() {
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        const stopPoint = this.get('stopPoint'); // Ensure flatpickr has a default date

        stopPoint.rollbackAttributes();
        this.onCloseClick();
      },

      onPerformButtonClick() {
        const tooltip = this.get('tooltip');
        const route = this.get('route');
        const stopPoint = this.get('stopPoint');
        const arrive = this.get('arrive');
        const depart = this.get('depart');
        const routeEndTime = route.get('plannedEndTime');
        const actualStartTime = route.get('actualStartTimestamp');
        const arriveTime = (0, _moment.default)(this.get('actualArriveTime'));
        const departTime = (0, _moment.default)(this.get('actualDropTime'));
        const routeOrdinal = stopPoint.get('routeOrdinal');
        const routeStops = route.get('dispatchRoute.orderedStopsWithGaragesAndBreaks');

        const maxStopDepartTime = _lodash.default.maxBy(routeStops, 'actualDepartTime');

        const currentDateTime = (0, _moment.default)();
        const odometerInMiles = stopPoint.get('odometerInMiles');
        const title = 'Perform Stop';
        let messageText = '';
        let tooltipText = ''; // Reset error text

        this.set('errorText');

        if ((0, _moment.default)(departTime).isBefore(arriveTime, 'minutes')) {
          this.set('actualDropTime', this.get('actualArriveTime'));
        }

        this.set('stopPoint.arriveTimestamp', this.get('actualArriveTime'));
        this.set('stopPoint.departTimestamp', this.get('actualDropTime'));

        if (arrive && depart) {
          messageText = 'Performed drop stop';
          tooltipText = 'Perform drop stop?';
        } else if (arrive) {
          messageText = 'Arrived drop stop';
          tooltipText = 'Arrive drop stop?';
        } else {
          this.set('errorText', 'Please select at least one option of arrive or depart to perform the stop.');
          return false;
        }

        if (Ember.isEmpty(odometerInMiles) || odometerInMiles.length <= 0) {
          this.set('errorText', 'Odometer cannot be empty');
          return false;
        }

        if (arrive) {
          if (Ember.isEmpty(arriveTime)) {
            this.set('errorText', 'Please fill in a time for arrive time');
            return false;
          }
        }

        if (depart) {
          if (Ember.isEmpty(departTime)) {
            this.set('errorText', 'Please fill in a time for depart time');
            return false;
          }
        }

        if (arriveTime.isBefore(actualStartTime) || departTime.isBefore(actualStartTime)) {
          this.set('errorText', "Performed Drop Stop Failed. The stop cannot be performed before the route's start time");
          return false;
        }

        if (arriveTime.isAfter(routeEndTime) || departTime.isAfter(routeEndTime)) {
          this.set('errorText', "Performed Drop Stop Failed. The stop cannot be performed after the route's end time.");
          return false;
        }

        if (arriveTime.isAfter(currentDateTime) || departTime.isAfter(currentDateTime)) {
          this.set('errorText', 'Performed Drop Stop Failed. The stop cannot be performed after the current time.');
          return false;
        }

        if (routeOrdinal > 0 && maxStopDepartTime) {
          if (arriveTime.isBefore(maxStopDepartTime.get('actualDepartTime')) || departTime.isBefore(maxStopDepartTime.get('actualDepartTime'))) {
            this.set('errorText', 'Performed Drop Stop Failed. The stop cannot be performed before the previous Actual drop time.');
            return false;
          }
        }

        tooltip.setProperties({
          tip: tooltipText,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('saveTask').perform().then(() => {
              this.get('activeContext').get('refreshTableContent').perform('route');
              this.get('notifications').success(`${messageText} successful`);
              return this.onCloseClick();
            }).catch(response => {
              // clear odometer in stop point model
              stopPoint.set('odometer');
              const payloadMessage = Ember.get(response, 'payload.message') ? JSON.parse(Ember.get(response, 'payload.message')) : null;
              messageText = messageText + ' failed';

              if (response.status === 400) {
                if (payloadMessage.errorDetail.includes('cannot be in state arrived unless all previous stops are performed')) {
                  messageText = 'Performed Drop Stop Failed. You cannot perform this stop until previous stops are performed';
                }
              }

              this.get('notifications').warning(`${messageText}`);
            }).finally(() => {
              return this.onCloseClick();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return;
          }
        });
      }

    }
  });

  _exports.default = _default;
});