define("adept-iq/pods/components/iq-widgets/edit-trip-form-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/form-widget/component", "adept-iq/pods/components/iq-widgets/edit-trip-form-widget/config", "moment"], function (_exports, _component, _config, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    editModal: Ember.inject.service(),
    geocode: Ember.inject.service(),
    editableSections: _config.default.editableSections,
    classNames: ['new-trip-form-widget'],

    validateRecords() {
      let valid = true;
      const records = this.get('editableRecords') || [];
      this.set('editModal.errors', []);
      records.forEach(record => {
        const isEligible = record.get('leg.rider.riderEligibilities') || {};
        const requestedTime = (0, _moment.default)(record.get('requestedTime'));

        if (Ember.isNone(requestedTime)) {
          valid = false;
          this.get('editModal.errors').pushObject('Date should be there');
        }

        const eligibleRecord = isEligible.find(value => {
          const from = (0, _moment.default)(Ember.get(value, 'from'), 'DD/MM/YYYY');
          const to = (0, _moment.default)(Ember.get(value, 'to'), 'DD/MM/YYYY');
          const tripDate = (0, _moment.default)(requestedTime, 'DD/MM/YYYY');
          return tripDate.isAfter(from) && tripDate.isBefore(to);
        });

        if (Ember.isNone(eligibleRecord)) {
          valid = false;
          this.get('editModal.errors').pushObject('Eligibility to date cannot be before from date');
        }

        if (Ember.isEmpty(record.get('pick.place.location.latlng'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Pickup address should be there');
        } else if (Ember.isEmpty(record.get('originlocations.firstObject.geoNode'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Pickup address must be a valid mailing address');
        }

        if (Ember.isEmpty(record.get('drop.place.location.latlng'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Drop address should be there');
        } else if (Ember.isEmpty(record.get('destinationlocations.firstObject.geoNode'))) {
          valid = false;
          this.get('editModal.errors').pushObject('Drop address must be a valid mailing address');
        }
      });
      return valid;
    },

    actions: {
      // override undo because we will have to deal with undoing created
      // models for addresses, travel needs, and eligibility later.
      // IQUX-510
      onUndoClick() {
        const lastUndoState = this.get('editModal.undoHistory').popObject();

        if (lastUndoState === null) {
          const records = this.get('editableRecords') || [];
          records.forEach(record => {
            record.set('isForceDirty', false);
          });
        } else {
          lastUndoState.forEach(({
            record,
            properties
          }) => {
            record.setProperties(properties);
          });
        }

        this.set('isLastUndoStateCommitted', true);
      },

      onApplyClick() {
        if (this.validateRecords()) {
          const title = this.get('title');
          Ember.$('html,body,.tomtom-map').removeClass('custom-cursor');
          this.get('geocode').activateGeocode(false);
          this.set('addressRecord', null);
          this.get('service').apply(title);
        }
      }

    }
  });

  _exports.default = _default;
});