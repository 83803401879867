define("adept-iq/pods/components/side-drawer/activity-log/vehicle-activity-log/config", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.columns = void 0;
  const TIME_FORMAT = 'YYYY-MM-DD hh:mmA';
  const columns = [{
    label: 'Activity',
    valuePath: 'activity'
  }, {
    label: 'Time',
    valuePath: 'date',
    format: date => (0, _moment.default)(date).format(TIME_FORMAT)
  }, {
    label: 'Details',
    valuePath: 'details'
  }, {
    label: 'User',
    valuePath: 'user'
  }];
  _exports.columns = columns;
});