define("adept-iq/config/map-layers-avlmlite", ["exports", "adept-iq/config/icon-paths", "adept-iq/config/environment", "adept-iq/pods/components/iq-widgets/routes-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/trips-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/stops-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/vehicles-widget/avlmConfig"], function (_exports, _iconPaths, _environment, _avlmConfig, _avlmConfig2, _avlmConfig3, _avlmConfig4) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @TODO: make this JSON-only
  // @TODO: it would be better to look up values from static config at time of use,
  // but for now this handles the differences in config format
  const extractLabelsFromWidgetConfig = widgetConfig => {
    return widgetConfig.columns.map(columnConfig => {
      const {
        id,
        index,
        type,
        label,
        valuePath,
        valuePreview,
        searchable,
        isMapLabelVisible,
        unAvailable
      } = columnConfig;
      return {
        id,
        index,
        type,
        label,
        valuePath,
        valuePreview,
        isSearchable: searchable,
        isVisible: isMapLabelVisible,
        unAvailable: unAvailable
      };
    });
  };

  const layers = [{
    id: 'agency',
    type: 'markers',
    label: 'Agency',
    modelName: 'agency-marker',
    isVisible: false,
    isActive: false,
    // does not respond to active context
    opacity: 1,
    selectedDisplay: 'On Click',
    types: [{
      id: 'location',
      label: 'Agency Location',
      style: 'location',
      isVisible: true,
      display: true,
      canFilter: true
    }],
    labelDisplay: 'select',
    labels: []
  }, {
    id: 'stops',
    type: 'markers',
    label: 'Stops',
    modelName: 'avlm-stop-point',
    isVisible: true,
    isActive: true,
    opacity: 1,
    selectedDisplay: 'On Click',
    types: [{
      id: 'pullinGarage',
      label: 'Pullin Garage',
      filterKey: 'stopType',
      filterValue: 'pullinGarage',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned
    }, {
      id: 'pulloutGarage',
      label: 'Pullout Garage',
      filterKey: 'stopType',
      filterValue: 'pulloutGarage',
      style: 'garage',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.garagePlanned
    }, {
      id: 'break',
      label: 'Break',
      filterKey: 'stopType',
      filterValue: 'break',
      style: 'break',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.break
    }, {
      id: 'deployStop',
      label: 'Deploy Stop',
      valueKey: 'stopType',
      style: 'pick',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.planned
    }, {
      id: 'pick',
      label: 'Pickup',
      filterKey: 'stopType',
      filterValue: 'pick',
      style: 'pick',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlanned
    }, {
      id: 'drop',
      label: 'Drop Off',
      filterKey: 'stopType',
      filterValue: 'drop',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.dropPlanned
    }, {
      id: 'performedStop',
      label: 'Performed Stop',
      filterKey: 'stopType',
      filterValue: 'performedStop',
      style: 'Depart',
      canFilter: true,
      isVisible: true,
      display: true,
      iconPath: _iconPaths.default.stops.pickCompleted
    }, {
      id: 'next',
      label: 'Next Stop',
      filterKey: 'stopType',
      filterValue: 'next',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickNext
    }, {
      id: 'wheelchair',
      label: 'Wheelchair Stop',
      filterKey: 'stopType',
      filterValue: 'wheelchair',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickPlannedWheelchair
    }, {
      id: 'NextWheelchair',
      label: 'Wheelchair Next Stop',
      filterKey: 'stopType',
      filterValue: 'NextWheelchair',
      style: 'drop',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.pickNextWheelchair
    }, {
      id: 'noshow',
      label: 'No Show Stop',
      filterKey: 'stopType',
      filterValue: 'noshow',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.stops.noshow
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_avlmConfig3.default)
  }, {
    id: 'routes',
    type: 'polylines',
    label: 'Routes',
    modelName: 'avlm-route',
    isVisible: true,
    isActive: true,
    opacity: 1,
    selectedDisplay: 'On Left Click',
    types: [{
      id: 'planned',
      label: 'Day of Service - Planned',
      style: 'solid',
      styleDecorator: 'solidPlanned',
      isVisible: true,
      canFilter: true,
      display: true,
      iconPath: _iconPaths.default.routes.planned,
      linePath: _iconPaths.default.routes.plannedLine
    }, {
      id: 'navigation',
      label: 'Navigation',
      style: 'solid',
      styleDecorator: 'solidNavigation',
      isVisible: true,
      canFilter: true,
      filterKey: 'status',
      filterValue: 'A',
      display: true,
      iconPath: _iconPaths.default.routes.navigation,
      linePath: _iconPaths.default.routes.navigationLine
    }, {
      id: 'performed',
      label: 'Performed',
      // needs to be present for any selected routes, even if it has no data
      // filterKey: 'isPerformed',
      isVisible: false,
      canFilter: true,
      display: true,
      iconPath: _iconPaths.default.routes.performed,
      linePath: _iconPaths.default.routes.performedLine
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_avlmConfig.default)
  }, {
    id: 'trips',
    type: 'polylines',
    label: 'Trips',
    modelName: 'avlm-trip',
    isVisible: true,
    isActive: true,
    opacity: 1,
    selectedDisplay: 'On Left Click',
    types: [{
      id: 'Waitlisted',
      label: 'Waitlist',
      filterKey: 'mapId',
      filterValue: 'Waitlisted',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.planned
    }, {
      id: 'Scheduled',
      label: 'Scheduled',
      filterKey: 'mapId',
      filterValue: 'Scheduled',
      style: 'dotted',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.planned
    }, {
      id: 'Active',
      label: 'Active',
      filterKey: 'mapId',
      filterValue: 'Active',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.performed
    }, {
      id: 'Completed',
      label: 'Completed',
      filterKey: 'mapId',
      filterValue: 'Completed',
      style: 'solid',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.trips.performed
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_avlmConfig2.default)
  }, {
    id: 'vehicles',
    type: 'markers',
    label: 'Vehicles',
    modelName: 'avlm-vehicle',
    isVisible: true,
    isActive: true,
    selectedDisplay: 'On Left Click',
    opacity: 1,
    types: [{
      id: 'avlm-vehicle',
      label: 'Vehicle',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.atWork
    }, {
      id: 'avlm-vehicle-danger',
      label: 'Vehicle OTP Yellow (Danger)',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-danger',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.atWorkDanger
    }, {
      id: 'avlm-vehicle-late',
      label: 'Vehicle OTP Red (Late)',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-late',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.atWorkLate
    }, {
      id: 'avlm-vehicle-emergency',
      label: 'Vehicle in Emergency',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-emergency',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.atWorkEmergency
    }, {
      id: 'avlm-vehicle-emergency-danger',
      label: 'Vehicle in Emergency w/ OTP Danger',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-emergency-danger',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.atWorkEmergencyDanger
    }, {
      id: 'avlm-vehicle-emergency-late',
      label: 'Vehicle in Emergency w/ OTP Late',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-emergency-late',
      style: 'vehicle',
      isVisible: true,
      display: true,
      canFilter: true,
      iconPath: _iconPaths.default.vehicles.atWorkEmergencyLate
    }, {
      id: 'avlm-vehicle-break',
      label: 'Vehicle on Break',
      filterKey: 'mapId',
      filterValue: 'avlm-vehicle-break',
      style: 'vehicle',
      isVisible: !_environment.default.APP.avlmLite,
      display: !_environment.default.APP.avlmLite,
      canFilter: false,
      iconPath: _iconPaths.default.vehicles.onBreak
    }],
    labelDisplay: 'select',
    labels: extractLabelsFromWidgetConfig(_avlmConfig4.default)
  }];
  var _default = layers;
  _exports.default = _default;
});