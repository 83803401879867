define("adept-iq/pods/dashboard-info/route", ["exports", "moment", "ember-simple-auth/mixins/authenticated-route-mixin", "adept-iq/classes/road-supervisor-api"], function (_exports, _moment, _authenticatedRouteMixin, _roadSupervisorApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LOCAL_STORAGE_KEY = 'dashboard-info';

  const ROLE_NAME = _roadSupervisorApi.default.getRoleName();

  function getAccessData(userId) {
    let accessData;
    let data = localStorage.getItem(LOCAL_STORAGE_KEY) || '{}';

    try {
      let json = JSON.parse(data);

      if (json.userId === userId && json.accessData) {
        accessData = json.accessData;
      }
    } catch (e) {}

    return accessData;
  }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    classNames: ['road-supervisor-modal'],
    user: Ember.inject.service(),
    session: Ember.inject.service(),
    providerNames: Ember.computed.alias('session.data.authenticated.tokenInfo.providerNames'),

    resetController(controller) {
      controller.set('vehicleId', undefined);
      controller.set('driverId', undefined);

      this._super(...arguments);
    },

    setupController(controller) {
      let category = 'supervisor_vehicle_list';
      controller.set('vehicleProvidersList', '');

      if (this.user.hasRole(ROLE_NAME)) {
        controller.set('isDisclaimerAgreed', false);
      }

      this.store.query('cs-config-item', {
        category
      }).then(configItems => {
        return {
          data: configItems.map(configItem => {
            const list = configItem.get('value').supervisorVehicleList;
            const matchedSupervisorVehicleList = list.filter(item => {
              if (this.providerNames.includes('ALL')) {
                return true;
              }

              return this.providerNames.includes(item.provider);
            });
            controller.set('vehicleProvidersList', matchedSupervisorVehicleList);
          })
        };
      });
    },

    beforeModel() {
      if (this.user.hasRole(ROLE_NAME)) {
        let accessData = getAccessData(this.user.userId);

        if (!accessData) {
          return;
        }
      }

      this.transitionTo('index');
    },

    actions: {
      onClickCheckBox(index) {
        if (index.target.value) {
          var selectedProvider = this.get('controller.vehicleProvidersList').objectAt(index.target.value);
          this.set('controller.vehicleId', selectedProvider.vehicleId);
          this.set('controller.providerId', selectedProvider.provider);
        } else {
          this.set('controller.vehicleId', '');
          this.set('controller.providerId', '');
        }
      },

      setAccessData(vehicleId, providerId) {
        let driverId = vehicleId;
        let provider = providerId;
        let accessData = {
          vehicleId,
          driverId,
          provider
        };
        let json = {
          userId: this.user.userId,
          accessData
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(json));
        this.transitionTo('index');
      },

      onAgreeButtonClick() {
        this.set('controller.isDisclaimerAgreed', true);
      }

    }
  });

  _exports.default = _default;
});