define("adept-iq/adapters/dispatch-route", ["exports", "adept-iq/adapters/-dispatch", "adept-iq/config/api-urls"], function (_exports, _dispatch, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // export default DispatchAdapter;
  var _default = _dispatch.default.extend({
    ajaxService: Ember.inject.service('ajax'),
    // not using "ajax" so there won't be conflict with the base's ajax calls
    session: Ember.inject.service(),
    // TODO: This is a temporary solution for creating a route
    // dispatch backend will have a proper endpoint for POST route
    buildURL: function (modelType, id, snapshot, requestType) {
      if (requestType === 'createRecord') {
        return `${this.host}/schedule/${snapshot.record.get('schedule.id')}/route/clusterization`;
      }

      return this._super(...arguments);
    },

    createDispatchRoute(routePayload, dispatchSchedule) {
      const session = this.get('session');
      const ajax = this.get('ajaxService');
      const {
        providerName
      } = routePayload.data.relationships;
      const json = {
        data: {
          type: 'dispatchRoute',
          attributes: {
            lockedForOptimization: false
          },
          relationships: {
            route: {
              data: {
                id: routePayload.data.id,
                type: 'route'
              }
            },
            dispatchSchedule: {
              data: {
                id: dispatchSchedule.get('id'),
                type: 'dispatchSchedule'
              }
            },
            providerName: {
              data: {
                id: providerName.data.id,
                type: 'providerName'
              }
            }
          }
        }
      };
      return ajax.post(_apiUrls.API.dispatchService.host + '/dispatch-route/', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    createDriverBreak(dispatchRoute, plannedRouteOrdinal, routeId) {
      const session = this.get('session');
      const json = {
        data: {
          type: 'stopPoint',
          attributes: {
            routeOrdinal: plannedRouteOrdinal
          },
          relationships: {
            routeBreak: {
              data: {
                id: routeId,
                type: 'routeBreak'
              }
            }
          }
        }
      };
      return this.get('ajaxService').post(_apiUrls.API.dispatchService.host + `/dispatch-route/${dispatchRoute.get('id')}/break`, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    removeDriverBreak(dispatchRouteId, breakId) {
      const session = this.get('session');
      return this.get('ajaxService').post(_apiUrls.API.dispatchService.host + `/dispatch-route/${dispatchRouteId}/break/${breakId}`, {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    postBreakdown(dispatchRoute, breakdownPlaceId, vehicle) {
      const session = this.get('session');
      const firstUnDepartedStop = dispatchRoute.get('firstUnDepartedStop');
      const json = {
        type: 'breakdown',
        attributes: {
          vehicleId: vehicle.get('id'),
          description: vehicle.get('breakdownDescription'),
          breakdownTypeName: vehicle.get('breakdownType.id'),
          breakdownOrdinal: firstUnDepartedStop.get('routeOrdinal'),
          breakdownTime: vehicle.get('breakdownTime'),
          breakdownPlaceId: breakdownPlaceId,
          pickupTime: firstUnDepartedStop.get('promisedTime')
        }
      };
      return this.get('ajaxService').post(_apiUrls.API.dispatchService.host + `/dispatch-route/${dispatchRoute.id}/breakdown`, {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: {
          data: json
        }
      });
    },

    formatSpecialRoutePayload(dispatchRoute, breakdownPlace = null) {
      const data = {
        data: {
          type: 'specialRoute',
          attributes: {
            name: 'a route name',
            routeType: 'breakdown replacement',
            shiftStartTime: '2019-06-27T07:00:00-07:00',
            shiftEndTime: '2019-06-27T17:00:00-07:00'
          },
          relationships: {}
        }
      };
      data.data.attributes.name = dispatchRoute.get('replacementRouteId');
      data.data.attributes.shiftStartTime = dispatchRoute.get('replacementStartTime');
      data.data.attributes.shiftEndTime = dispatchRoute.get('replacementEndTime');

      if (dispatchRoute.get('provider.id')) {
        data.data.relationships.providerName = {
          data: {
            id: dispatchRoute.get('provider.id'),
            type: 'providerName'
          }
        };
      }

      if (dispatchRoute.get('dispatchSchedule.id')) {
        data.data.relationships.dispatchSchedule = {
          data: {
            id: dispatchRoute.get('dispatchSchedule.id'),
            type: 'dispatchSchedule'
          }
        };
      }

      if (dispatchRoute.get('replacementDriver.id')) {
        data.data.relationships.driver = {
          data: {
            id: dispatchRoute.get('replacementDriver.id'),
            type: 'driver'
          }
        };
      }

      if (dispatchRoute.get('replacementVehicle.id')) {
        data.data.relationships.vehicle = {
          data: {
            id: dispatchRoute.get('replacementVehicle.id'),
            type: 'vehicle'
          }
        };
      }

      if (dispatchRoute.get('id')) {
        data.data.relationships.replacedRoute = {
          data: {
            id: dispatchRoute.get('id'),
            type: 'dispatchRoute'
          }
        };
      } // starting location should be where the dispatcher marked the breakdown at
      // if not found, we use the start garage


      if (breakdownPlace.get('id')) {
        data.data.relationships.startGaragePlace = {
          data: {
            id: breakdownPlace.get('id'),
            type: 'place'
          }
        };
      } else if (dispatchRoute.get('startGarage.id')) {
        data.data.relationships.startGaragePlace = {
          data: {
            id: dispatchRoute.get('startGarage.id'),
            type: 'place'
          }
        };
      }

      if (dispatchRoute.get('endGarage.id')) {
        data.data.relationships.endGaragePlace = {
          data: {
            id: dispatchRoute.get('endGarage.id'),
            type: 'place'
          }
        };
      }

      return data;
    },

    postSpecialRoute(dispatchRoute, breakdownPlace = null) {
      const session = this.get('session');
      const data = this.formatSpecialRoutePayload(dispatchRoute, breakdownPlace);
      const json = JSON.stringify(data);
      return this.get('ajaxService').post(_apiUrls.API.dispatchService.host + '/special-route', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    transferAllTripsToRouteFunction(dispatchRoute, newDispatchRoute, remainingStopPoints) {
      const ajax = this.get('ajaxService');
      const session = this.get('session');
      const data = {
        data: {
          type: 'recipe',
          attributes: {
            instructions: []
          }
        }
      };

      function formatBulkTransfer(remainingStops, sourceId, destinationId) {
        remainingStops.forEach(stop => {
          data.data.attributes.instructions.push({
            id: stop.id,
            type: 'stop',
            source: sourceId,
            destination: destinationId,
            ordinal: stop.routeOrdinal
          });
        });
      }

      return new Promise((resolve, reject) => {
        formatBulkTransfer(remainingStopPoints, dispatchRoute.id, newDispatchRoute.data.id);
        return ajax.post(_apiUrls.API.dispatchService.host + '/bulk-operation', {
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${session.data.authenticated.token}`
          },
          data: JSON.stringify(data)
        }).then(() => {
          return resolve(null);
        }).catch(function (error) {
          reject(error);
        });
      });
    }

  });

  _exports.default = _default;
});