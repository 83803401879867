define("adept-iq/pods/components/side-drawer/perform-stop/pick/container/component", ["exports", "ember-concurrency", "moment", "lodash", "adept-iq/utils/convertDistance", "adept-iq/models/stop-point"], function (_exports, _emberConcurrency, _moment, _lodash, _convertDistance, _stopPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TravelNeedItem = Ember.Object.extend({
    type: null,
    count: null
  }); // "service animal" is the one client travel need not shown in dropdowns

  const dropdownExemptedTravelNeedTypeNames = ['serviceAnimal'];
  const paymentTypeOptions = ['Cash', 'Credit Card'];

  var _default = Ember.Component.extend({
    classNames: ['perform-stop-drawer'],
    activeContext: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    // passed in by side-drawer-container component
    stopPoint: null,
    onShrinkClick: null,
    onCloseClick: null,
    arrive: false,
    depart: false,
    travelNeedOptions: null,
    travelNeedItems: null,
    shouldArrive: true,
    minCompanionCount: null,
    maxCompanionCount: null,
    _travelNeedTypesQuery: null,
    trip: Ember.computed.readOnly('stopPoint.trip'),
    route: Ember.computed.readOnly('stopPoint.dispatchRoute.route'),
    rider: Ember.computed.readOnly('trip.rider'),
    routeVehicleDriver: Ember.computed.readOnly('route.dispatchRoute.latestRouteVehicleDriver'),
    driver: Ember.computed.readOnly('routeVehicleDriver.driver'),
    address: Ember.computed.readOnly('stopPoint.tripStop.segmentStop.place.address'),
    actualPickTime: Ember.computed('stopPoint.departTimestamp', function () {
      const departTime = this.get('stopPoint.departTimestamp');
      const curDate = new Date();

      if (Ember.isNone(departTime)) {
        return curDate;
      }

      return departTime;
    }),
    actualArriveTime: Ember.computed('stopPoint.arriveTimestamp', function () {
      const arriveTime = this.get('stopPoint.arriveTimestamp');
      const curDate = new Date();

      if (Ember.isNone(arriveTime)) {
        return curDate;
      }

      return arriveTime;
    }),
    isArriveDisabled: Ember.computed.or('depart', 'isArrived'),
    isArrived: Ember.computed.equal('stopPoint.computedStatus', _stopPoint.ARRIVE_STOP_STATUS),

    init() {
      this._super(...arguments); // Ensure flatpickr has a date


      const stopPoint = this.get('stopPoint');
      const curDate = new Date();

      if (Ember.isNone(stopPoint.get('arriveTimestamp'))) {
        this.set('stopPoint.arriveTimestamp', curDate);
      }

      if (Ember.isNone(stopPoint.get('departTimestamp'))) {
        this.set('stopPoint.departTimestamp', curDate);
      } // only need to do this query once


      const travelNeedTypesQuery = this.get('store').findAll('travel-need-type');
      this.set('_travelNeedTypesQuery', travelNeedTypesQuery);
    },

    didInsertElement() {
      const setStopContainerReference = this.get('setStopContainerReference');

      if (setStopContainerReference) {
        setStopContainerReference(this);
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.get('refreshTask').perform();
    },

    unusedTravelNeedOptions: Ember.computed('travelNeedOptions', 'travelNeedItems.@each.type', function () {
      const travelNeedOptions = this.get('travelNeedOptions') || [];
      const travelNeedItems = this.get('travelNeedItems') || [];
      const usedTravelNeedTypes = travelNeedItems.mapBy('type');
      return travelNeedOptions.reject(({
        type
      }) => {
        return usedTravelNeedTypes.includes(type);
      });
    }),

    setMinMaxTravelNeedCount(travelNeedItem) {
      const count = travelNeedItem ? travelNeedItem.get('count') : 0; // create min count for each travel need

      if (count === 0) travelNeedItem.set('minCount', count);else travelNeedItem.set('minCount', count - 1); // create max count for each travel need

      travelNeedItem.set('maxCount', count + 1);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const travelNeedTypes = yield this.get('_travelNeedTypesQuery') || [];
      const stopPoint = this.get('stopPoint');
      const isArrived = this.get('isArrived');
      stopPoint.set('odometerInMiles');
      stopPoint.set('odometer'); // "Pick Stop" panel

      this.set('arrive', isArrived);
      this.set('depart', false); // "Travel Needs" panel

      const travelNeedOptions = travelNeedTypes.reduce((arr, travelNeedType) => {
        const name = travelNeedType.get('name');
        const displayName = travelNeedType.get('displayName'); // e.g. don't include "serviceAnimal" since it has its own UI

        if (dropdownExemptedTravelNeedTypeNames.includes(name)) return arr;
        arr.push({
          type: name,
          label: displayName || name
        });
        return arr;
      }, []);
      this.set('travelNeedOptions', travelNeedOptions); // dropdown travel needs

      const travelNeeds = stopPoint.get('trip.segment.leg.legTravelNeeds');
      const clientTravelNeeds = travelNeeds ? travelNeeds.filterBy('isClient', true) : null;
      const travelNeedItems = travelNeedOptions.reduce((arr, {
        type
      }) => {
        const travelNeed = clientTravelNeeds ? clientTravelNeeds.findBy('travelNeedTypeName', type) : null;
        const count = travelNeed ? travelNeed.get('count') : 0;
        if (Ember.isNone(count) || count === 0) return arr;
        const travelNeedItem = TravelNeedItem.create({
          type,
          count
        }) || [];
        arr.push(travelNeedItem);
        return arr;
      }, []);
      travelNeedItems.forEach(travelNeed => {
        if (Ember.isNone(travelNeed.get('oldCount'))) {
          travelNeed.set('oldCount', travelNeed.get('count'));
        } else {
          travelNeed.set('count', travelNeed.get('oldCount'));
        }

        this.setMinMaxTravelNeedCount(travelNeed);
      });
      this.set('travelNeedItems', travelNeedItems); // additional travel needs

      const serviceAnimalTravelNeed = clientTravelNeeds.findBy('isServiceAnimal', true);
      const ambulatoryTravelNeeds = travelNeeds.filterBy('isAmbulatory', true);
      const pcaTravelNeed = ambulatoryTravelNeeds.findBy('isPca', true);
      const companionTravelNeed = ambulatoryTravelNeeds.findBy('isCompanion', true);
      const companionCount = companionTravelNeed ? companionTravelNeed.get('count') : 0;
      this.set('serviceAnimalCount', serviceAnimalTravelNeed ? serviceAnimalTravelNeed.get('count') : 0);
      this.set('pcaCount', pcaTravelNeed ? pcaTravelNeed.get('count') : 0);
      this.set('companionCount', companionCount);
      this.set('minCompanionCount', companionCount - 1 > 0 ? companionCount - 1 : 0);
      this.set('maxCompanionCount', companionCount + 1); // "Fare" panel

      this.set('paymentTypeOptions', paymentTypeOptions);
      this.set('selectedPaymentType', paymentTypeOptions[0]);
    }).keepLatest(),
    saveTask: (0, _emberConcurrency.task)(function* () {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripPaymentAdapter = store.adapterFor('trip-payment');
      const stopPoint = this.get('stopPoint');
      const trip = stopPoint.get('trip');
      const isArrived = this.get('isArrived'); // convert temporary attribute in miles to odometer in meters

      stopPoint.set('odometer', (0, _convertDistance.convertMilesToMeters)(stopPoint.get('odometerInMiles')));

      if (this.get('arrive') && !isArrived) {
        yield routeExecEventAdapter.createStopArriveEvent(stopPoint);
      }

      if (this.get('depart')) {
        // Handle Travel Needs
        stopPoint.set('serviceAnimalCount', this.get('serviceAnimalCount'));
        stopPoint.set('pcaCount', this.get('pcaCount'));
        stopPoint.set('companionCount', this.get('companionCount'));
        stopPoint.set('travelNeeds', this.get('travelNeedItems').map(travelNeedItem => ({
          type: travelNeedItem.get('type'),
          cnt: travelNeedItem.get('count')
        })));
        yield routeExecEventAdapter.createStopDepartEvent(stopPoint);
      }

      if (this.get('amount')) {
        trip.set('fare', this.get('amount'));
        trip.set('fareName', this.get('selectedPaymentType'));
        yield tripPaymentAdapter.createTripPaymentEvent(stopPoint.get('trip'));
      }
    }).drop(),
    actions: {
      onRefreshButtonClick() {
        this.get('refreshTask').perform();
      },

      onCancelButtonClick() {
        const stopPoint = this.get('stopPoint'); // Ensure flatpickr has a default date

        stopPoint.rollbackAttributes();
        this.onCloseClick();
      },

      onPerformButtonClick() {
        const tooltip = this.get('tooltip');
        const route = this.get('route');
        const stopPoint = this.get('stopPoint');
        const arrive = this.get('arrive');
        const depart = this.get('depart');
        const routeEndTime = route.get('plannedEndTime');
        const actualStartTime = route.get('actualStartTimestamp');
        const arriveTime = (0, _moment.default)(this.get('actualArriveTime'));
        const departTime = (0, _moment.default)(this.get('actualPickTime'));
        const routeOrdinal = stopPoint.get('routeOrdinal');
        const routeStops = route.get('dispatchRoute.orderedStopsWithGaragesAndBreaks');

        const maxStopDepartTime = _lodash.default.maxBy(routeStops, 'actualDepartTime');

        const currentDateTime = (0, _moment.default)();
        const odometerInMiles = stopPoint.get('odometerInMiles');
        const title = 'Perform Stop';
        let messageText = '';
        let tooltipText = ''; // Reset error text

        this.set('errorText');

        if ((0, _moment.default)(departTime).isBefore(arriveTime, 'minutes')) {
          this.set('actualPickTime', this.get('actualArriveTime'));
        }

        this.set('stopPoint.arriveTimestamp', this.get('actualArriveTime'));
        this.set('stopPoint.departTimestamp', this.get('actualPickTime'));

        if (arrive && depart) {
          messageText = 'Performed pick stop';
          tooltipText = 'Perform pick stop?';
        } else if (arrive) {
          messageText = 'Arrived pick stop';
          tooltipText = 'Arrive pick stop?';
        } else {
          this.set('errorText', 'Please select at least one option of arrive or pick up to perform the stop.');
          return false;
        }

        if (Ember.isEmpty(odometerInMiles) || odometerInMiles.length <= 0) {
          this.set('errorText', 'Odometer cannot be empty');
          return false;
        }

        if (arrive) {
          if (Ember.isEmpty(arriveTime)) {
            this.set('errorText', 'Please fill in a time for arrive time');
            return false;
          }
        }

        if (depart) {
          if (Ember.isEmpty(departTime)) {
            this.set('errorText', 'Please fill in a time for pick up time');
            return false;
          }
        }

        if (arriveTime.isBefore(actualStartTime) || departTime.isBefore(actualStartTime)) {
          this.set('errorText', "Performed Pick Stop Failed. The stop cannot be performed before the route's start time");
          return false;
        }

        if (arriveTime.isAfter(routeEndTime) || departTime.isAfter(routeEndTime)) {
          this.set('errorText', "Performed Pick Stop Failed. The stop cannot be performed after the route's end time.");
          return false;
        }

        if (arriveTime.isAfter(currentDateTime) || departTime.isAfter(currentDateTime)) {
          this.set('errorText', 'Performed Pick Stop Failed. The stop cannot be performed after the current time.');
          return false;
        }

        if (routeOrdinal > 0 && maxStopDepartTime) {
          if (arriveTime.isBefore(maxStopDepartTime.get('actualDepartTime')) || departTime.isBefore(maxStopDepartTime.get('actualDepartTime'))) {
            this.set('errorText', 'Performed Pick Stop Failed. The stop cannot be performed before the previous Actual drop time.');
            return false;
          }
        }

        tooltip.setProperties({
          tip: tooltipText,
          title,
          hasOverlay: true,
          primaryActionText: 'Confirm',
          primaryAction: () => {
            return this.get('saveTask').perform().then(() => {
              this.get('activeContext').get('refreshTableContent').perform('route');
              this.get('notifications').success(`${messageText} successful`);
            }).catch(response => {
              // clear odometer in stop point model
              stopPoint.set('odometer');
              const payloadMessage = Ember.get(response, 'payload.message') ? JSON.parse(Ember.get(response, 'payload.message')) : null;
              messageText = messageText + ' failed';

              if (response.status === 400) {
                if (payloadMessage.errorDetail.includes('cannot be in state arrived unless all previous stops are performed')) {
                  messageText = 'Performed Pick Stop Failed. You cannot perform this stop until previous stops are performed';
                }
              }

              this.get('notifications').warning(`${messageText}`);
            }).finally(() => {
              return this.onCloseClick();
            });
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return;
          }
        });
      }

    }
  });

  _exports.default = _default;
});