define("adept-iq/serializers/route-break", ["exports", "adept-iq/serializers/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    serialize()
    /* snapshot, options */
    {
      const json = this._super(...arguments);

      const {
        breakType,
        stopPoint
      } = json.data.relationships;
      breakType.data.type = 'breakTypeName';
      json.data.relationships.breakTypeName = breakType;
      delete json.data.relationships.breakType;

      if (!Ember.isNone(stopPoint)) {
        delete json.data.relationships.stopPoint;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.breakTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.breakType = {
          data: {
            type: 'break-type',
            id: resourceHash.attributes.breakTypeName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.breakTypeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});