define("adept-iq/serializers/route", ["exports", "adept-iq/serializers/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    attrs: {
      otp: {
        serialize: false
      },
      otpValue: {
        serialize: false
      },
      odometer: {
        serialize: false
      },
      actualStartTimestamp: {
        serialize: false
      },
      actualEndTimestamp: {
        serialize: false
      }
    },

    serialize()
    /*snapshot, options*/
    {
      const json = this._super(...arguments); // because we modify status coming in from route-exec-event
      // scheduling service only accepts normal or breakdown
      // we don't want to PATCH/PUT the status. This is controlled by
      // the service


      if (json.data.id && json.data.attributes) {
        delete json.data.attributes.status;
      } // affinites must be converted into numbers to be valid
      // safest conversion is using JSON.parsed for "[1,2,3]" -> [1,2,3]


      if (json.data.attributes.affinities) {
        json.data.attributes.affinities = JSON.parse(json.data.attributes.affinities);
      }

      if (json.data && json.data.relationships) {
        delete json.data.relationships.dispatchRoute;
        delete json.data.relationships.provider;
        delete json.data.relationships.etaRoute;
        delete json.data.relationships.navigationPolyline;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.providerName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.provider = {
          data: {
            type: 'provider',
            id: resourceHash.attributes.providerName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.providerName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});