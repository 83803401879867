define("adept-iq/pods/components/iq-widgets/stops-widget/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    component: 'iq-widgets/stops-widget',
    rowComponent: 'iq-widgets/stops-widget/stops-row',
    modelName: 'stop-point',
    defaultSortId: 'tripId',
    defaultSortAsc: false,
    title: 'Stops',
    columns: [{
      id: 'id',
      index: 18,
      type: 'uuid',
      label: 'ID',
      valuePath: 'id',
      valuePreview: '1',
      searchable: true,
      isMapLabelVisible: true,
      defaultWidth: 60,
      hidden: true
    }, {
      id: 'type',
      index: 0,
      type: 'text',
      label: 'Type',
      valuePath: 'stopType',
      valuePreview: 'Pick',
      searchable: true,
      isMapLabelVisible: true,
      hidden: true,
      defaultWidth: 50
    }, {
      id: 'tripId',
      index: 1,
      type: 'uuid',
      label: 'Trip ID',
      valuePath: 'trip.tripId',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'riderLastName',
      index: 2,
      type: 'text',
      label: 'Passenger Last Name',
      valuePath: 'trip.segment.leg.rider.lastName',
      searchable: true,
      defaultWidth: 75
    }, {
      id: 'riderFirstName',
      index: 3,
      type: 'text',
      label: 'Passenger First Name',
      valuePath: 'trip.segment.leg.rider.firstName',
      searchable: true,
      defaultWidth: 60
    }, {
      id: 'riderPhone',
      index: 16,
      type: 'enum',
      label: 'Passenger Phone Number',
      valuePath: 'trip.segment.leg.rider.formattedPhoneNumbers',
      defaultWidth: 60,
      hidden: true
    }, {
      id: 'riderId',
      index: 17,
      type: 'uuid',
      label: 'Passenger ID',
      valuePath: 'trip.segment.leg.rider.riderId',
      searchable: true,
      defaultWidth: 60,
      hidden: true
    }, {
      id: 'status',
      index: 7,
      type: 'text',
      label: 'Status',
      valuePath: 'computedStatus',
      valuePreview: 'Planned',
      editable: false,
      defaultWidth: 60,
      searchable: true,
      hidden: true
    }, {
      id: 'OTPstatus',
      index: 4,
      type: 'text',
      label: 'OTP Status',
      valuePath: 'otpLabel',
      defaultWidth: 40,
      highlightable: true,
      searchable: true
    }, {
      id: 'ETA',
      index: 5,
      type: 'date',
      label: 'ETA',
      valuePath: 'eta',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 40,
      highlightable: true
    }, {
      id: 'promisedTime',
      index: 13,
      type: 'datetime',
      label: 'Promise Time',
      valuePath: 'computedPromiseTime',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'requestedTime',
      index: 6,
      type: 'time',
      label: 'Request Time',
      valuePath: 'trip.segment.leg.requestTime',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'actualArrivalTime',
      index: 8,
      type: 'time',
      label: 'Actual Arrive Timestamp',
      valuePath: 'computedActualArriveTime',
      editable: true,
      hidden: true,
      format: 'MM/DD/YYYY HH:mm',
      defaultWidth: 150
    }, {
      id: 'actualDepartTime',
      index: 9,
      type: 'time',
      label: 'Actual Depart Timestamp',
      valuePath: 'computedActualDepartTime',
      editable: true,
      hidden: true,
      format: 'MM/DD/YYYY HH:mm',
      defaultWidth: 150
    }, {
      id: 'plannedDepartTime',
      index: 14,
      type: 'datetime',
      label: 'Planned Depart Time',
      valuePath: 'departTime',
      editable: true,
      format: 'MM/DD/YYYY HH:mm',
      defaultWidth: 130,
      hidden: true
    }, {
      id: 'routeName',
      index: 10,
      type: 'uuid',
      label: 'Route Name',
      valuePath: 'dispatchRoute.route.name',
      valuePreview: '40',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'notes',
      index: 25,
      type: 'text',
      label: 'Notes',
      valuePath: 'notes',
      editable: true,
      searchable: true,
      defaultWidth: 100,
      hidden: true
    }, {
      id: 'address',
      index: 11,
      type: 'text',
      label: 'Address ',
      valuePath: 'place.address.readableAddress',
      searchable: true,
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'date',
      index: 12,
      type: 'date',
      label: 'Date',
      valuePath: 'stopDate',
      format: 'MM/DD/YYYY',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'city',
      type: 'text',
      label: 'City',
      valuePath: 'place.address.locality',
      editable: true,
      searchable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'odometer',
      index: 26,
      type: 'number',
      label: 'Odometer on Arrival',
      valuePath: 'computedOdometer',
      editable: true,
      hidden: true
    }, {
      id: 'serviceWindow',
      index: 15,
      type: 'text',
      label: 'Service Window',
      valuePath: 'serviceWindow',
      editable: true,
      hidden: true,
      defaultWidth: 80
    }, {
      id: 'BreakStartTime',
      index: 19,
      type: 'time',
      label: 'Planned Break Start Time',
      valuePath: 'routeBreak.estimatedStart',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'BreakEndTime',
      index: 20,
      type: 'time',
      label: 'Planned Break End Time',
      valuePath: 'routeBreak.estimatedEnd',
      editable: true,
      format: 'HH:mm',
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'BreakType',
      index: 21,
      type: 'text',
      label: 'Break Type',
      valuePath: 'routeBreak.breakType.name',
      valuePreview: 'Floating',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'BreakCategory',
      index: 22,
      type: 'text',
      label: 'Break Category',
      valuePath: 'routeBreak.breakType.category',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'slackMinutes',
      index: 23,
      type: 'text',
      label: 'Slack Minutes',
      valuePath: 'slackMinutes',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'onboardCount',
      index: 24,
      type: 'number',
      label: 'Onboard Count',
      valuePath: 'onBoardCount',
      defaultWidth: 70,
      hidden: true
    }, {
      id: 'stopBreakOrd',
      type: 'number',
      label: 'Stop Break Ord',
      valuePath: 'routeOrdinal',
      searchable: false,
      editable: false,
      defaultWidth: 50,
      hidden: true
    }, {
      id: 'otp',
      index: 27,
      type: 'number',
      label: 'OTP',
      valuePath: 'otpValueWithSign',
      hidden: true,
      highlightable: true
    }, {
      id: 'arriveLat',
      index: 28,
      type: 'string',
      label: 'Actual Arrive Lat',
      valuePath: 'computedArriveLat',
      hidden: true,
      highlightable: false
    }, {
      id: 'arriveLng',
      index: 29,
      type: 'string',
      label: 'Actual Arrive Lng',
      valuePath: 'computedArriveLng',
      hidden: true,
      highlightable: false
    }, {
      id: 'departLat',
      index: 30,
      type: 'string',
      label: 'Actual Depart Lat',
      valuePath: 'computedDepartLat',
      hidden: true,
      highlightable: false
    }, {
      id: 'departLng',
      index: 31,
      type: 'string',
      label: 'Actual Depart Lng',
      valuePath: 'computedDepartLng',
      hidden: true,
      highlightable: false
    }]
  };
  _exports.default = _default;
});