define("adept-iq/serializers/segment", ["exports", "adept-iq/serializers/-bs-bookingService"], function (_exports, _bsBookingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsBookingService.default.extend({
    serialize()
    /*snapshot, options*/
    {
      const json = this._super(...arguments);

      const fareTypeRelationship = json.data.relationships.fareType || {};

      if (!Ember.isNone(fareTypeRelationship) && !Ember.isNone(fareTypeRelationship.data)) {
        fareTypeRelationship.data.type = 'fareTypeName';
        json.data.relationships.fareTypeName = json.data.relationships.fareType;
        delete json.data.relationships.fareType;
      }

      return json;
    },

    normalize(modelClass, resourceHash) {
      if (!Ember.isNone(resourceHash.attributes) && !Ember.isNone(resourceHash.attributes.fareTypeName)) {
        const relationships = resourceHash.relationships ? resourceHash.relationships : {};
        relationships.fareType = {
          data: {
            type: 'fare-type',
            id: resourceHash.attributes.fareTypeName
          }
        };
        resourceHash.relationships = relationships;
        delete resourceHash.attributes.fareTypeName;
      }

      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    }

  });

  _exports.default = _default;
});