define("adept-iq/models/route-break", ["exports", "ember-data", "moment", "adept-iq/utils/convertDistance", "lodash"], function (_exports, _emberData, _moment, _convertDistance, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OFF_BREAK_STOP_STATUS = _exports.ON_BREAK_STOP_STATUS = _exports.UNSCHEDULED_BREAK_STATUS = _exports.SCHEDULED_BREAK_STATUS = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const SCHEDULED_BREAK_STATUS = 'scheduled';
  _exports.SCHEDULED_BREAK_STATUS = SCHEDULED_BREAK_STATUS;
  const UNSCHEDULED_BREAK_STATUS = 'unscheduled';
  _exports.UNSCHEDULED_BREAK_STATUS = UNSCHEDULED_BREAK_STATUS;
  const ON_BREAK_STOP_STATUS = 'OnBreak';
  _exports.ON_BREAK_STOP_STATUS = ON_BREAK_STOP_STATUS;
  const OFF_BREAK_STOP_STATUS = 'OffBreak';
  _exports.OFF_BREAK_STOP_STATUS = OFF_BREAK_STOP_STATUS;

  var _default = Model.extend({
    state: attr('string'),
    plannedRouteOrdinal: attr('number'),
    requestTime: attr('date'),
    promisedStart: attr('date'),
    estimatedStart: attr('date'),
    estimatedEnd: attr('date'),
    plannedDuration: attr('string'),
    notes: attr('string'),
    // populated by route-exec-event
    arriveStatus: attr('string'),
    status: attr('string'),
    otp: attr('string'),
    otpValue: attr('number'),
    odometer: attr('string'),
    arriveLat: attr('string'),
    arriveLng: attr('string'),
    departLat: attr('string'),
    departLng: attr('string'),
    actualArriveTime: attr('date'),
    actualDepartTime: attr('date'),
    arrivePerformedBy: attr('string'),
    departPerformedBy: attr('string'),
    breakType: belongsTo('break-type'),
    route: belongsTo('route'),
    place: belongsTo('place'),
    stopPoint: belongsTo('stop-point'),
    etaNavRouteBreak: belongsTo('etanav-route-break'),
    projectedEndDate: Ember.computed('plannedDuration', 'requestTime', function () {
      const plannedDuration = this.get('plannedDuration') ? this.get('plannedDuration') : '0';
      const requestTime = this.get('requestTime');
      if (!requestTime) return;
      const requestTimeMoment = (0, _moment.default)(requestTime);
      const estimatedEndMoment = requestTimeMoment.clone();
      return estimatedEndMoment.add(parseInt(plannedDuration, 10), 'minutes').toDate();
    }),
    computedStatus: Ember.computed('status', 'arriveStatus', function () {
      const status = this.get('status');
      const arriveStatus = this.get('arriveStatus');

      if (status === OFF_BREAK_STOP_STATUS) {
        return status;
      } else if (!Ember.isEmpty(arriveStatus)) {
        return arriveStatus;
      } // otherwise, return scheduled/unscheduled


      return status;
    }),
    convertedOdometer: Ember.computed('odometer', function () {
      const odometer = this.get('odometer');

      if (odometer) {
        return (0, _convertDistance.convertMetersToMiles)(odometer);
      }

      return '';
    }),
    computedArrivePerformedBy: Ember.computed('arrivePerformedBy', function () {
      const arrivePerformedBy = this.get('arrivePerformedBy');
      return arrivePerformedBy ? `by ${(0, _lodash.upperCase)(arrivePerformedBy)}` : '';
    }),
    computedDepartPerformedBy: Ember.computed('departPerformedBy', function () {
      const departPerformedBy = this.get('departPerformedBy');
      return departPerformedBy ? `by ${(0, _lodash.upperCase)(departPerformedBy)}` : '';
    })
  });

  _exports.default = _default;
});