define("adept-iq/models/sso-role", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    roleName: attr('string'),
    name: attr('string'),
    displayName: attr('string'),
    status: attr('string'),
    permissions: attr(),
    permissionList: attr(),
    users: hasMany('sso-user'),
    groupedPermissions: Ember.computed('permissions', function () {
      const permissions = this.get('permissions');
      const groupedPermissions = (0, _lodash.groupBy)(permissions, 'category');
      return groupedPermissions;
    }),
    permissionHash: Ember.computed('permissionList', function () {
      const permissionList = this.get('permissionList');
      return permissionList.reduce((acu, perm) => {
        acu[perm] = true;
        return acu;
      }, {});
    })
  });

  _exports.default = _default;
});