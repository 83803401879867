define("adept-iq/workers/socket", ["adept-iq/topic-transforms/avlm-alert", "adept-iq/topic-transforms/avlm-message", "adept-iq/topic-transforms/avlm-route-exec-event", "adept-iq/topic-transforms/driver", "adept-iq/topic-transforms/eta-route", "adept-iq/topic-transforms/lock", "adept-iq/topic-transforms/polyline", "adept-iq/topic-transforms/rider", "adept-iq/topic-transforms/subscription", "adept-iq/topic-transforms/trip", "adept-iq/topic-transforms/vehicle", "adept-iq/topic-transforms/stop-point", "adept-iq/topic-transforms/user", "adept-iq/topic-transforms/avlm-rider-event", "adept-iq/topic-transforms/avlm-route", "adept-iq/topic-transforms/avlm-trip", "adept-iq/topic-transforms/avlm-stop-point", "adept-iq/topic-transforms/avlm-trip-rider", "adept-iq/topic-transforms/avlm-route-break"], function (_avlmAlert, _avlmMessage, _avlmRouteExecEvent, _driver, _etaRoute, _lock, _polyline, _rider, _subscription, _trip, _vehicle, _stopPoint, _user, _avlmRiderEvent, _avlmRoute, _avlmTrip, _avlmStopPoint, _avlmTripRider, _avlmRouteBreak) {
  "use strict";

  /* global postMessage */

  /* CORE + Required AVLM Topic Transforms */

  /* AVLM Topic Transforms */
  // worker will throw an error if it encounters any model type that isn't
  // registered here; set to `null` if no transform required
  const TRANSFORMS = {
    'address': null,
    'avl': null,
    'avlm-address': null,
    'avlm-alert': _avlmAlert.default,
    'avlm-canned-message-template': null,
    'avlm-cluster': null,
    'avlm-driver': null,
    'avlm-driver-state-event': null,
    'avlm-message': _avlmMessage.default,
    'avlm-rider': null,
    'avlm-rider-event': _avlmRiderEvent.default,
    'avlm-route': _avlmRoute.default,
    'avlm-route-exec-event': _avlmRouteExecEvent.default,
    'avlm-route-update': null,
    'avlm-trip': _avlmTrip.default,
    'avlm-trip-payment': null,
    'avlm-trip-rider': _avlmTripRider.default,
    'avlm-trip-rider-attribute': null,
    'avlm-schedule': null,
    'avlm-route-break': _avlmRouteBreak.default,
    'avlm-route-vehicle-driver': null,
    'avlm-stop-point': _avlmStopPoint.default,
    'avlm-vehicle': null,
    'avlm-vehicle-info': null,
    'avlm-zone': null,
    'booking': null,
    'cluster': null,
    'dispatch-route': null,
    'dispatch-schedule': null,
    'driver': _driver.default,
    'etanav-route': _etaRoute.default,
    'stop-point': _stopPoint.default,
    'leg': null,
    'location': null,
    'lock': _lock.default,
    'no-show': null,
    'place': null,
    'polyline': _polyline.default,
    'rider': _rider.default,
    'route': null,
    'route-vehicle-driver': null,
    'route-break': null,
    'segment': null,
    'segment-stop': null,
    'schedule': null,
    'subscription': _subscription.default,
    'trip': _trip.default,
    'trip-route-assignment': null,
    'trip-stop': null,
    'vehicle': _vehicle.default,
    'vehicle-breakdown': null,
    'user': _user.default,
    'zone': null
  };
  const FLUSH_INTERVAL = 500;
  let mainQueue = {};
  let avlQueue = {};
  let lockQueue = {};
  const sockets = {};
  let ENV;

  function dasherize(str) {
    return str.replace(/([A-Z])/g, $1 => '-' + $1.toLowerCase());
  }

  function setENV(env) {
    ENV = env;
  }

  function handlePayloadData(data) {
    let payloads;

    try {
      payloads = JSON.parse(data);
    } catch (err) {
      throw new Error(`bad topic json data: ${data}`);
    }

    if (!payloads) return []; // convert to array

    payloads = Array.from(payloads); // remove any avlmEvent. IQUX does not need this topic.

    payloads = payloads.filter(payload => {
      return payload.model !== 'avlmEvent';
    });
    payloads.forEach(payload => {
      switch (payload.action) {
        case 'UPDATE':
          {
            const transformKey = dasherize(payload.model);

            if (!TRANSFORMS.hasOwnProperty(transformKey)) {
              console.warn(`no transform for topic model '${payload.model}'`); // eslint-disable-line no-console
            }

            const transform = TRANSFORMS[transformKey];
            const transformed = transform ? transform(payload.message, ENV) : payload.message; // replace the `message` property with array`messages`

            if (!transformed) {
              payload.transformedPayloads = [];
            } else if (!Array.isArray(transformed)) {
              payload.transformedPayloads = [transformed];
            } else {
              payload.transformedPayloads = transformed;
            }

            break;
          }

        case 'INIT':
        case 'DELETE':
        default:
          break;
      }
    }, []);
    return payloads;
  }

  function sendFilter(socketId, startDate, endDate) {
    const socket = sockets[socketId];
    sockets[socketId] = socket;
    const request = {
      'filters': {
        'widgets': 'All',
        'startTime': startDate,
        'endTime': endDate
      }
    };
    sockets[socketId].send(JSON.stringify(request));
  }

  function connectSocket(socketId, url, token, startDate, endDate) {
    if (sockets[socketId]) {
      throw new Error(`socketId '${socketId}' already used`);
    }

    const socket = new WebSocket(url, [token]);
    sockets[socketId] = socket;

    socket.onopen = () =>
    /* e */
    {
      if (!sockets[socketId]) {
        // this socket has been removed
        socket.close();
        return;
      } // send filter or we won't get any data


      sendFilter(socketId, startDate, endDate);
      postMessage({
        action: 'open',
        socketId
      });
    };

    socket.onmessage = e => {
      if (!sockets[socketId]) return; // parse & apply topic transforms

      const payloads = handlePayloadData(e.data);
      payloads.forEach(payload => {
        if (!payload.message) {
          mainQueue[socketId] = mainQueue[socketId] || [];
          mainQueue[socketId].push(payload);
          return;
        }
        /* eslint-disable indent */


        if (payload.action !== 'DELETE' && payload.message && payload.message.data && payload.message.data.type) {
          switch (payload.message.data.type) {
            case 'avl':
              {
                const {
                  id
                } = payload.message.data.relationships.vehicle.data;
                avlQueue[socketId] = avlQueue[socketId] || {};
                avlQueue[socketId][id] = payload;
                break;
              }

            case 'lock':
              {
                const {
                  id
                } = payload.message.data.relationships.dispatchRoute.data;
                lockQueue[socketId] = lockQueue[socketId] || {};
                lockQueue[socketId][id] = payload;
                break;
              }

            default:
              {
                mainQueue[socketId] = mainQueue[socketId] || [];
                mainQueue[socketId].push(payload);
                break;
              }
          }
        } else {
          mainQueue[socketId] = mainQueue[socketId] || [];
          mainQueue[socketId].push(payload);
        }
        /* eslint-enable indent */

      });
    };

    socket.onerror = e => {
      if (!sockets[socketId]) return;
      postMessage({
        action: 'error',
        code: e.code,
        socketId
      });
    };

    socket.onclose = e => {
      // clear out handlers
      socket.onopen = null;
      socket.onmessage = null;
      socket.onerror = null;
      socket.onclose = null;
      postMessage({
        action: 'close',
        code: e.code,
        socketId
      });
    };
  }

  function disconnectSocket(socketId) {
    const socket = sockets[socketId]; // socket was probably already terminated

    if (!socket) return;
    socket.close();
    delete sockets[socketId];
  }

  self.addEventListener('message', function (e) {
    const {
      action,
      socketId,
      startDate,
      endDate
    } = e.data;

    switch (action) {
      case 'connect':
        return connectSocket(socketId, e.data.url, e.data.token, startDate, endDate);

      case 'disconnect':
        return disconnectSocket(socketId);

      case 'setEnv':
        return setENV(e.data.ENV);

      case 'sendFilter':
        return sendFilter(socketId, startDate, endDate);

      default:
        break;
    }
  }, false);
  setInterval(() => {
    Object.entries(mainQueue).forEach(([socketId, payloads]) => {
      postMessage({
        action: 'data',
        socketId,
        payloads
      });
    });
    mainQueue = {};
  }, FLUSH_INTERVAL); // stagger avl flushes between main flushes

  setTimeout(() => {
    setInterval(() => {
      Object.entries(avlQueue).forEach(([socketId, queueHash]) => {
        const payloads = Object.values(queueHash);
        postMessage({
          action: 'data',
          socketId,
          payloads
        });
      });
      avlQueue = {};
    }, FLUSH_INTERVAL * 2);
  }, FLUSH_INTERVAL / 2); // stagger lock flushes between the other main flushes

  setTimeout(() => {
    setInterval(() => {
      Object.entries(lockQueue).forEach(([socketId, queueHash]) => {
        const payloads = Object.values(queueHash);
        postMessage({
          action: 'data',
          socketId,
          payloads
        });
      });
      lockQueue = {};
    }, FLUSH_INTERVAL * 2);
  }, 3 * FLUSH_INTERVAL / 2);
});